import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig, StatusBarService } from '../../fw/services/status-bar.service';
import { LoginService } from '../services/login.service';
import { FormControl, Validators, FormGroup, AbstractControl, ValidatorFn, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService } from '../cart/cart.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SESSION_AUTHENTICATED_CUSTOMER, DEF_DATE_FORMAT, SESSION_LOGIN_PROVIDER, REGISTERED_LOGIN } from '../app.constants';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { GtmService } from '../gtm.service';
export interface Title {
  value: string;
  display: string;
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: DEF_DATE_FORMAT },
  ]
})
export class SignUpComponent implements OnInit {

  validPassword: boolean;
  validEmail: boolean;
  formControl: any;
  loading: boolean;
  invalidMessage: string;
  passwordConfirm: any;
  password: any;
  email: any;
  phoneNumber: any;
  dob: any;
  gender: String;
  postCode: any;
  country: any;
  state: any;
  city: any;
  address: any;
  lastName: any;
  firstName: any;
  today: any;
  // title: String = 'Mr.';
  userId: any;
  // hide = true;

  /* Group for password */
  pwGroup: FormGroup;
  redirectUrl: any;
  termsCondtns: any;
  subscription: any;
  captcha: any;
  siteKey = environment.captchaKey;
  titles: Title[] = [
    // {value:'',display:'Select one'},
    { value: 'Mr.', display: 'Mr.' },
    { value: 'Ms.', display: 'Ms.' },
    { value: 'Mrs.', display: 'Mrs.' }
  ];
  // selectedTitle: any =  this.titles[1].value;
  selectedTitle: any;
  /* Compare the passwords and set the error */
  matchPassword(pwGroup: AbstractControl) {
    const password = pwGroup.get('password').value;
    const confirmPassword = pwGroup.get('passwordConfirm').value;
    if (password !== confirmPassword) {
   //   console.log('false');
      pwGroup.get('passwordConfirm').setErrors({ mismatch: true });
    } else {
    //  console.log('true');
      return null;
    }
  }


  constructor(private router: Router, private fb: FormBuilder, private utilService: UtilService, private loginService: LoginService,
    private statusBarService: StatusBarService, private activatedRoute: ActivatedRoute, private cartService: CartService,
    @Inject(SESSION_STORAGE) private storage: WebStorageService, private datePipe: DatePipe,
    private gtmService:GtmService) {
    this.loading = false;
    this.initTitleBarParams();
    this.redirectUrl = this.activatedRoute.snapshot.queryParams['redirectUrl'];
    this.siteKey=environment.captchaKey;
    /* Form validators */
    this.formControl = {
      email: new FormControl('', [Validators.required, Validators.email]),
      firstName: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(9)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(6)]),
      dob: new FormControl(_moment()),
      termsCondtns: new FormControl('', [Validators.required]),
      captcha: new FormControl('', [Validators.required]),
    };
  }

  ngOnInit() {
    this.today = new Date();
  }
  resolved(captchaResponse: string) {
    
  }
  /* Returns email validation errors */
  getEmailError(): any {

    return this.formControl.email.hasError('required') ? 'Please enter a valid email address.' :
      this.formControl.email.hasError('email') ? 'It is not a valid email address.' :
        '';
  }

  /* Returns password validation errors */
  getPasswordError(): any {
    let error: any;
    if (this.formControl.password.hasError('required')) {
      // error = 'Password contains at least six characters, one number, one special character, one lowercase and one uppercase letter';
      error = 'Password should contain at least six characters.';
    } else if (this.formControl.password.hasError('minlength')) {
      error = 'Password should contain at least six characters.';
    }
    return error;
  }

  getFirstNameError(): any {
    let error: any;
    if (this.formControl.firstName.hasError('required')) {
      error = 'Please enter your name';
    } else if (!this.formControl.firstName.touched) {
      error = 'Please enter your name';
    }
    return error;
  }
  getPhoneNumberError(): any {
    let error: any;
    if (this.formControl.phoneNumber.hasError('required')) {
      error = 'Please enter a valid phone number.';
    } else if
      (this.formControl.phoneNumber.errors.maxlength !== undefined &&
      (this.formControl.phoneNumber.errors.maxlength.actualLength) > (this.formControl.phoneNumber.errors.maxlength.requiredLength)) {
      error = 'Phone number with in 13 characters long.';
    } else if (this.formControl.phoneNumber.errors.minlength !== undefined &&
      (this.formControl.phoneNumber.errors.minlength.actualLength) < (this.formControl.phoneNumber.errors.minlength.requiredLength)) {
      error = 'Phone number should be at least 9 characters long.';
    }
    return error;
  }

  /* Returns confirm password validation errors */
  getPasswordConfirmError(): any {
    let error: any;
    if (this.formControl.passwordConfirm.hasError('required')) {
      // error = 'Password contains at least six characters, one number, one special character, one lowercase and one uppercase letter';
      error = 'Password should contain at least six characters.';
    } else if (this.formControl.passwordConfirm.hasError('minlength')) {
      error = 'Password should contain at least six characters.';
    } else if (this.formControl.password.value !== this.formControl.passwordConfirm.value) {
      error = 'Passwords does not match.';
    }
    return error;
  }

  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = true;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'SIGN UP',
      showLogo: false,
      showShoppingCart: false,
      showLogin:false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showSignUpBar: true
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }

  isFormValid(): boolean {
    const form = document.getElementById('signUpForm');
    return (form[0].checkValidity());
  }
  redirect() {
    // if (this.redirectUrl !== undefined && this.redirectUrl === '/order-confirmation') {
      if (this.redirectUrl !== undefined) {
      this.router.navigate([this.redirectUrl]);
      // this.router.navigate(['/login'], { queryParams: { 'redirectUrl': this.redirectUrl } })
    } else {
      this.router.navigate(['/order']);
    }
  }

  clearFunction() {
    this.invalidMessage = '';
  }
  /**
   *
   * @param email email validation
   */
  emailValidation(email) {
    this.validEmail = false;
    // tslint:disable-next-line:max-line-length
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validEmailRegEx.test(email)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    return this.validEmail;
  }
  /**
   *
   * @param str password validation
   * at least one number, one lowercase and one uppercase letter
   * at least six characters
   */
  checkPassword(str) {
    this.validPassword = false;
    // const  re = /(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}/;
    if (str.length >= 6) {
      this.validPassword = true;
    } else {
      this.validPassword = false;
    }
    // if (re.test(str)) {
    //   this.validPassword =  true;
    // } else {
    //   this.validPassword =  false;
    // }
    return this.validPassword;
  }

  signUpUser() {
    const responsez = grecaptcha.getResponse();
    if (responsez !== "") {
      if (this.isFormValid()) {
        if (this.password !== this.passwordConfirm) {
          this.invalidMessage = 'Entered Password does not match';
        } else {
          const userRegistration = {
            'loginId': this.userId, 'title': this.selectedTitle, 'firstName': this.firstName, 'lastName': this.lastName,
            'address': this.address, 'city': this.city, 'state': this.state, 'country': this.country, 'postCode': this.postCode,
            'gender': this.gender,
            'dob': this.datePipe.transform(this.dob, 'yyyy/MM/dd'),
            'phoneNumber': this.phoneNumber, 'email': this.userId,
            'password': this.password, 'subscription': (this.subscription) ? 1 : 0,
            'termsCondtns': this.termsCondtns
          };

          if (userRegistration.termsCondtns !== undefined && userRegistration.termsCondtns !== false) {
            if (userRegistration.firstName !== undefined && userRegistration.email !== undefined && userRegistration.firstName !== '' &&
              userRegistration.email !== '' && userRegistration.password !== '' && userRegistration.phoneNumber !== '' &&
              userRegistration.firstName !== null &&
              userRegistration.email !== null && userRegistration.password !== null && userRegistration.phoneNumber !== null &&
              userRegistration.password !== undefined && userRegistration.phoneNumber !== undefined) {
              if (this.emailValidation(userRegistration.email)) {
                if (this.checkPassword(userRegistration.password)) {
                  if (this.formControl.phoneNumber.errors !== null &&
                    ((this.formControl.phoneNumber.errors.minlength.actualLength) <
                      (this.formControl.phoneNumber.errors.minlength.requiredLength))) {
                    this.utilService.openDialog('Customer Info.', 'Phone number should be at least 9 characters long.');
                  } else if (this.phoneNumber <= 0) {
                    this.utilService.openDialog('Customer Info.', 'Enter a valid phone number');
                  } else {
                    this.utilService.progressService.isLoading = true;
                    this.loginService.registerCustomer(userRegistration).subscribe(
                      res => {
                        this.utilService.progressService.isLoading = false;
                        if (res.success) {
                          this.cartService.setCustomer(res.object);
                          this.storage.set(SESSION_LOGIN_PROVIDER, REGISTERED_LOGIN);
                          this.storage.set(SESSION_AUTHENTICATED_CUSTOMER, res.object);
                          /**
                           * GTM Code
                           */
                          this.gtmService.pushEvent(
                            'account_creation',
                            {
                              userId: this.gtmService.getCustomer(),
                            });
                          this.utilService.openDialog('Sign up', res.message);
                          this.redirect();
                        } else {
                          this.utilService.openDialog('Sign up', res.message);
                        }
                      }
                    );
                  }
                } else {
                  // this.utilService.openDialog('Customer Info.',
                  // 'Password contains at least six characters, one number, one special character, one lowercase and one uppercase letter');
                  this.utilService.openDialog('Customer Info.',
                    'Password should contain at least six characters.');
                }
              } else {
                this.utilService.openDialog('Customer Info.', 'enter valid Email Address of the form a@gmail.com');
              }
            } else {
              this.utilService.openDialog('Failed', 'Failed to sign up. You did not fill out the required fields.');
            }
          } else {
            this.utilService.openDialog('Failed', 'Please accept the terms and conditions.');
          }
        }
      }
      // alert(responsez);
    } else {
      this.utilService.openDialog('SORRY',
        'You didn\'t checked the captcha correctly. Please select the captcha.');
    }
  }
}
