import { Component, OnInit } from '@angular/core';
import { StatusBarService } from '../services/status-bar.service';
import { Router } from '@angular/router';
import { CartService } from '../../app/cart/cart.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.css']
})
export class StatusBarComponent implements OnInit {

  constructor(public statusBarService: StatusBarService, public cartService: CartService,
              private router: Router) { }

  ngOnInit() {
  }

  /**Navigate to shop selection screen on 'Continue Order' button click */
  continueOrder() {
    // if (this.cartService.cart.customer !== undefined) {
    //   this.router.navigate(['/guest-form'], { queryParams: { 'redirectUrl': '/order-confirmation' } });
    // } else {
   //   this.router.navigate(['/storefinder']);
      // this.router.navigate(['/guest-form']);
        this.statusBarService.parent.continueAsGuest();
    // }

  }

  getButtonCaption(btnId: any): String{
    return this.statusBarService.parent.getStatusBarButtonCaption(btnId);
  }

  /**Navigate to item category list page on 'Proceed' button click */
   proceedDateSelection() {
    //  if (this.statusBarService.parent.addDateTimeToCart()) {
    // this.router.navigate(['/category-list']);
    this.statusBarService.parent.addDateTimeToCart();
    //  }
   }
   addToCart() {
     this.statusBarService.parent.addToCart();
   }
   updateCartItem() {
     this.statusBarService.parent.updateCart();
   }
   redirectToMenu() {
    this.router.navigate(['/category-list']);
   }
   myOrderClick() {

   }
   orderDetails() {
    this.router.navigate(['/cart']);
   }
   orderConfirmation() {
     // validate token expiery
   this.statusBarService.parent.proceed();
   }
   back() {
    this.router.navigate(['/cart']);

   }
   retry() {
    this.router.navigate(['/order-confirmation']);

   }

  proceedToHome() {
    this.router.navigate(['/order']);
  }
  download() {
    this.statusBarService.parent.downloadReceipt();
  }
}
