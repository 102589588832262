import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../../app/cart/cart.service';
import { StatusBarService } from '../../services/status-bar.service';

@Component({
  selector: 'app-cart-bottom-nav',
  templateUrl: './cart-bottom-nav.component.html',
  styleUrls: ['./cart-bottom-nav.component.css']
})
export class CartBottomNavComponent implements OnInit {

  constructor(private router: Router, private cartService: CartService, private statusBarService: StatusBarService) { }

  ngOnInit() {
  }
  goToMenu() {
    this.router.navigate(['/category-list']);
  }
  proceed() {
    this.statusBarService.parent.proceed();
  }
}
