import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SESSION_SYSTEM_SETTINGS, SESSION_SALE_ITEM_LIST, PRODUCT_IMAGE_FOLDER } from '../app.constants';
import { UUID } from 'angular2-uuid';
import { CartService, CartItem } from '../cart/cart.service';
import { Router } from '@angular/router';
import { StatusBarService } from '../../fw/services/status-bar.service';
import { AnimationService } from '../services/animation.service';
import { SettingsService } from '../settings/settings.service';
import { ItemListService } from '../item-list/item-list.service';
import { OrderConfirmationService } from '../order-confirmation/order-confirmation.service';
import { UtilService } from '../services/util.service';
import { ConfirmationService } from 'primeng/api';
import { StartupService } from '../services/startup.service';
import { GtmService } from '../gtm.service';


@Component({
  selector: 'app-cart-panel',
  templateUrl: './cart-panel.component.html',
  styleUrls: ['./cart-panel.component.css']
})
export class CartPanelComponent implements OnInit {
  itemHasError: any;
  validItem: any;
  currencySymbol: any;
  saleItemInfo: any;
  allSaleItemList: any[];
  systemSettings: any;
  imageUrl: string;
  uploadUrl: any;
  customizations:String;
  @Input() cartItem: any;
  @Output() deleteItemClickEvent: EventEmitter<any> = new EventEmitter<any>();
  showCustomizationInCart: any;
  useToggleViewInCartCustomization=false;
  isShow: boolean;
  isCustOptInOutOfStock=false;
  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService,
    private orderConfirmationService: OrderConfirmationService, private confirmationService: ConfirmationService,
    public cartService: CartService, private router: Router, private statusBarService: StatusBarService, private utilService: UtilService,
    private animService: AnimationService, private _settingsService: SettingsService, private itemListService: ItemListService,
    private startUp: StartupService,
    private gtmService:GtmService) {
    
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.imageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;

    this.systemSettings = this._settingsService.systemSettings;
    // this.showCustomizationInCart= (this.startUp.startupData.showCustomizationInCart && (this.cartItem.isCustomized || this.cartItem.remarks !== null));
    // this.useToggleViewInCartCustomization= this.startUp.startupData.useToggleViewInCartCustomization;
    // this.isShow=(this.showCustomizationInCart && !this.useToggleViewInCartCustomization);
    this.allSaleItemList = this.sessionStorage.get(SESSION_SALE_ITEM_LIST);

    this.getSaleItem();
    if (this.systemSettings !== null) {
      this.currencySymbol = this.systemSettings.currency.symbol;
    }


  }

  ngOnInit() {

    if (this.orderConfirmationService.errorJsonArrayOfCart !== null &&
      this.orderConfirmationService.errorJsonArrayOfCart !== undefined) {
      const error = this.orderConfirmationService.errorJsonArrayOfCart;

      this.validItem = error.filter(e => e.type === 'item');
      if (this.validItem !== null && this.validItem !== undefined) {
        this.itemHasError = this.validItem.find(e => e.id === this.cartItem.itemId);
      }
    }
    this.saleItemInfo = this.allSaleItemList.find(asl => asl.id === this.cartItem.ooSaleItemId);
    // this.isCustOptInOutOfStock = (!this.cartService.isitemAndCustOptInValid(this.cartItem) && this.cartItem.hasWarning);
    this.showCustomizationInCart= (this.startUp.startupData.showCustomizationInCart && (this.cartItem.isCustomized || (this.cartItem.remarks !== undefined && this.cartItem.remarks !== null && this.cartItem.remarks.toString().trim().length > 0)));
    this.useToggleViewInCartCustomization=true;
    this.isShow=(this.showCustomizationInCart );
    var options=this.cartService.getSelectedCustomizationOptionValueData(this.cartItem);
    this.customizations="";
    if (options) {
      for (var opt of options) {
        this.customizations += opt.nameToPrint + ", ";
      }
    }
    // this.cartItem.hasError=true;
    // this.cartItem.hasWarning=true;
  }
  /**
   *
   * @param cartItem
   * when clicking the plus sign on each cart,
   * the cart item is incremented to one
   */
  incrementQuantity(cartItem) {
    this.animService.itemAddedToCart('checkout-btn');
    cartItem.quantity = +cartItem.quantity + 1;
    this.cartService.setCustomization(cartItem);
    this.cartService.changeItem(cartItem);
  }

  showError() {
    this.utilService.openDialog('Sorry',this.cartItem.errorMessage);
   }

   showWarning(){
    this.utilService.openDialog('Warning',this.cartItem.warningMessage);
   }
  /**
     * gettting  all sale items
     */
  getSaleItem() {
    this.allSaleItemList = this.sessionStorage.get(SESSION_SALE_ITEM_LIST);
    if (this.allSaleItemList === undefined || this.allSaleItemList === null) {
      this.itemListService.loadSaleItem().subscribe(
        res => {
          this.allSaleItemList = res.object;
          this.sessionStorage.set(SESSION_SALE_ITEM_LIST, this.allSaleItemList);
          this.saleItemInfo = this.allSaleItemList.find(asl => asl.id === this.cartItem.ooSaleItemId);
        });
    }
    
  }
 
  /**
   *
   * @param cartItem
   *  when clicking the delete button on the cart,
   * that item is deleted from the cart
   */
  deleteItemFromCart(cartItem) {
    this.animService.removeFromCart('checkout-btn');
    if (+cartItem.quantity > 1) {
      cartItem.quantity = +cartItem.quantity - 1;
      this.cartService.setCustomization(cartItem);
      this.cartService.changeItem(cartItem);
    } else {
      // this.updateGTMInfo('remove_from_cart');
      this.deleteItemClickEvent.emit(this.cartItem);
      
    }
  }

  deleteAllItemFromCart(cartItem) {
    this.animService.removeFromCart('checkout-btn');
    this.cartService.deleteItem(cartItem);
  }

  /**
   * @param cartItem
   * when the item quantity is either increment or decrement,
   * it will reflect on the total price of the cart
   */
  getItemTotalPrice(cartItem) {
    const total = this.cartItem.quantity * (this.cartItem.unitPriceWithTax);
    const custTotal= this.cartItem.quantity * (this.cartItem.customizationUnitPriceWithTax!==undefined?this.cartItem.customizationUnitPriceWithTax:0);
    cartItem.netCustomizationTotal=custTotal;
    cartItem.totalPrice = total;
    this.cartService.changeItem(cartItem);
    return cartItem.netTotal+cartItem.netCustomizationTotal;
  }

  /**
   * @param cartItem
   * when click on the edit icon
   * the page will redirect to the cart detail page
   */
  editItemDetail(cartItem) {
    if(this.saleItemInfo !== null &&  this.saleItemInfo !== undefined && cartItem.hasError !== true){
      this.router.navigate(['/item-details'], { queryParams: { 'source': 'cart', 'id': cartItem.itemId } });
    }
  }

  customizationInfo(cartItem){
    // if ((cartItem.customizations !== null) && cartItem.isCustomized) {
      this.isShow = !this.isShow
    // }
  }

  /**
   * @param cartItem
   * when click on the cart clone icon
   * the exact same copy of the item is generated
   */
  cloneCartItem(cartItem) {

    this.animService.itemAddedToCart('checkout-btn');
    const item = <CartItem>{};
    Object.assign(item, cartItem);
    this.cartService.addItemAfter(cartItem, item);
  }

  

  


}
