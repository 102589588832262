import { Injectable, Inject } from '@angular/core';
import { Observable, throwError, from } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../services/startup.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import {
  SESSION_SALE_ITEM_LIST, SESSION_ITEM_CATEGORY_LIST, SESSION_SELECTED_SHOP,
  SESSION_SHOP_LIST, SESSION_FILTERED_SALE_ITEM_LIST, SESSION_FILTERED_CATEGORY_ITEM_LIST, SESSION_FILTERED_SUB_CATEGORY_ITEM_LIST
} from '../app.constants';
import { CartService, Cart } from '../cart/cart.service';
import { TimeService } from '../services/time-service.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ItemListService {
  selectedBusType: any;
  selectedDeliveryDate: any;
  // daY: any;
  // itemCategories: Array<any>;
  visibleCategory: any;
  saleItemUrl: string;
  /* Variables for prev and next buttons on Nav */
  currentElement: any;
  lastElementId: any;
  lastchild: any;
  firstElementId: any;
  firstchild: any;
  nextbtnposn: any;
  Elementrect: any;
  prevbtnposn: any;
  prevElementrect: any;
  prebtnposn: any;
  prebtnposnpos: any;
  filteredSaleitemList = [];
  filteredCategoryitemList = [];

  private _saleItemList: any;
  cart: Cart;
  saleItemUrlExt: string;
  promotionalItemUrl: string;
  selectedTimeValidityUrl: string;
  customizationOptDtlUrl:string;

  get saleItemList(): any {
    this._saleItemList = this.sessionStorage.get(SESSION_SALE_ITEM_LIST);
    if (this._saleItemList === null || this._saleItemList === undefined) {
      this.loadSaleItem().subscribe(
        res => {
          this._saleItemList = res.object;
          this.sessionStorage.set(SESSION_SALE_ITEM_LIST, this._saleItemList);

        });
    }
    return this._saleItemList;
  }

  constructor(private http: HttpClient, private startUp: StartupService,
    private cartService: CartService, private datePipe: DatePipe,
    private router: Router,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService, private timeService: TimeService) {
    this.saleItemUrl = this.startUp.startupData.apiurl + '/saleItem/get-all';
    this.saleItemUrlExt = this.startUp.startupData.apiurl + '/saleItem/get-all-ext';
    this.promotionalItemUrl = this.startUp.startupData.apiurl + '/order-info/getPromotionalSaleItemInfo';
    this.selectedTimeValidityUrl = this.startUp.startupData.apiurl + '/shop/selectedTimeValidity/';
    this.customizationOptDtlUrl = this.startUp.startupData.apiurl + '/customization-info/getCustomizationOptionDtl/'
    this.cart = this.cartService.cart;

  }
  /**
   * get all sale item
   */
  loadSaleItem(): Observable<any> {
    return this.http.get<any>(this.saleItemUrl)
      .pipe(
      );
  }

  
  loadCustomizationOptionDtl(): Observable<any> {
    return this.http.get<any>(this.customizationOptDtlUrl)
      .pipe(

      );
  }

  loadSaleItemExt(businessTypeId, pickUpDate, shop): Observable<any> {
     const datas = {
      'ooBusinessTypeId': businessTypeId,
      'departmentIds': shop.department.join(","),
      'pickUpDate': pickUpDate,
      'shopId': shop.id
    };
    return this.http.post<any>(this.saleItemUrlExt, datas)
      .pipe(

      );
  }
  getButtonPosition() {
    this.currentElement = document.getElementById('pnProductNavContents');
    this.lastElementId = this.currentElement.lastElementChild.id;
    this.lastchild = document.getElementById(this.lastElementId).getBoundingClientRect();
    this.firstElementId = this.currentElement.firstElementChild.id;
    this.firstchild = document.getElementById(this.firstElementId).getBoundingClientRect();
    this.nextbtnposn = document.getElementById('next-menu-btn');
    this.Elementrect = this.nextbtnposn.getBoundingClientRect();
    this.prevbtnposn = document.getElementById('previous-menu-btn');
    this.prevElementrect = this.prevbtnposn.getBoundingClientRect();
    this.prebtnposn = document.getElementById('previous-menu-btn');
    this.prebtnposnpos = this.prebtnposn.getBoundingClientRect();
  }
  // getSaleItem() {
  //   this.saleItemList = this.sessionStorage.get(SESSION_SALE_ITEM_LIST);
  //     if (this.saleItemList === undefined || this.saleItemList === null ) {
  //       this.loadSaleItem().subscribe(
  //       res => {
  //         this.saleItemList = res.object;
  //         this.sessionStorage.set(SESSION_SALE_ITEM_LIST, this.saleItemList);
  //       });
  //     }
  // }
  /*****Filter saleitem list : may 23, 2019****/
  getFilteredSaleitemlist(saleItemList) {
    // const saleItemList = this.sessionStorage.get(SESSION_SALE_ITEM_LIST);
    // const len = saleItemList.length;
    this.filteredSaleitemList = [];
    for (const saleItem of saleItemList) {
      const saleItemId = saleItem.id;
      const salaitemActiveStatus = this.isSaleItemActive(saleItem);
      if (salaitemActiveStatus === true) {
        // checking duplicates and push unique array elements
        // const filteredSaleitemListId = this.filteredSaleitemList.find(ic => ic.id === saleItemId);
        // if (filteredSaleitemListId === null || filteredSaleitemListId === undefined) {
        this.filteredSaleitemList.push(saleItem);
        // }
      }
    }

    return this.filteredSaleitemList;
  }
  /*****Filter category list : may 23, 2019****/
  getFilteredItemCategoryList(categoryList, salelist) {
    this.filteredCategoryitemList = [];
    let superCategoryitemList = [];
    // const categoryList = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST);
    // const salelist = this.sessionStorage.get(SESSION_FILTERED_SALE_ITEM_LIST);
    for (const category of categoryList) {
      // const subCategories = category.subItemCategoryIds;
      if (category.parentId !== 0) {
        if (category.saleItemIds !== null &&
          category.saleItemIds !== undefined && category.saleItemIds.length > 0) {
          for (const saleId of category.saleItemIds) {
            const filteredCategoryitemListId = salelist.find(ic => ic.id === saleId);
            // avoid duplicates to the array
            // const checkFiltercategory = this.filteredCategoryitemList.find(ic => ic.id === saleId);
            // if (filteredCategoryitemListId !== null && filteredCategoryitemListId !== undefined
            //   && (checkFiltercategory === null || checkFiltercategory === undefined)) {
            if (filteredCategoryitemListId !== null && filteredCategoryitemListId !== undefined) {
              // push these data array to the  new array...
              this.filteredCategoryitemList.push(category);
              break;
            }
          }
        }
      } else {
        superCategoryitemList.push(category);
      }
    }

    for (const category of superCategoryitemList) {
      if (category.subItemCategoryIds !== null &&
        category.subItemCategoryIds !== undefined && category.subItemCategoryIds.length > 0) {
        for (const subcategoryid of category.subItemCategoryIds) {
          const superCategoryitemListId = this.filteredCategoryitemList.find(ic => ic.id === subcategoryid);
          if (superCategoryitemListId !== null && superCategoryitemListId !== undefined) {
            this.filteredCategoryitemList.push(category);
            break;
          }
        }
      }
    }

    return this.filteredCategoryitemList;
  }

  // getFilteredSuperCategoryList(categoryList, filteredCategoryList) {
  //   // const categoryList = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST);
  //   // const filteredCategoryList = this.sessionStorage.get(SESSION_FILTERED_SUB_CATEGORY_ITEM_LIST);
  //   for (const categories of categoryList) {
  //     if (categories.subItemCategoryIds !== null &&
  //       categories.subItemCategoryIds !== undefined && categories.subItemCategoryIds.length > 0 && categories.parentId === 0) {
  //       for (const subcategoryid of categories.subItemCategoryIds) {
  //         const superCategoryitemListId = filteredCategoryList.find(ic => ic.id === subcategoryid);
  //         if (superCategoryitemListId !== null && superCategoryitemListId !== undefined) {
  //           this.filteredCategoryitemList.push(categories);
  //           break;
  //         }
  //       }
  //     }
  //   }
  //   return this.filteredCategoryitemList;
  // }
  /*********this function is used to avoid subcategories with no products ************/
  // getVisibleCategories(category) {
  //   this.visibleCategory = false;
  //   const selectedCategory = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST).find(ic => ic.id === category);
  //   if (selectedCategory !== null && selectedCategory !== undefined) {
  //     const subItemCatIdsArray = selectedCategory.subItemCategoryIds;
  //     if (selectedCategory.subItemCategoryIds !== null &&
  //       selectedCategory.subItemCategoryIds.length !== 0 && selectedCategory.parentId === 0
  //     ) {
  //       for (const subCatId of subItemCatIdsArray) {
  //         if (this.getVisibleCategories(subCatId)) {
  //           this.visibleCategory = true;
  //           break;
  //         }
  //       }
  //     } else {
  //       if (selectedCategory.saleItemIds !== null &&
  //         selectedCategory.saleItemIds !== undefined && selectedCategory.saleItemIds.length > 0) {
  //         for (const saleId of selectedCategory.saleItemIds) {
  //           if (this.isItemActive(saleId) === true) {
  //             this.visibleCategory = true;
  //             break;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return this.visibleCategory;
  // }

  isItemActiveByID(saleId): Boolean {

    const saleItemList = this.sessionStorage.get(SESSION_SALE_ITEM_LIST);
    const saleItem = saleItemList.find(ic => ic.id === saleId);
    return this.isSaleItemActive(saleItem);
  }

  isSaleItemActive(saleItem): Boolean {
    let isActive = true;
    if (saleItem !== null && saleItem !== undefined) {
      /**** checking item inactive within the date range from saleitemlist.****/
      const itemInactiveFrom = saleItem.inactiveFrom;
      const itemInactiveTo = saleItem.inactiveTo;
      isActive = this.isInactive(itemInactiveFrom, itemInactiveTo);
      if (isActive) {
        const selectedShopID = this.cartService.cart.shop.id;
        const selectedShop = this.sessionStorage.get(SESSION_SHOP_LIST).find(shp => shp.id === selectedShopID);
        // const selectedShop = this.cartService.cart.shop; /* To check */
        if (saleItem != null && saleItem !== undefined) {
          // Checking In shop wise inactive items
          const shopinactiveItemFind = selectedShop.inactiveItems.find(ic => ic.oosaleItemId === saleItem.id);
          if (shopinactiveItemFind !== null && shopinactiveItemFind !== undefined) {
            isActive = false;
            const shopInactiveFrom = shopinactiveItemFind.inactiveFrom;
            const shopInactiveTo = shopinactiveItemFind.inactiveTo;
            /***** checking item inactive within the date range from shop inactiveitemlist. */
            isActive = this.isInactive(shopInactiveFrom, shopInactiveTo);
          }
          // Checking for available Day
          if (isActive) {
            const itemAvailDay = saleItem.itemAvailableDay;
            /******Checking the selected day exist in the "itemAvailableDay and
             the selected business type exist in the category item and checking inactive items from shop list******/
            isActive = itemAvailDay.includes(this.getSelectedDeliveryDay());
          }
          // Checking for biz type
          if (isActive) {
            const businType = saleItem.ooBusinessTypeId;
            isActive = businType.includes(this.cartService.cart.businessType.id);
          }
        }
      }
    }
    return isActive;
  }

  isInactive(fromDate, toDate): boolean {
    let active = true;
    const currentDate = this.datePipe.transform(this.cartService.cart.deliveryDate.date, 'yyyy-MM-dd');
    if (fromDate !== null) {
      if (fromDate <= currentDate) {
        if (toDate != null && toDate < currentDate) {
          active = true;
        } else {
          active = false;
        }
      } else {
        active = true;
      }
    }
    return active;
  }

  getSelectedDeliveryDay() {
    this.selectedDeliveryDate = this.cartService.cart.deliveryDate;
    const dateSelected = new Date(this.selectedDeliveryDate.date);
    const selectedDay = dateSelected.getDay();
    let daY: any;
    if (selectedDay === 0) {
      daY = 7;
    } else {
      daY = selectedDay;
    }
    return daY;
  }

  togglePreviousItem() {
    /*****Show/Hide of Next and Previous Menus***/
    this.getButtonPosition();
    if (this.lastchild.left > this.Elementrect.left) {
      document.getElementById('next-menu-btn').style.visibility = 'visible';
    }
    if (this.firstchild.right > this.prevElementrect.right) {
      document.getElementById('previous-menu-btn').style.visibility = 'hidden';
      document.getElementById('pnProductNav').scrollLeft -= 2;
    }
  }

  toggleNextItem() {
    /*****Show/Hide of Next  button on Menus***/
    this.getButtonPosition();
    if (this.lastchild.left < this.Elementrect.left) {
      document.getElementById('next-menu-btn').style.visibility = 'hidden';
      document.getElementById('pnProductNav').scrollLeft += 5;
    } if (this.firstchild.left < this.prevElementrect.right) {
      document.getElementById('previous-menu-btn').style.visibility = 'visible';
    }
  }
  /* for hide/show prev and next buttons from Nav bar on scroll */
  toggleButtonsHorizontalScroll() {
    this.getButtonPosition();
    if (this.lastchild.left > this.Elementrect.right) {
      document.getElementById('next-menu-btn').style.visibility = 'visible';
    } else {
      document.getElementById('next-menu-btn').style.visibility = 'hidden';
    }
    if (this.firstchild.right < this.prevElementrect.left) {
      document.getElementById('previous-menu-btn').style.visibility = 'visible';
    } else {
      document.getElementById('previous-menu-btn').style.visibility = 'hidden';
    }

  }
  hidePreandNextButtons() {
    /*****Show/Hide of Previous button on Menus***/
    this.getButtonPosition();
    if (this.lastchild.right < this.Elementrect.right) {
      document.getElementById('next-menu-btn').style.visibility = 'hidden';
    }
    if (this.firstchild.right > this.prevElementrect.right) {
      document.getElementById('previous-menu-btn').style.visibility = 'hidden';
    }
  }

  highlightMenuitem(id) {
    const elementz = document.getElementById('cat_hlist_' + id);
    const Elemntrect = elementz.getBoundingClientRect();
    const navelement = document.getElementById('pnProductNav');
    const navrect = navelement.getBoundingClientRect();
    const midOfNav = (navrect.left + navrect.right) / 2;
    if (Elemntrect.left > midOfNav) {
      document.getElementById('pnProductNav').scrollLeft += 55; // scroll to left
    } else {
      document.getElementById('pnProductNav').scrollLeft -= 55; // scroll to right
    }
  }

  setProductData(redirectUrl) {
   
    const businessTypeId = this.cartService.cart.businessType.id;
    const pickUpDate = this.cartService.cart.deliveryDate.date;
    const shopId = this.cartService.cart.shop.id;
    const filteredSaleitemlist = this.loadSaleItemExt(businessTypeId, pickUpDate, this.cartService.cart.shop).subscribe(
      res => {
        const _saleItemList = res.object;
        this.sessionStorage.set(SESSION_FILTERED_SALE_ITEM_LIST, _saleItemList);
        const categoryList = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST);
        const filteredCategory = this.getFilteredItemCategoryList(categoryList, _saleItemList);
        this.sessionStorage.set(SESSION_FILTERED_CATEGORY_ITEM_LIST, filteredCategory);
        this.router.navigate([redirectUrl]);

      });
  }

  getPromotionalItems(): Observable<any> {
    return this.http.get<any>(this.promotionalItemUrl)
    .pipe(
    );
  }

  selectedTimeValidity(shopId: any, selectedDate: any,selectedTime: any): Observable<any> {
    const url = this.selectedTimeValidityUrl + shopId + '/' + selectedDate + '/' + selectedTime;
    return this.http.get<any>(url)
      .pipe(
      );
  }
}
