import { Component, OnInit, Inject, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import {
  SESSION_SELECTED_SHOP, SESSION_CART, SESSION_SALE_ITEM_LIST,
  SESSION_SYSTEM_SETTINGS, SESSION_AUTHENTICATED_CUSTOMER, SESSION_START_TIME,
  SESSION_ITEM_CATEGORY_LIST, SESSION_SHOP_LIST, SESSION_DEPARTMENT_LIST, SESSION_FILTERED_SALE_ITEM_LIST, SESSION_PROMOTIONAL_SALEITEM_IDS, ERROR_CODE_OUT_OF_STOCK, SESSION_OUTOFSTOCK_ITEM_LIST, SESSION_MASTER_CUSTMIZATION
} from '../app.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService, Cart } from './cart.service';
import { ShopDetailService } from '../shop-detail/shop-detail.service';
import { SettingsService } from '../settings/settings.service';
import { ItemListService } from '../item-list/item-list.service';
import { AppDataService } from '../services/app-data.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { Customer } from '../view-models/customer';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RemarksDialogComponent, DialogData } from '../remarks-dialog/remarks-dialog.component';
import { DateTimeServiceService } from '../services/date-time-service.service';
import { MenuService } from '../../fw/services/menu.service';
import { DateSelectionService } from '../services/date-selection.service';
import { OrderConfirmationService } from '../order-confirmation/order-confirmation.service';
import { TimeService } from '../services/time-service.service';
import { ConfirmationService } from 'primeng/api';
import * as moment from 'moment';
import { GtmService } from '../gtm.service';

export interface Title {
  value: string;
  display: string;
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})

export class CartComponent implements OnInit {

  validItem: any;
  validTime: any;
  validShop: any;
  workinghours: any;
  selectedShopDeptDtl: any;
  shopList: any;
  itemCategories: any;
  departmentList: any;
  validEmail: boolean;
  isCustomerTabOpen: boolean;
  formControl: any;
  invalidMessage: string;
  preparationTime: number;
  // email: any;
  // phoneNumber: any;
  // firstName: any;
  // lastName: any;
  // title: any;
  // userId: any;
  isContinueOptioniVisible = false;
  selectedCustomer: Customer;
  isddMMyyyy: any;
  isDate2: Boolean;
  // remark: any = '';
  showIcon: boolean;
  showServiceIcon: boolean;
  showCustomerIcon: boolean;
  serviceName: any;
  customerName; any;
  shopName: any;
  shopAccordianTitle: any;
  saleItemInfo: any;
  currencySymbol: any;
  systemSettings: any;
  cartItemInfo: any[] = [];
  shopWorkingInfo: any;
  selectedService: any;
  allSaleItemList: any[];
  cart: Cart;
  checked = false;
  orderServiceTypes: any[];
  pwGroup: FormGroup;
  displaytitle: any;
  titles: Title[] = [
    { value: 'Mr.', display: 'Mr.' },
    { value: 'Ms.', display: 'Ms.' },
    { value: 'Mrs.', display: 'Mrs.' }
  ];
  // @ViewChild("firstName") elFirstName: ElementRef;
  remarkButtonCaption: String = 'Special Instructions';
  promotionalItems: any[];

  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService,
    private dateSelectionService: DateSelectionService,
    private utilService: UtilService, private dateTimeServiceService: DateTimeServiceService,
    private appDataService: AppDataService, private route: ActivatedRoute, private router: Router,
    public cartService: CartService,
    private _settingsService: SettingsService,
    private datePipe: DatePipe, private orderConfirmationService: OrderConfirmationService,
    private _shopDetailService: ShopDetailService, private itemListService: ItemListService,
    private loginService: LoginService, private confirmationService: ConfirmationService,
    public dialog: MatDialog, public menuService: MenuService,
    private timeService: TimeService,
    private gtmService: GtmService) {

    this.initTitleBarParams();
    this.isCustomerTabOpen = false;
    this.cart = this.cartService.cart;
    this.cartItemExist();
    this.allSaleItemList = this.itemListService.saleItemList;
    this.departmentList = this.sessionStorage.get(SESSION_DEPARTMENT_LIST);
    this.itemCategories = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST);
    this.shopList = this.sessionStorage.get(SESSION_SHOP_LIST);
    this.preparationTime = this.cart.businessType.initialDelay;
    this.selectedShopDeptDtl = this.departmentList.filter(e =>
      this.cart.shop.department.find(csd => csd === e.id && e.isCommon === 0)
    );
    this.workinghours = this.cart.shop.shopDailyWorkingHours;
    this.formControl = {
      email: new FormControl('', [Validators.required, Validators.email]),
      title: new FormControl(),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl(),
      phoneNumber: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(9)])
    };
    if (this.orderConfirmationService.errorJsonArrayOfCart !== null && this.orderConfirmationService.errorJsonArrayOfCart !== undefined) {
      const error = this.orderConfirmationService.errorJsonArrayOfCart;
      this.validShop = error.filter(e => e.type === 'shop');
      this.validTime = error.filter(e => e.type === 'pickuptime');
      this.validItem = error.filter(e => e.type === 'item');
    }
    this.utilService.progressService.isLoading = true;
    // if (this.cart.customer.email == null) {
    //     this.isCustomerTabOpen=true;
    //   }

  }

  ngOnInit() {
    this.menuService.isVertical = true;
    this.showIcon = true;
    this.showServiceIcon = true;
    this.showCustomerIcon = true;
    let selectedDateFt = moment(this.cart.deliveryDate.date).format('YYYY-MM-DD');
    this.onShopDetailTabClose('');
    this.preValidateCartItems();
    /**
     * out of stock list by shop_id and pickupdate
     * returns  array list of ooSaleItemIds.
     */
    let inputParam = { "shop_id": this.cart.shop.id, "pickUp_date_time": selectedDateFt + " " + this.cart.deliveryTime };
    this.dateTimeServiceService.getOutofStockByShop(inputParam);
  }

  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = true;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'ORDER DETAILS',
      showLogo: false,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showCartBar: true
    };
    this.utilService.setFooterBarParams(this, footerConfig);

  }

  resetCartValidationError(): void {
    let cartItem: any;
    for (cartItem of this.cart.items) {
      cartItem.hasError = false;
      cartItem.errorMessage = '';
      cartItem.hasOutofStockError = false;
    }
  }

  vaildateDepartment(preValidation) {

    this.selectedShopDeptDtl = this.departmentList.filter(e =>
      this.cart.shop.department.find(csd => csd === e.id && e.isCommon === 0)
    );

    let shopDepartmentValid = true;
    const tz = this.dateTimeServiceService.timezoneOffset();
    const formttedDelivaryDate = new Date(this.cart.deliveryDate.date + ' ' + this.cart.deliveryTime);
    const day = formttedDelivaryDate.getDay();
    const weekDay = (day === 0) ? 7 : day;
    // const workHours = this.workinghours.find(wh => wh.weekDay === weekDay);

    const workHours = this.workinghours.find(wh => wh.date === this.datePipe.transform(this.cart.deliveryDate.date, "yyyy-MM-dd"));
    const deptWrkHoursList = workHours.deparmentHours;
    let errorMsg = '';

    for (const department of this.selectedShopDeptDtl) {
      shopDepartmentValid = true;
      const shopDepartmentHour = deptWrkHoursList.filter(function (obj) {
        return obj.departmentId === department.id;
      });
      if (shopDepartmentHour !== undefined && shopDepartmentHour !== null && shopDepartmentHour.length > 0) {

        const deliveryTime = this.datePipe.transform('1900-01-01T' + this.cart.deliveryTime, 'HH:mm:ss');

        if (!(((new Date('1970-01-01T' + deliveryTime + tz)) >= (new Date('1970-01-01T' + shopDepartmentHour[0].openTime + tz))) &&
          ((new Date('1970-01-01T' + deliveryTime + tz)) <= (new Date('1970-01-01T' + shopDepartmentHour[0].closeTime + tz))))) {

          shopDepartmentValid = !this.isItemInDepartment(department);
          if (!shopDepartmentValid) {

            // this.utilService.openDialog(department.name + ' closed', 'Your order contains ' +
            //   department.name + ' item. ' + department.name +
            //   ' department is only ready for Pickup from ' + shopDepartmentHour[0].displayTime +
            //   ' for ' + this.cart.shop.name + ' on ' + this.cart.deliveryDate.label + '.' + ' Please choose a pick up time between '
            //   + shopDepartmentHour[0].displayTime +
            //   '  or remove ' + department.name + ' items from your order.');
            var dptWrkHrs = shopDepartmentHour.filter
            errorMsg = 'Your order contains ' +
              department.name + ' item. ' + department.name +
              ' department is only ready for Pickup from ' + shopDepartmentHour[0].displayHours +
              ' for ' + this.cart.shop.name + ' on ' + this.cart.deliveryDate.label + '.' + ' Please choose a pick up time between '
              + shopDepartmentHour[0].displayHours +
              '  or remove ' + department.name + ' items from your order.';
          }
        }
      } else {
        shopDepartmentValid = !this.isItemInDepartment(department);
        if (!shopDepartmentValid) {

          // this.utilService.openDialog(
          //   department.name + ' closed', 'Your order contains ' + department.name +
          //   ' item.' + department.name + ' department is closed at ' + this.cart.shop.name + ' on ' +
          //   this.cart.deliveryDate.label + '.' +
          //   ' Please choose another day or remove ' + department.name + ' items from your order.');

          errorMsg = 'Your order contains ' + department.name +
            ' item.' + department.name + ' department is closed at ' + this.cart.shop.name + ' on ' + this.cart.deliveryDate.label + '.' +
            ' Please choose another day or remove ' + department.name + ' items from your order.';

        }
      }

      if (!shopDepartmentValid) {
        if (preValidation) {
          this.markDepartmentItemsAsInvalid(department);
        } else {
          this.utilService.openDialog(department.name + ' closed', errorMsg);
          break;
        }
      }
    }
    return shopDepartmentValid;
  }

  markDepartmentItemsAsInvalid(department): void {

    let cartItem: any;

    for (cartItem of this.cart.items) {

      const item = this.allSaleItemList.find(e => e.id === cartItem.ooSaleItemId);
      const itemCat = this.itemCategories.find(e => e.id === item.itemCategoryId);

      if (department.id === itemCat.departmentId) {
        cartItem.hasError = true;
        cartItem.errorMessage = 'Department ' + department.name + ' is closed at ' +
          this.cart.deliveryDate.label + ' ' + this.datePipe.transform('1900-01-01T' + this.cart.deliveryTime, 'h:mm a');
      }

    }

  }

  preValidateCartItems(): Boolean {
    let isValid = false;
    this.resetCartValidationError();

    if (!this.vaildateDepartment(true)) {
      isValid = false;
    }

    if (!this.isItemAvailableforSelectedDay(true)) {
      isValid = false;
    }

    if (!this.isCartItemInFilterdList(true)) {
      isValid = false;
    }

    // if(!this.validateReorderedItem()){
    //   isValid = false;
    // }
    this.utilService.progressService.isLoading = false;

    return isValid;
  }

  isItemInDepartment(department: any): boolean {
    const cartItems = this.cart.items;
    const saleItems = this.allSaleItemList.filter(asl =>
      cartItems.find(ci => ci.ooSaleItemId === asl.id)
    );
    const itemCategories = this.itemCategories.filter(ic => saleItems.find(si => si.itemCategoryId === ic.id));
    const isAvailable = (itemCategories.findIndex(ic => ic.departmentId === department.id) >= 0);
    return isAvailable;
  }


  /**
   * If cart have item,then only checking various cart validations
   */
  cartItemExist() {

    if (this.cart.items.length !== 0) {

      this.allSaleItemList = this.itemListService.saleItemList;
      this.selectedService = this.sessionStorage.get(SESSION_CART).service;

      if (this.cart.customer !== undefined) {
        this.selectedCustomer = this.cart.customer;
        // Object.assign(this.sessionStorage.get(SESSION_CART).customer);
      } else {
        this.selectedCustomer = <Customer>{};
        if (this.selectedCustomer.title !== null && this.selectedCustomer.title !== undefined) {
          this.displaytitle = this.titles[0].value;
        } else {
          this.displaytitle = '';
        }
        this.selectedCustomer.title = this.displaytitle;
        this.selectedCustomer.customerType = 2;
        this.cartService.setCustomer(this.selectedCustomer);
      }

      this.shopWorkingInfoChecking();
      this.shopName = this.cart.shop.name;

      if (this.selectedCustomer.firstName !== undefined) {
        this.customerName = this.getCustomerDisplayTitle();
      } else {
        this.customerName = 'Customer Detail (Guest)';
      }

      if (this.cart.deliveryDate !== undefined && this.cart.deliveryTime !== undefined) {
        this.serviceName = this.getServiceDisplayTitle();
      } else {
        this.utilService.openDialog('Select Time', 'Select a pick up time from Date Time selection');
        this.router.navigate(['/datetime-selection'], { queryParams: { 'redirectUrl': '/cart' } });
      }

      this.systemSettings = this._settingsService.systemSettings;
      this.cartItemInfo = this.cart.items;
      if (this.systemSettings !== null) {
        this.currencySymbol = this.systemSettings.currency.symbol;
      }
    }
  }

  shopWorkingInfoChecking() {
    const searchParam = {
      'id': this.cart.shop.id, 'from': this._shopDetailService.formatDate(new Date(this.timeService.serverTime)),
      'to': this._shopDetailService.formatDate(new Date(this.timeService.serverTime))
    };
    this.dateSelectionService.getShopHoliday(searchParam).subscribe(
      res => {
        const shopHoliday = res.object;
        this.shopWorkingInfo = this._shopDetailService.getShopHoursByDate(this.cart.shop,
          new Date(this.cart.deliveryDate.date + ' ' + this.cart.deliveryTime));
      });
  }
  /**
   * checking the mail validations
   */
  getEmailError(): any {

    return this.formControl.email.hasError('required') ? 'Please enter a valid email address.' :
      this.formControl.email.hasError('email') ? 'It is not a valid email address.' :
        '';

  }


  emailValidation(email) {
    this.validEmail = false;
    // tslint:disable-next-line:max-line-length
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validEmailRegEx.test(email)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    return this.validEmail;
  }
  /**
   * check whether first name entered
   */
  getFirstNameError(): any {

    let error: any;
    if (this.formControl.firstName.hasError('required')) {
      error = 'Please enter your name';
    } else if (!this.formControl.firstName.touched) {
      error = 'Please enter your name';
    }
    return error;
  }
  getTitleError(): any {
    let error: any;
    if (this.formControl.title.hasError('required')) {
      error = 'Please enter your title';
    } else if (!this.formControl.title.touched) {
      error = 'Please enter your title';
    }
    return error;
  }
  /**
   * check whether the phone number  is valid or not
   */
  getPhoneNumberError(): any {
    let error: any;
    if (this.formControl.phoneNumber.hasError('required')) {
      error = 'Please enter a valid phone number.';
    } else if
      (this.formControl.phoneNumber.errors.maxlength !== undefined &&
      (this.formControl.phoneNumber.errors.maxlength.actualLength) > (this.formControl.phoneNumber.errors.maxlength.requiredLength)) {
      error = 'Phone number with in 13 characters length.';
    } else if (this.formControl.phoneNumber.errors.minlength !== undefined &&
      (this.formControl.phoneNumber.errors.minlength.actualLength) < (this.formControl.phoneNumber.errors.minlength.requiredLength)) {
      error = 'Phone number should be 9 characters long.';
    }
    return error;
  }
  /**
   * check whether is it a valid form
   */
  isFormValid(): boolean {
    const form = document.getElementById('guestForm');
    return (form[0].checkValidity());
  }
  /**
   * To change the shop
   */
  changeShop() {
    this.router.navigate(['/storefinder']);
  }
  /**
   * To change different services available in the shop
   */
  changeService() {
    this.router.navigate(['/order-services']);
  }
  changeCustomer() {
    this.router.navigate(['/guest-form'], { queryParams: { 'redirectUrl': '/cart' } });
  }

  editCustomer() {
    this.router.navigate(['/my-profile'], { queryParams: { 'redirectUrl': '/cart' } });
  }

  /**
   * To change ordering date and time
   */
  changeDateAndTime() {
    // this.router.navigate(['/datetime-selection']);
    this.router.navigate(['/datetime-selection'], { queryParams: { 'redirectUrl': '/cart' } });

  }
  /**
   *
   * @param info
   *on opening the shop detail tab the shop name(heading) is changed to shop detail
   */
  onShopDetailTabOpen(info) {
    this.shopAccordianTitle = 'Shop Details';
    this.showIcon = false;
  }
  /**
   *
   * @param info on closing the shop detail tab heading is changes to shop name
   */
  onShopDetailTabClose(info) {
    this.shopAccordianTitle = 'Shop:  ' + this.cart.shop.name;
    this.shopName = this.cart.shop.name;
    this.showIcon = true;
  }
  /**
   *
   * @param info on opening the shop service
   */
  onShopServiceTabOpen(info) {
    this.serviceName = 'Service Details';
    this.showServiceIcon = false;
  }
  onCustomerTabOpen(info) {
    this.customerName = 'Customer Details';
    this.showCustomerIcon = false;
  }

  onCustomerTabClose(info) {
    if (this.selectedCustomer.firstName !== undefined) {
      this.customerName = this.getCustomerDisplayTitle();
    }
    this.showCustomerIcon = true;
  }
  /**
   *
   * @param info on closing the shop service
   */
  onShopServiceTabClose(info) {
    this.serviceName = this.getServiceDisplayTitle();
    this.showServiceIcon = true;
  }
  getCustomerDisplayTitle(): string {
    if (this.selectedCustomer.title !== null && this.selectedCustomer.title !== undefined) {

      this.displaytitle = this.selectedCustomer.title;
    } else {
      this.displaytitle = '';
    }
    // return 'Customer (' + this.selectedCustomer.firstName + ', ' + this.selectedCustomer.phoneNumber + ', '
    //   + this.selectedCustomer.email + ')';
    return 'Customer (' + this.displaytitle + ' ' +
      this.selectedCustomer.firstName + ' ' +
      ((this.selectedCustomer.lastName !== null && this.selectedCustomer.lastName !== undefined) ? this.selectedCustomer.lastName : ' ') +
      ')';
  }
  /** Return formated service title */
  getServiceDisplayTitle(): string {
    return this.selectedService.name +
      ' (' + this.cart.deliveryDate.label + ' ' + this.datePipe.transform('1900-01-01T' + this.cart.deliveryTime, 'h:mm a') + ')';
  }

  proceed() {
    if (
      this.cartService.cart.items !== undefined &&
      this.cartService.cart.items.length > 0
    ) {
      if (!this.cartValidation()) {
        return;
      } else if (!this.vaildateDepartment(false)) {
        return;
      } else if (!this.isItemAvailableforSelectedDay(false)) {
        return;
      } else if (!this.isCartItemInFilterdList(false)) {
        return;
      } else if (this.cartService.isGuestLogin() && !this.guestUser()) {
        return;
      }

      const cartParam = this.cartService.getCartParam();
      this.dateTimeServiceService.validateCart(cartParam).subscribe(
        res => {
          if (!res.success) {
            if (res.errorCode == ERROR_CODE_OUT_OF_STOCK) {
              let OutofStockCartItem = res.object.invalid_items;

              let cartItem: any;
              for (cartItem of this.cart.items) {
                OutofStockCartItem.forEach(e => {
                  if (Number(e.id) == cartItem.ooSaleItemId) {
                    cartItem.hasError = true;
                    cartItem.hasOutofStockError = true;
                    cartItem.errorMessage = e.message;
                  }
                });
              }
            }
            this.utilService.openDialog('Information', res.message);

          } else {

            this.updateGTMInfoForCheckout();
            this.router.navigate(['/order-confirmation']);
          }
        }, err => {
        });


    } else {
      this.utilService.openDialog(
        'Payment.',
        'There are no items in your order. Add items to your order.'
      );
    }
  }

  isCartItemInFilterdList(preValidation) {
    let isCartItemInSaleItemList = true;
    const cartItems = this.cart.items;
    let filteredSaleItemList = this.sessionStorage.get(SESSION_FILTERED_SALE_ITEM_LIST);

    for (let cItem of this.cart.items) {
      let isCartValid = filteredSaleItemList.some(e => e.saleItemId === cItem.saleItemId);
      if (!isCartValid) {
        cItem.hasError = !isCartValid;
        cItem.errorMessage = 'This item is temporarily unavailable. Please remove this item from your order to continue.';
        isCartItemInSaleItemList = false;
      }
    }
    if (!isCartItemInSaleItemList) {
      isCartItemInSaleItemList = false;
      this.utilService.openDialog('Sorry',
        // tslint:disable-next-line: max-line-length
        'Your order contains items which are temporarily unavailable. Please remove the items from your order.');
    }
    return isCartItemInSaleItemList;
  }

  // validateReorderedItem(){
  //   let isCartValid = true;
  //   const cartItems = this.cart.items;
  //   let filteredSaleItemList = this.sessionStorage.get(SESSION_FILTERED_SALE_ITEM_LIST);

  //   for (let cItem of this.cart.items) {
  //     isCartValid = filteredSaleItemList.filter(e => e.saleItemId === cItem.saleItemId);
  //   }
  //   return true;
  // }

  isItemAvailableforSelectedDay(preValidation) {
    let isCartValid = true;
    // let visibleItem = true;
    // the cart item is not avaiable selected date, then no need to confirm.
    const cartItems = this.cart.items;
    let items;
    for (items of cartItems) {

      const saleItemID = items.ooSaleItemId;
      const visibleItem = this.itemListService.isItemActiveByID(saleItemID);
      // let item = this.allSaleItemList.find(wh => wh.id === saleItemID);

      if (!visibleItem) {
        items.hasError = !visibleItem;
        items.errorMessage = 'This item is temporarily unavailable. Please remove this item from your order to continue.';
        isCartValid = false;
      }
    }
    if (isCartValid === false && !preValidation) {
      this.utilService.openDialog('Sorry',
        // tslint:disable-next-line: max-line-length
        'Your order contains items which are temporarily unavailable. Please remove the items from your order.');
    }
    return isCartValid;
  }

  getMaxAmountLimit(cart) {
    // let valid=false;
    let curDate = moment(new Date()).format('YYYY/MM/DD');
    let amountLimit = 0;
    if (curDate === cart.deliveryDate.date) {
      amountLimit = cart.businessType.amountMaxLimitSameDay;
    } else {
      amountLimit = cart.businessType.amountMaxLimit;
    }
    // valid = ((this.cartService.getCartTotal() <= amountLimit));

    return amountLimit;
  }

  cartValidation(): Boolean {
    let isCartValid = false;
    let maxAmountLimit = this.getMaxAmountLimit(this.cartService.cart);
    if ((this.cartService.getCartTotal() >=
      this.cartService.cart.businessType.amountMinLimit)) {
      if ((this.cartService.getCartTotal() <= maxAmountLimit)) {

        const dt = new Date(this.timeService.serverTime);
        const deliveryDate = this.cart.deliveryDate.date;
        const formttedDelivaryDate = new Date(this.cart.deliveryDate.date + ' ' + this.cart.deliveryTime);
        const orderBefore = new Date(this.cart.deliveryDate.date + ' ' + this.cart.deliveryTime);
        orderBefore.setMinutes(formttedDelivaryDate.getMinutes() - this.preparationTime);
        // const orderBefore = new Date((formttedDelivaryDate.setMinutes(formttedDelivaryDate.getMinutes() - this.preparationTime )));
        if (formttedDelivaryDate <= dt) {
          this.utilService.openDialog('Time Expired.', 'The selected pickup time has expired. Please select another pickup time.');
          this.router.navigate(['/datetime-selection'], { queryParams: { 'redirectUrl': '/cart' } });
        } else if (dt >= orderBefore) {
          this.utilService.openDialog('We are sorry.', 'We need at least ' + this.timeService.toHMFormat(this.preparationTime)
            + ' to prepare your order. Please select another pickup time.');
          this.cartService.cart.deliveryTime = undefined;
          this.router.navigate(['/datetime-selection'], { queryParams: { 'redirectUrl': '/cart' } });
        } else {
          // this.router.navigate(['/order-confirmation']);
          isCartValid = true;
        }
      } else {
        const city = (this.cartService.cart.shop.city) ? '\n' + this.cartService.cart.shop.city : '';
        const phoneNum = (this.cartService.cart.shop.phoneNumber) ? this.cartService.cart.shop.phoneNumber : '';
        const country = (this.cartService.cart.shop.country) ? ', ' + this.cartService.cart.shop.country : '';
        this.utilService.openDialog
          ('Sorry!', 'Thank you for ordering. The total order amount for '
            + this.cart.businessType.name +
            ' is in the range of ' + this.currencySymbol +
            this.cartService.cart.businessType.amountMinLimit + ' and ' +
            this.currencySymbol + maxAmountLimit + //  this.cartService.cart.businessType.amountMaxLimit + // => changed as the amount limit is different for same day orders
            ' If you want a larger order, please call your selected store to make the order.' + '\n' + this.cartService.cart.shop.name +
            '\n' + this.cartService.cart.shop.address +
            city + country + '\n' +
            phoneNum);
      }
    } else {
      this.utilService.openDialog
        ('Sorry!', 'Thank you for ordering. The total order amount for Order '
          + this.cart.businessType.name +
          ' is in the range of ' + this.currencySymbol +
          this.cartService.cart.businessType.amountMinLimit + ' and ' +
          this.currencySymbol + maxAmountLimit // this.cartService.cart.businessType.amountMaxLimit // => changed as the amount limit is different for same day orders
          + '.');

    }
    return isCartValid;
  }

  /** Fetch all online service types */
  getAllOnlineServices() {
    this.appDataService.getOrderServiceTypes().subscribe(
      res => {
        if (res.success) {
          this.orderServiceTypes = res.object;
        }
      }
    );
  }

  /**
   * if the customer is a guest user,then fill the guest details.
   * If the guest is already registered customer redirect that customer to the login page
   */
  guestUser() {

    let isValidCustomer = false;
    if (this.isFormValid()) {

      if (this.selectedCustomer.phoneNumber != null && this.selectedCustomer.email !== null
        && this.selectedCustomer.firstName !== undefined && this.selectedCustomer.firstName !== null) {
        if (this.emailValidation(this.selectedCustomer.email)) {
          this.selectedCustomer.loginId = this.selectedCustomer.email;
          if (this.formControl.phoneNumber.errors !== null &&
            ((this.formControl.phoneNumber.errors.minlength.actualLength) <
              (this.formControl.phoneNumber.errors.minlength.requiredLength))) {
            this.utilService.openDialog('Customer Info.', 'Phone number should be 9 characters long.');
          } else {
            this.loginService.guestCustomer(this.selectedCustomer).subscribe(
              res => {
                if (res.success) {
                  isValidCustomer = true;
                  // if (res.object.customerType !== 2) {
                  //   this.utilService.openDialog('Please login', 'Mail id is already registered. Please login.');
                  //   this.router.navigate(['/login'], { queryParams: { 'redirectUrl': '/order-confirmation' } });
                  // } else {
                  this.cartService.setCustomer(res.object);
                  this.sessionStorage.set(SESSION_AUTHENTICATED_CUSTOMER, res.object);
                  
                 this.updateGTMInfoForCheckout();
                  this.router.navigate(['/order-confirmation']);

                } else {
                  this.utilService.openDialog('Please login', res.message);
                  this.router.navigate(['/login'], { queryParams: { 'redirectUrl': '/cart' } });

                }
              }
            );
          }
        } else {
          this.utilService.openDialog('Customer Info.', 'Enter valid Email Address of the form a@gmail.com');

        }
      } else {
        // this.isCustomerTabOpen = true;
        // this.utilService.openDialog('Customer Info.', 'Customer information is not entered. Please enter your details');
        this.isContinueOptioniVisible = true;
      }
    } else {
      //this.isCustomerTabOpen = true;
      //this.utilService.openDialog('Customer Info.', 'Customer information is not entered. Please enter your details or ' +
      //  'login if you are already registered.');
      this.isContinueOptioniVisible = true;

    }
    return isValidCustomer;
  }
  /**Dialog to accept the remarks from user */
  openDialog(): void {
    const dlgRemarks = this.dialog.open(RemarksDialogComponent, {
      maxWidth: '450px',
      width: '90%',
      // maxHeight: '300px',
      // height: '80%',
      data: { title: 'Special Instructions', remarks: this.cart.remarks }
    });

    dlgRemarks.afterClosed().subscribe(result => {
      const dlgResult: DialogData = result;
      if (!dlgResult.isCancelled) {
        this.cartService.setRemarks(dlgResult.remarks);
        // this.remarkButtonCaption = this.getRemarkButtonCaption();
      }
    });
  }

  shopInfo() {
    this.router.navigate(['/shop-details'], {
      queryParams: {
        'redirectUrl': '/cart',
        'id': this.cart.shop.id, 'map': 1, 'direction': 1
      }
    });
  }

  /**Redirect to gust info page*/
  changeGustInfo() {
    // if (this.isFormValid() &&
    // this.selectedCustomer.title !== null && this.selectedCustomer.title !== undefined && this.selectedCustomer.phoneNumber !== undefined
    //    && this.selectedCustomer.phoneNumber !== null && this.selectedCustomer.email !== null && this.selectedCustomer.email !== undefined
    //   && this.selectedCustomer.firstName !== undefined && this.selectedCustomer.firstName !== null) {
    //     if (this.emailValidation(this.selectedCustomer.email)) {


    this.router.navigate(['/guest-form'], { queryParams: { 'redirectUrl': '/cart' } });
    //       } else {
    //         this.utilService.openDialog('Customer Info.', 'enter valid Email Address of the form a@gmail.com');

    //       }
    // } else {
    //   this.utilService.openDialog('Customer Info.', 'Customer information is not entered.');
    // }
  }

  /**Redirect to gust login page*/
  goToLoginPage() {
    this.hideContinueOptions();
    this.router.navigate(['/login'], { queryParams: { 'redirectUrl': '/cart' } });
  }

  hideContinueOptions() {
    this.isContinueOptioniVisible = false;
  }

  setGustLogin() {
    this.hideContinueOptions();
    this.isCustomerTabOpen = true;

    setTimeout(() => { // this will make the execution after the above boolean has changed
      document.getElementById("firstName").focus();
    }, 1);
  }

  gotoRegisterAccount() {
    this.hideContinueOptions();
    this.router.navigate(['/sign-up'], { queryParams: { 'redirectUrl': '/cart' } });

  }



  deleItemClickEvent(cartItem) {
    let saleItemData = this.allSaleItemList.find(asl => asl.id === cartItem.ooSaleItemId);

    this.confirmationService.confirm({
      message: 'Do you want to delete ' + saleItemData.name + '?',
      accept: () => {
        this.updateGTMInfoForDelete('remove_from_cart', cartItem);
        this.cartService.deleteItem(cartItem);
        this.cartService.getValidPromotionalItemData();
      },
      reject: () => {
        // cartItem.quantity = +cartItem.quantity;
      }
    });
  }

  /**
   * Enter key press prevented in cart order remarks.
   * ie, new line not allowed.
   * @param event 
   * @returns 
   */
  onKeydown(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
  }

  /**
   * GTM CODE STARTS HERE =>
   * @param event 
   * @param cartItem 
   */
  updateGTMInfoForDelete(event: string, cartItem: any): void {

    this.gtmService.pushEvent(
      '',
      {
        userId: this.gtmService.getCustomer(),
        visitorType: this.gtmService.getVisitorType(),
        dateSelectd: this.gtmService.geDeliveryDate(),
        timeSelected: this.gtmService.getDeliveryTime(),
        preOrder: this.gtmService.isPreOrder()

      });

    let items = [];
    let saleItemInfo = this.gtmService.getSaleItem(cartItem.ooSaleItemId);
    items.push({
      item_id: saleItemInfo.id,
      item_name: saleItemInfo.name,
      affiliation: this.gtmService.getStore(),
      item_brand: this.gtmService.getItemParentCategory(saleItemInfo).name,
      item_category: this.gtmService.getItemSubCategory(saleItemInfo).name,
      item_variant: '',
      price: cartItem.netTotal,
      quantity: cartItem.quantity

    });
    //{selectedCustomization:selectedCustomization,total:custTotalPrice};
    let customization = this.getSelectedCustomizations(cartItem);

    if (customization.selectedCustomization.length > 0) {
      items.push(
        {
          item_id: saleItemInfo.id,
          item_name: saleItemInfo.name,
          item_category2: (customization.selectedCustomization.length > 0) ? customization.selectedCustomization[0].nameToPrint : '',
          item_category3: (customization.selectedCustomization.length > 1) ? customization.selectedCustomization[1].nameToPrint : '',
          item_category4: (customization.selectedCustomization.length > 2) ? customization.selectedCustomization[2].nameToPrint : '',
          item_category5: (customization.selectedCustomization.length > 3) ? customization.selectedCustomization[3].nameToPrint : '',
          price: customization.total * cartItem.quantity,
          quantity: cartItem.quantity
        }
      );
    }

    this.gtmService.pushG4AEvent(
      event,
      {
        ecommerce: {
          currency: 'NZD',
          value: cartItem.netTotal + cartItem.netCustomizationTotal,
          items: items
        }
      });
  }

  /**
 * Extract the selected customizations
 * @returns 
 */
  getSelectedCustomizations(cartItem): any {
    let selectedCustomization = [];
    let custTotalPrice = 0;
    for (let cd of cartItem.customization) {
      for (let ov of cd.optionValue) {
        if (ov.isSelected === 1 && ov.isDefault == 0) {
          selectedCustomization.push(ov);
          custTotalPrice += ov.fixedPrice;
        }
      }
    }
    return { selectedCustomization: selectedCustomization, total: custTotalPrice };

  }

  updateGTMInfoForCheckout(){
    const cartParam = this.cartService.getCartParam();


    this.gtmService.pushEvent(
      '',
      {
        userId: this.gtmService.getCustomer(),
        visitorType: this.gtmService.getVisitorType(),
        dateSelectd: this.gtmService.geDeliveryDate(),
        timeSelected: this.gtmService.getDeliveryTime(),
        preOrder: this.gtmService.isPreOrder()

      });

    let items = [];
    for (const cartItem of cartParam.items) {
      let saleItemInfo = this.gtmService.getSaleItem(cartItem.ooSaleItemId);
      items.push({
        item_id: saleItemInfo.id,
        item_name: saleItemInfo.name,
        affiliation: this.gtmService.getStore(),
        item_brand: this.gtmService.getItemParentCategory(saleItemInfo).name,
        item_category: this.gtmService.getItemSubCategory(saleItemInfo).name,
        item_variant: '',
        price: cartItem.netTotal,
        quantity: cartItem.quantity

      });
      //{selectedCustomization:selectedCustomization,total:custTotalPrice};
      let customization = this.getSelectedCustomizations(cartItem);

      if (customization.selectedCustomization.length > 0) {
        items.push(
          {
            item_id: saleItemInfo.id,
            item_name: saleItemInfo.name,
            item_category2: (customization.selectedCustomization.length > 0) ? customization.selectedCustomization[0].nameToPrint : '',
            item_category3: (customization.selectedCustomization.length > 1) ? customization.selectedCustomization[1].nameToPrint : '',
            item_category4: (customization.selectedCustomization.length > 2) ? customization.selectedCustomization[2].nameToPrint : '',
            item_category5: (customization.selectedCustomization.length > 3) ? customization.selectedCustomization[3].nameToPrint : '',
            price: customization.total * cartItem.quantity,
            quantity: cartItem.quantity
          }
        );
      }
    }

    this.gtmService.pushG4AEvent(
      'begin_checkout',
      {
        ecommerce: {
          currency: 'NZD',
          value:this. cartService.getCartTotal() ,
          items: items
        }
      });

  }

  

  /**
   * GTM CODE ENDS HERE =>
   */
}
