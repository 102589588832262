import { Inject, Injectable } from '@angular/core';
import { ItemCategory } from '../view-models/itemcategory';
import { Observable } from 'rxjs';
import { StartupService } from './startup.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UtilService } from './util.service';
import { catchError, tap } from 'rxjs/operators';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SESSION_AUTHENTICATED_CUSTOMER, SESSION_CUSTOMER_FAVOURITES, SESSION_SELECTED_SHOP } from '../app.constants';
import { CartService } from '../cart/cart.service';
import { environment } from 'src/environments/environment';
import { User } from 'td-aio-auth/lib/_models/user';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  forgotPasswordUrl: string;
  changeCustomerUrl: string;
  updateCustomerUrl: string;
  guestCustomerUrl: string;
  authenticateUrl: string;
  getCustomerInfoById:string;
  authenticateSocialUrl: string;
  registerCustomerUrl: string;
  validateCustomerUrl: string;
  constructor(private startUp: StartupService, private http: HttpClient,
              private utilService: UtilService,@Inject(SESSION_STORAGE) private storage: WebStorageService,
              public cartService: CartService) {
    this.authenticateUrl = this.startUp.startupData.apiurl + '/customer/get-validated-customer';
    this.authenticateSocialUrl = this.startUp.startupData.apiurl + '/customer/get-validated-social-customer';
  this.registerCustomerUrl =  this.startUp.startupData.apiurl + '/customer/register-customer';
  this.guestCustomerUrl = this.startUp.startupData.apiurl + '/customer/guest-customer';
  this.updateCustomerUrl = this.startUp.startupData.apiurl + '/customer/update-customer';
  this.changeCustomerUrl = this.startUp.startupData.apiurl + '/customer/change-password';
  this.forgotPasswordUrl = this.startUp.startupData.apiurl + '/customer/forgot-password';
  this.getCustomerInfoById = this.startUp.startupData.apiurl + '/customer/get/';
  this.validateCustomerUrl =  this.startUp.startupData.apiurl + '/customer/validate-customerMail/';
  }
 
   
  // async autoLogin(){
  //   let customerInfo = JSON.parse(localStorage.getItem('customerDetail'));
  //   if (customerInfo !== null || customerInfo !== undefined) {
  //        this.getCustomerDetail(customerInfo);
  //   }
    
  // }

  /**Authenticate customer*/
  authenticateCustomer(customer: any): Observable<any> {
    return this.http.post<any>(this.authenticateUrl, customer)
      .pipe(
        catchError(this.utilService.handleError)
      );
  }

  /**Authenticate social customer */
  authenticateSocialCustomer(socialCustomer: any): Observable<any> {
    return this.http.post<any>(this.authenticateSocialUrl, socialCustomer)
      .pipe(
        catchError(this.utilService.handleError)
      );
  }
    /**Register customer*/
  registerCustomer(customer: any): Observable<any> {
    return this.http.post<any>(this.registerCustomerUrl, customer)
      .pipe(
        catchError(this.utilService.handleError)
      );
  }
  guestCustomer(customer: any): Observable<any> {
    return this.http.post<any>(this.guestCustomerUrl, customer)
    .pipe(
      catchError(this.utilService.handleError)
    );
  }
    updateCustomer(customer: any): Observable<any> {
      return this.http.post<any>(this.updateCustomerUrl, customer)
      .pipe(
        catchError(this.utilService.handleError)
      );
     }
     verifyCustomerMail(customer: any): Observable<any> {
      return this.http.post<any>(this.validateCustomerUrl, customer)
      .pipe(
        catchError(this.utilService.handleError)
      );
     }

      changePassword(customer: any): Observable<any> {
        return this.http.post<any>(this.changeCustomerUrl, customer)
        .pipe(
          catchError(this.utilService.handleError)
        );
      }
      forgotPassword(credential: any): Observable<any> {
        return this.http.post<any>(this.forgotPasswordUrl, credential)
        .pipe(
          catchError(this.utilService.handleError)
        );
      }

      getCustomerInfo(customerId: any): Observable<any> {
        const url = this.getCustomerInfoById + customerId;
        return this.http.get<any>(url)
            .pipe(
            );
    }
      getCustomerDetail(customerDetail: any) {
        this.cartService.setCustomer(customerDetail.customer);
        if (customerDetail.cart !== null) {
          this.storage.set(SESSION_SELECTED_SHOP, customerDetail.cart.shop);
          // this.storage.set(SESSION_SELECTED_SHOP_SERVICES , customerDetail.cart.shop.shopServices[0]);
        }
        this.storage.set(SESSION_AUTHENTICATED_CUSTOMER, customerDetail.customer);
        this.storage.set(SESSION_CUSTOMER_FAVOURITES, customerDetail.customer.favourites.favShops);
      }

}
