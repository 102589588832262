import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { COMPANY_INFO } from '../app.constants';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
  companyInfo: any;
  tcContent: any;

  constructor(private utilService: UtilService,
    private sanitizer: DomSanitizer,
    @Inject(SESSION_STORAGE) private storage: WebStorageService) {
    this.initTitleBarParams();
   }

  ngOnInit() {
    this.companyInfo = this.storage.get(COMPANY_INFO);
    this.tcContent = this.sanitizer.bypassSecurityTrustHtml(this.companyInfo.sales_policy);
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'TERMS AND CONDITIONS',
      showLogo: false,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }
}
