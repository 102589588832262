import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl, ValidatorFn, FormBuilder } from '@angular/forms';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { CartService } from '../cart/cart.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SESSION_AUTHENTICATED_CUSTOMER, DEF_DATE_FORMAT } from '../app.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { DatePipe } from '@angular/common';
import { Customer } from '../view-models/customer';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
export interface Title {
  value: string;
  display: string;
}

@Component({
  selector: 'app-guest-form',
  templateUrl: './guest-form.component.html',
  styleUrls: ['./guest-form.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: DEF_DATE_FORMAT},
  ],
})
export class GuestFormComponent implements OnInit {

  validPassword: boolean;
  validEmail: boolean;
  dob: any;
  redirectUrl: any;
  formControl: any;
  guest = <Customer>{};
  invalidMessage: string;
  hide = true;
  titles: Title[] = [
    { value: 'Mr.', display: 'Mr.' },
    { value: 'Ms.', display: 'Ms.' },
    { value: 'Mrs.', display: 'Mrs.' }
  ];
  /* Group for password */
  pwGroup: FormGroup;
  constructor(private utilService: UtilService, private cartService: CartService,
    @Inject(SESSION_STORAGE) private storage: WebStorageService, private router: Router, private activatedRoute: ActivatedRoute,
    private loginService: LoginService, private datePipe: DatePipe) {
    this.initTitleBarParams();
    this.redirectUrl = this.activatedRoute.snapshot.queryParams['redirectUrl'];


    const guestData = ((this.cartService.cart.customer !== undefined) ?
      this.cartService.cart.customer : { id: 0, firstName: 'Guest', customerType: 2 });
      Object.assign(this.guest, guestData);
      this.dob = new Date(this.guest.dob);
      /* Form validators */
    this.formControl = {
      email: new FormControl('', [Validators.required, Validators.email]),
      firstName: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(9)]),
      dob: new FormControl(_moment()),
      title: new FormControl('', [Validators.required])
    };
  }

  ngOnInit() {
  }

  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = true;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'GUEST FORM',
      showLogo: false,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showGuestBar: true
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }

  /* Returns email validation errors */
  getEmailError(): any {

    return (this.formControl.email.hasError('required') && (this.emailValidation(this.guest.email))) ?
     'Please enter a valid email address.' :
      (this.formControl.email.hasError('email') && (this.emailValidation(this.guest.email))) ? 'It is not a valid email address.' : '';

  }
  /**
   * first name validation
   */
  getFirstNameError(): any {
    let error: any;
    if (this.formControl.firstName.hasError('required')) {
      error = 'Please enter your name';
    } else if (!this.formControl.firstName.touched) {
      error = 'Please enter your name';
    }
    return error;
  }

  emailValidation(email) {
    this.validEmail = false;
    // tslint:disable-next-line:max-line-length
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validEmailRegEx.test(email)) {
        this.validEmail = true;
    } else {
      this.validEmail = false;
    }
   return this.validEmail;
  }
/**
 *
 * @param str password validation
 * at least one number, one lowercase and one uppercase letter
 * at least six characters
 */
checkPassword(str) {
  this.validPassword =  false;
  const  re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
  if (re.test(str)) {
    this.validPassword =  true;
  } else {
    this.validPassword =  false;
  }
  return this.validPassword;
}


  /**
   * phone number validation
   */
  getPhoneNumberError(): any {
    let error: any;
    if (this.formControl.phoneNumber.hasError('required')) {
      error = 'Please enter a valid phone number.';
    } else if
    (this.formControl.phoneNumber.errors.maxlength !== undefined &&
      (this.formControl.phoneNumber.errors.maxlength.actualLength) > (this.formControl.phoneNumber.errors.maxlength.requiredLength)) {
      error = 'Phone number with in 13 characters long.';
    } else if ( this.formControl.phoneNumber.errors.minlength !== undefined &&
      (this.formControl.phoneNumber.errors.minlength.actualLength) < (this.formControl.phoneNumber.errors.minlength.requiredLength)) {
      error = 'Phone number should be 9 characters long.';
    }
    return error;
  }
  /**
   * form validation
   */
  isFormValid(): boolean {
    const form = document.getElementById('guestForm');
    return (form[0].checkValidity());
  }
  /**
   * user form submition
   */
  guestUser() {
    if (this.isFormValid()) {
      // const userRegistration = {
      // 'loginId': this.guest, 'title': this.selectedTitle, 'firstName': this.firstName, 'lastName': this.lastName,
      // 'address': this.address, 'city': this.city, 'state': this.state,
      //  'dob': this.datePipe.transform(this.dob , 'yyyy/MM/dd'),
      // 'phoneNumber': this.phoneNumber, 'email': this.userId
      // };

      if (this.guest.phoneNumber !== null && this.guest.email !== null && this.guest.phoneNumber !== '' &&
       this.guest.title !== null && this.guest.title !== '' && this.guest.title !== undefined
        && this.guest.firstName !== undefined && this.guest.firstName !== null && this.guest.firstName !== '' && this.guest.email !== '') {
          if (this.emailValidation(this.guest.email)) {
          this.guest.loginId = this.guest.email;

            this.guest.dob = this.datePipe.transform(this.dob , 'yyyy/MM/dd');
            if (this.formControl.phoneNumber.errors !== null &&
              ((this.formControl.phoneNumber.errors.minlength.actualLength) <
              (this.formControl.phoneNumber.errors.minlength.requiredLength))) {
                  this.utilService.openDialog('Customer Info.', 'Phone number should be 9 characters long.');
        } else {
        this.loginService.guestCustomer(this.guest).subscribe(
          res => {
            if (res.success) {
              // if (res.object.customerType !== 2) {
              //   this.utilService.openDialog('Please login', 'Already  registered. Please login.');
              //   this.router.navigate(['/login'], { queryParams: { 'redirectUrl': '/order-confirmation' } });
              // } else {

                this.cartService.setCustomer(res.object);
                this.storage.set(SESSION_AUTHENTICATED_CUSTOMER, res.object);
                this.router.navigate([this.redirectUrl]);
             // }
            } else {
              this.utilService.openDialog('Please login', res.message);
              this.router.navigate(['/login']);
            }
          }
        );
      }
      } else {
        this.utilService.openDialog('Customer Info.', 'Enter valid email address ');

      }
      } else {
        this.utilService.openDialog('Customer Info.', 'Customer information is not entered.');
      }
    } else {
      this.utilService.openDialog('Customer Info.', 'Customer information is not entered.');
    }

  }

}
