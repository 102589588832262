import { Component, OnInit, Inject, ViewEncapsulation, HostListener } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig, StatusBarService } from '../../fw/services/status-bar.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import {
  SESSION_SELECTED_SHOP, SESSION_SALE_ITEM_LIST, PRODUCT_IMAGE_FOLDER,
  SESSION_SYSTEM_SETTINGS,
  SESSION_CART,
  SESSION_ITEM_CATEGORY_LIST, SESSION_SELECTED_SUBCATEGORY_ID, SESSION_MASTER_CUSTMIZATION, SESSION_FILTERED_SALE_ITEM_LIST, SESSION_OUTOFSTOCK_ITEM_LIST
} from '../app.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { StartupService } from '../services/startup.service';
import { SelectItem } from 'primeng/components/common/selectitem';
import { SettingsService } from '../settings/settings.service';
import { CartService, CartItem, Cart } from '../cart/cart.service';
import { AnimationService } from '../services/animation.service';
import { TaxService } from '../services/tax.service';
import { GtmService } from '../gtm.service';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ItemDetailsComponent implements OnInit {
  cart: Cart;
  remark: any = '';
  cartItemInfo: CartItem;
  sourceParam: any;
  currencySymbol: any;
  systemSettings: any;
  shopItemCategories: any[];
  quantity: SelectItem[];
  selectedQuantity: any = 1;
  uploadUrl: any;
  imageUrl: any;
  shopName: any;
  currentSaleItemId: number;
  allSaleItemList: any[];
  saleItemInfo: any;
  images: any[] = [];
  checked = false;
  itemCategories: any;
  shopDepartmentIds: any;
  message: any;
  selectedSubCategory: any;
  selectedParentCategory: any;
  currentSubCategoryId: any;
  customizationDetail: any;
  customizationUnitPriceWithTax: any;
  customizationUnitTotalTax: number;
  CurrentCustzdOptnSelected: any;
  filteredSaleItemList: any;
  outOfStockItemIds: any;
  outOfStockItemInfo: any;

  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService, private router: Router,
    private route: ActivatedRoute, private _settingsService: SettingsService, private statusBarService: StatusBarService,
    private utilService: UtilService, private startUp: StartupService, private cartService: CartService, public taxService: TaxService,
    private cartAnimServce: AnimationService,
    private gtmService:GtmService) {
    this.filteredSaleItemList = this.sessionStorage.get(SESSION_FILTERED_SALE_ITEM_LIST);
    this.outOfStockItemIds = this.sessionStorage.get(SESSION_OUTOFSTOCK_ITEM_LIST);
    this.outOfStockItemInfo = this.filteredSaleItemList.filter(fs => this.outOfStockItemIds.findIndex(os => fs.id === os) >= 0);
    this.shopName = this.sessionStorage.get(SESSION_SELECTED_SHOP).name;
    this.cart = this.cartService.cart;
    this.customizationUnitPriceWithTax = 0;
    this.customizationUnitTotalTax = 0;
    this.CurrentCustzdOptnSelected='';
    this.sourceParam = this.route.snapshot.queryParams['source'];
    this.allSaleItemList = this.sessionStorage.get(SESSION_SALE_ITEM_LIST);
    this.customizationDetail = [];
    if (this.sourceParam === 'cart') {
      const cartItemId = this.route.snapshot.queryParams['id'];
      this.cartItemInfo = this.cart.items.find(cs => cs.itemId === cartItemId);
      if(this.cartItemInfo === undefined){
        this.router.navigate(['/category-list']);
      }
      this.saleItemInfo = this.allSaleItemList.find(asl => asl.id == this.cartItemInfo.ooSaleItemId);
      this.currentSaleItemId = this.saleItemInfo.saleItemId;
      this.selectedQuantity = this.cartItemInfo.quantity;
      this.customizationUnitPriceWithTax=((this.cartItemInfo.customizationUnitPriceWithTax !== undefined)?this.cartItemInfo.customizationUnitPriceWithTax:0);
      this.remark = this.cartItemInfo.remarks;
      this.customizationDetail = this.cartItemInfo.customization;
      if(this.customizationDetail.length>0){
        this.customizationDetail = JSON.parse(JSON.stringify(this.customizationDetail));
      }else{
        this.customizationDetail = this.cartService.getCustomizationDetail(this.saleItemInfo);
      }
    } else {
      this.currentSaleItemId = this.route.snapshot.queryParams['id'];
      this.saleItemInfo = this.allSaleItemList.find(asl => asl.saleItemId == this.currentSaleItemId);
      /**
       * customization detail
       */
       this.customizationDetail = this.cartService.getCustomizationDetail(this.saleItemInfo);
    }
   
    this.shopItemCategories = this.sessionStorage.get(SESSION_SELECTED_SHOP).item_category;
    this.itemCategories = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST);
    this.shopDepartmentIds = this.sessionStorage.get(SESSION_SELECTED_SHOP).department;

    // this.currentSubCategoryId = this.sessionStorage.get(SESSION_SELECTED_SUBCATEGORY_ID);
    this.currentSubCategoryId = this.saleItemInfo.itemCategoryId;
    this.selectedSubCategory = this.itemCategories.find(ic => ic.id === this.currentSubCategoryId);
    this.selectedParentCategory = this.itemCategories.find(ic => ic.id === this.selectedSubCategory.parentId);
    if (this.selectedParentCategory.code === 'BENTO') {
      this.message = 'AVAILABLE ONLY IN SHOPS SELLING BENTO BOWL';

    } else {
      this.message = 'TEMPORARILY UNAVAILABLE';

    }

    this.getQuantity();
    this.initTitleBarParams();
    this.systemSettings = sessionStorage.get(SESSION_SYSTEM_SETTINGS);
    this.currencySymbol = this.systemSettings.currency.symbol;
    if (this.systemSettings === null || this.systemSettings === undefined) {
      this._settingsService.loadSystemSettings().subscribe(
        res => {
          this.systemSettings = res.object;
          this.sessionStorage.set(SESSION_SYSTEM_SETTINGS, this.systemSettings);
          this.currencySymbol = this.systemSettings.currency.symbol;
        }
      );
    }
  }

  ngOnInit() {
    this.statusBarService.parent = this;
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.imageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;
    this.getSaleInfoImages();
    this.updateGTMInfo('view_item');
  }

  

  getSaleInfoImages() {
    if (this.saleItemInfo.images !== null) {
      for (let count = 0; count < this.saleItemInfo.images.length; count++) {
        this.images.push({
          source: this.imageUrl + this.saleItemInfo.images[count],
          alt: ' Description for Image 1', title: 'Title 1'
        });
      }
    } else {
      this.images.push({
        source: 'assets/no-image-available.png',
        alt: '  No image', title: 'No image'
      });
    }
  }
  // getSystemSettings() {
  //   const systemSettings = this._settingsService.getSystemSetings();
  //   this.currencySymbol = this.systemSettings.currency.symbol;
  // }
  // getSystemSettings() {
  //   this._settingsService.getSystemSettings().subscribe(
  //     res => {
  //       this.systemSettings = res.object;
  //       this.sessionStorage.set(SESSION_SYSTEM_SETTINGS, this.systemSettings);
  //       this.currencySymbol = this.systemSettings.currency.symbol;
  //     }
  //   );

  // }

  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = this.isItemAvailable();
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'ITEM DETAILS',
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: false,
      hideBackButton: false

    };
    this.utilService.setTitleBarParams(this, titleConfig);
    if (this.sourceParam === 'cart') {
      const footerConfig: FooterConfig = {
        showitemDetailAddCartBar: false,
        showUpdateCartItemBar: true
      };
      this.utilService.setFooterBarParams(this, footerConfig);
    } else {
      const footerConfig: FooterConfig = {
        showitemDetailAddCartBar: true
      };
      this.utilService.setFooterBarParams(this, footerConfig);
    }
  }


  isItemAvailable() {
    let itemAvailable = true;
    const itemCatIndex = this.itemCategories.findIndex(obj => obj.id === this.saleItemInfo.itemCategoryId);

    if (this.saleItemInfo.ooBusinessTypeId === null ||
      !(this.saleItemInfo.ooBusinessTypeId.findIndex(bt => bt === this.cart.businessType.id) > -1)) {
      itemAvailable = false;
      this.message = 'ITEM NOT AVAILABLE FOR ' + this.cart.businessType.name;
    } else if (this.shopDepartmentIds === null ||
      !(this.shopDepartmentIds.findIndex(obj => obj === this.itemCategories[itemCatIndex].departmentId) >= 0)) {
      itemAvailable = false;
    } else if (this.shopItemCategories !== null && !(this.shopItemCategories.findIndex
      (obj => obj === this.saleItemInfo.itemCategoryId) >= 0)) {
      itemAvailable = false;
    } else if (!this.availableDay()) {
      itemAvailable = false;
      this.message = 'ITEM NOT AVAILABLE on ' + this.cart.deliveryDate.label;
    }
    return itemAvailable;
  }

  getQuantity() {
    this.quantity = [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
    ];
  }
  availableDay() {

    let pickUpDay = new Date(this.cart.deliveryDate.date).getDay();
    if (pickUpDay === 0) {
      pickUpDay = 7;
    }
    return this.saleItemInfo.itemAvailableDay.findIndex(e => e === pickUpDay) > -1;
  }
  redirectToShop() {
    this.router.navigate(['/storefinder']);
  }

  addToCart() {
    this.checked = false;
    this.isCustomizable();
    this.cartAnimServce.addToCartAnimation('item-image-panel', 'checkout-btn');
    this.cartAnimServce.scalAnim('cart-update-anim');
    let item: CartItem;

    item = {
      itemId: 0,
      ooSaleItemId: this.saleItemInfo.id, saleItemId: this.saleItemInfo.saleItemId, quantity: this.selectedQuantity,
      fixedPrice: this.saleItemInfo.fixedPrice, 
      totalPrice: (this.saleItemInfo.fixedPrice * this.selectedQuantity),
      unitPriceWithTax: this.saleItemInfo.unitPriceWithTax,
      customizationUnitPriceWithTax: 0,
      isCustomized: this.checked, customization: JSON.parse(JSON.stringify(this.customizationDetail)), remarks: this.remark,
      taxCalculationMethod: this.saleItemInfo.taxCalculationMethod, tax1Percentage: this.saleItemInfo.tax1Percentage,
      netCustomizationTotal:0,
      netTotal: 0, totalTax: 0
    };
    this.cartService.setCustomization(item);
    this.taxService.reCalculateTax(item);
    this.cartService.addItem(item);

    this.updateGTMInfo('add_to_cart');
  }

  updateCart() {
    this.cartAnimServce.itemAddedToCart('checkout-btn');
    /**
     * for checking , is customized or not
     */
    this.checked = false;
    this.isCustomizable();

    this.cartItemInfo.quantity = this.selectedQuantity,
    this.cartItemInfo.totalPrice = (this.saleItemInfo.unitPriceWithTax * this.selectedQuantity) ;
    this.cartItemInfo.isCustomized = this.checked;
    this.cartItemInfo.customization = this.customizationDetail;
    this.cartService.setCustomization(this.cartItemInfo);

    this.cartItemInfo.remarks = this.remark;
    if (this.sourceParam === 'cart') {
      this.router.navigate(['/cart']);
    }
    this.cartService.changeItem(this.cartItemInfo);
  }



  isCustomizable(){
    for(let cd of this.customizationDetail){
      for(let ov of cd.optionValue){
        if( ov.isDefault === 0 && ov.isSelected ===1){
          this.checked = true;
          return;
        }
      }
    }
  }

  nutritionalAccordionOpen() {

    const ement = document.querySelector('.item-nutritional-info-span');
    this.scrollToView(ement);

  }

  descriptionAccordionOpen() {

    const ement = document.querySelector('.item-description-span');
    this.scrollToView(ement);

  }

  customizationAccordionOpen(optIndex) {
    
    const ement = document.querySelector('#customization-option_'+optIndex);
    this.scrollToView(ement);

  }


  scrollToView(element) {
    setTimeout(() => {
      element.scrollIntoView({
        behavior: "smooth",
        block:"nearest"
      });
    }, 500);
  }


  selectCustomization(selectedValue, customizationOption) {
    this.removeOptionVal(customizationOption.optionValue);
    selectedValue.isSelected = 1;
    this.customizationUnitPriceWithTax +=  (selectedValue.isDefault)?0:selectedValue.unitPriceWithTax;
    // if(selectedValue.isDefault !== 1 && selectedValue.isSelected ===1){
    //  this.CurrentCustzdOptnSelected =  selectedValue;
    // }
  }

  getCustomizationDisplayName(customizationData){
    let dispName = '';

    let custOptData = customizationData.optionValue.find(co => co.isDefault !== 1 && co.isSelected === 1)
    if (custOptData !== undefined) {
      dispName = custOptData.name + ' : ' + this.currencySymbol + parseFloat(custOptData.unitPriceWithTax).toFixed(2);
    }

    return dispName;
  }
  
  isValidCustOptVal(custOptVal){
    let isValid = true;
    if(custOptVal.saleItemId !== null ){
      isValid = this.filteredSaleItemList.some(oi => custOptVal.saleItemId === oi.saleItemId);
    }

    return isValid;
  }

  isOutOfStockCustOptVal(custOptVal){
    let isValid = true;
    if(custOptVal.saleItemId !== null && this.outOfStockItemInfo !== undefined && this.outOfStockItemInfo.length > 0){
      isValid = !this.outOfStockItemInfo.some(oi => custOptVal.saleItemId === oi.saleItemId);
    }

    return isValid;
  }

  removeOptionVal(customizationOptionValues){
    for(let elem of customizationOptionValues){
      if (elem.isSelected == 1) {
        this.customizationUnitPriceWithTax += (elem.isDefault)?0:(-elem.unitPriceWithTax);
        this.customizationUnitTotalTax += (elem.isDefault)?0:(-elem.tax);
        elem.isSelected = 0;
        return;
      }
     }
  }

    /**
   * Enter key press prevented in cart item remarks.
   * ie, new line not allowed.
   * @param event 
   * @returns 
   */
     onKeydown(event){
      if(event.keyCode === 13) {
        event.preventDefault();
        return false;
      }
    }

    /**
     * Extract the selected customizations
     * @returns 
     */
    getSelectedCustomizations():any{
      let selectedCustomization =[];
      let custTotalPrice=0;
      for (let cd of this.customizationDetail) {
        for (let ov of cd.optionValue) {
          if (ov.isSelected === 1 && ov.isDefault==0) {
            selectedCustomization.push(ov);
            custTotalPrice+=ov.fixedPrice;
          }
        }
      }
      return {selectedCustomization:selectedCustomization,total:custTotalPrice};

    }
  /**
   * Update the GTM info
   */
    goToCart(){
      this.updateGTMInfo('view_cart');
    }


    /**
   * GTM CODE BRGINS
   */

  updateGTMInfo(event: string): void {

    this.gtmService.pushEvent(
      '',
      {
        userId: this.gtmService.getCustomer(),
        visitorType: this.gtmService.getVisitorType(),
        dateSelectd: this.gtmService.geDeliveryDate(),
        timeSelected: this.gtmService.getDeliveryTime(),
        preOrder: this.gtmService.isPreOrder()

      });

    let items = [];

    items.push({
      item_id: this.saleItemInfo.code,
      item_name: this.saleItemInfo.name,
      affiliation: this.gtmService.getStore(),
      item_brand: this.selectedParentCategory.name,
      item_category: this.selectedSubCategory.name,
      item_variant: '',
      price: this.saleItemInfo.unitPriceWithTax * this.selectedQuantity,
      quantity: this.selectedQuantity

    });
    //{selectedCustomization:selectedCustomization,total:custTotalPrice};
    let customization = this.getSelectedCustomizations();

    if (customization.selectedCustomization.length > 0) {
      items.push(
        {
          item_id: this.saleItemInfo.code,
          item_name: this.saleItemInfo.name,
          item_category2: (customization.selectedCustomization.length > 0) ? customization.selectedCustomization[0].nameToPrint : '',
          item_category3: (customization.selectedCustomization.length > 1) ? customization.selectedCustomization[1].nameToPrint : '',
          item_category4: (customization.selectedCustomization.length > 2) ? customization.selectedCustomization[2].nameToPrint : '',
          item_category5: (customization.selectedCustomization.length > 3) ? customization.selectedCustomization[3].nameToPrint : '',
          price: customization.total * this.selectedQuantity,
          quantity: this.selectedQuantity
        }
      );
    }

    this.gtmService.pushG4AEvent(
      event,
      {
        ecommerce: {
          currency: 'NZD',
          value: (this.saleItemInfo.unitPriceWithTax + this.customizationUnitPriceWithTax) * this.selectedQuantity,
          items: items
        }
      });
  }

  /**
   * Handle back button press
   */

  handleGTMPageEngagement(previousUrl: string) {
    if (previousUrl.startsWith('/item-list') || previousUrl.startsWith('/cart')) {
      this.gtmService.pushEvent(
        'page_engagement',
        {
          actionType: (previousUrl.startsWith('/cart')) ? 'Back to Order details' : 'Back to Item List',
          brandName: this.gtmService.getItemCatgoryName(this.selectedParentCategory.id),
          categoryName: this.selectedSubCategory.name,
          storeSelected: this.gtmService.getStore(),
          visitorType: this.gtmService.getVisitorType(),
          dateSelectd: this.gtmService.geDeliveryDate(),
          timeSelected: this.gtmService.getDeliveryTime(),
          preOrder: this.gtmService.isPreOrder()
        });
    }
  }

  /**
   * GTM CODE END
   */

}
