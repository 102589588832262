import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { orderServiceMenuItems } from './order-service.menu';
import { ScreenService } from '../../fw/services/screens.service';
import { AppDataService } from '../services/app-data.service';
import { UtilService } from '../services/util.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { CartService } from '../cart/cart.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SESSION_CART } from '../app.constants';
import { initialMenuItems } from '../app.menu';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-service-selection',
  templateUrl: './order-service-selection.component.html',
  styleUrls: ['./order-service-selection.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})

export class OrderServiceSelectionComponent implements OnInit {
  orderServiceTypes: Array<any>;
  constructor( @Inject(SESSION_STORAGE) private storage: WebStorageService, private confirmationService: ConfirmationService,
  private utilService: UtilService, private appDataService: AppDataService, private cartService: CartService,
             private screenService: ScreenService, private router: Router) {
              this.utilService.progressService.isLoading = true;
              this.getAllOnlineServices();

  }

  ngOnInit() {
  }

  /** Fetch all online service types */
  getAllOnlineServices() {
    this.appDataService.getOrderServiceTypes().subscribe(
      res => {
        if (res.success) {
          this.orderServiceTypes = res.object;
          if ( this.orderServiceTypes.length > 1) {
            this.initTitleBarParams();
            // this.utilService.setMenuItems(orderServiceMenuItems);
            this.utilService.setMenuItems(initialMenuItems);

          } else {

            this.cartService.setService(this.orderServiceTypes[0]);
            this.router.navigate(['/login']);
          }

        }
        this.utilService.progressService.isLoading = false;

      }
    );
  }

  /**Configure title bar and footer bar */
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title:  'SELECT SERVICE',
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: false,
      hideBackButton: false

    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showGuestUserBtn: false,
      showShopListBar: false,
      showShopInfoBar: false,
      showitemDetailAddCartBar: false,
      showUpdateCartItemBar: false,
      showPaymentSucessBottomBar: false,
      showOrderDetailsBar: false,
      showItemListBar: false,
      showCartBar: false,
      showSignUpBar: false,
      orderConfirmationBar: false,
      showGuestBar: false,
      showChangePasswordBar: false,
      showMyProfileBar: false,
      showSubmitContactUs: false,
      showFaqBar: false,
      showRetryPaymentBar: false


    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }

  serviceSelected(orderServiceType) {
    if (this.cartService.cart.service !== undefined && this.cartService.cart.service.id !== orderServiceType.id &&
       (this.cartService.cart.items.length !== 0)) {
      this.confirmationService.confirm({
        message: 'This will reset your cart. Do you want to proceed?',
        accept: () => {
          this.cartService.cart.shop = undefined;
          this.cartService.cart.items = [];
          this.cartService.cart.service = undefined;
          this.cartService.cart.deliveryDate = undefined;
          this.cartService.cart.deliveryTime = undefined;
          this.cartService.cart.remarks = undefined;
          this.cartService.cart.pickupLocationId = undefined;
          this.cartService.setToSession();
          this.setService (orderServiceType);
      }

      });
    } else {
      this.setService (orderServiceType);
    }


  }
  setService (orderServiceType) {
    this.cartService.setService(orderServiceType);
    //   this.storage.set(SESSION_SELECTED_SERVICE, this.orderServiceType);
      this.router.navigate(['/login']);
  }
}
