import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  isCancelled: boolean;
  title: string;
  remarks: string;
}

@Component({
  selector: 'app-remarks-dialog',
  templateUrl: './remarks-dialog.component.html',
  styleUrls: ['./remarks-dialog.component.css']
})
export class RemarksDialogComponent implements OnInit {

  ngModelData: DialogData;
  constructor(
    public dlgRemarks: MatDialogRef<RemarksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      this.ngModelData = Object.assign(this.data);
      this.ngModelData.isCancelled = false;
    }

  onNoClick(): void {
    this.ngModelData.isCancelled = true;
    this.dlgRemarks.close(this.ngModelData);
  }

  ngOnInit() {
  }

}
