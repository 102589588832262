import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ItemListService } from '../item-list/item-list.service';

@Component({
  selector: 'app-horizontal-nav-bar',
  templateUrl: './horizontal-nav-bar.component.html',
  styleUrls: ['./horizontal-nav-bar.component.css']
})
export class HorizontalNavBarComponent implements OnInit {
  @Input() activeContents: any;
  @Input() selectedContent: any;
  @Input() contents: any;
  @Output() contentSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _itemListService: ItemListService) { }

  ngOnInit() {
      if (this.selectedContent === undefined) {
      this.selectedContent = this.contents[2];
    }
  }
  /**
   * @param content
   * when click on each content this event will be raised
   */
  contentClick(content) {
    this.contentSelected.emit(content);
    this.selectedContent = content;
    const element = document.getElementById('cat_hlist_' + content.id);
    const Elemntrect = element.getBoundingClientRect();
    const navelement = document.getElementById('pnProductNav');
    const navrect = navelement.getBoundingClientRect();
    const midOfNav = (navrect.left + navrect.right) / 2;
    if (Elemntrect.left > midOfNav) {
      document.getElementById('pnProductNav').scrollLeft += 90; // scroll to left
    } else {
      document.getElementById('pnProductNav').scrollLeft -= 90; // scroll to right
    }
  }
  onScroll() {
    this._itemListService.toggleButtonsHorizontalScroll();
    }
  // getVisibleCategories(category) {
  //   return this._itemListService.getVisibleCategories(category);

  // }
  isSelected(content): boolean {
    // console.log('content id' + content.id);
    // console.log('selected id' + this.selectedContent.id);
    return (this.selectedContent.id === content.id) ? true : false;
  }
  isInactiveCategory(content) {
    let isInactive = false;
    if (this.activeContents !== null && this.selectedContent !== undefined) {
      if (this.activeContents.findIndex(obj => obj === content.id) >= 0) {
        isInactive = false; // active show #fff200 color
      } else {
        isInactive = (this.selectedContent.id !== content.id) ? true : false;
      }
    }
    return isInactive;
  }
  loadNextItem() {
    document.getElementById('pnProductNav').scrollLeft += 50;
    /*****Show/Hide of Next and Previous Menus***/
    this._itemListService.toggleNextItem();
  }
  loadPreviousItem() {
    document.getElementById('pnProductNav').scrollLeft -= 50;
    /*****Show/Hide of Next and Previous Menus***/
    this._itemListService.togglePreviousItem();
  }
}



