import { Component, Input, OnInit } from '@angular/core';
import { CartService } from 'src/app/cart/cart.service';
import { RewardService } from 'src/app/services/reward.service';
import { UtilService } from 'src/app/services/util.service';
import { TitleConfig } from 'src/fw/services/hdr-title.service';

@Component({
  selector: 'app-reward-list',
  templateUrl: './reward-list.component.html',
  styleUrls: ['./reward-list.component.css']
})
export class RewardListComponent implements OnInit {

 
  @Input() activeOnly:boolean=false;

  myRewards:any[];
  myActiveRewards:any[];
  customer:any;

  constructor(private rewardService:RewardService,private cartService:CartService, ) { 

    this.customer=cartService.cart.customer;
    this.myRewards=rewardService.getMyRewards(this.customer.id);
    this.myActiveRewards=this.myRewards.filter((rw)=> rw.customer_status==1);
    

  }

  ngOnInit() {
  }


  /***
   * Returns active / all rewards based on input
   */
  getRewards():any[]{

    return (this.activeOnly)?this.myActiveRewards:this.myRewards;
  }

}
