import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StartupService } from './startup.service';
import { UtilService } from './util.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { E } from '@angular/core/src/render3';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class CrmApiService {

  token: string;
  crmBaseUrl: string

  constructor(private http: HttpClient, private utilService: UtilService,
    private startUp: StartupService,
    private tokenService:TokenService) {
    this.crmBaseUrl = this.startUp.startupData.crmApiUrl;
    // this.getToken();
  }

  /***
   * Get the CRM tocken
   */
  // async getToken(): Promise<void> {
  //   let data = { user_name: environment.crmUser, password: environment.crmPassword, apikey: environment.crmAppKey };
  //   this.http.post(this.crmBaseUrl + '/auth', data).subscribe({
  //     next: (response:any) => {
  //       if (response.status == 'SUCCESS') {
  //         this.token=response.token;
  //       } else {
  //       }
  //     },
  //     error: (error) => {
        
  //     }
  //   });
  // }

  /**
   * Call server api to fetch the customer rewards
   * @param customerId 
   * @returns 
   */
  getRewards(customerId): Observable<any>{

    return this.tokenService.getToken().pipe( 
    map((response) => response), 
    catchError((error) => {
      throw error;
    }),
    switchMap((response: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: response,
        }),
      };
  
     return this.http.get(this.crmBaseUrl + '/customer/getRewards/' + customerId,httpOptions);
    })
    );

    
  }
}
