import { Component, OnInit, Inject } from '@angular/core';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from 'src/fw/services/status-bar.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private utilService: UtilService,
    @Inject(SESSION_STORAGE) private storage: WebStorageService) {
      this.initTitleBarParams();
     }
    ngOnInit() {
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'ABOUT US',
      showLogo: false,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showFaqBar: true
    };
    this.utilService.setFooterBarParams(this, footerConfig);

  }
}
