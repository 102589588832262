import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import {
  PRODUCT_IMAGE_FOLDER, SESSION_ITEM_CATEGORY_LIST, SESSION_SELECTED_SUBCATEGORY_ID,
  SESSION_SELECTED_SHOP,
  SESSION_OUTOFSTOCK_ITEM_LIST,
  SESSION_MASTER_CUSTMIZATION
} from '../../app.constants';
import { StartupService } from '../../services/startup.service';
import { ItemListService } from '../../item-list/item-list.service';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { Router } from '@angular/router';
import { SettingsService } from '../../settings/settings.service';
import { CartService, Cart, CartItem } from '../../cart/cart.service';
import { StatusBarService } from 'src/fw/services/status-bar.service';
import { AnimationService } from 'src/app/services/animation.service';
import { TaxService } from 'src/app/services/tax.service';
import { GtmService } from 'src/app/gtm.service';

@Component({
  selector: 'app-item-list-panel',
  templateUrl: './item-list-panel.component.html',
  styleUrls: ['./item-list-panel.component.css']
})
export class ItemListPanelComponent implements OnInit {
  cart: Cart;
  shopDepartmentIds: any;
  shopItemCategories: any[];
  imageUrl: any;
  uploadUrl: any;
  @Input() saleItem: any;
  itemCategories: any;
  message: any;
  messageclass: any;
  selectedSubCategory: any;
  selectedParentCategory: any;
  currentSubCategoryId: any;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  checked = false;
  remark: any = '';
  outOfStockItemIds: any;
  OutItem: any;
  customizatinDetail: any[];
  allCustmizationMasterDtl: any;


  constructor(private startUp: StartupService, private cartAnimServce: AnimationService,
    public statusBarService: StatusBarService, public taxService: TaxService,
    private _itemListService: ItemListService, private cartService: CartService,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService, public settingsService: SettingsService, private router: Router,
    private gtmService:GtmService) {
    this.shopItemCategories = this.sessionStorage.get(SESSION_SELECTED_SHOP).item_category;
    this.itemCategories = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST);
    this.shopDepartmentIds = this.sessionStorage.get(SESSION_SELECTED_SHOP).department;
    this.outOfStockItemIds = this.sessionStorage.get(SESSION_OUTOFSTOCK_ITEM_LIST);
    this.allCustmizationMasterDtl = this.sessionStorage.get(SESSION_MASTER_CUSTMIZATION);
    this.cart = this.cartService.cart;
  }

  ngOnInit() {
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.imageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;
    this.currentSubCategoryId = this.saleItem.itemCategoryId; // this.sessionStorage.get(SESSION_SELECTED_SUBCATEGORY_ID);
    this.selectedSubCategory = this.itemCategories.find(ic => ic.id === this.currentSubCategoryId);
    this.selectedParentCategory = this.itemCategories.find(ic => ic.id === this.selectedSubCategory.parentId);
    if (this.selectedParentCategory.code === 'BENTO') {
      this.message = 'AVAILABLE ONLY IN SHOPS SELLING BENTO BOWL';
      this.messageclass = 'na-button1';
    } else {
      this.message = 'TEMPORARILY UNAVAILABLE';
      this.messageclass = 'na-button';
    }
    if(this.outOfStockItemIds.includes(this.saleItem.id)){
      this.message = 'OUT OF STOCK';
      this.messageclass = 'na-button1';
    }
    
  }
  /**
   * Check whether the current product is available on the shop product list
   */
  addToCart(event,idd) {
    if(!this.outOfStockItemIds.includes(this.saleItem.id)){
    event.stopPropagation(); 
    /**
     * customization detail
     */
    this.customizatinDetail =[];
    if(this.saleItem.itemCustOptionIds!== null && this.saleItem.itemCustOptionIds.length>0){
      this.customizatinDetail = this.allCustmizationMasterDtl.filter(ac =>
      this.saleItem.itemCustOptionIds.find(e => e === ac.custOptId)
      );
    if(this.customizatinDetail !== undefined && this.customizatinDetail.length>0){
      this.customizatinDetail = JSON.parse(JSON.stringify(this.customizatinDetail));
     }
    }

    const imageclass = 'item-img_' + idd;
    this.cartAnimServce.addToCartAnimation(imageclass, 'checkout-btn');
    let item: CartItem;
    item = {
      itemId: 0,
      ooSaleItemId: this.saleItem.id, saleItemId: this.saleItem.saleItemId, quantity: 1,
      fixedPrice: this.saleItem.fixedPrice, totalPrice: this.saleItem.fixedPrice * 1,
      unitPriceWithTax: this.saleItem.unitPriceWithTax,customizationUnitPriceWithTax:0,
      isCustomized: this.checked, customization: this.customizatinDetail, remarks: this.remark,
      taxCalculationMethod: this.saleItem.taxCalculationMethod, tax1Percentage: this.saleItem.tax1Percentage,
      netTotal: 0, totalTax: 0, netCustomizationTotal:0
    };
    this.taxService.reCalculateTax(item);

    this.cartService.addItem(item);
    this.updateGTMInfo('add_to_cart');
    // if(this.saleItem.itemType === 2 && this.saleItemInfo.isCustomizable === 1){
    //   this.router.navigate(['/item-details'], { queryParams: {'source' : 'itemlist', 'id': item.itemId} });
    // }
    }
  }
  isItemAvailable() {
    let itemAvailable = true;
    const itemCatIndex = this.itemCategories.findIndex(obj => obj.id === this.saleItem.itemCategoryId);


    if (this.saleItem.ooBusinessTypeId === null ||
      !(this.saleItem.ooBusinessTypeId.findIndex(bt => bt === this.cart.businessType.id) > -1)) {
      itemAvailable = false;
      this.message = 'ITEM NOT AVAILABLE FOR ' + this.cart.businessType.name;
    } else if (this.shopDepartmentIds === null || ((itemCatIndex !== -1) &&
      !(this.shopDepartmentIds.findIndex(obj => obj === this.itemCategories[itemCatIndex].departmentId) >= 0))) {
      itemAvailable = false;
    } else if (this.shopItemCategories !== null && !(this.shopItemCategories.findIndex(obj => obj === this.saleItem.itemCategoryId) >= 0)) {
      itemAvailable = false;
    } else if (!this.availableDay()) {
      itemAvailable = false;
      this.message = 'ITEM NOT AVAILABLE ON ' + this.cart.deliveryDate.label;
    }else if(this.outOfStockItemIds.includes(this.saleItem.id)){
      itemAvailable = false;
      this.message = 'OUT OF STOCK';
    }
    return itemAvailable;
  }
  /**
   * rediect to  item detail page
   */
  itemClick() {
    if(!this.outOfStockItemIds.includes(this.saleItem.id)){
      this.updateGTMInfo('select_item');
      this.itemSelected.emit(this.saleItem);
    }
    // this.router.navigate(['/item-details'], { queryParams: {'source' : '', 'id': this.saleItem.saleItemId} });
  }

 

  availableDay() {

    let pickUpDay = new Date(this.cart.deliveryDate.date).getDay();
    if (pickUpDay === 0) {
      pickUpDay = 7;
    }
    return this.saleItem.itemAvailableDay.findIndex(e => e === pickUpDay) > -1;
  }

  /**
   * GTM CODE BEGIN
   */

   /**
   * update gtm info
   * @param event 
   */
   updateGTMInfo(event: string): void {

    if (event == 'select_item') {

      this.gtmService.pushEvent(
        '',
        {
          userId: this.gtmService.getCustomer(),
          visitorType: this.gtmService.getVisitorType(),
          dateSelected: this.gtmService.geDeliveryDate(),
          timeSelected: this.gtmService.getDeliveryTime(),
          preOrder: this.gtmService.isPreOrder()
        }
      );

      this.gtmService.pushG4AEvent(
        'select_item',
        {
          ecommerce: {
            items: [
              {
                item_id: this.saleItem.code,
                item_name: this.saleItem.name,
                affiliation: this.gtmService.getStore(),
                item_brand: this.gtmService.getItemCatgoryName(this.selectedParentCategory.id),
                item_category: this.gtmService.getItemCatgoryName(this.saleItem.itemCategoryId),
                item_variant: "",
                price: this.saleItem.fixedPrice,
                quantity: 1
              }]
          }
        }
      );
    } else if (event == 'add_to_cart') {

      this.gtmService.pushEvent(
        '',
        {
          userId: this.gtmService.getCustomer(),
          visitorType: this.gtmService.getVisitorType(),
          dateSelectd: this.gtmService.geDeliveryDate(),
          timeSelected: this.gtmService.getDeliveryTime(),
          preOrder: this.gtmService.isPreOrder()

        });

      let items = [];

      items.push({
        item_id: this.saleItem.code,
        item_name: this.saleItem.name,
        affiliation: this.gtmService.getStore(),
        item_brand: this.selectedParentCategory.name,
        item_category: this.selectedSubCategory.name,
        item_variant: '',
        price: this.saleItem.fixedPrice,
        quantity: 1

      });

      // items.push(
      //   {
      //     item_id: this.saleItem.id,
      //     item_name: this.saleItem.name,
      //     item_category2: 'customization1',
      //     item_category3: 'customization2',
      //     item_category4: 'customization3',
      //     item_category5: 'customization4',
      //     price: this.saleItem.fixedPrice,
      //     quantity:1
      //   }
      // );


      this.gtmService.pushG4AEvent(
        'add_to_cart',
        {
          ecommerce: {
            currency: 'NZD',
            value: this.saleItem.fixedPrice,
            items: items
          }
        });
    }
  }

/**
 * GTM CODE ENDS
 */

  
}

