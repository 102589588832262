import { Component, OnInit, Inject } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import {
  SESSION_AUTHENTICATED_CUSTOMER,
  SESSION_CUSTOMER_FAVOURITES, SESSION_CART,
  SESSION_SELECTED_SHOP, SESSION_SELECTED_SERVICE,
  SESSION_SELECTED_SHOP_SERVICES, SESSION_ITEM_CATEGORY_LIST,
  SESSION_SELECTED_SUBCATEGORY_ID, SESSION_SALE_ITEM_LIST, SESSION_SYSTEM_SETTINGS,
  COMPANY_INFO, SESSION_SHOP_LIST, SESSION_REGION_LIST, SESSION_AMENITY_LIST, SESSION_FAV_FILTER_BTN, SESSION_LAST_SUCCESS_TXN_ID, SESSION_LAST_SUCCESS_TXN_RECEIPT, SESSION_MASTER_CUSTMIZATION, SESSION_LOGIN_PROVIDER, SESSION_CUSTOMER_REWARDS
} from '../app.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService } from '../cart/cart.service';
import { UtilService } from '../services/util.service';
// import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private router: Router, 
    private cartService: CartService, 
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute

  ) {
    this.utilService.progressService.isLoading = true;
  }

  ngOnInit() {
    this.saveallcartdata();
  }
  /**********SAVE CART DETAILS BEFORE LOGOUT***********/
  saveallcartdata() {

    // if (this.cartService.isCartValid()) {
    //   this.cartService.saveCart().subscribe(
    //     res => {
    //       const result = res.success;
    //       this.clearSession();
    //       this.router.navigate(['/order']);

        
    //     }
    //   );
    // } else {
      this.clearSession();
      this.router.navigate(['/order']);
    // }
  }
  /** clear session stored values */
  clearSession() {
    this.storage.remove(SESSION_AUTHENTICATED_CUSTOMER);
    this.storage.remove(SESSION_LOGIN_PROVIDER);
    localStorage.removeItem('customerId');
    this.storage.remove(SESSION_CART);
    this.storage.remove(SESSION_CUSTOMER_FAVOURITES);
    this.storage.remove(SESSION_SELECTED_SHOP); // new
    this.storage.remove(SESSION_SELECTED_SERVICE);
    //  this.storage.remove(SESSION_SELECTED_SHOP_SERVICES);
    this.storage.remove(SESSION_ITEM_CATEGORY_LIST);
    this.storage.remove(SESSION_SALE_ITEM_LIST);
    this.storage.remove(SESSION_SYSTEM_SETTINGS);
    this.storage.remove(SESSION_SELECTED_SUBCATEGORY_ID);
    this.storage.remove(COMPANY_INFO);
    this.storage.remove(SESSION_SHOP_LIST);
    this.storage.remove(SESSION_REGION_LIST);
    this.storage.remove(SESSION_AMENITY_LIST);
    this.storage.remove(SESSION_FAV_FILTER_BTN);
    this.storage.remove(SESSION_MASTER_CUSTMIZATION);
    this.storage.remove(SESSION_CUSTOMER_REWARDS);
    
    // this.storage.remove(SESSION_LAST_SUCCESS_TXN_ID);
    // this.storage.remove(SESSION_LAST_SUCCESS_TXN_RECEIPT);
  }
}
