import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationBarService {

    showNotification:boolean;

    constructor(){
        this.showNotification=false;
    }

}
