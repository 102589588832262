import {Component, Inject, OnChanges} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import { CustomerDialogData } from './customer-dialog-data';

@Component({
    selector: 'app-customer-login-dialog',
    templateUrl: './customer-login-dialog.component.html',
    styleUrls : ['./customer-login-dialog.component.css']
  })
  export class CustomerLoginDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: CustomerDialogData) {

    }
  }
