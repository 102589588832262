import { Component, OnInit, Input, ViewEncapsulation, Inject, EventEmitter, Output } from '@angular/core';
import { OrderService } from '../../view-models/order-service';
import { ScreenService } from '../../../fw/services/screens.service';
import { Router } from '@angular/router';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { SESSION_SELECTED_SERVICE } from '../../app.constants';
import { CartService } from '../../cart/cart.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-order-service-panel',
  templateUrl: './order-service-panel.component.html',
  styleUrls: ['./order-service-panel.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})
export class OrderServicePanelComponent implements OnInit {
@Output() serviceSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() orderServiceType: any;
  constructor(private screenService: ScreenService, private cartService: CartService,
              private router: Router,
              @Inject(SESSION_STORAGE) private storage: WebStorageService) {

  }

  ngOnInit() {

  }

  /**Save selected service type in session and redirect to login page */
  redirect() {
    this.serviceSelected.emit(this.orderServiceType);
  }
}
