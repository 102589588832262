import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { UtilService } from '../services/util.service';
import { initialMenuItems } from '../app.menu';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig, StatusBarService } from '../../fw/services/status-bar.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import {
  SESSION_SELECTED_SERVICE, SESSION_SELECTED_SHOP_SERVICES, SESSION_SELECTED_SHOP,
  SESSION_CART,
  SESSION_SALE_ITEM_LIST,
  SESSION_ITEM_CATEGORY_LIST,
  SESSION_FILTERED_SALE_ITEM_LIST,
  SESSION_FILTERED_SUB_CATEGORY_ITEM_LIST,
  SESSION_FILTERED_CATEGORY_ITEM_LIST,
  SESSION_OUTOFSTOCK_ITEM_LIST,
  PICKUP_LOCATION_LIST
} from '../app.constants';
import { DateSelectionService } from '../services/date-selection.service';
import { CartService } from '../cart/cart.service';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DateTimeServiceService } from '../services/date-time-service.service';
import { ShopDetailService } from '../shop-detail/shop-detail.service';
import { TimeService } from '../services/time-service.service';
import { ItemListService } from '../item-list/item-list.service';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { GtmService } from '../gtm.service';

const ASAP = 'ASAP';
@Component({
  selector: 'app-pickup-date-selection',
  templateUrl: './servicetype-date-selection.component.html',
  styleUrls: ['./servicetype-date-selection.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})
export class ServiceTypeDateSelectionComponent implements OnInit {
  serverTime: any;
  noShopServiceTimeSlot: boolean;
  holidays: void;
  shopHoliday: any[];
  endingDate: any;
  startingDate: any;
  isTimeSlot: boolean;
  timeDelay: any;
  redirectUrl: any;
  currntTimeStr: any;
  minDate = new Date();
  maxDate: Date;
  selectedDate: any;
  shopServices: any;
  selectedService: any;
  selectedShop: any;
  advanceOrderDays: number;
  timeSlotData: any;
  timeSlotDropdwnArray: any[];
  timeSlots: any[];
  timeSelected: any;
  orderReadyTime: string;
  dateArray: any[];
  serviceTitle = '';
  dateTimeTitlePrefix = '';
  selectedBuizType: any;
  isLoading: Boolean;
  pauseMessage: any;
  contactLessLocation = '';
  shopPickupLocation: any[];
  selectedPickUplocation: any;
  isDateChanged: boolean;
  constructor(public utilService: UtilService, private cartService: CartService,
    @Inject(SESSION_STORAGE) private storage: WebStorageService, private shopDetailService: ShopDetailService,
    private _itemListService: ItemListService,
    public statusBarService: StatusBarService, private datePipe: DatePipe,
    private dateSelectionService: DateSelectionService, private router: Router,
    private activatedRoute: ActivatedRoute, private dateTimeServiceService: DateTimeServiceService,
    private timeService: TimeService, private confirmationService: ConfirmationService,
    private gtmService: GtmService) {
    this.isLoading = false;
    this.initTitleBarParams();
    this.isTimeSlot = false;
    this.selectedPickUplocation = undefined;
    this.noShopServiceTimeSlot = false;
    this.selectedShop = this.cartService.cart.shop;// this.storage.get(SESSION_SELECTED_SHOP);
    this.selectedBuizType = this.cartService.cart.businessType;
    this.shopServices = this.selectedShop.shopServices; // this.storage.get(SESSION_SELECTED_SHOP_SERVICES);
    this.selectedService = this.cartService.cart.service;

    const shopService = this.shopServices.find(
      obj => obj.serviceId === this.selectedService.id);
    this.advanceOrderDays = shopService.advanceOrderDays;

    // this.utilService.progressService.isLoading = true;

    this.maxDate = this.addDays(new Date(), this.advanceOrderDays);
    this.startingDate = this.shopDetailService.formatDate(new Date());
    this.endingDate = this.shopDetailService.formatDate(this.maxDate);
    const searchParam = { 'id': this.selectedShop.id, 'NumDays': this.advanceOrderDays };
    // this.dateSelectionService.getHolidayBasedOnNumDays(searchParam).subscribe(
    //   res => {
    //     this.shopHoliday = res.object;
    //     this.serverTime = this.timeService.serverTime;
    //     this.setEndDate();
    //     const shopServiceData = { 'shopId': this.selectedShop.id, 'serviceId': this.selectedService.id };
    //     this.dateSelectionService.getShopServiceTimeSlots(shopServiceData).subscribe(
    //       result => {
    //         if (result.success) {
    //           this.timeSlotData = result.object;
    //           this.serverTime = this.timeService.serverTime;
    //           this.timeSlots = this.setTimeSlotDataByDay();
    //           this.setServiceTitle();
    //           }
    //         this.utilService.progressService.isLoading = false;
    //       }
    //     );

    //   }
    // );

    this.shopHoliday = this.selectedShop.shopDailyWorkingHours.filter(obj => obj.dayType === 1);
    this.serverTime = this.timeService.serverTime;
    this.setEndDate();
    this.timeSlotData = this.selectedShop.shopDailyTimeSlots;
    this.timeSlots = this.setTimeSlotDataByDay();
    this.getShopPickupLocation();
    this.setServiceTitle();
    this.utilService.progressService.isLoading = false;

    this.utilService.setMenuItems(initialMenuItems);
    this.redirectUrl = this.activatedRoute.snapshot.queryParams['redirectUrl'];
  }

  ngOnInit() {
    this.statusBarService.parent = this;
  }

  /**
   * select pick up location
   */
  getShopPickupLocation() {

    let pickupLocation = this.storage.get(PICKUP_LOCATION_LIST);
    this.shopPickupLocation = pickupLocation.filter(so => this.selectedShop.pickupLocation.findIndex(sc => sc.ooPickupLocationId === so.id) >= 0);
    if (this.shopPickupLocation.length > 0) {
      if (this.cartService.cart.pickupLocationId !== undefined) {
        let pickUpLocation = this.shopPickupLocation.filter(sco => sco.id === this.cartService.cart.pickupLocationId);
        this.selectedPickUplocation = pickUpLocation[0];
      } else {
        let selectedDefault = this.selectedShop.pickupLocation.filter(sco => sco.isDefault === 1);
        let defaultLocation = this.shopPickupLocation.filter(sco => sco.id === selectedDefault[0].ooPickupLocationId);
        this.selectedPickUplocation = defaultLocation[0];

      }
    }
  }

  /**
   * change pick up location
   * @param selectedLocation 
   */
  selectPickupLoc(selectedLocation) {
    this.selectedPickUplocation = selectedLocation;
  }



  /**Get time slots on changing date */
  onDateChange(event): void {
    this.isDateChanged=false;
    this.timeSelected = null;
    this.timeSlots = this.setTimeSlotDataByDay();
    if(this.cartService.cart.deliveryDate!==undefined)
      this.isDateChanged=this.cartService.cart.deliveryDate.date!=this.selectedDate.date;
  }

  /**Calculate the end date in date selection dropdown based on advance order days in shop service */
  setEndDate() {
    const startDate = this.getStartDate();
    this.dateArray = this.getDateArray(startDate, this.maxDate);
    const deliveryDate = this.cartService.cart.deliveryDate;
    if (deliveryDate !== null && deliveryDate !== undefined) {
      const dateCheck = this.dateArray.find(sh => sh.date === deliveryDate.date);
      if (dateCheck !== null && dateCheck !== undefined) {
        this.selectedDate = dateCheck;
      } else {
        this.selectedDate = this.dateArray[0];
      }
    } else {
      this.selectedDate = this.dateArray[0];
    }

    // this.selectedDate = this.dateArray[0];
  }

  /**Add number of days to a date */
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  // slotData(): any {
  //   let slotData: any[];
  //   const dateSelected = new Date(this.selectedDate.date);
  //   if (dateSelected.getDay() !== 0) {  // time slots for all days other than sunday
  //     slotData = this.timeSlotData.filter(
  //       obj => (obj.weekDay === dateSelected.getDay() || obj.weekDay === 0)
  //     );
  //   } else if (dateSelected.getDay() === 0) {  // time slots for sunday
  //     slotData = this.timeSlotData.filter(
  //       obj => obj.weekDay === 7
  //     );
  //   }
  //   return slotData;
  // }

  slotData(): any {
    let slotData: any[];
    const dateSelected = this.datePipe.transform(new Date(this.selectedDate.date), "yyyy-MM-dd");

    slotData = this.timeSlotData.filter(
      obj => (obj.date === dateSelected)
    );

    return slotData;
  }

  /** Set time slots in time selection dropdown based on the selected date*/
  getStartDate(): Date {
    const dt = new Date(this.serverTime);
    dt.setMinutes(dt.getMinutes() + this.selectedBuizType.initialDelay);
    return dt;
  }


  // setTimeSlotDataByDay(): any {
  //   let slotData: any[];
  //   let count: number;
  //   const data: any[] = [];
  //   const tz = this.dateTimeServiceService.timezoneOffset();
  //   const dt = this.getStartDate();
  //   const delay = 0;
  //   const timeSlotData = [];


  //   /** If date switch to next day after adding the initial delay set the slotData = [] */
  //   // if (dt.getDate() !== (new Date(this.serverTime)).getDate()) {
  //   //   this.currntTimeStr = '23:59:59';
  //   // } else {
  //   this.currntTimeStr = dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds(); // now
  //   // }

  //   // this.currntTimeStr = dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds(); // now
  //   slotData = this.slotData();
  //   if (slotData.length === 0) { // time slots for all days if set to all days together
  //     slotData = this.timeSlotData.filter(
  //       obj => obj.weekDay === 0
  //     );
  //   }
  //   if (slotData.length !== 0) {
  //     // now
  //     if (this.isCurrentDate() &&
  //       (new Date(this.dateTimeServiceService.convertTimeToDateTime(this.currntTimeStr)) >=
  //         (new Date(this.dateTimeServiceService.convertTimeToDateTime(slotData[slotData.length - 1].to))))) {
  //       // this.dateArray = this.getDateArray(new Date(), this.maxDate);
  //       // this.dateArray.splice(0, 1);
  //       // this.selectedDate = this.dateArray[0];
  //       slotData = []; // this.slotData();
  //     }
  //     count = 0;
  //     let strMin = '';
  //     let strHour = '';
  //     for (let i = 0; i < slotData.length; i++) {
  //       const fromTime = new Date(this.dateTimeServiceService.convertToDateTime(dt, this.convertToValidTimeString(slotData[i].from)));
  //       const toTime = new Date(this.dateTimeServiceService.convertToDateTime(dt, this.convertToValidTimeString(slotData[i].to)));
  //       const currntTime = dt;
  //       // new Date(this.dateTimeServiceService.convertToDateTime(this.convertToValidTimeString(dt)));
  //       this.currntTimeStr = dt.getHours() + ':' + dt.getMinutes();

  //       while (fromTime <= toTime) {
  //         strHour = (fromTime.getHours() < 10) ? ('0' + fromTime.getHours()) : (fromTime.getHours() + '');
  //         strMin = (fromTime.getMinutes() < 10) ? ('0' + fromTime.getMinutes()) : (fromTime.getMinutes() + '');
  //         const timeStr = strHour + ':' + strMin + ':00';
  //         if (data.findIndex(e => e === timeStr) === -1) {
  //           // if (!this.isCurrentDate() || (this.isCurrentDate() &&
  //           //   (currntTime <= fromTime))) {
  //           //   data[count] = timeStr;
  //           //   count++;
  //           // }
  //           const selectedDate = this.selectedDate.date;
  //           const possibleDate = this.dateTimeServiceService.formatDate(dt, '/');
  //           if ((possibleDate === selectedDate && currntTime <= fromTime) || possibleDate !== selectedDate) {
  //             data[count] = timeStr;
  //             count++;
  //           }
  //         }
  //         this.timeDelay = slotData[i].delay;
  //         fromTime.setMinutes(fromTime.getMinutes() + this.timeDelay);
  //       }

  //     }
  //   }
  //   for (let i = 0; i < data.length; i++) {
  //     const slot = { time: '', displayTime: '' };
  //     slot.time = data[i];
  //     slot.displayTime = this.datePipe.transform(new Date(this.dateTimeServiceService.convertTimeToDateTime(data[i])), 'h:mm a');
  //     timeSlotData.push(slot);

  //   }

  //   this.noShopServiceTimeSlot = false;
  //   const dateSelected = new Date(this.selectedDate.date);
  //   let selectedDay = dateSelected.getDay();
  //   selectedDay = (selectedDay === 0) ? 7 : selectedDay;
  //   const selectedShopWorkInfo = this.selectedShop.shopWorkingHours.filter(sw => sw.weekDay === selectedDay);

  //   const selectedShopWorkArray = (selectedShopWorkInfo.length === 0) ? selectedShopWorkInfo :
  //     timeSlotData.filter(ts => ts.time >= selectedShopWorkInfo[0].openTime && ts.time <= selectedShopWorkInfo[0].closeTime);

  //   //   (currntTime >= selectedShopWorkInfo[0].openTime && currntTime <= selectedShopWorkInfo[0].closeTime)
  //   const currntTime = new Date(this.dateTimeServiceService.convertTimeToDateTime(this.convertToValidTimeString(dt)));

  //   if (this.isCurrentDate() && (selectedShopWorkInfo.length > 0) && (currntTime >=
  //     new Date(this.dateTimeServiceService.convertTimeToDateTime(selectedShopWorkInfo[0].openTime))
  //     && currntTime <= new Date(this.dateTimeServiceService.convertTimeToDateTime(selectedShopWorkInfo[0].closeTime)))) {
  //     if (timeSlotData.length === 0) {
  //       this.noShopServiceTimeSlot = true;
  //     }

  //   } else {
  //     this.noShopServiceTimeSlot = false;
  //   }

  //   const deliveryDate = this.cartService.cart.deliveryDate;
  //   const selectedDate = this.selectedDate.date;
  //   const DeliveryTime = this.cartService.cart.deliveryTime;
  //   if (DeliveryTime !== null && DeliveryTime !== undefined) {
  //     const timeCheck = selectedShopWorkArray.find(sh => sh.time === DeliveryTime);
  //     if (timeCheck !== null && timeCheck !== undefined && deliveryDate.date !== null &&
  //       deliveryDate.date !== undefined && deliveryDate.date === selectedDate) {
  //       this.timeSelected = timeCheck;
  //     } else {
  //       this.timeSelected = selectedShopWorkArray[0];
  //     }
  //   } else {
  //     this.timeSelected = selectedShopWorkArray[0];
  //   }

  //   // this.timeSelected = selectedShopWorkArray[0];
  //   if (this.timeSelected !== null && this.timeSelected !== undefined && !(this.isShopHoliday(this.selectedDate.date))
  //   ) {
  //     this.isTimeSlot = false;
  //   } else {
  //     this.isTimeSlot = true;
  //   }
  //   /*********to check shop is active or not******/
  //   if (this.isShopActiveStatus(this.selectedDate.date) === false) {
  //     this.isTimeSlot = true;
  //   }
  //   return selectedShopWorkArray;
  // }

  setTimeSlotDataByDay(): any {

    let slotData: any[];
    let count: number;
    const data: any[] = [];
    const tz = this.dateTimeServiceService.timezoneOffset();
    const dt = this.getStartDate();
    const delay = 0;
    const timeSlotData = [];


    /** If date switch to next day after adding the initial delay set the slotData = [] */
    // if (dt.getDate() !== (new Date(this.serverTime)).getDate()) {
    //   this.currntTimeStr = '23:59:59';
    // } else {
    this.currntTimeStr = dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds(); // now
    // }

    // this.currntTimeStr = dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds(); // now
    slotData = this.slotData();
    // if (slotData.length === 0) { // time slots for all days if set to all days together
    //   slotData = this.timeSlotData.filter(
    //     obj => obj.weekDay === 0
    //   );
    // }
    if (slotData.length !== 0) {
      // now
      if (this.isCurrentDate() &&
        (new Date(this.dateTimeServiceService.convertTimeToDateTime(this.currntTimeStr)) >=
          (new Date(this.dateTimeServiceService.convertTimeToDateTime(slotData[slotData.length - 1].to))))) {
        // this.dateArray = this.getDateArray(new Date(), this.maxDate);
        // this.dateArray.splice(0, 1);
        // this.selectedDate = this.dateArray[0];
        slotData = []; // this.slotData();
      }
      count = 0;
      let strMin = '';
      let strHour = '';
      for (let i = 0; i < slotData.length; i++) {
        const fromTime = new Date(this.dateTimeServiceService.convertToDateTime(dt, this.convertToValidTimeString(slotData[i].from)));
        const toTime = new Date(this.dateTimeServiceService.convertToDateTime(dt, this.convertToValidTimeString(slotData[i].to)));
        const currntTime = dt;
        // new Date(this.dateTimeServiceService.convertToDateTime(this.convertToValidTimeString(dt)));
        this.currntTimeStr = dt.getHours() + ':' + dt.getMinutes();

        while (fromTime <= toTime) {
          strHour = (fromTime.getHours() < 10) ? ('0' + fromTime.getHours()) : (fromTime.getHours() + '');
          strMin = (fromTime.getMinutes() < 10) ? ('0' + fromTime.getMinutes()) : (fromTime.getMinutes() + '');
          const timeStr = strHour + ':' + strMin + ':00';
          if (data.findIndex(e => e === timeStr) === -1) {
            // if (!this.isCurrentDate() || (this.isCurrentDate() &&
            //   (currntTime <= fromTime))) {
            //   data[count] = timeStr;
            //   count++;
            // }
            const selectedDate = this.selectedDate.date;
            const possibleDate = this.dateTimeServiceService.formatDate(dt, '/');
            if ((possibleDate === selectedDate && currntTime <= fromTime) || possibleDate !== selectedDate) {
              data[count] = timeStr;
              count++;
            }
          }
          this.timeDelay = slotData[i].delay;
          fromTime.setMinutes(fromTime.getMinutes() + this.timeDelay);
        }

      }
    }
    for (let i = 0; i < data.length; i++) {
      const slot = { time: '', displayTime: '' };
      slot.time = data[i];
      slot.displayTime = this.datePipe.transform(new Date(this.dateTimeServiceService.convertTimeToDateTime(data[i])), 'h:mm a');
      timeSlotData.push(slot);

    }

    this.noShopServiceTimeSlot = false;
    const dateSelected = new Date(this.selectedDate.date);
    let selectedDay = dateSelected.getDay();
    selectedDay = (selectedDay === 0) ? 7 : selectedDay;
    // const selectedShopWorkInfo = this.selectedShop.shopWorkingHours.filter(sw => sw.weekDay === selectedDay);
    const selectedShopWorkInfo = this.selectedShop.shopDailyWorkingHours.filter(sw => sw.date === this.datePipe.transform(new Date(this.selectedDate.date), "yyyy-MM-dd"));
    const selectedShopWorkArray = (selectedShopWorkInfo.length === 0) ? selectedShopWorkInfo :
      timeSlotData.filter(ts => ts.time >= selectedShopWorkInfo[0].openTime && ts.time <= selectedShopWorkInfo[0].closeTime);

    //   (currntTime >= selectedShopWorkInfo[0].openTime && currntTime <= selectedShopWorkInfo[0].closeTime)
    const currntTime = new Date(this.dateTimeServiceService.convertTimeToDateTime(this.convertToValidTimeString(dt)));

    if (this.isCurrentDate() && (selectedShopWorkInfo.length > 0) && (currntTime >=
      new Date(this.dateTimeServiceService.convertTimeToDateTime(selectedShopWorkInfo[0].openTime))
      && currntTime <= new Date(this.dateTimeServiceService.convertTimeToDateTime(selectedShopWorkInfo[0].closeTime)))) {
      if (timeSlotData.length === 0) {
        this.noShopServiceTimeSlot = true;
      }

    } else {
      this.noShopServiceTimeSlot = false;
    }

    const deliveryDate = this.cartService.cart.deliveryDate;
    const selectedDate = this.selectedDate.date;
    const DeliveryTime = this.cartService.cart.deliveryTime;
    if (DeliveryTime !== null && DeliveryTime !== undefined) {
      const timeCheck = selectedShopWorkArray.find(sh => sh.time === DeliveryTime);
      if (timeCheck !== null && timeCheck !== undefined && deliveryDate.date !== null &&
        deliveryDate.date !== undefined && deliveryDate.date === selectedDate) {
        this.timeSelected = timeCheck;
      } else {
        this.timeSelected = selectedShopWorkArray[0];
      }
    } else {
      this.timeSelected = selectedShopWorkArray[0];
    }

    // this.timeSelected = selectedShopWorkArray[0];
    if (this.timeSelected !== null && this.timeSelected !== undefined && !(this.isShopHoliday(this.selectedDate.date))
    ) {
      this.isTimeSlot = false;
    } else {
      this.isTimeSlot = true;
    }
    /*********to check shop is active or not******/
    if (this.isShopActiveStatus(this.selectedDate.date) === false) {
      this.isTimeSlot = true;
    }
    return selectedShopWorkArray;
  }

  diff(start, end) {
    start = start.split(':');
    end = end.split(':');
    const startDate = new Date(0, 0, 0, start[0], start[1], 0);
    const endDate = new Date(0, 0, 0, end[0], end[1], 0);
    let diff = endDate.getTime() - startDate.getTime();
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);

    return (hours < 9 ? '0' : '') + hours + ':' + (minutes < 9 ? '0' : '') + minutes;
  }

  /**Configure title bar and footer bar */
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = true;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'DATE & TIME',
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showDateSelectionBar: true
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }

  /**Check if selected date is current date */
  isCurrentDate(): boolean {
    const dt = new Date(this.serverTime);
    const slctdDate = new Date(this.selectedDate.date);

    const dateSelected = new Date(slctdDate.getFullYear(), slctdDate.getMonth(),
      slctdDate.getDate());
    dt.setHours(0, 0, 0, 0);
    const isToday = (dateSelected.getTime() === dt.getTime()) ? true : false;
    return isToday;
  }

  /**set selected time as time selection drop down value changes */
  onTimeChange(event) {
    this.timeSelected = event.value;

  }

  /**Convert date or string type to valid time string of hh:mm:ss format*/
  convertToValidTimeString(date: any): any {

    let dateStr = '';
    let hr = '';
    let min = '';
    let sec = '';
    if (date instanceof Date) {
      hr = (date.getHours() < 10) ? ('0' + date.getHours()) : date.getHours() + '';
      min = (date.getMinutes() < 10) ? ('0' + date.getMinutes()) : date.getMinutes() + '';
      sec = (date.getSeconds() < 10) ? ('0' + date.getSeconds()) : date.getSeconds() + '';
    } else {
      hr = date.split(':')[0];
      min = date.split(':')[1];
      sec = date.split(':')[2];
    }
    dateStr = hr + ':' + min + ':' + sec;
    return dateStr;
  }

  /**Get date array by start date and calculated end date , to populate date dropdown */
  getDateArray(startDate: Date, endDate: Date) {
    const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dateArray = [];
    let current = startDate;
    while (current <= endDate) {
      const dateObj = { date: '', label: '', isClosed: false };
      const curDate = new Date(current);
      const dayWeek = dayOfWeek[curDate.getDay()];
      dateObj.date = this.shopDetailService.formatDate(curDate);
      // this.isShopHoliday(dateObj.date);
      dateObj.label =
        this.getDateDisplayLabel(dateObj.date) + ' ' + '(' + dayWeek + ')';
      if (this.isShopHoliday(dateObj.date) || (this.isShopActiveStatus(dateObj.date) === false)) {
        dateObj.label = dateObj.label + ' - Closed ';
        dateObj.isClosed = true;
      }
      dateArray.push(dateObj);
      current = this.addDays(new Date(current), 1);
    }
    return dateArray;
  }

  getShopActiveStatus(selectedDate, inactiveDateFrom, inacticveDateTo): boolean {
    let ActiveStatus = true;
    // const datetime = new Date();
    // const currentDate = this.datePipe.transform(datetime, 'yyyy-MM-dd');
    if (inactiveDateFrom !== '') {
      if (inactiveDateFrom <= selectedDate) {
        if (inacticveDateTo !== '' && inacticveDateTo < selectedDate) {
          ActiveStatus = true;
        } else {
          ActiveStatus = false;
        }
      }
    }
    return ActiveStatus;
  }

  isShopActiveStatus(dateObj) {
    const inactiveDateFrom = this.selectedShop.inactiveFrom;
    const inacticveDateTo = this.selectedShop.inactiveTo;
    dateObj = this.datePipe.transform(dateObj, 'yyyy-MM-dd');
    const Active = this.getShopActiveStatus(dateObj, inactiveDateFrom, inacticveDateTo);
    return Active;
  }

  isShopHoliday(date): any {
    return (this.shopHoliday.findIndex(sh => sh.date === this.datePipe.transform(date, 'yyyy-MM-dd')) > -1) ? true : false;
  }

  /**Format date to dd/MM/YYYY */
  // formatDate(date: Date): any {
  //   const dt = date.getDate();
  //   const month = date.getMonth() + 1;
  //   const yyyy = date.getFullYear();

  //   const dd = (dt < 10) ? ('0' + dt) : dt + '';
  //   const mm = (month < 10) ? ('0' + month) : month + '';

  //   return (yyyy + '/' + mm + '/' + dd);
  // }

  getShopClosedMsg(): any {
    return 'on ' + this.selectedDate.label.split('-', 1)[0].trim();
  }

  redirect() {

    if (this.redirectUrl !== undefined) {
      this.setProductData(this.redirectUrl);
    } else {
      this.utilService.progressService.isLoading = true;
      this.setProductData('/category-list');

    }
  }
  setPickUpLocationToCart() {
    if (this.selectedPickUplocation !== undefined) {
      this.cartService.setPickupLocation(this.selectedPickUplocation.id);
      // this.cartService.setRemarks(this.selectedPickUplocation.description);
    }
  }

  setProductData(redirectUrl) {
    // const saleItemList = this.storage.get(SESSION_SALE_ITEM_LIST);
    // const filteredSaleitemlist = this._itemListService.getFilteredSaleitemlist(saleItemList);
    const businessTypeId = this.cartService.cart.businessType.id;
    const pickUpDate = this.cartService.cart.deliveryDate.date;
    const shopId = this.cartService.cart.shop.id;
    this.updateGTMInfo();
    const filteredSaleitemlist = this._itemListService.loadSaleItemExt(businessTypeId, pickUpDate, this.cartService.cart.shop).subscribe(
      res => {
        const _saleItemList = res.object;
        this.storage.set(SESSION_FILTERED_SALE_ITEM_LIST, _saleItemList);
        const categoryList = this.storage.get(SESSION_ITEM_CATEGORY_LIST);
        const filteredCategory = this._itemListService.getFilteredItemCategoryList(categoryList, _saleItemList);
        this.storage.set(SESSION_FILTERED_CATEGORY_ITEM_LIST, filteredCategory);
        this.utilService.progressService.isLoading = false;
        this.router.navigate([redirectUrl]);
      });
  }

  addDateTimeToCart(): any {
    let isProceed = true;
    if (this.selectedDate.isClosed === true) {
      isProceed = false;
      this.utilService.openDialog('Invalid Selection', 'Select time from the above list');
    } else if (this.timeSelected !== null && this.timeSelected !== undefined) {
      /**
          * confirm the warnText of pickuplocation.
          */
      if (this.selectedPickUplocation !== undefined && this.selectedPickUplocation.warnText !== null && this.selectedPickUplocation.warnText.toString().trim().length > 0) {
        this.confirmationService.confirm({
          header: 'Pickup Alert',
          message: this.selectedPickUplocation.warnText,
          accept: () => {
            this.isSelectedTimeNotinpausetimeList(this.selectedShop.id, this.selectedDate, this.timeSelected.time);
          }
        });
      } else {
        this.isSelectedTimeNotinpausetimeList(this.selectedShop.id, this.selectedDate, this.timeSelected.time);
      }
    } else {
      isProceed = false;
      this.utilService.openDialog('Invalid Selection', 'Select time from the above list');
    }
    return isProceed;
  }

  isSelectedTimeNotinpausetimeList(selectedShopId, selectedDate, selectedTime) {
    // let isNotInPauseList = true;
    this.pauseMessage = "";
    let selectedDateFt = moment(selectedDate.date).format('YYYY-MM-DD');
    this.utilService.progressService.isLoading = true;
    this._itemListService.selectedTimeValidity(selectedShopId, selectedDateFt, selectedTime).subscribe(
      res => {
        if (!res.success && res.object != null) {
          // isNotInPauseList = false;
          let pauseMsg = res.message;
          this.pauseMessage = pauseMsg.replace("<shop_name>", this.selectedShop.name);
          this.utilService.openDialog('Information', this.pauseMessage);
          this.utilService.progressService.isLoading = false;
        } else {
          //   isNotInPauseList = true;
          this.cartService.setDeliveryDate(this.selectedDate);
          this.cartService.setDeliveryTime(this.timeSelected.time);
          this.setPickUpLocationToCart();
          /**
           * out of stock list by shop_id and pickupdate
           * returns  array list of ooSaleItemIds.
           */
          let inputParam = { "shop_id": selectedShopId, "pickUp_date_time": selectedDateFt + " " + selectedTime };
          this.dateTimeServiceService.getOutofStockByShop(inputParam);
          this.redirect();
        }
      });
    // return isNotInPauseList;
  }

  getDateDisplayLabel(dateParam) {
    let dateLabel = this.datePipe.transform(dateParam, 'dd/MM/yyyy');
    const compDate = new Date(dateParam);

    const dt = new Date(this.serverTime);
    dt.setHours(0, 0, 0, 0);
    const dayDiff = (compDate.valueOf() - dt.valueOf()) / (1000 * 3600 * 24);
    if (dayDiff === 0) {
      dateLabel = 'TODAY';
    } else if (dayDiff === 1) {
      dateLabel = 'TOMORROW';
    }

    return dateLabel;
  }

  i

  setServiceTitle(): any {

    switch (this.selectedService.id) {
      case 1: {
        this.serviceTitle = 'PICKUP ORDER FROM';
        this.dateTimeTitlePrefix = 'PICKUP';
        this.contactLessLocation = 'PICKUP';
        break;
      }
      case 2: {
        this.serviceTitle = 'DELIVER ORDER TO';
        this.dateTimeTitlePrefix = 'DELIVERY';
        break;
      }
      case 3: {
        this.serviceTitle = 'DRIVE THROUGH';
        this.dateTimeTitlePrefix = 'DRIVE THROUGH';
        break;
      }
    }
  }

  /***
   * GTM CODE BEGINS
   */

  updateGTMInfo() {
    this.gtmService.pushEvent(
      'select_date',
      {
        storeSelected: this.gtmService.getStore(),
        visitorType: this.gtmService.getVisitorType(),
        dateSelected: this.gtmService.geDeliveryDate(),
        timeSelected: this.gtmService.getDeliveryTime(),
        preOrder: this.gtmService.isPreOrder()
      }
    );

    if (this.gtmService.previousUrl.startsWith("/category-list") && this.isDateChanged) {
      this.gtmService.pushEvent(
        'page_engagement',
        {
          actionType: 'Back to Date and Time',
          storeSelected: this.gtmService.getStore(),
          visitorType: this.gtmService.getVisitorType(),
          dateSelectd: this.gtmService.geDeliveryDate(),
          timeSelected: this.gtmService.getDeliveryTime(),
          preOrder: this.gtmService.isPreOrder(),
          dateChanged: this.isDateChanged
        });
    }
  }

  /**
   * Handle back button press
   */
  handleGTMPageEngagement(previousUrl: string) {
    if (previousUrl.startsWith('/storefinder')) {
        this.gtmService.pushEvent(
          'page_engagement',
          {
            actionType: 'Back to store page',
            storeSelected: this.gtmService.getStore(),
            visitorType: this.gtmService.getVisitorType(),
          });
      }
  }

  /**
   * GTM CODE ENDS
   */


}
