import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ItemListService } from '../item-list/item-list.service';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { SESSION_SALE_ITEM_LIST, SESSION_FILTERED_SALE_ITEM_LIST } from '../app.constants';
import { Input, ViewChild, ElementRef } from '@angular/core';
import { CartService, Cart } from '../cart/cart.service';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.css']
})
export class SearchItemComponent implements OnInit {
  @ViewChild('searchitem') nameField: ElementRef;
  cart: Cart;
  saleItemList: any;
  allItems: any;
  userenter: string;
  isSearchItem: boolean;
  @Output() backClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemClickEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(private router: Router, private _itemListService: ItemListService,
    @Inject(SESSION_STORAGE) private storage: WebStorageService, private cartService: CartService) {
    this.getSaleItem();
    this.cart = this.cartService.cart;

  }

  ngOnInit() {
    document.getElementById('next-menu-btn').style.visibility = 'hidden';
    document.getElementById('previous-menu-btn').style.visibility = 'hidden';
    this.setFocus();
  }
  backButton() {
    this.backClick.emit();
  }
  itemClear() {
    this.userenter = '';
  }
  itemClick(itemName) {
    this.userenter = itemName;
    this.nameField.nativeElement.focus();
    this.itemClickEvent.emit(this.userenter);
    // this.router.navigate(['/all-item-list'], { queryParams: {'search' : this.userenter} });
  }
  selectItemList() {
    this.allItems = null;
    if (this.saleItemList !== null && this.saleItemList !== undefined) {
      this.allItems = this.saleItemList.filter(si => this.isItemAvailableDay(si));
    }
    return this.allItems;
  }
  getSaleItem() {
    // this.saleItemList = this._itemListService.saleItemList;
    // this.saleItemList = this._itemListService.saleItemList.filter(si => this._itemListService.isSaleItemActive(si));
    // if (this.saleItemList === undefined || this.saleItemList === null) {
    //   this._itemListService.loadSaleItem().subscribe(
    //     res => {
    //       this.saleItemList = res.object;
    //       this.storage.set(SESSION_SALE_ITEM_LIST, this.saleItemList);
    //     });
    // }
    this.saleItemList = this.storage.get(SESSION_FILTERED_SALE_ITEM_LIST);
  }
  onItemChange(event) {
    this.userenter = event.replace(/^\s+|\s+$/g, '');
  }
  isItemAvailableDay(saleItem) {

    let pickUpDay = new Date(this.cart.deliveryDate.date).getDay();
    if (pickUpDay === 0) {
      pickUpDay = 7;
    }
    return saleItem.itemAvailableDay.findIndex(e => e === pickUpDay) > -1;
  }
  setFocus(){
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      document.getElementById("user-enter").focus();
    },1); 
  }
}
