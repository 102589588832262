import { Component, OnInit, Inject, ViewEncapsulation, OnChanges, AfterViewInit, Output, EventEmitter, HostListener } from '@angular/core';
import { AppDataService } from '../services/app-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemCategory } from '../view-models/itemcategory';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import {
  SESSION_SELECTED_SHOP, SESSION_ITEM_CATEGORY_LIST, SESSION_CART,
  CATEGORY_IMAGE_FOLDER, SESSION_SELECTED_SUBCATEGORY_ID, SESSION_SALE_ITEM_LIST,
  SESSION_SELECTED_ITEM_CATEGORY_ID,
  SESSION_FILTERED_CATEGORY_ITEM_LIST,
  SESSION_MASTER_CUSTMIZATION
} from '../app.constants';
import { Cart, CartService } from '../cart/cart.service';
import { $, jQuery } from 'jquery';
import { StartupService } from '../services/startup.service';
import { ItemListService } from '../item-list/item-list.service';
import * as moment from 'moment';
import { DateTimeServiceService } from '../services/date-time-service.service';
import { GtmService } from '../gtm.service';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-item-category-list',
  templateUrl: './item-category-list.component.html',
  styleUrls: ['./item-category-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ItemCategoryListComponent implements AfterViewInit {
  currentSubCategoryId: any;
  oldScrollTop: any;
  userenter: any;
  // allItems: any;
  saleItemList: any;
  isSearchItem: boolean;
  itemCategories: Array<any>;
  parentCategories: Array<any>;
  subCategories: Array<any> = [];
  tabs: any[] = [];
  imageUrl: any;
  uploadUrl: any;
  selectedShop: any;
  shopItemCategoryIds: any[];
  selectedItemCategory: any;
  customizationOptionValues: any;
  cart: Cart;

  constructor(private utilService: UtilService, private router: Router, private startUp: StartupService,
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private _itemListService: ItemListService, private cartService: CartService,
    private dateTimeServiceService: DateTimeServiceService,
    private gtmService:GtmService) {
    this.isSearchItem = false;
     //  this.itemCategories = this.storage.get(SESSION_ITEM_CATEGORY_LIST).filter(ic => _itemListService.getVisibleCategories(ic.id));    
     this.itemCategories = this.storage.get(SESSION_FILTERED_CATEGORY_ITEM_LIST);
    this.parentCategories = this.itemCategories.filter(
      obj => obj.parentId === 0
    );
    this.cart = this.cartService.cart;
    this.getSaleItem();
    this.currentSubCategoryId = this.storage.get(SESSION_SELECTED_SUBCATEGORY_ID);
    const selectedSubCategory = this.itemCategories.find(ic => ic.id === this.currentSubCategoryId);
    if (selectedSubCategory !== undefined) {
      this.selectedItemCategory = this.itemCategories.find(ic => ic.id === selectedSubCategory.parentId);
      if ((this.storage.get(SESSION_SELECTED_ITEM_CATEGORY_ID)) !== null &&
        (this.storage.get(SESSION_SELECTED_ITEM_CATEGORY_ID)) !== undefined) {
        this.selectedItemCategory = this.itemCategories.find(ic => ic.id === (this.storage.get(SESSION_SELECTED_ITEM_CATEGORY_ID)));
      }
    }
    this.selectItemCategory(this.selectedItemCategory === undefined ? this.parentCategories[0] : this.selectedItemCategory);
    this.initTitleBarParams();
    this.selectedShop = this.storage.get(SESSION_SELECTED_SHOP);
    if (this.selectedShop !== null && this.selectedShop !== undefined) {
      this.shopItemCategoryIds = this.selectedShop.item_category;
    } else {
      this.utilService.openDialog('Select Shop', 'Select a shop from store finder');
      this.router.navigate(['/storefinder']);
    }
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.imageUrl = this.uploadUrl + CATEGORY_IMAGE_FOLDER;
    this.getCustomizationOptionDtl();
  }

  ngAfterViewInit() {
    this.scrollToView(this.selectedItemCategory.id);
    this._itemListService.hidePreandNextButtons();
    this._itemListService.toggleNextItem();
    this._itemListService.togglePreviousItem();
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'MENU',
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }
  // getVisibleCategories(category) {
  //   return this._itemListService.getVisibleCategories(category);
  // }

  parentCategorySelected(category) {
    const catVlist = 'cat_vlist_' + category.id;
    const el = document.getElementById(catVlist);
    el.scrollIntoView();
    // this.storage.set(SESSION_SELECTED_SUBCATEGORY_ID, category.id);
    this.selectedItemCategory = category;
    this.storage.set(SESSION_SELECTED_ITEM_CATEGORY_ID, category.id);
  }

  isValidCatergory(cat) {
    var isValid=false;
    if(cat!==undefined){
      for(var index=0; index<cat.saleItemIds.length; index++){
        var saleItem=this.saleItemList.find(si => si.id==cat.saleItemIds[index]);
        if(saleItem!==undefined && saleItem.isDirectSaleAllowed==1 && this.isItemAvailableDay(saleItem)){
          isValid=true;
          break;
        }
      }
    }
    return isValid;
  }

  isItemAvailableDay(saleItem) {

    let pickUpDay = new Date(this.cart.deliveryDate.date).getDay();
    if (pickUpDay === 0) {
      pickUpDay = 7;
    }
    return saleItem.itemAvailableDay.findIndex(e => e === pickUpDay) > -1;
  }

  /*Select a parent category and get its subcategories*/
  selectItemCategory(category) {
    this.selectedItemCategory = category;
    this.getSubCategories(category);
  }

  /*Select a its subcategories*/
  getSubCategories(category): any {
    this.subCategories = [];
    const subCategoryIds = category.subItemCategoryIds;
    let count = 0;
    if (subCategoryIds !== null && this.itemCategories !== null && this.itemCategories !== undefined) {
      subCategoryIds.forEach(function (element1) {
        this.subCategories[count] = {};
        this.subCategories[count] = this.itemCategories.find(
          function (obj) { return obj.id === element1; }
        );
        count++;
      }.bind(this));
    }
    return this.subCategories;
  }
  isInactiveCategory(content) {
    let isInactive = false;
    if (this.shopItemCategoryIds !== null && this.selectedItemCategory !== undefined) {
      if (this.shopItemCategoryIds.findIndex(obj => obj === content.id) >= 0) {
        isInactive = false;
      } else {
        isInactive = true;
      }
      return isInactive;
    }
  }
  searchAllItems() {
    this.isSearchItem = true;
    // this.router.navigate(['/all-item-list']);
  }
  // backButton() {
  //   this.isSearchItem = false;

  // }
  // selectItemList() {
  //   this.allItems = null;
  //   if (this.saleItemList !== null && this.saleItemList !== undefined) {
  //       this.allItems = this.saleItemList;
  //   }
  //   return this.allItems;
  // }
  getSaleItem() {
    this.saleItemList = this._itemListService.saleItemList;
    if (this.saleItemList === undefined || this.saleItemList === null) {
      this._itemListService.loadSaleItem().subscribe(
        res => {
          this.saleItemList = res.object;
          this.storage.set(SESSION_SALE_ITEM_LIST, this.saleItemList);
        });
    }
  }

 /**
   * load customization option and option values as Json.
   */
  getCustomizationOptionDtl(){
    this.customizationOptionValues = this.storage.get(SESSION_MASTER_CUSTMIZATION);
    if (this.customizationOptionValues === undefined || this.customizationOptionValues === null) {
      this._itemListService.loadCustomizationOptionDtl().subscribe(
        res => {
          this.customizationOptionValues = JSON.parse(res.object);
          this.storage.set(SESSION_MASTER_CUSTMIZATION, this.customizationOptionValues);
        });
    }

  }

  itemClick(itemName) {
    this.userenter = itemName;
    this.router.navigate(['/all-item-list'], { queryParams: { 'search': this.userenter } });
  }

  backClick(event) {
    this.isSearchItem = false;
  }
  @HostListener('scroll', ['$event'])
  onScroll(event) {

    const isScrollUp = event.target.scrollTop > this.oldScrollTop;
    this.oldScrollTop = event.target.scrollTop;

    const scrollTop = event.target.scrollTop + event.target.offsetTop;
    const scrollBottom = scrollTop + event.target.clientHeight;
    const cat = this.getCatogoryInViewPort(scrollTop, scrollBottom, isScrollUp);
    if (cat !== undefined && this.selectedItemCategory.id !== cat.id) {
      this.selectedItemCategory = cat;
      this.scrollIntoView('cat_hlist_' + cat.id);
    }
  }
  scrollToView(id) {
    this.scrollIntoView('cat_hlist_' + id);
    this.scrollIntoView('cat_vlist_' + id);
  }

  scrollIntoView(id) {
    const el = document.getElementById(id);
    el.scrollIntoView();
  }
  getCatogoryInViewPort(scrollTop, scrollBottom, isScrollUp): any {

    const scrollViewHeight = scrollBottom - scrollTop;
    // const searchArray = (isScrollUp) ? this.subCategories : this.subCategories.slice(0).reverse() ;
    const searchArray = this.parentCategories.slice(0).reverse();
    let ele: HTMLElement;
    const cat = searchArray.find(el => {
      ele = document.getElementById('cat_vlist_' + el.id);
      const checkPoint = (isScrollUp) ? ele.offsetTop : (ele.offsetTop + ele.clientHeight);
      if (checkPoint < scrollBottom - (scrollViewHeight / 2) && checkPoint > scrollTop) {
        return el;
      }
    });
    return cat;
  }

  /**
   * GTM CODE BRGINS
   */

  /**
   * Handle back button press
   */
  handleGTMPageEngagement(previousUrl:string){
    // if (previousUrl.startsWith("/datetime-selection")) {
    //   this.gtmService.pushEvent(
    //     'page_engagement',
    //     {
    //       actionType: 'Back to Date and Time',
    //       storeSelected: this.gtmService.getStore(),
    //       visitorType: this.gtmService.getVisitorType(),
    //       dateSelectd: this.gtmService.geDeliveryDate(),
    //       timeSelected: this.gtmService.getDeliveryTime(),
    //       preOrder: this.gtmService.isPreOrder(),
    //       dateChanged: false
    //     });
    // }
  }

  /**
   * GTM CODE END
   */


}
