import { Component, OnInit } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private utilService: UtilService) {
    this.initTitleBarParams();
   }

  ngOnInit() {
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'OUR POLICIES',
      showLogo: false,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }
}
