import { Component, HostListener, OnInit } from '@angular/core';
import { ScreenService } from '../services/screens.service';
import { MenuService } from '../services/menu.service';
import { HdrTitleService } from '../services/hdr-title.service';
import { NavigationEnd, Router } from '@angular/router';
import { ProgressBarLoadService } from '../../app/services/progress-bar-load.service';
import { UtilService } from '../../app/services/util.service';
import { Location } from '@angular/common';
import { CartService } from '../../app/cart/cart.service';
import { StartupService } from '../../app/services/startup.service';
import { GtmService } from 'src/app/gtm.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.css']
})
export class TitleBarComponent implements OnInit {
  public timer: any;
  public showCheckOut: Boolean;
  imageUrl: string;
  previousUrl: string;
  currentUrl:string;
  constructor(public screenService: ScreenService,
    public menuService: MenuService, public cartService: CartService,
    public hdrService: HdrTitleService, private router: Router,
    public utilService: UtilService, private location: Location, private startUp: StartupService,
    private gtmService:GtmService) {
    this.showCheckOut   = false;

    this.router.events.subscribe((event:any) => {

      if (event instanceof NavigationEnd) {
        this.previousUrl=this.currentUrl;
        this.currentUrl=this.location.path();
        this.gtmService.previousUrl=this.previousUrl;
        this.gtmService.currentUrl=this.currentUrl;
        // this.updateGTMInfo();

      }
    });
      // this.setTimer();
  }


  // @HostListener('window:popstate', ['$event'])
  // onPopState(event: Event) {

  // }

  ngOnInit() {
    this.imageUrl = this.hdrService.cartImage;
    
  }



  public setTimer() {

    // set showloader to true to show loading div on view
    this.showCheckOut   = true;

    this.timer        = setInterval(() => {
      this.toggleCart();
    }, 2000); // 2000 millisecond means 2 seconds

  }
  toggleCart() {
    this.showCheckOut = !this.showCheckOut ;
  }
  goToDashboard() {
    this.router.navigate(['/order']);
  }

  goPreviousPage() {

    if(this.hdrService.parent.handleGTMPageEngagement!==undefined && this.previousUrl!==undefined) this.hdrService.parent.handleGTMPageEngagement(this.previousUrl);
   
    if (this.hdrService.overrideBackButton === undefined ||
      this.hdrService.overrideBackButton === false || !this.hdrService.parent.onBackButttonPressed()) {
    this.location.back();
    }
  }
  goToShoppingCart() {
    if (this.cartService.getTotalQuantity() !== 0) {
      if (this.cartService.cart.shop !== undefined) {
        if (this.cartService.cart.deliveryDate !== undefined && this.cartService.cart.deliveryTime !== undefined) {
          this.hdrService.goToCartCallback();
          this.router.navigate(['/cart']);
        } else {
          this.utilService.openDialog('Select Time', 'Select a pick up time from Date Time selection');
          //  this.router.navigate(['/datetime-selection'], { queryParams: { 'redirectUrl': '/cart' } });
        }
      } else {
        this.utilService.openDialog('Select Shop', 'Select a shop from store finder.');

      }
    } else {
      this.utilService.openDialog('Empty Order', 'You have not ordered anything yet.');
      
    }
  }

  /**
   * This will redirect to login page
   */
  redirectLogin(){
    
    let redirectUrl=this.router.url;
    this.router.navigate(['/login'], { queryParams: { 'redirectUrl': redirectUrl } });
  }

  /**
   * Redirect to logout
   */
  redirectLogout(){
    this.router.navigate(['/logout']);
  }

  // /**
  //  * GTM Code
  //  */
  // updateGTMInfo() {
  //   // alert(this.previousUrl + "  " + this.currentUrl);
  //   if (this.currentUrl == undefined || this.previousUrl == undefined) return;
  //   if (this.currentUrl.startsWith("/storefinder") && this.previousUrl.startsWith("/datetime-selection")) {
  //     this.gtmService.pushEvent(
  //       'page_engagement',
  //       {
  //         actionType: 'Back to store page',
  //         storeSelected: this.gtmService.getStore(),
  //         visitorType: this.gtmService.getVisitorType(),

  //       });
  //   }
  // }

}
