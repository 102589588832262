import { Component, OnInit, Inject, AfterViewInit, HostListener } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import {
  SESSION_ITEM_CATEGORY_LIST, SESSION_SELECTED_SUBCATEGORY_ID,
  SESSION_SELECTED_SHOP, SESSION_SALE_ITEM_LIST, SESSION_CART, CATEGORY_IMAGE_FOLDER, SESSION_FILTERED_CATEGORY_ITEM_LIST, 
  SESSION_FILTERED_SALE_ITEM_LIST
} from '../app.constants';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { ItemListService } from './item-list.service';
import { Router } from '@angular/router';
import { $, jQuery } from 'jquery';
import { element } from '@angular/core/src/render3/instructions';
import { StartupService } from '../services/startup.service';
import { SettingsService } from '../settings/settings.service';
import { Cart, CartService } from '../cart/cart.service';
import { GtmService } from '../gtm.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.css']
})

export class ItemListComponent implements AfterViewInit {
  cart: Cart;
  isSearchItem: boolean;
  userenter: any;
  imageUrl: any;
  uploadUrl: any;
  shopItemCategories: any[];
  selectedSubCategory: any;
  shopName: any;
  subCategories: any[];
  saleItemList: any[];
  selectedSaleItems: any[];
  currentSubCategoryId: any;
  selectedParentCategory: any;
  currentsubCategoryData: any;
  itemCategories: Array<any>;
  oldScrollTop: any;
  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService, private utilService: UtilService,
    private router: Router, private _itemListService: ItemListService, private startUp: StartupService,
    private settingsService: SettingsService, private cartService: CartService,private gtmService:GtmService) {
    this.isSearchItem = false;
    // this.getSaleItem();
    this.cart = this.cartService.cart;
    // this.itemCategories = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST);
    // this.itemCategories = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST).filter(ic => _itemListService.getVisibleCategories(ic.id));
    this.saleItemList = this.sessionStorage.get(SESSION_FILTERED_SALE_ITEM_LIST);
    this.itemCategories = this.sessionStorage.get(SESSION_FILTERED_CATEGORY_ITEM_LIST);
    this.currentSubCategoryId = this.sessionStorage.get(SESSION_SELECTED_SUBCATEGORY_ID);
    this.selectedSubCategory = this.itemCategories.find(ic => ic.id === this.currentSubCategoryId);
    this.selectedParentCategory = this.itemCategories.find(ic => ic.id === this.selectedSubCategory.parentId);
    this.shopName = this.sessionStorage.get(SESSION_SELECTED_SHOP).name;
    this.subCategories = this.itemCategories.filter(e => e.parentId === this.selectedParentCategory.id);
    this.validateItemSubCategories(this.subCategories)
    this.initTitleBarParams();
    this.shopItemCategories = this.sessionStorage.get(SESSION_SELECTED_SHOP).item_category;
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.imageUrl = this.uploadUrl + CATEGORY_IMAGE_FOLDER;
  }

  ngAfterViewInit() {
    /*****Hide Next and Previous Menu Buttons***/
    this._itemListService.hidePreandNextButtons();
    this._itemListService.toggleNextItem();
    this._itemListService.togglePreviousItem();
    this.scrollToView(this.currentSubCategoryId);
  }
  @HostListener('scroll', ['$event'])
  onScroll(event) {
    const isScrollUp = event.target.scrollTop > this.oldScrollTop;
    this.oldScrollTop = event.target.scrollTop;
    const scrollTop = event.target.scrollTop + event.target.offsetTop;
    const scrollBottom = scrollTop + event.target.clientHeight;
    const cat = this.getCatogoryInViewPort(scrollTop, scrollBottom, isScrollUp);
    if (cat !== undefined && this.selectedSubCategory.id !== cat.id) {
      this.selectedSubCategory = cat;
      this.scrollIntoView('cat_hlist_' + cat.id);
    }
     /*********** highlight vertical menu item fully on scroll.***********/
     this._itemListService.toggleNextItem();
     this._itemListService.togglePreviousItem();
  }
  // getVisibleSaleitems(category) {
  //   const itemVisible = this._itemListService.isItemActiveByID(category);
  //   return itemVisible;
  // }
  /**Find the item in the view */
  getCatogoryInViewPort(scrollTop, scrollBottom, isScrollUp): any {
    const scrollViewHeight = scrollBottom - scrollTop;
    // const searchArray = (isScrollUp) ? this.subCategories : this.subCategories.slice(0).reverse() ;
    const searchArray = this.subCategories.slice(0).reverse();
    let ele: HTMLElement;
    const cat = searchArray.find(el => {
      ele = document.getElementById('cat_vlist_' + el.id);
      const checkPoint = (isScrollUp) ? ele.offsetTop : (ele.offsetTop + ele.clientHeight);
      if (checkPoint < scrollBottom - (scrollViewHeight / 2) && checkPoint > scrollTop) {
        return el;
      }
    });
    return cat;
  }
  scrollToView(id) {
    this.scrollIntoView('cat_hlist_' + id);
    this.scrollIntoView('cat_vlist_' + id);
  }

  scrollIntoView(id) {
    const el = document.getElementById(id);
    el.scrollIntoView();
  }

  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: this.selectedParentCategory.name,
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showItemListBar: false
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }
  categorySelected(category) {
    this.scrollToView(category.id);
    this.sessionStorage.set(SESSION_SELECTED_SUBCATEGORY_ID, category.id);
    this.selectedSubCategory = category;
  }
  /**
   * gettting  all sale items
   */
  getSaleItem() {
  //   this.saleItemList = this._itemListService.saleItemList.filter(si => this._itemListService.isItemActive(si.id));
  //  // this.saleItemList = this._itemListService.saleItemList;
  //   if (this.saleItemList === undefined || this.saleItemList === null) {
  //     this._itemListService.loadSaleItem().subscribe(
  //       res => {
  //         this.saleItemList = res.object;
  //         this.sessionStorage.set(SESSION_SALE_ITEM_LIST, this.saleItemList);
  //       });
  //   }
  }
  // getSaleItem() {
  //   this.saleItemList = this.sessionStorage.get(SESSION_SALE_ITEM_LIST);
  // }

  //   getSaleItem() {
  //     this.saleItemList = this.sessionStorage.get(SESSION_SALE_ITEM_LIST);
  //   if (this.saleItemList === undefined || this.saleItemList === null ) {
  //       this._itemListService.getSaleItem().subscribe(
  //           res => {
  //               this.saleItemList = res.object;
  //               this.sessionStorage.set(SESSION_SALE_ITEM_LIST, this.saleItemList);
  //           });
  //   }
  // }
  /*getting   saleitem details of selected category*/
  selectItemList(category) {
    this.selectedSaleItems = null;
    if (category.saleItemIds !== null && this.saleItemList !== null && this.saleItemList !== undefined) {
      const saleItemDetail = this.saleItemList.filter(si => category.saleItemIds.findIndex(csi => csi === si.id && si.isDirectSaleAllowed==1) >= 0 &&
          this.isItemAvailableDay(si));
      this.selectedSaleItems = saleItemDetail;
    }
    this.selectedSaleItems=(this.selectedSaleItems!==undefined && this.selectedSaleItems.length > 0)? this.selectedSaleItems:null;
    return this.selectedSaleItems;
  }

  // validate the categories
  validateItemSubCategories(categories){
    var filteredCategories=[];
    filteredCategories=this.subCategories.filter(e => this.selectItemList(e)!=null)
    this.subCategories=filteredCategories;
  }
  redirectToShop() {
    this.router.navigate(['/storefinder']);
  }

  isInactiveCategory(content) {
    let isInactive = false;
    if (this.shopItemCategories !== null && this.selectedSubCategory !== undefined) {
      if (this.shopItemCategories.findIndex(obj => obj === content.id) >= 0) {
        isInactive = false;
      } else {
        isInactive = true;
      }
      return isInactive;
    }
  }

  itemSelected(saleItem) {
    this.sessionStorage.set(SESSION_SELECTED_SUBCATEGORY_ID, saleItem.itemCategoryId);
    this.router.navigate(['/item-details'], { queryParams: { 'source': '', 'id': saleItem.saleItemId } });
  }
  searchAllItems() {
    this.isSearchItem = true;
  }
  itemClick(itemName) {
    this.userenter = itemName;
    this.router.navigate(['/all-item-list'], { queryParams: { 'search': this.userenter } });
  }

  backClick(event) {
    this.isSearchItem = false;
  }

  isItemAvailableDay(saleItem) {

    let pickUpDay = new Date(this.cart.deliveryDate.date).getDay();
    if (pickUpDay === 0) {
      pickUpDay = 7;
    }
    return saleItem.itemAvailableDay.findIndex(e => e === pickUpDay) > -1;
  }

  /**
   * GTM CODE BRGINS
   */

  /**
   * Handle back button press
   */
  handleGTMPageEngagement(previousUrl:string){
    this.gtmService.pushEvent(
      'page_engagement',
      {
        actionType: 'Back to Menu',
        brandName: this.gtmService.getItemCatgoryName(this.selectedParentCategory.id),
        categoryName:  this.selectedSubCategory.name,
        storeSelected: this.gtmService.getStore(),
        visitorType: this.gtmService.getVisitorType(),
        dateSelectd: this.gtmService.geDeliveryDate(),
        timeSelected: this.gtmService.getDeliveryTime(),
        preOrder: this.gtmService.isPreOrder()
      });
  }

  /**
   * GTM CODE END
   */

}
