import { Component, OnInit, ViewEncapsulation, Inject, AfterViewInit } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentInitService } from '../payment-init/payment-init.service';
import { CartService } from '../cart/cart.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { SESSION_LAST_SUCCESS_TAX_INVOICE_NO, SESSION_LAST_SUCCESS_TXN_ID, SESSION_LAST_SUCCESS_TXN_RECEIPT, SESSION_LAST_TXN_RECEIPT_DOWNLOADED } from '../app.constants';
import html2canvas from 'html2canvas';
import { StartupService } from '../services/startup.service';
import { GtmService } from '../gtm.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})
export class PaymentSuccessComponent implements AfterViewInit {

  cart: any;
  result: boolean;
  paymentResult: any;
  htmlData: SafeHtml;
  paymentDetails: any;
  retryId: any;
  retryCount:number;
  retryIntrvl: number;
  constructor(private utilService: UtilService, private cartService: CartService, private router: Router, @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private route: ActivatedRoute, private paymentInitService: PaymentInitService, private sanitizer: DomSanitizer,
    private confirmationService: ConfirmationService,private startUp: StartupService,
    private gtmService:GtmService) {
    this.initTitleBarParams();
    this.retryIntrvl= this.startUp.startupData.statusRetryInterval*1000;

  }

  processCart() {

    try {
      this.cart = this.cartService.cart;
      // const customerId = this.cart.customer.id;
      this.cartService.cart.items = [];
      this.cartService.cart.remarks = undefined;
      this.cartService.cart.pickupLocationId = undefined;
      this.cartService.setToSession();
    } catch (err) {

    }

  }

  ngAfterViewInit() {
    // this.cart = this.cartService.cart;
    this.retryCount = 0;
    this.retryId = undefined;
    this.getOrderStatus();
    const txnId = this.route.snapshot.queryParams['txnid'];
    this.updateGTMInfo(txnId);
    
  }

  getOrderStatus(){
    const pid = this.route.snapshot.queryParams['pid']; //this.cart.customer.id;
    const sucessResultParam = this.route.snapshot.queryParams['result'];
    const txnId = this.route.snapshot.queryParams['txnid'];
    
    this.paymentInitService.getOrderStatus(sucessResultParam, pid,txnId,this.retryId).subscribe(
      res => {
        this.result = res.success;
        this.paymentDetails = res.object;
        this.htmlData = undefined;
        if (this.result) {

           this.htmlData='';
          if (this.paymentDetails !== undefined && this.paymentDetails.txnId !== undefined && txnId !== null && this.paymentDetails.mailContent !== undefined && this.paymentDetails.mailContent.length > 0) {
            this.storage.set(SESSION_LAST_SUCCESS_TAX_INVOICE_NO, this.paymentDetails.taxInvoiceNumber);
            this.storage.set(SESSION_LAST_SUCCESS_TXN_ID, this.paymentDetails.txnId);
            this.storage.set(SESSION_LAST_SUCCESS_TXN_RECEIPT, this.paymentDetails.mailContent);
            this.storage.set(SESSION_LAST_TXN_RECEIPT_DOWNLOADED, false);
            this.htmlData = this.sanitizer.bypassSecurityTrustHtml(this.paymentDetails.mailContent);
            
          }
          this.processCart();
          if(this.htmlData!==''){
            
            this.router.navigate(['/view-receipt'],{ queryParams: { 'tn': this.paymentDetails.taxInvoiceNumber}});
          }

        } else {
          if(res.errorCode=='INVALID_ORDER'){
            this.processCart();
            this.confirmationService.confirm({
              key: 'payment-success',
              header: 'ORDER PROCESSED',
              message: "Your last order was found processed. Please check your mail.",
              accept: () => {
                this.router.navigate(['/order']);
              },
            });
          }else if(res.errorCode == "RETRY"){
            
            if(this.startUp.startupData.statusRetryLimit>this.retryCount){
            setTimeout(()=>{ // this will make the execution after 3000ms
              
              this.retryId = res.object;
              this.getOrderStatus();

              /**Retrun the txnId received from the gateway response,
              send it back to the client side and retry with the txnId */

              
              this.retryCount++;
            },this.retryIntrvl);           
            
          }else{

            this.confirmationService.confirm({
              header:'Receipt preview not available.',
              message: 'Your payment is successful. Please check your mail for the receipt.',
              accept: () => {
                this.processCart();
                this.router.navigate(['/order']);
              },
              });
          }
            return;
          } else{
            this.processCart();
            this.confirmationService.confirm({
              key: 'payment-success',
              header: 'FAILED TO PROCESS ORDER',
              message: res.message,
              accept: () => {
                this.router.navigate(['/order']);
              },
            });
          }
        }
      }
      
    );
  }

  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'FINISH PAYMENT',
      showLogo: false,
      showShoppingCart: false,
      showLogin: false,
      hideHamBurgerMenu: true,
      hideBackButton: true
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showPaymentSucessBottomBar: true
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }

  downloadReceipt() {

    var container = document.getElementById('receiptContentDiv');
    html2canvas(container).then(function (canvas) {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = "stpierres_receipt.png";
      link.href = canvas.toDataURL("image/png");
      link.target = '_blank';
      link.click();
    });
  }

 /**
   * GTM CODE STARTS HERE =>
   * 
   * 
   */
   
  /**
 * Extract the selected customizations
 * @returns 
 */
  getSelectedCustomizations(cartItem): any {
    let selectedCustomization = [];
    let custTotalPrice = 0;
    for (let cd of cartItem.customization) {
      for (let ov of cd.optionValue) {
        if (ov.isSelected === 1 && ov.isDefault == 0) {
          selectedCustomization.push(ov);
          custTotalPrice += ov.fixedPrice;
        }
      }
    }
    return { selectedCustomization: selectedCustomization, total: custTotalPrice };

  }

  updateGTMInfo(txnId:any){
    const cartParam = this.cartService.getCartParam();


    this.gtmService.pushEvent(
      '',
      {
        userId: this.gtmService.getCustomer(),
        visitorType: this.gtmService.getVisitorType(),
        dateSelectd: this.gtmService.geDeliveryDate(),
        timeSelected: this.gtmService.getDeliveryTime(),
        preOrder: this.gtmService.isPreOrder()

      });
     let taxP=cartParam.items[0].tax1Percentage;

    let items = [];
    for (const cartItem of cartParam.items) {
      let saleItemInfo = this.gtmService.getSaleItem(cartItem.ooSaleItemId);
      items.push({
        item_id: saleItemInfo.id,
        item_name: saleItemInfo.name,
        affiliation: this.gtmService.getStore(),
        item_brand: this.gtmService.getItemParentCategory(saleItemInfo).name,
        item_category: this.gtmService.getItemSubCategory(saleItemInfo).name,
        item_variant: '',
        price: cartItem.netTotal,
        quantity: cartItem.quantity

      });
      //{selectedCustomization:selectedCustomization,total:custTotalPrice};
      let customization = this.getSelectedCustomizations(cartItem);

      if (customization.selectedCustomization.length > 0) {
        items.push(
          {
            item_id: saleItemInfo.id,
            item_name: saleItemInfo.name,
            item_category2: (customization.selectedCustomization.length > 0) ? customization.selectedCustomization[0].nameToPrint : '',
            item_category3: (customization.selectedCustomization.length > 1) ? customization.selectedCustomization[1].nameToPrint : '',
            item_category4: (customization.selectedCustomization.length > 2) ? customization.selectedCustomization[2].nameToPrint : '',
            item_category5: (customization.selectedCustomization.length > 3) ? customization.selectedCustomization[3].nameToPrint : '',
            price: customization.total * cartItem.quantity,
            quantity: cartItem.quantity
          }
        );
      }
    }
    let totalAmount = this.cartService.getCartTotal();
    let tax = totalAmount - totalAmount / (1 + (taxP / 100));
    this.gtmService.pushG4AEvent(
      'purchase',
      {
        ecommerce: {
          transaction_id: txnId,
          currency: 'NZD',
          value: totalAmount,
          tax: tax.toFixed(2),
          items: items
        }
      });

  }

  /**
   * GTM CODE ENDS HERE =>
   */


}
