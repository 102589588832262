import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { DialogData } from 'src/app/remarks-dialog/remarks-dialog.component';

@Component({
  selector: 'app-reward-dialog',
  templateUrl: './reward-dialog.component.html',
  styleUrls: ['./reward-dialog.component.css']
})
export class RewardDialogComponent implements OnInit {

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<RewardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }


  ngOnInit(): void {
  }

  onOkClick(): void {
    this.dialogRef.close();
  }

  showAll():void{
    this.dialogRef.close();
    setTimeout(() => {
      this.router.navigate(['/my-rewards']);
    }, 500);
    
  }

}
