import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { ItemListService } from '../item-list/item-list.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SESSION_SALE_ITEM_LIST, SESSION_SELECTED_SUBCATEGORY_ID, SESSION_FILTERED_SALE_ITEM_LIST } from '../app.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { Input, ViewChild, ElementRef } from '@angular/core';
import { CartService, Cart } from '../cart/cart.service';

@Component({
  selector: 'app-all-item-list',
  templateUrl: './all-item-list.component.html',
  styleUrls: ['./all-item-list.component.css']
})
export class AllItemListComponent implements OnInit {
  @ViewChild('searchitem') nameField: ElementRef;
  cart: Cart;
  searchParam: any;
  allItems: any;
  saleItemList: any;
  userenter: string;
  constructor(private utilService: UtilService, private _itemListService: ItemListService, private route: ActivatedRoute,
     @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService, private router: Router, private cartService: CartService) {
    this.initTitleBarParams();
    this.getSaleItem();
    this.cart = this.cartService.cart;
    this.searchParam = this.route.snapshot.queryParams['search'];
    this.userenter = this.searchParam;
   }

  ngOnInit() {
    this.nameField.nativeElement.focus();
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'ITEM LIST',
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: true,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showCartBar: false
    };
    this.utilService.setFooterBarParams(this, footerConfig);

  }
  selectItemList() {
    this.allItems = null;
    if (this.saleItemList !== null && this.saleItemList !== undefined) {
        // this.allItems = this.saleItemList.filter(si => this.isItemAvailableDay(si));
        // this.allItems = this.saleItemList.filter(ic => this._itemListService.isItemActive(ic.id));
        this.allItems =  this.sessionStorage.get(SESSION_FILTERED_SALE_ITEM_LIST);
    }
    return this.allItems;
}

getSaleItem() {
  this.saleItemList = this._itemListService.saleItemList;
  if (this.saleItemList === undefined || this.saleItemList === null ) {
          this._itemListService.loadSaleItem().subscribe(
              res => {
                  this.saleItemList = res.object;
                  this.sessionStorage.set(SESSION_SALE_ITEM_LIST, this.saleItemList);
              });
      }
}
itemSelected(saleItem) {
  this.sessionStorage.set(SESSION_SELECTED_SUBCATEGORY_ID, saleItem.itemCategoryId);
     this.router.navigate(['/item-details'], { queryParams: {'source' : '', 'id': saleItem.saleItemId} });
  }


  isItemAvailableDay(saleItem) {

    let pickUpDay = new Date(this.cart.deliveryDate.date).getDay();
    if (pickUpDay === 0) {
      pickUpDay = 7;
    }
    return saleItem.itemAvailableDay.findIndex(e => e === pickUpDay) > -1;
  }

}
