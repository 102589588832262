import { Injectable, Inject } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../services/startup.service';
import { DOCUMENT } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class OrderConfirmationService {

errorJsonArrayOfCart: any;
  getOrderConfirmationUrl: string;
  constructor(private cartService: CartService, private http: HttpClient,  private startUp: StartupService,
    @Inject(DOCUMENT) private document: Document) {
    this.getOrderConfirmationUrl = this.startUp.startupData.apiurl + '/order-confirmation/confirmation-content';
   }

  getOrderConfirmation(): Observable<any> {
  const cartParam = this.cartService.getCartParam();
    return this.http.post<any>(this.getOrderConfirmationUrl, cartParam)
    .pipe(
    );
}

}
