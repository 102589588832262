import { Injectable } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate, group, keyframes, AnimationBuilder, AnimationPlayer } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class AnimationService {

  constructor(private builder: AnimationBuilder) { }

  removeFromCart(ele) {
    this.shake(ele);
  }
  addToCartAnimation(sourceEl, cartEl) {

    let player: AnimationPlayer;

    /**Find the source and target elements */
    const itemElements = document.getElementsByClassName(sourceEl);
    const cartElements = document.getElementsByClassName(cartEl);

    if ( itemElements === undefined || itemElements.length <= 0 || cartElements === undefined || cartElements.length <= 0  ) {

      return;
    }

    const cartElement = cartElements[0];
    const itemElement = itemElements[0];

    const cartItemOffset = this.getOffsets(cartElement);
    // const itemElementOffset = this.getOffsets(itemElement);
    // const xDx = (cartItemOffset.left - itemElementRrect.left) + cartElement.clientWidth / 2;
    // const yDx = (cartItemOffset.top - itemElementRrect.top) ;


    const cartItemElementRrect = cartElement.getBoundingClientRect();
    const itemElementRrect = itemElement.getBoundingClientRect();
    const xDx = (cartItemElementRrect.left - itemElementRrect.left) + cartItemElementRrect.width / 2;
    const yDx = (cartItemElementRrect.top - itemElementRrect.top) + cartItemElementRrect.height / 2;

    // const xDx = (cartItemOffset.left - itemElementOffset.left) + cartElement.clientWidth / 2;
    // const yDx = (cartItemOffset.top - itemElementOffset.top) ;

    /**Clone the target and add to its parent */
    const clonedEle =  <HTMLElement> itemElement.cloneNode(true);
    // const parent = itemElement.closest('div'); // document.body;
    const parent =  document.getElementsByClassName('app')[0];
    const parentRect = parent.getBoundingClientRect();
    // clonedEle.style.top = itemElementOffset.top.toString() + 'px';
    // clonedEle.style.left = itemElementOffset.left.toString() + 'px';

    clonedEle.style.top = itemElementRrect.top.toString() + 'px';
    clonedEle.style.left = (itemElementRrect.left - parentRect.left).toString() + 'px';
    clonedEle.style.height =  itemElementRrect.height.toString() + 'px';
    clonedEle.style.width =  itemElementRrect.width.toString() + 'px';

    clonedEle.style.opacity = '.9';
    clonedEle.style.zIndex = '99999999';
    clonedEle.style.position = 'absolute';
    parent.appendChild(clonedEle);
    /**
     * Item move effect
     * Animate the cloned item. */
    const itemAnimFactory = this.builder.build([

      animate('.6s 0.0s ease-in-out', style({
        width: '20px',
        height: '20px',
        transform: 'translate({{xDiff}}px,{{yDiff}}px)'
      })),
    ]);
    player = itemAnimFactory.create(clonedEle, { params: { xDiff: xDx, yDiff: yDx } });
    player.play();
    /**Remove the item after fininshing the animation */
    player.onDone(() => {
      parent.removeChild(clonedEle);
      this.itemAddedToCart(cartEl);
    });
  }

  getOffsets(element) {
    let el: any;
    el = element;
    let leftOffset = 0 ;
    let toptOffset = 0 ;
    /**Find the distance to move in pixcel */
    do {
      if (!isNaN(el.offsetLeft)) {
        leftOffset = leftOffset + el.offsetLeft;
      }
      if (!isNaN(el.offsetTop)) {
        toptOffset = toptOffset + el.offsetTop;
      }
    }while (el = el.offsetParent);

    return {left: leftOffset, top: toptOffset};
  }

  itemAddedToCart(ele) {
    this.shake(ele);
  }
  /** The droping to cart animation. Shake cart */
  private shake(ele) {

    /**Find the source and target elements */
    const cartElements = document.getElementsByClassName(ele);

    if ( cartElements === undefined || cartElements.length <= 0  ) {
      return;
    }

    const cartElement = cartElements[0];
    let player: AnimationPlayer;

    const carTAminFactory = this.builder.build([
      animate('.5s 0ms ease-in', keyframes([
        style({ opacity: 1, transform: 'translate(1px, 1px) rotate(0deg)' }),
        style({ opacity: 1, transform: 'translate(-1px, -2px) rotate(-1deg)' }),
        style({ opacity: 1, transform: 'translate(-3px, 0px) rotate(1deg)' }),
        style({ opacity: 1, transform: 'translate(3px, 2px) rotate(0deg)' }),
        style({ opacity: 1, transform: 'translate(1px, -1px) rotate(1deg)' }),
        style({ opacity: 1, transform: 'translate(-1px, 2px) rotate(-1deg)' }),
        style({ opacity: 1, transform: 'translate(-3px, 1px) rotate(0deg)' }),
        style({ opacity: 1, transform: 'translate(3px, 1px) rotate(-1deg)' }),
        style({ opacity: 1, transform: 'translate(-1px, -1px) rotate(1deg)' }),
        style({ opacity: 1, transform: 'translate(1px, 2px) rotate(0deg)' }),
        style({ opacity: 1, transform: 'translate(1px, -2px) rotate(-1deg)' }),
        style({ opacity: 1, transform: 'translate(1px, -2px) rotate(1deg)' }),
        style({ opacity: 1, transform: 'translate(0px, 0px) rotate(0deg)' })
      ])),
    ]);
    player = carTAminFactory.create(cartElement, {});
    player.play();
  }

  scalAnim(ele) {

    /**Find the source and target elements */
    const cartElements = document.getElementsByClassName(ele);

    if ( cartElements === undefined || cartElements.length <= 0  ) {
      return;
    }

    const cartElement = cartElements[0];
    let player: AnimationPlayer;

    const carTAminFactory = this.builder.build([
      animate('500ms 0ms ease-in', keyframes([
        style({ opacity: .1, transform: 'scale(0)'}),
        style({ opacity: .2, transform: 'scale(.25)'}),
        style({ opacity: .3, transform: 'scale(.50)'}),
        style({ opacity: .4, transform: 'scale(.75)'}),
        style({ opacity: .5, transform: 'scale(1.00)'}),
        style({ opacity: .6, transform: 'scale(1.00)'}),
        style({ opacity: .7, transform: 'scale(1.00)'}),
        style({ opacity: .8, transform: 'scale(1.00)'})
      ])),
      animate('2s 0ms ease-in', keyframes([
        style({ opacity: .99, transform: 'scale(1.00)'})
      ])),
      animate('100ms 1ms ease-in', keyframes([
        style({ opacity: .7, transform: 'scale(1.00)'}),
        style({ opacity: .5, transform: 'scale(.75)'}),

        style({ opacity: .4, transform: 'scale(.50)'}),
        style({ opacity: .2, transform: 'scale(.25)'}),

        style({ opacity: 0, transform: 'scale(0)'}),
      ])),
    ]);
    player = carTAminFactory.create(cartElement, {});
    player.play();
  }
}
