import { Component, OnInit, Input } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { OrderHistoryService } from '../order-history/order-history.service';
import { SettingsService } from '../settings/settings.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PRODUCT_IMAGE_FOLDER } from '../app.constants';
import { StartupService } from '../services/startup.service';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.css']
})
export class OrderInfoComponent implements OnInit {
  currencySymbol: any;
  systemSettings: any;
  @Input() orderInfo: any;
  recentOrderObject: any;
  displayDate: any;
  receiptMail: boolean;
  htmlData: SafeHtml;
  imageUrl: string;
  uploadUrl: any;
  constructor(private _settingsService: SettingsService,
    private orderHistoryService: OrderHistoryService,
    private cartService: CartService,private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private router: Router,
    private startUp: StartupService) {
    this.receiptMail = false;
    this.systemSettings = this._settingsService.systemSettings;
    if (this.systemSettings !== null) {
      this.currencySymbol = this.systemSettings.currency.symbol;
    }
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.imageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;
  }

  ngOnInit() {

  }

  orderAgainClick(){
    let orderid = this.orderInfo.orderId;
    this.orderHistoryService.getRecentOrderByOrderId(orderid).subscribe(
      res => {
        this.recentOrderObject = JSON.parse(res.object);/**json string return type convert to  object*/
        this.cartService.setCartFromRecentOrders(this.recentOrderObject);

       },err=>{
       });
  }
  getDisplayDate(orderInfo){
    let parsedDate1 =moment(orderInfo.deliveryDate.split(" ")[0],"YYYY-MM-DD").format("DD-MMM-YY")
    return parsedDate1;
  }

  /**
   * Redirect to details page
   * @param orderId 
   */
  redirectToOrderInfoDtl(orderId) {
    this.router.navigate(['/my-order-detail'], { queryParams: {'id': orderId} });
  
  }


  receiptClick() {
    const ement = document.querySelector('.main-div-wrap');
    ement.scrollTo(0, 0);
    ement.classList.add('Orderdet');
    this.receiptMail = true;
    this.htmlData = this.sanitizer.bypassSecurityTrustHtml(this.orderInfo.orderMailReceipt);
  }

  outerReceiptClick() {
    this.receiptMail = false;
    const ement = document.querySelector('.main-div-wrap');
    ement.classList.remove('Orderdet');

  }

  downloadReceipt() {

    var container = document.getElementById('receiptContentDiv'); 
    html2canvas(container).then(function(canvas) {
              var link = document.createElement("a");
              document.body.appendChild(link);
              link.download = "oo_recipt.png";
              link.href = canvas.toDataURL("image/png");
              link.target = '_blank';
              link.click();
          });
}
  ngOnDestroy(): void {
    this.outerReceiptClick();
  }
}
