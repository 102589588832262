import { Inject, Injectable } from '@angular/core';
import { CartService } from './cart/cart.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SESSION_ITEM_CATEGORY_LIST, SESSION_SALE_ITEM_LIST } from './app.constants';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  previousUrl: string;
  currentUrl: string;

  constructor(
    private cartService:CartService,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService, 
    ) { }

  init():void{
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  }

  /**
   * Function to push non-ecommers events to DataLayer
   * @param event 
   * @param data 
   */
  pushEvent(event: string, data: any = {}) {
    if(event!=="")
      (window as any).dataLayer.push({ event:event, ...data });
    else
      (window as any).dataLayer.push({...data });
  }

  /**
   * 
   * @param event  Function to push ecommers events to DataLayer
   * @param data 
   */
  pushG4AEvent(event: string, data: any = {}){

    (window as any).dataLayer.push({ ecommerce: null });
    this.pushEvent(event,data);
  }

  /**
   * Get the visitor type
   * @returns guest/customer
   */
  getVisitorType(): string{
   return this.cartService.getCustomerType();

  }

  /**
   * 
   * @returns customer id
   */
  getCustomer(): any{
    if(this.cartService.getCustomerType()=='guest'){
     return "";
    }else{
      return this.cartService.cart.customer.id;
    }
  }

  /**
   * 
   * @returns delivery date
   */
  geDeliveryDate(): string{
    return this.cartService.cart.deliveryDate.date;
  }

  /**
   * 
   * @returns delivery time
   */
  getDeliveryTime():string{
    return this.cartService.cart.deliveryTime;
  }

  /**
   * Whether the order is for future date
   */
  isPreOrder():boolean{
    return !this.cartService.cart.deliveryDate.label.includes('TODAY');
  }

  /**
   * 
   * @returns the shop name
   */
  getStore():string{
    return this.cartService.cart.shop.name;
  }

  /**
   * Return category name
   * @param id 
   * @returns 
   */
  getItemCatgoryName(id:any): string{
    let itemCategories = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST);
    let catgory=itemCategories.find((c:any) => c.id==id);
    return (catgory!==undefined)?catgory.name:"unknown";
  }

  /**
   * 
   * @param id of the category
   * @returns category 
   */
  getCategory(id:any):any{
    let itemCategories = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST);
    let catgory=itemCategories.find((c:any) => c.id==id);
    return catgory;
  }

  getItemParentCategory(item:any):any{
    let subCat=this.getItemSubCategory(item);
    return this.getCategory(subCat.parentId);
  }
  /**
   * Return the item's sub category
   * @param item 
   */
  getItemSubCategory(item:any):any{
    return this.getCategory(item.itemCategoryId);
  }
/**
 * Search for sale item
 * @param id 
 */
  getSaleItem(id:any):any{
    let allSaleItemList = this.sessionStorage.get(SESSION_SALE_ITEM_LIST);
    let saleItem=allSaleItemList.find((si:any) => si.id==id);
    return saleItem;
  }
}
