import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StartupService } from './startup.service';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './util.service';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DateSelectionService {
  shopHolidayNumDaysUrl: string;
  shopHolidayUrl: any;
  timeSlotUrl: any;
  constructor(private startUp: StartupService, private http: HttpClient,
              private utilService: UtilService) {
    this.timeSlotUrl = this.startUp.startupData.apiurl + '/company/get-shop-service-timeslot-info';
    this.shopHolidayUrl = this.startUp.startupData.apiurl + '/shop/shop-Holiday';
    this.shopHolidayNumDaysUrl = this.startUp.startupData.apiurl + '/shop/shop-Holiday-Num-Days';
  }

  /**Get time slots data by the selected shop and service */
  getShopServiceTimeSlots(shopServiceData: any): Observable<any> {
    return this.http.post<any>(this.timeSlotUrl, shopServiceData)
      .pipe(
        catchError(this.utilService.handleError)
      );
  }
  getShopHoliday(searchParam: any): Observable<any> {
    const holidayUrl =
    this.shopHolidayUrl + '/' + searchParam.id + '?from=' + searchParam.from + '&to=' + searchParam.to;
    return this.http.get<any>(holidayUrl)
      .pipe(
        catchError(this.utilService.handleError)
      );
  }
  getHolidayBasedOnNumDays(searchParam: any): Observable<any> {
    const shopHolidayNumDaysUrl =
    this.shopHolidayNumDaysUrl +
     '/' + searchParam.id + '?NumDays=' + searchParam.NumDays;
    return this.http.get<any>(shopHolidayNumDaysUrl)
      .pipe(
        catchError(this.utilService.handleError)
      );
  }
}
