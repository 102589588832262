import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { OrderConfirmationService } from './order-confirmation.service';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { CartService } from '../cart/cart.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { SESSION_TOKEN, SESSION_CONFIRMATION_MESSAGE } from '../app.constants';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { SettingsService } from '../settings/settings.service';
import { TimeService } from '../services/time-service.service';
import { GtmService } from '../gtm.service';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css'],
  providers: [ConfirmationService]
})
export class OrderConfirmationComponent implements OnInit {

  result: boolean;
  htmlData: SafeHtml;
  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService,
    private utilService: UtilService, private orderConfirmationService: OrderConfirmationService,
    private sanitizer: DomSanitizer, private cartService: CartService, private router: Router,
    private confirmationService: ConfirmationService, private settingsService: SettingsService,
    private timeService: TimeService,
    private gtmService:GtmService) {
    this.initTitleBarParams();
  }

  ngOnInit() {
    if (this.cartService.cart === undefined || this.cartService.cart.items.length === 0) {
      this.confirmationService.confirm({
        key: 'cd-fail',
        header: 'FAILED TO PROCESS ORDER',
        message: 'No Order details found. Please make order first.',
        accept: () => {
          this.router.navigate(['/order']);
        },
      });
    } else {
      this.cartService.saveCart().subscribe(
        res => {
          const result = res.success;
          if (result) {
            this.sessionStorage.set(SESSION_TOKEN, res.object.token);

            this.getOrderConfirmationHtml();
          } else {
            this.orderConfirmationService.errorJsonArrayOfCart = res.object;
            this.utilService.openDialog('Operation failed', 'Failed to save order, please try again');
            this.router.navigate(['/cart']);
          }
        }

      );
    }
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = true;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'CONFIRM ORDER',
      showLogo: false,
      showShoppingCart: false,
      showLogin:false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      orderConfirmationBar: true
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }
  getOrderConfirmationHtml() {
    this.orderConfirmationService.getOrderConfirmation().subscribe(
      res => {
        this.result = res.success;
        if (this.result) {
          this.htmlData = this.sanitizer.bypassSecurityTrustHtml(res.message);
          this.sessionStorage.set(SESSION_CONFIRMATION_MESSAGE, res.message);

        }
      }
    );

  }
  // validate is token expired
  proceed() {

    if(!this.result) return;
    
    const token = this.sessionStorage.get(SESSION_TOKEN);

    if (!this.validateDeliveryTime()) {
      this.router.navigate(['/datetime-selection'], { queryParams: { 'redirectUrl': '/cart' } });
      return;
    }

    this.settingsService.validateToken(token).subscribe(
      res => {
        if (res.success) {
          this.updateGTMInfoForCheckout();
          this.router.navigate(['/payment-init']);
        } else {
          this.utilService.openDialog('FAILED TO PROCESS ORDER', res.message);
          if (res.errorCode === 'INVALID_PAYMENT_TOKEN') {
            this.router.navigate(['/cart']);
          }
        }
      }
    );

  }

  validateDeliveryTime(): Boolean {

    let isValid = true;
    const dt = new Date(this.timeService.serverTime);
    const deliveryDate = this.cartService.cart.deliveryDate.date;
    const preparationTime = this.cartService.cart.businessType.initialDelay;
    const formttedDelivaryDate = new Date(this.cartService.cart.deliveryDate.date + ' ' + this.cartService.cart.deliveryTime);
    const orderBefore =  new Date(this.cartService.cart.deliveryDate.date + ' ' + this.cartService.cart.deliveryTime);
    orderBefore.setMinutes(formttedDelivaryDate.getMinutes() - preparationTime );

    if (formttedDelivaryDate <= dt) {
      this.utilService.openDialog('Time Expired.', 'The selected pickup time has expired. Please select another pickup time.');
      isValid = false;
    } else if (  dt  >= orderBefore  ) {
      this.utilService.openDialog('We are sorry.', 'We need at least ' + this.timeService.toHMFormat(preparationTime)
      + ' to prepare your order. Please select another pickup time.');
      this.cartService.cart.deliveryTime = undefined;
      isValid = false;
    }
    return isValid;
  }


  getStatusBarButtonCaption(btnId: String): String{
    
    switch(btnId){
      case "pay":
       return "PAY $" + this.cartService.getCartTotal().toLocaleString('en-us', {minimumFractionDigits: 2});
    }
  }

  /**
  * GTM CODE STARTS HERE =>
  * 
  * 
  */

  /**
 * Extract the selected customizations
 * @returns 
 */
  getSelectedCustomizations(cartItem): any {
    let selectedCustomization = [];
    let custTotalPrice = 0;
    for (let cd of cartItem.customization) {
      for (let ov of cd.optionValue) {
        if (ov.isSelected === 1 && ov.isDefault == 0) {
          selectedCustomization.push(ov);
          custTotalPrice += ov.fixedPrice;
        }
      }
    }
    return { selectedCustomization: selectedCustomization, total: custTotalPrice };

  }

  updateGTMInfoForCheckout() {
    const cartParam = this.cartService.getCartParam();


    this.gtmService.pushEvent(
      '',
      {
        userId: this.gtmService.getCustomer(),
        visitorType: this.gtmService.getVisitorType(),
        dateSelectd: this.gtmService.geDeliveryDate(),
        timeSelected: this.gtmService.getDeliveryTime(),
        preOrder: this.gtmService.isPreOrder()

      });

    let items = [];
    for (const cartItem of cartParam.items) {
      let saleItemInfo = this.gtmService.getSaleItem(cartItem.ooSaleItemId);
      items.push({
        item_id: saleItemInfo.id,
        item_name: saleItemInfo.name,
        affiliation: this.gtmService.getStore(),
        item_brand: this.gtmService.getItemParentCategory(saleItemInfo).name,
        item_category: this.gtmService.getItemSubCategory(saleItemInfo).name,
        item_variant: '',
        price: cartItem.netTotal,
        quantity: cartItem.quantity

      });
      //{selectedCustomization:selectedCustomization,total:custTotalPrice};
      let customization = this.getSelectedCustomizations(cartItem);

      if (customization.selectedCustomization.length > 0) {
        items.push(
          {
            item_id: saleItemInfo.id,
            item_name: saleItemInfo.name,
            item_category2: (customization.selectedCustomization.length > 0) ? customization.selectedCustomization[0].nameToPrint : '',
            item_category3: (customization.selectedCustomization.length > 1) ? customization.selectedCustomization[1].nameToPrint : '',
            item_category4: (customization.selectedCustomization.length > 2) ? customization.selectedCustomization[2].nameToPrint : '',
            item_category5: (customization.selectedCustomization.length > 3) ? customization.selectedCustomization[3].nameToPrint : '',
            price: customization.total * cartItem.quantity,
            quantity: cartItem.quantity
          }
        );
      }
    }

    this.gtmService.pushG4AEvent(
      'add_payment_info',
      {
        ecommerce: {
          currency: 'NZD',
          value: this.cartService.getCartTotal(),
          items: items
        }
      });

  }

  /**
   * GTM CODE ENDS HERE =>
   */

}
