import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../services/startup.service';

@Injectable({
  providedIn: 'root'
})
export class OrderHistoryService {

  historyDtlUrl: string;
  orderHistoryUrl: string;
  orderReceiptUrl:string;
  recentOrderUrl:string;
  recentOrderByOrderIdUrl: string;
  myOrdersUrl: string;
  myOrderDetailUrl: string;
  constructor(private http: HttpClient,  private startUp: StartupService) {
    this.orderHistoryUrl = this.startUp.startupData.apiurl + '/order-info/get-all';
    this.historyDtlUrl = this.startUp.startupData.apiurl + '/order-info/order-detail';
    this.orderReceiptUrl = this.startUp.startupData.apiurl + '/order-info/get-order-receipt';
    this.recentOrderUrl  = this.startUp.startupData.apiurl + '/order-info/getRecentOrders';
    this.recentOrderByOrderIdUrl = this.startUp.startupData.apiurl + '/order-info/getRecentOrderByOrderId?orderId=';
    this.myOrdersUrl=this.startUp.startupData.apiurl + '/customer/get-my-orders';
    this.myOrderDetailUrl=this.startUp.startupData.apiurl + '/customer/get-my-order-detail';
  }

  getMyOrders(customerid:any,limit:any,offset:any): Observable<any> {
    const url = this.myOrdersUrl +  '/' + customerid+ '/' +limit+'/'+offset;
    return this.http.get<any>(url)
      .pipe(
      );
  }

  getMyOrderDetail(orderId): Observable<any> {
    const url = this.myOrderDetailUrl +  '/' + orderId;
    return this.http.get<any>(url)
      .pipe(
      );
  }

  getOrderHistory(orderHistory): Observable<any> {
    return this.http.post<any>(this.orderHistoryUrl, orderHistory)
      .pipe(

      );
  }

  getOrderHistoryDetails(orderId): Observable<any> {
    const url = this.historyDtlUrl +  '/' + orderId;
    return this.http.get<any>(url)
      .pipe(
      );
  }

  getPaymentReceiptInfo(taxInvNo:any): Observable<any> {
    const url = this.orderReceiptUrl +  '/' + taxInvNo;
    return this.http.get<any>(url)
      .pipe(
      );
  }

  getRecentOrders(bodyParam): Observable<any> {
    return this.http.post<any>(this.recentOrderUrl, bodyParam)
      .pipe(

      );
  }

  getRecentOrderByOrderId(requestParam): Observable<any> {
    let reuestUrl = this.recentOrderByOrderIdUrl+requestParam;
    return this.http.get<any>(reuestUrl)
      .pipe(

      );
  }
  
}