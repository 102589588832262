import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { StartupService } from 'src/app/services/startup.service';
import { RewardDialogComponent } from '../../dialogs/reward-dialog/reward-dialog.component';
import { RewardDetailDialogComponent } from '../../dialogs/reward-detail-dialog/reward-detail-dialog.component';

@Component({
  selector: 'app-reward-panel',
  templateUrl: './reward-panel.component.html',
  styleUrls: ['./reward-panel.component.css']
})
export class RewardPanelComponent implements OnInit {

  @Input() reward:any;
  assetsUrl: any;

  constructor(private startUp: StartupService,private dialog: MatDialog) { 
    this.assetsUrl = this.startUp.startupData.crmAssetUrl;
  }

  ngOnInit() {
  }

  /**
   * Open the dialog with selected rewwards details
   */
  viewRewardDetail():void{


    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = { bottom: '0' };
    dialogConfig.maxWidth = "500px"
    dialogConfig.width = "100%"
    dialogConfig.data={reward:this.reward};

    const dialogRef = this.dialog.open(RewardDetailDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
     
    });
  }

}
