import { Injectable, Inject } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SESSION_AUTHENTICATED_CUSTOMER } from '../../app/app.constants';
import { Router } from '@angular/router';
import { HdrTitleService } from './hdr-title.service';

export interface MenuItem {
  text: string;
  icon: string;
  route: string;
  submenu: Array<MenuItem>;
  redirectUrl?: string;
  isVisibile: Boolean;
}


@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    public hdrService: HdrTitleService,
     private router: Router) { }

  items: Array<MenuItem>;
  isVertical = false;
  showingLeftSideMenu = false;

  toggleLeftSideMenu(): void {


    this.isVertical = true;
    this.showingLeftSideMenu = !this.showingLeftSideMenu;

    const dashboard = this.items.find(e => e.route === 'order');
    const login = this.items.find(e => e.route === 'login');
    const myOrder = this.items.find(e => e.route === 'my-order');
    login.isVisibile =this.hdrService.showLogin ;
    if (this.router.url === '/order') {
      // dashboard.isVisibile = false;
      // login.isVisibile = false;
    } else {
      dashboard.isVisibile = true;
      // login.isVisibile = true; -- service based
    }
    if (this.router.url.includes('/login') ||
      this.router.url.includes('/terms-and-policies') || this.router.url.includes('/contact-us') ||
      this.router.url.includes('/faq') || this.router.url.includes('/guest-form')
      || this.router.url.includes('about-us') || !this.hdrService.showLogin ) {
      // login.isVisibile = false; -- service based
    }
   
    const myProfile = this.items.find(e => e.route === 'my-profile');
    if (this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) !== null &&
      this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) !== undefined &&
      this.storage.get(SESSION_AUTHENTICATED_CUSTOMER).customerType !== 2 ) {
      myProfile.isVisibile = true;
      // login.isVisibile = true; -- service based
      myOrder.isVisibile = true;
    } else {
      myProfile.isVisibile = false;
      myOrder.isVisibile = false;
    }

    // if (this.router.url.includes('/logout') ) {
    //   myProfile.isVisibile = false;
    //   myOrder.isVisibile = false;
    //   login.isVisibile = false;
    // }
}

  toggleMenuOrientation() {
    this.isVertical = !this.isVertical;
  }

  

}
