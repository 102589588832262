import { Injectable, Inject } from '@angular/core';
import { Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { COMPANY_INFO } from '../app.constants';
import { LoginService } from './login.service';


@Injectable({
  providedIn: 'root'
})
export class StartupService {

  _startupData: any;
  companyInfo: any;
  private apiUrl = 'assets/url.json';
  private companyInfoUrl;
  constructor(private http: HttpClient,
    @Inject(SESSION_STORAGE) private storage: WebStorageService
    ) { }

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  /**Fetch company info and api url */
  async load() {

    this._startupData = await this.http.get(this.apiUrl).toPromise();
    this.companyInfoUrl =  this._startupData.apiurl + '/company/get-info';
    this.companyInfo = await this.http.get(this.companyInfoUrl).toPromise();
    this.storage.set(COMPANY_INFO, this.companyInfo.object[0]);
    
  }

  get startupData(): any {
    return this._startupData;
  }

  get companyData(): any {
  return this.companyInfo;
  }


}
