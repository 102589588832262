import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { StatusBarService, FooterConfig } from '../../fw/services/status-bar.service';
import { UtilService } from '../services/util.service';
import { ScreenService } from '../../fw/services/screens.service';
import { LoginService } from '../services/login.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import {
  SESSION_AUTHENTICATED_CUSTOMER, SESSION_CUSTOMER_FAVOURITES, SESSION_CART, SESSION_SELECTED_SHOP,
  SESSION_SELECTED_SHOP_SERVICES,
  SESSION_SALE_ITEM_LIST,
  SESSION_FILTERED_SALE_ITEM_LIST,
  SESSION_ITEM_CATEGORY_LIST,
  SESSION_FILTERED_CATEGORY_ITEM_LIST,
  SESSION_LOGIN_PROVIDER,
  REGISTERED_LOGIN,
  GOOGLE_LOGIN,
  FACEBOOK_LOGIN
} from '../app.constants';
import { loginMenuItems } from './login.menu';
import { Router, NavigationEnd, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { filter, pairwise, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider, LinkedinLoginProvider } from 'angular-6-social-login';
import { SocialLoginModule, AuthServiceConfig } from 'angular-6-social-login';  
import { CustomerLoginDialogComponent } from '../shared/customer-dialog/customer-login-dialog.component';
import { Customer } from '../view-models/customer';
import { MessageService } from 'primeng/components/common/messageservice';
import { CartService } from '../cart/cart.service';
import { initialMenuItems } from '../app.menu';
import { ConfirmationService } from 'primeng/api';
import { StoreFinderService } from '../store-finder/store-finder.service';
import { ShopDetailService } from '../shop-detail/shop-detail.service';
import { SettingsService } from '../settings/settings.service';
import { ItemListService } from '../item-list/item-list.service';
import { DateTimeServiceService } from '../services/date-time-service.service';
import { MenuService } from '../../fw/services/menu.service';
import { UserData } from './userData';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GtmService } from '../gtm.service';
import { CrmApiService } from '../services/crm-api.service';
import { RewardService } from '../services/reward.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})
export class LoginComponent implements OnInit {
    userData=new UserData(); 

  validEmail: boolean;
  saleItemList: any;
  customerCart: any;
  customerName: string;
  password: string;
  redirectUrl: string;
  credentialErrorMsg: string;
  userNameField: HTMLElement;
  socialCustomer: Customer;
  guest: Customer;
  guestdetails: any;
  showLoading: Boolean = false;
  isRemembered:Boolean= false;
  cart: any;
  // initialDelay: any;
  // initialDelayHours: any;
  // initialHours: any;
  // initialDelayMinutes: any;
  // initialMinutes: any;
  // initialTime: any;
  // amount: any;
  constructor(private utilService: UtilService,
    private screenService: ScreenService, private loginService: LoginService,
    @Inject(SESSION_STORAGE) private storage: WebStorageService, private _storeFinderService: StoreFinderService,
    private router: Router, private activatedRoute: ActivatedRoute, public cartService: CartService,
    private socialAuthService: AuthService, private statusBarService: StatusBarService, private confirmationService: ConfirmationService,
    private messageService: MessageService, private _settingsService: SettingsService,
    private itemListService: ItemListService, @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService,
    private dateTimeService: DateTimeServiceService, public menuService: MenuService,private http: HttpClient,
    private crmApiService:CrmApiService,
    private rewardService:RewardService,
    private gtmService: GtmService) {
    this.redirectUrl = this.activatedRoute.snapshot.queryParams['redirectUrl'];
    // this.urlSource = this.activatedRoute.snapshot.queryParams['source'];
    if (this.redirectUrl === null || this.redirectUrl === undefined) {
      this.continueAsGuest();
    }
    this.initTitleBarParams();
    this.utilService.setMenuItems(initialMenuItems);
    this.utilService.progressService.isLoading = false;
    this.storage.get(SESSION_CART);
    this.cart = this.cartService.cart;
    // if (this.cartService.cart !== undefined) {


    //   this.cart = this.cartService.cart;
    //   this.initialDelay = this.cart.businessType.initialDelay;
    //   this.amount = this.cart.businessType.amountMinLimit;
    //   // this.initialDelay = 260;
    //   this.initialDelayHours = this.initialDelay / 60;
    //   this.initialHours = Math.floor(this.initialDelayHours);
    //   this.initialDelayMinutes = (this.initialDelayHours - this.initialHours) * 60;
    //   this.initialMinutes = Math.round(this.initialDelayMinutes);
    //   if (this.initialHours > 0 && this.initialMinutes > 0) {
    //     this.initialTime = this.initialHours + ' hours' + "' " + this.initialMinutes + ' minutes ';
    //   } else if (this.initialHours <= 0) {
    //     this.initialTime = this.initialMinutes + ' minutes ';
    //   } else if (this.initialMinutes <= 0) {
    //     this.initialTime = this.initialHours + ' hours' + "' ";
    //   }
    // }
  }

  ngOnInit() {
    this.statusBarService.parent = this;
    this.userNameField = <HTMLElement>document.querySelectorAll('#customerName')[0];
  }

  /**Configure title bar and footer bar  */
  initTitleBarParams() {
    const showBtn = (this.redirectUrl !== undefined) ? false : true;
    this.utilService.fwConfigService.showFooterBar = showBtn;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'SIGN IN OR SIGN UP',
      showLogo: false,
      showShoppingCart: false,
      showLogin: false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showGuestUserBtn: showBtn,
      showShopListBar: false,
      showShopInfoBar: false,
      showitemDetailAddCartBar: false,
      showUpdateCartItemBar: false,
      showItemListBar: false,
      showCartBar: false,
      showPaymentSucessBottomBar: false,
      showOrderDetailsBar: false,
      showSignUpBar: false,
      orderConfirmationBar: false,
      showGuestBar: false,
      showChangePasswordBar: false,
      showMyProfileBar: false,
      showSubmitContactUs: false,
      showFaqBar: false,
      showRetryPaymentBar: false
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }

  rememberMeCheck(){
    this.isRemembered = !this.isRemembered;
    
  }

  /** Authenticate customer with username and password */
  onSubmit() {
    const credential = { 'customerName': this.customerName, 'password': this.password };
    this.utilService.progressService.isLoading = true;
    this.loginService.authenticateCustomer(credential).subscribe(
      res => {
        this.utilService.progressService.isLoading = false;
        if (res.success) {

          this.getCustomerCartDetail(res.object);
          this.rewardService.loadCustomerRewards(res.object.customer.id);
          this.storage.set(SESSION_LOGIN_PROVIDER, REGISTERED_LOGIN);
          /**keep customer info in local storage */
          /**
           * GTM Code
           */
          this.gtmService.pushEvent(
            'login',
            {
              userId: res.object.customer.id,
              authentication_method: 'account',
              visitorType:this.gtmService.getVisitorType()
            });
          if(this.isRemembered == true){
            localStorage.setItem('customerId',(res.object.customer.id));
            
          }
          
        } else {
          this.credentialErrorMsg = res.message;
        }
      }
    );
  }

  /**social customer login*/
  public socialSignIn(socialPlatform: string) {
    this.socialCustomer = null;
    let socialPlatformProvider;
    if (socialPlatform === FACEBOOK_LOGIN) {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === GOOGLE_LOGIN) {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'instagram') {
    //  this.loginWithInstagram(socialPlatform);
    }
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        this.setSocialCustomerData(userData,socialPlatform);
      }
    );
  }



  /** Authenticate social customer and redirect  */
  setSocialCustomerData(userData,socialPlatform?): void {
    this.socialCustomer = { customerType: 0 };
    if (userData && userData.email !== undefined) {
      this.socialCustomer.loginId = userData.email;
      this.socialCustomer.email = userData.email;
      this.socialCustomer.name = userData.name;
    }
    if(userData.provider === GOOGLE_LOGIN){
      this.storage.set(SESSION_LOGIN_PROVIDER, GOOGLE_LOGIN);
    }else if(userData.provider === FACEBOOK_LOGIN){
      this.storage.set(SESSION_LOGIN_PROVIDER, FACEBOOK_LOGIN);
    }

    this.utilService.progressService.isLoading = true;
    this.loginService.authenticateSocialCustomer(this.socialCustomer).subscribe(
      res => {
        this.utilService.progressService.isLoading = false;
        if (res.success) {
          this.socialCustomer = res.object;
          this.cartService.setCustomer(this.socialCustomer);
          this.storage.set(SESSION_AUTHENTICATED_CUSTOMER, this.socialCustomer);
          this.storage.set(SESSION_CUSTOMER_FAVOURITES, res.object.favourites.favShops);
          /**
           * GTM Code
           */
          this.gtmService.pushEvent(
            'login',
            {
              userId: this.gtmService.getCustomer(),
              authentication_method: socialPlatform,
              visitorType:this.gtmService.getVisitorType()
            });
          this.redirect();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error Occured', detail: res.message });
        }
      }
    );
  }
  /** Redirect the user after authenticating*/
  redirect() {
    // this.menuService.toggleLeftSideMenu();
    this.menuService.isVertical = false;
    if (this.redirectUrl !== undefined && this.redirectUrl.split('=')[2] !== undefined) {
      this.router.navigate([this.redirectUrl.split('?')[0]], { queryParams: { 'source': '', 'id': +this.redirectUrl.split('=')[2] } });
    } else if (this.redirectUrl !== undefined) {
      this.router.navigate([this.redirectUrl]);
    } else {
      this.menuService.showingLeftSideMenu = false;
      this.menuService.isVertical = false;
      if(this.cart !== undefined && this.cart.businersstype !==undefined && this.cart.service !== undefined){
        this.router.navigate(['/storefinder']);
      }else{
          this.router.navigate(['/order']);
       }
    }
  }
  continueAsGuest() {
    this.guestdetails = this.sessionStorage.get(SESSION_AUTHENTICATED_CUSTOMER);
    if ((this.guestdetails !== null)) {
      this.guest = this.guestdetails;
    } else {
      this.guest = { id: 0, name: 'Guest', customerType: 2 };
    }
    if (this.redirectUrl !== undefined) {
      this.router.navigate([this.redirectUrl]);
    } else {
      this.cartService.setCustomer(this.guest);
      this.router.navigate(['/storefinder']);
    }
  }
  signUp() {
    // if (this.redirectUrl !== undefined && this.redirectUrl === '/order-confirmation') {
      // this.router.navigate(['/sign-up'], { queryParams: { 'redirectUrl': '/order-confirmation' } });
    if (this.redirectUrl !== undefined ) {
      this.router.navigate(['/sign-up'], { queryParams: { 'redirectUrl': this.redirectUrl } });
    } else {
      this.router.navigate(['/sign-up']);
    }
  }

  /**
   * 
   * @param customerDetail 
   * previously stored cart items 
   * is not loaded from the api side since 2021/10/20.
   * for that api code changed,clientside angular code not changed.(2021/10/20)
   * and also now customer info stored to local storage.
   */

  getCustomerCartDetail(customerDetail) {
    /* *****session businersstype id  this.cartService.cart.businessType.id
        previous businesstype id customerDetail.cart.businessType.id ***** */
    if (customerDetail.cart !== null && (customerDetail.cart.items.length !== 0)) {
      // if (this.cartService.cart.businessType !== undefined && this.cartService.cart.businessType.id === customerDetail.cart.businessType.id) {
        this.confirmationService.confirm({
          message: 'Previously saved order exist. Do you want to load it?',
          accept: () => {
            this.cartService.resetCart();
            if (customerDetail.cart !== null) {
              customerDetail.cart.deliveryDate = this.dateTimeService.getDisplayDate(customerDetail.cart.deliveryDate);
              // { date: customerDetail.cart.deliveryDate, label: customerDetail.cart.deliveryDate };
              this.storage.set(SESSION_SELECTED_SHOP, customerDetail.cart.shop);
              // this.storage.set(SESSION_SELECTED_SHOP_SERVICES , customerDetail.cart.shop.shopServices[0]);
            }
            this.storage.set(SESSION_CART, customerDetail.cart);
            this.cartService.initializeCart();
            this.cartService.setCustomer(customerDetail.customer);
            this.saleItemList = this.itemListService.saleItemList;
            this.storage.set(SESSION_AUTHENTICATED_CUSTOMER, customerDetail.customer);
            this.storage.set(SESSION_CUSTOMER_FAVOURITES, customerDetail.customer.favourites.favShops);
            // this.menuService.toggleLeftSideMenu();
            this.menuService.isVertical = false;
            this.setProductData();
          },
          reject: () => {
            this.setCustomerSession(customerDetail);
            
          }
        });
      // } else {
      //   this.setCustomerSession(customerDetail);
      //   this.cartService.cartdelete().subscribe(
      //     res => {
      //       const result = res.success;
      //     });
      // }

    } else {
      this.setCustomerSession(customerDetail);
    }
  }
  setProductData() {
    const businessTypeId = this.cartService.cart.businessType.id;
    const pickUpDate = this.cartService.cart.deliveryDate.date;
    const shopId = this.cartService.cart.shop.id;
    this.utilService.progressService.isLoading = true;
    const filteredSaleitemlist = this.itemListService.loadSaleItemExt(businessTypeId, pickUpDate, this.cartService.cart.shop).subscribe(
      res => {
        const _saleItemList = res.object;
        this.storage.set(SESSION_FILTERED_SALE_ITEM_LIST, _saleItemList);
        const categoryList = this.storage.get(SESSION_ITEM_CATEGORY_LIST);
        const filteredCategory = this.itemListService.getFilteredItemCategoryList(categoryList, _saleItemList);
        this.storage.set(SESSION_FILTERED_CATEGORY_ITEM_LIST, filteredCategory);
        this.utilService.progressService.isLoading = false;
        this.router.navigate(['/cart']);
      });
  }
  // setProductData() {
  //   const saleItemList = this.storage.get(SESSION_SALE_ITEM_LIST);
  //   const filteredSaleitemlist = this.itemListService.getFilteredSaleitemlist(saleItemList);
  //   this.storage.set(SESSION_FILTERED_SALE_ITEM_LIST, filteredSaleitemlist);
  //   const categoryList = this.storage.get(SESSION_ITEM_CATEGORY_LIST);
  //   const filteredCategory = this.itemListService.getFilteredItemCategoryList(categoryList, filteredSaleitemlist);
  //   // const filteredCategory = this._itemListService.getFilteredSuperCategoryList(categoryList, filteredsubCategory);
  //   this.storage.set(SESSION_FILTERED_CATEGORY_ITEM_LIST, filteredCategory);
  // }
  setCustomerSession(customerDetail) {
    this.loginService.getCustomerDetail(customerDetail);
    this.saleItemList = this.itemListService.saleItemList;
    this.redirect();
  }
  clearFunction() {
    this.credentialErrorMsg = ' ';
  }
  /**
    *
    * @param email email validation
    */
  emailValidation(email) {
    this.validEmail = false;
    // tslint:disable-next-line:max-line-length
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validEmailRegEx.test(email)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    return this.validEmail;
  }

  forgotPasswordClick() {
    if (this.customerName === undefined || this.customerName.length <= 0) {
      this.credentialErrorMsg = 'Email address can not be blank. Please enter your email.';
      return;
    } else if (!(this.emailValidation(this.customerName))) {
      this.credentialErrorMsg = 'Email address not valid!';
      return;
    }
    this.confirmationService.confirm({
      message: 'This will reset your password and a new password will be sent to your mail?',
      accept: () => {
        this.utilService.progressService.isLoading = true;
        const credential = { 'customerName': this.customerName };
        this.loginService.forgotPassword(credential).subscribe(
          res => {
            if (res.success) {
              this.utilService.progressService.isLoading = false;
              this.utilService.openDialog('Password Reset!', res.message);

            } else {
              this.utilService.progressService.isLoading = false;
              this.credentialErrorMsg = res.message;
            }
          }
        );
      },
      reject: () => {
      }

    });

  }
}
