import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { CartService } from '../cart/cart.service';
import { SESSION_SYSTEM_SETTINGS, SESSION_CONFIRMATION_MESSAGE } from '../app.constants';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { StartupService } from '../services/startup.service';
import { PaymentInitService } from './payment-init.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { TimeService } from '../services/time-service.service';


@Component({
  selector: 'app-payment-init',
  templateUrl: './payment-init.component.html',
  styleUrls: ['./payment-init.component.css'],
  providers: [ConfirmationService]
})
export class PaymentInitComponent implements AfterViewInit {

  confirmationMessage: any;
  redirectUrl: any;
  baseUrl: any;
  currency: any;
  cartToInit: any;
  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService, @Inject(DOCUMENT) private document: Document,
  private utilService: UtilService, private cartService: CartService, private startUp: StartupService,
  private paymentInitService: PaymentInitService, private router: Router, private confirmationService: ConfirmationService,
  private activatedRoute: ActivatedRoute,  private timeService: TimeService) {
    this.initTitleBarParams();
    this.currency = this.sessionStorage.get(SESSION_SYSTEM_SETTINGS).currency;
    this.baseUrl = this.startUp.startupData.apiurl;
    this.redirectUrl = this.activatedRoute.snapshot;
    this.confirmationMessage = this.sessionStorage.get(SESSION_CONFIRMATION_MESSAGE);
  }

  ngAfterViewInit() {
   //this.cartToInit = this.cartService.cart;
   this.cartToInit=JSON.parse(JSON.stringify(this.cartService.cart));
   this.paymentProcess();
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'PAYMENT', 
      showLogo: false,
      showShoppingCart: false,
      showLogin:false,
      hideHamBurgerMenu: true,
      hideBackButton: true
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showCartBar: false
    };
    this.utilService.setFooterBarParams(this, footerConfig);

  }

  paymentProcess() {
    this.getPaymentRequestParam();
  }
    getPaymentRequestParam() {
      // const cartCustomer = this.cartService.cart.customer;
      const cartShop =  this.cartService.cart.shop;
      this.cartToInit.deliveryDate = this.cartService.cart.deliveryDate.date;
      this.cartToInit.customerId = this.cartService.cart.customer.id;
      const today = new Date(this.timeService.serverTime);
      const date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      this.cartToInit.orderDate = date;
      this.cartToInit.orderTime = time;
      const paymentParam = {customerId: this.cartService.cart.customer.id, customerName: this.cartService.cart.customer.firstName, customerPhone: this.cartService.cart.customer.phoneNumber,
       customerAddress: this.cartService.cart.customer.address, customerEmail: this.cartService.cart.customer.email, amount: this.cartService.getCartTotal().toFixed(2),
       currency : this.currency.code, baseUrl: this.document.baseURI, shopAccountNumber: cartShop.accountNumber, cart: this.cartToInit,
        orderConfirmation: this.confirmationMessage};
       this.paymentInitService.paymentRequest(paymentParam).subscribe(
        res => {
         const result = res.success;
           if (result) {
            this.document.location.href = res.object.paymentUrl;
         } else if (res.errorCode === 'INVALID_ORDER') {
          this.confirmationService.confirm({
            key: 'payment-fail',
            header: 'FAILED TO PROCESS ORDER',
            message: res.message,
            accept: () => {
              this.cartService.cart.items = [];
              this.cartService.cart.remarks = undefined;
              this.cartService.cart.pickupLocationId = undefined;
              this.cartService.setToSession();
              this.router.navigate(['/order']);
            },
          });
         } else {
          this.router.navigate(['/payment-fail']);

         }
       }
     );
  }
}
