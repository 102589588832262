import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { RewardDialogComponent } from 'src/app/myrewards/dialogs/reward-dialog/reward-dialog.component';
import { DialogData } from 'src/app/remarks-dialog/remarks-dialog.component';

@Component({
  selector: 'app-reward-notification',
  templateUrl: './reward-notification.component.html',
  styleUrls: ['./reward-notification.component.css'],
})
export class RewardNotificationComponent implements OnInit {

  animal: string;
  name: string;

  constructor( private dialog: MatDialog) { }

  ngOnInit() {
  }

  /***
  * Show the listing the available rewards
  */
  showRewardInfo() {
    this.openDialog();
  }

  openDialog(): void {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = { bottom: '0' };
    dialogConfig.maxWidth = "500px"
    dialogConfig.width = "100%"
    dialogConfig.minHeight 

    const dialogRef = this.dialog.open(RewardDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
 
    });
  }
}
