import { Component, OnInit, Inject, AfterContentInit, AfterViewInit } from '@angular/core';
import { UtilService } from '../services/util.service';
import { CartService } from '../cart/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { PaymentInitService } from '../payment-init/payment-init.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { TitleConfig } from 'src/fw/services/hdr-title.service';
import { SESSION_LAST_SUCCESS_TAX_INVOICE_NO, SESSION_LAST_SUCCESS_TXN_ID, SESSION_LAST_SUCCESS_TXN_RECEIPT, SESSION_LAST_TXN_RECEIPT_DOWNLOADED } from '../app.constants';
import html2canvas from 'html2canvas';
import { FooterConfig } from 'src/fw/services/status-bar.service';
import { OrderHistoryService } from '../order-history/order-history.service';
import * as moment from 'moment';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-view-receipt',
  templateUrl: './view-receipt.component.html',
  styleUrls: ['./view-receipt.component.css']
})
export class ViewReceiptComponent implements OnInit, AfterContentInit, AfterViewInit{
  lastTaxInvNo: any;
  htmlData: SafeHtml;
  lastTxnMail: any;
  queryTaxInvNumber: any;
  scrollToBottmVisible=true;
  receiptDownloaded=false;
  showScrollButton=true;
  constructor(private utilService: UtilService, private route: ActivatedRoute, private router: Router,  @Inject(SESSION_STORAGE) private storage: WebStorageService,
     private sanitizer: DomSanitizer, public cartService: CartService, private dataService: OrderHistoryService) {

    this.initTitleBarParams();

    this.lastTaxInvNo=this.storage.get(SESSION_LAST_SUCCESS_TAX_INVOICE_NO);
    this.receiptDownloaded= this.storage.get(SESSION_LAST_TXN_RECEIPT_DOWNLOADED);
    this.queryTaxInvNumber= this.route.snapshot.queryParams['tn'];
    this.htmlData = undefined;
    if(this.queryTaxInvNumber!==this.lastTaxInvNo){
      this.lastTaxInvNo=undefined;
      this.dataService.getPaymentReceiptInfo(this.queryTaxInvNumber).subscribe(
        res => {
          var result = res.success;
          var paymentDetails = res.object;
          if (result) {
            this.storage.set(SESSION_LAST_SUCCESS_TAX_INVOICE_NO, paymentDetails.taxInvoiceNumber);
            this.storage.set(SESSION_LAST_SUCCESS_TXN_RECEIPT, paymentDetails.mailContent);
            this.htmlData = this.sanitizer.bypassSecurityTrustHtml(paymentDetails.mailContent);
            this.lastTaxInvNo=paymentDetails.taxInvoiceNumber;
            setTimeout(() => {
              this.downloadReceipt();
            }, 3);
            this. setScrollButtons();
          }else{
            
            // this.confirmationService.confirm({
            //   key: 'receiptinfo-failed',
            //   header: 'Receipt info',
            //   message: "Not able to show the receipt. Please check your mail or 'My Orders' ",
            //   accept: () => {
            //     this.router.navigate(['/order']);
            //   },
            // });
          }
        }
      )
    }else{
      this.lastTxnMail=this.storage.get(SESSION_LAST_SUCCESS_TXN_RECEIPT);
      this.htmlData = this.sanitizer.bypassSecurityTrustHtml(this.lastTxnMail);
      
    }
  }

  /**
   * 
   */
  ngAfterViewInit(): void {
   
    this. setScrollButtons();
   
  }

  /**
   * 
   */
  setScrollButtons():void{
    setTimeout(() => {
      var element = document.getElementsByClassName("main-div-wrap")[0];
      this.showScrollButton=(element.scrollHeight > element.clientHeight);
    }, 3);
  }

  /**
   * 
   */
  ngAfterContentInit(): void {
  
    if(!this.receiptDownloaded){
      setTimeout(() => {
        this.downloadReceipt();
      }, 3);
    }
  }

  ngOnInit() { 

    let tracker = document.getElementsByClassName("main-div-wrap")[0];

    let windowYOffsetObservable = fromEvent(tracker, 'scroll').pipe(map(() => {
      return tracker.scrollTop;
    }));

    // subscribe to our Observable so that for each new item, our callback runs
    // this is our event handler
    let scrollSubscription = windowYOffsetObservable.subscribe((scrollPos) => {
      let botomLimit = tracker.scrollHeight - tracker.clientHeight;

      if (scrollPos === botomLimit) {
        this.scrollToBottmVisible=false;
      }else if(scrollPos === 0) {
        this.scrollToBottmVisible=true;
      }

    });

   }

  

  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = true;
    this.utilService.resetFooterConfigData(this);
    const shoLogin=(this.cartService.cart !== undefined  && this.cartService.cart.customer !== undefined && this.cartService.cart.customer.customerType===1);
    const titleConfig: TitleConfig = {
      title: 'YOUR RECEIPT',
      showLogo: false,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: true,
      showLogin: shoLogin
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showViewReceiptFooter: true
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }

  downloadReceipt() {
    
    if(this.htmlData!==undefined){
      var container = document.getElementById('receiptContentDiv'); 
      html2canvas(container).then(canvas => {
                // var fileName= "oo_recipt-"+ this.lastTaxInvNo.split("-")[2]+"-.png"
                
                var fileName= "oo_recipt-("+ moment().format("DD-MM-YYYY")+")-(" + this.lastTaxInvNo.split("-")[2]+").png"
                var link = document.createElement("a");
                document.body.appendChild(link);
                link.download = fileName;
                link.href = canvas.toDataURL("image/png");
                link.target = '_blank';
                link.click();
            });
     this.storage.set(SESSION_LAST_TXN_RECEIPT_DOWNLOADED, true);
    }
  }

  scrolToBottom(){
    var element = document.getElementsByClassName("main-div-wrap")[0];
    var bottom = element.scrollHeight - element.clientHeight;
    this.showScrollButton=(element.scrollHeight > element.clientHeight);
    element.scrollTo({top:bottom,left:0,behavior:"smooth"});
    this.scrollToBottmVisible=false;
  }

  scrolToTop(){
    
    var element = document.getElementsByClassName("main-div-wrap")[0];
    element.scrollTo({top:0,left:0,behavior:"smooth"});
    this.scrollToBottmVisible=true;
  }

}

