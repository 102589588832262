import { Component, Input, OnInit, Inject } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import {
  SESSION_SELECTED_SUBCATEGORY_ID, SESSION_SELECTED_SHOP, CATEGORY_IMAGE_FOLDER,
  SESSION_DEPARTMENT_LIST
} from '../../app.constants';
import { Router } from '@angular/router';
import { StartupService } from '../../services/startup.service';
import { GtmService } from 'src/app/gtm.service';

@Component({
  selector: 'app-item-category-panel',
  templateUrl: './item-category-panel.component.html',
  styleUrls: ['./item-category-panel.component.css']
})
export class ItemCategoryPanelComponent implements OnInit {

  departmentList: any;
  shopDepartmentIds: any;
  selectedShop: any;
  @Input() subCategory: any;
  shopItemCategoryIds: any[];
  imageUrl: any;
  uploadUrl: any;
  getmessage: any;
  newclass: any;
  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService, private startUp: StartupService,
    private router: Router,
    private gtmService: GtmService
  ) { }



  ngOnInit() {
    this.selectedShop = this.sessionStorage.get(SESSION_SELECTED_SHOP);
    this.departmentList = this.sessionStorage.get(SESSION_DEPARTMENT_LIST);
    this.shopDepartmentIds = this.sessionStorage.get(SESSION_SELECTED_SHOP).department;
    this.shopItemCategoryIds = this.sessionStorage.get(SESSION_SELECTED_SHOP).item_category;
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.imageUrl = this.uploadUrl + CATEGORY_IMAGE_FOLDER;
    const itemcode = this.departmentList.find(e => e.id === this.subCategory.departmentId);

    if (itemcode.code === 'BENTO') {
      this.getmessage = 'AVAILABLE ONLY IN SHOPS SELLING BENTO BOWL';
      this.newclass = 'na-button1';
    } else {
      this.getmessage = 'TEMPORARILY UNAVAILABLE';
      this.newclass = 'na-button';
    }
  }
  /**Redirect to item list screen*/

  redirectToSubCategory() {
    this.sessionStorage.set(SESSION_SELECTED_SUBCATEGORY_ID, this.subCategory.id);
    this.gtmService.pushEvent(
      'select_category',
      {
        brandName: this.gtmService.getItemCatgoryName(this.subCategory.parentId),
        categoryName: this.subCategory.name,
        storeSelected: this.gtmService.getStore(),
        visitorType: this.gtmService.getVisitorType(),
        dateSelected: this.gtmService.geDeliveryDate(),
        timeSelected: this.gtmService.getDeliveryTime(),
        preOrder: this.gtmService.isPreOrder()
      }
    );
    this.router.navigate(['/item-list']);
  }

  /**Check whether the category is available in selected shop */
  isCategoryUnavailable() {
    let categoryUnavailable = false;
    if ((this.shopItemCategoryIds !== null && !(this.shopItemCategoryIds.indexOf(this.subCategory.id) !== -1)) ||
      (this.shopDepartmentIds !== null && !(this.shopDepartmentIds.indexOf(this.subCategory.departmentId) !== -1)) ||
      this.shopDepartmentIds === null) {
      categoryUnavailable = true;
    }
    return categoryUnavailable;
  }

}
