import { Injectable } from '@angular/core';
import { StartupService } from '../../app/services/startup.service';
import { CartService } from 'src/app/cart/cart.service';

export interface TitleConfig {
  title?: string;
  showLogo?: boolean;
  showShoppingCart?: boolean;
  showLogin?:boolean;
  hideHamBurgerMenu?: boolean;
  hideBackButton?: boolean;
  overrideBackButton?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class HdrTitleService {
  title: string;
  showLogo: boolean;
  showShoppingCart: boolean;
  showLogin: boolean
  parent: any;
  hideHamBurgerMenu: boolean;
  hideBackButton: boolean;
  overrideBackButton: boolean;
  public cartImage: any;

  constructor(private startUp: StartupService, private cartService: CartService, ) {

    if (this.cartService.cart.businessType !== undefined) {
      this.setCartImage(this.cartService.cart.businessType);
    }

  }
  configure(parent: any, titleSettings: TitleConfig): void {
    this.resetTitleBarParams();
    this.parent = parent;
    Object.assign(this, titleSettings);
  }

  setCartImage(businessType) {
    this.cartImage = this.startUp.startupData.uploadurl + '/images/icons/' + businessType.smallIcon;
  }

  resetTitleBarParams() {
    this.title = '';
    this.showLogo = false;
    this.showShoppingCart = true;
    this.showLogin = true;
    this.hideHamBurgerMenu = false;
    this.hideBackButton = false;
    this.overrideBackButton = false;
  }

  /**
   * Call the call back if defined.
   */
  goToCartCallback(){
    if(this.parent.goToCart!==undefined){
      this.parent.goToCart();
    }
  }
}
