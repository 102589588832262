import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'src/app/remarks-dialog/remarks-dialog.component';
import { RewardDialogComponent } from '../reward-dialog/reward-dialog.component';

@Component({
  selector: 'app-reward-detail-dialog',
  templateUrl: './reward-detail-dialog.component.html',
  styleUrls: ['./reward-detail-dialog.component.css']
})
export class RewardDetailDialogComponent implements OnInit {
  reward: any;

  constructor(
    public dialogRef: MatDialogRef<RewardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.reward=data.reward;
   }


  ngOnInit(): void {
  }

  onRedeem(): void {
    this.dialogRef.close();
  }

  onClose():void{
    this.dialogRef.close();
  }

}