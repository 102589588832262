import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { COMPANY_INFO } from '../app.constants';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css', '../../assets/css/quill.snow.css']
})
export class FaqComponent implements OnInit {

  companyInfo: any;
  faqContent: any;
  constructor(private utilService: UtilService,
    private sanitizer: DomSanitizer,
    @Inject(SESSION_STORAGE) private storage: WebStorageService) {
    this.initTitleBarParams();
   }

  ngOnInit() {
    this.companyInfo = this.storage.get(COMPANY_INFO);
    this.faqContent = this.sanitizer.bypassSecurityTrustHtml(this.companyInfo.faq);
  }

  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'FAQ',
      showLogo: false,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showFaqBar: true
    };
    this.utilService.setFooterBarParams(this, footerConfig);

  }

}
