import { Component, OnInit } from '@angular/core';
import { StoreSelectionService } from '../../services/store-selection.service';
import { StatusBarService } from '../../services/status-bar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shop-filter-bottom-nav',
  templateUrl: './shop-filter-bottom-nav.component.html',
  styleUrls: ['./shop-filter-bottom-nav.component.css']
})
export class ShopFilterBottomNavComponent implements OnInit {

  constructor(private storeSelectionService: StoreSelectionService, private statusBarService: StatusBarService,
    private router: Router) { }

  ngOnInit() {
  }
/**
   * Apply  filtering conditions
   */
  apply() {
    this.storeSelectionService.filterParams  = {'apply': true, 'amenities': [], 'distance': 5, 'itemCategory': []};
    this.statusBarService.parent.applyFilter();
   }
   /**
   * Remove Selected filtering conditions and reset the page to the initial stage
   */
   remove() {
     this.storeSelectionService.filterParams  = {'apply': false, 'amenities': [], 'distance': 5, 'itemCategory': []};
     this.router.navigate(['/storefinder']);

    }
 }
