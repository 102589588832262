import { Injectable, Inject } from '@angular/core';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import {
    SESSION_AUTHENTICATED_CUSTOMER, SESSION_BUSINESS_TYPE_LIST, SESSION_CART,
    SESSION_FILTERED_SALE_ITEM_LIST, SESSION_MASTER_CUSTMIZATION, SESSION_OUTOFSTOCK_ITEM_LIST, SESSION_PROMOTIONAL_SALEITEM_IDS, SESSION_SALE_ITEM_LIST, SESSION_SELECTED_SHOP, SESSION_SERVICE_LIST, SESSION_SHOP_LIST
} from '../app.constants';
import { Customer } from '../view-models/customer';
import { UUID } from 'angular2-uuid';
import { StartupService } from '../services/startup.service';
import { iif, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Time } from '@angular/common';
import { TaxService } from '../services/tax.service';
import { TimeService } from '../services/time-service.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Router } from '@angular/router';

export interface Cart {
    cartId: number;
    customer: Customer;
    service: any;
    shop: any;
    orderDate: any;
    orderTime: any;
    deliveryDate: any;
    deliveryTime: any;
    businessType: any;
    pickupLocationId: any;
    items: Array<CartItem>;
    remarks: any;
    reOrder?:boolean
}
export interface CartItem {

    itemId: any; // cart Item Id
    ooSaleItemId: number;
    saleItemId: number;
    name?: String;//item name
    quantity: number;
    fixedPrice: number;
    unitPriceWithTax: number;
    totalPrice: number;
    isCustomized: boolean;
    customization: Array<Customization>;
    remarks: any;
    taxCalculationMethod: any;
    tax1Percentage: any;
    netTotal: any;
    totalTax: any;
    customizationUnitPriceWithTax: number;
    netCustomizationTotal: Number;
    hasError?: Boolean;
    hasWarning?: Boolean;
    hasOutofStockError?: Boolean;
    errorMessage?: String;
    warningMessage?: String;
}
export interface Customization {
    id: number;
    bgColor: any;
    code: any;
    custOptId: number;
    description: String;
    displayName: String;
    displayOrder: any;
    fgColor: any;
    name: String;
    optionValue: Array<OptionValue>;
}
export interface OptionValue {
    code: String;
    custOptId: number;
    description: String;
    displayOrder: any;
    fixedPrice: number;
    id: number;
    isDefault: number;
    isSelected: number;
    name: any;
    nameToPrint: any;
    netTotal: number;
    price: number;
    quantity: number;
    saleItemId: number;
    tax1Percentage: number;
    taxCalculationMethod: number;
    totalPrice: number;
    totalTax: number;
    unitPriceWithTax: number;
}
@Injectable({
    providedIn: 'root'
})
export class CartService {
    customerCartUrl: String;
    cartSaveUrl: string;
    cartdeleteUrl: string;
    private _cart: Cart;
    saleItemList: any;
    promotionalItems: any[];
    outOfStockItemIds: any;
    filteredSaleItemListData: any;
    outOfStockItemInfo: any;
    get cart(): Cart {
        if (this._cart === null || this._cart === undefined) {
            this.initializeCart();
        }
        return this._cart;
    }

    constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService,
        private startUp: StartupService, private timeService: TimeService,
        private http: HttpClient, private taxService: TaxService, private router: Router) {
        this.cartSaveUrl = this.startUp.startupData.apiurl + '/cart/save';
        this.customerCartUrl = this.startUp.startupData.apiurl + '/cart/get/';
        this.cartdeleteUrl = this.startUp.startupData.apiurl + '/cart/delete';

    }
    /**
     * initialise  cart-
     * Check local storage for any cart object. If any get that cart else create new. Save it
     */
    initializeCart() {
        this._cart = this.sessionStorage.get(SESSION_CART);
        if (this._cart === null || this._cart === undefined) {
            this._cart = {
                cartId: 0, customer: undefined, service: undefined, shop: undefined, orderDate: undefined, orderTime: undefined,
                deliveryDate: undefined, deliveryTime: undefined, businessType: undefined, pickupLocationId: undefined, items: [], remarks: undefined
            };
            this.setToSession();
        }

    }
    setToSession() {
        this.sessionStorage.set(SESSION_CART, this._cart);
    }
    /**
      * Clear cart
      */
    clear() {
        this._cart.items = [];
        this.setToSession();
    }
    /**
      * add item to cart
      */
    addItem(itemInfo: any) {
        itemInfo.itemId = UUID.UUID();
        this._cart.items.push(itemInfo);
        this.setToSession();
    }
    /**
      * add item to cart after the given item.
      */
    addItemAfter(itemAter: any, newItem: any) {
        newItem.itemId = UUID.UUID();
        const index = this._cart.items.findIndex(e => e.itemId === itemAter.itemId);
        this._cart.items.splice(index + 1, 0, newItem);
        this.setToSession();
    }
    setCustomer(customer: any) {
        this._cart.customer = customer;
        this.setToSession();
    }

    setReOrder(isReorder:boolean):void{
        this._cart.reOrder=isReorder;
    }

    getReorder():boolean{
       return (this._cart.reOrder!==undefined && this._cart.reOrder)?true:false;
    }

    getCustomer():any{
        return this._cart.customer;
     
    }
    setService(service: any) {
        this._cart.service = service;
        this.setToSession();
    }
    setBusinessType(businessType: any) {
        this._cart.businessType = businessType;
        this.setToSession();
    }
    setPickupLocation(pickupLocationId: any) {
        this._cart.pickupLocationId = pickupLocationId;
        this.setToSession();
    }

    deleteItem(itemInfo: any) {
        let index = -1;
        index = this._cart.items.findIndex(e => e.itemId === itemInfo.itemId);
        this._cart.items.splice(index, 1);
        this.setToSession();
    }
    // deleteAllItem(itemInfo: any) {
    //   let index = -1;
    //   index = this._cart.items.findIndex(e => e.itemId === itemInfo.itemId);
    //   this._cart.items.splice(index, 1);
    //   this.setToSession();
    // }
    setDeliveryDate(deliveryDate: any) {
        this._cart.deliveryDate = deliveryDate;
        this.setToSession();
    }
    setDeliveryTime(deliveryTime: any) {
        this._cart.deliveryTime = deliveryTime;
        this.setToSession();
    }
    setShop(shop: any) {
        this._cart.shop = shop;
        this.setToSession();
    }
    resetCart() {
        this._cart.reOrder=false;
        this._cart.shop = undefined;
        this._cart.items = [];
        this._cart.deliveryDate = undefined;
        this._cart.deliveryTime = undefined;
        // this._cart.customer = undefined;
        this._cart.orderDate = undefined;
        this._cart.orderTime = undefined;
        this._cart.remarks = undefined;
        this._cart.service = undefined;
        this._cart.businessType = undefined;
        this._cart.pickupLocationId = undefined;
        this.setToSession();

    }
    /**
     * set remarks
     * @param remarks
     */
    setRemarks(remarks: any) {
        this._cart.remarks = remarks;
        this.setToSession();
    }
    /**
     * find cart total
     */
    getCartTotal() {
        let total = 0;
        this._cart.items.forEach(e => {
            total = total + (e.netTotal + (e.netCustomizationTotal !== undefined ? e.netCustomizationTotal : 0));
        });
        return total;
    }
    /**
     * find total  quantity
     */
    getTotalQuantity() {
        let quantity = 0;
        if (this._cart === undefined) {
            this.initializeCart();
        }
        this._cart.items.forEach(e => {
            quantity = quantity + e.quantity;
        });
        return quantity;
    }
    /**
     *
     * @param cartItem change cart item
     */
    changeItem(cartItem: any) {
        let index = -1;
        index = this._cart.items.findIndex(e => e.itemId === cartItem.itemId);
        if (index !== -1) {
            this._cart.items[index].totalPrice = cartItem.totalPrice;
            this._cart.items[index].quantity = cartItem.quantity;
            this._cart.items[index].customization = cartItem.customization;
            this._cart.items[index].customizationUnitPriceWithTax = cartItem.customizationUnitPriceWithTax;
            this._cart.items[index].netCustomizationTotal = cartItem.netCustomizationTotal;
            this.taxService.reCalculateTax(this._cart.items[index]);
            this.setToSession();
        }
    }
 
    /**
     * 
     * @param cartItem Updates the customization
     */
    setCustomization(cartItem: any) {
        var customizationDetail = cartItem.customization;
        var customizationUnitPriceWithTax = 0;
        var netCustomizationTotal = 0;
        for (let cd of customizationDetail) {
            for (let ov of cd.optionValue) {
                if (ov.isSelected === 1) {
                    ov.quantity = cartItem.quantity;
                    this.taxService.reCalculateTax(ov);
                    customizationUnitPriceWithTax += (ov.isDefault)?0:ov.unitPriceWithTax;
                    netCustomizationTotal += (ov.isDefault)?0:ov.netTotal;
                } else {
                    ov.quantity = 1;
                    this.taxService.reCalculateTax(ov);
                }
            }
        }
        cartItem.customizationUnitPriceWithTax = customizationUnitPriceWithTax;
        cartItem.netCustomizationTotal = netCustomizationTotal;
    }

    /**
     * check whether guest
     */
    isGuestLogin(): boolean {
        return (this._cart.customer ==undefined || this._cart.customer.customerType === 2);
    }

    /**
     * check whether guest or customer
     * return guest if guest or return customer
     */
    getCustomerType(): string {
        return this.isGuestLogin()? 'guest' : 'customer';
    }

    /**
     * cart param
     */
    getCartParam(): any {
        // const deliveryDate = this._cart.deliveryDate.date;
        // const deliveryDateFormatted = deliveryDate;
        const today = new Date(this.timeService.serverTime);
        //   const today = new Date();
        const date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        const cartParam = {
            customerId: this._cart.customer.id, service: this._cart.service, shop: this._cart.shop,
            orderDate: date, orderTime: time, deliveryDate: this._cart.deliveryDate.date,
            deliveryTime: this._cart.deliveryTime, businessType: this._cart.businessType,
            pickupLocationId: this._cart.pickupLocationId,
            items: this._cart.items, remarks: this._cart.remarks
        };
        return cartParam;
    }


    isCartValid(): boolean {

        return (this._cart !== undefined && this._cart.items !== undefined && this._cart.items.length > 0);
    }

    saveCart(): Observable<any> {

        const cartParam = this.getCartParam();
        return this.http.post<any>(this.cartSaveUrl, cartParam)
            .pipe(
            );
    }

    cartdelete(): Observable<any> {

        return this.http.delete<any>(this.cartdeleteUrl + '/' + this._cart.customer.id)
            .pipe(
            );
    }

    getCartBasedOnCustomerId(customerId: any): Observable<any> {
        const url = this.customerCartUrl + customerId;
        return this.http.get<any>(url)
            .pipe(
            );
    }

    /**
     * 
     * @param reOrderItem 
     * set recent order information to cart.
     */
    setCartFromRecentOrders(reOrderItem) {

        this.resetCart();
        this.setReOrder(true);
        this.setRecentOrderCustomerToCart(reOrderItem);
        this.setRecentOrderShopToCart(reOrderItem);
        this.setServiceToCart(reOrderItem);
        this.setRecentOrderItemsTocart(reOrderItem.items);
        this.setRecentOrderBusinessTypeTocart(reOrderItem.buisnessTypeId);
        this.setRemarks((reOrderItem.remarks !== null) ? reOrderItem.remarks.split("\n")[0] : '');
        this.router.navigate(['/datetime-selection'], { queryParams: { 'redirectUrl': '/cart' } });

    }

    /**
     * 
     * @param reOrderItem 
     * set recent order customer information to cart
     */
    setRecentOrderCustomerToCart(reOrderItem) {
        let customer = this.sessionStorage.get(SESSION_AUTHENTICATED_CUSTOMER);
        this.setCustomer(customer);
    }

    /**
     * 
     * @param reOrderItem 
     * set recent order service type to cart.
     */
    setServiceToCart(reOrderItem) {
        let service: any;
        let serviceList = this.sessionStorage.get(SESSION_SERVICE_LIST);
        service = serviceList.filter(sl => sl.id == reOrderItem.serviceId);
        this.setService(service[0]);
    }

    /**
     * 
     * @param businessTypeId 
     * set recent order business type info to cart.
     */
    setRecentOrderBusinessTypeTocart(businessTypeId) {
        let currentBusinessInfo: any;
        let businessTypeList = this.sessionStorage.get(SESSION_BUSINESS_TYPE_LIST);
        currentBusinessInfo = businessTypeList.filter(bt => bt.id == businessTypeId);
        this.setBusinessType(currentBusinessInfo[0]);
    }

    /**
     * 
     * @param reOrderItem 
     * re recent order shop is set to cart.
     */
    setRecentOrderShopToCart(reOrderItem) {
        const selectedShopID = reOrderItem.shopId;
        let shopList = this.sessionStorage.get(SESSION_SHOP_LIST);
        const selectedShop = shopList.filter(shp => shp.id === selectedShopID);
        this.setShop(selectedShop[0]);
        this.sessionStorage.set(SESSION_SELECTED_SHOP, selectedShop[0]);
    }

    /**
     * set reordered item to cart.
     * if an item is present in the master sale item,
     *  then fetch that data from there.
     * if not,reorder item information is considered.
     * 
     * In the price validation , compare prices of master saleitem and reordered item.
     * @param reOrderItems 
     */
    setRecentOrderItemsTocart(reOrderItems) {

        this.saleItemList = this.sessionStorage.get(SESSION_SALE_ITEM_LIST);
        for (let reItem of reOrderItems) {
            let itemInfo;
            let itemCustomization = [];
            let item: CartItem;
            let isCust = true;
            reItem.customizationUnitPriceWithTax = ((reItem.customizationUnitPriceWithTax !== undefined) ? reItem.customizationUnitPriceWithTax : 0)
            itemInfo = this.saleItemList.find(si => si.saleItemId === reItem.saleItemId);
            if (itemInfo !== null && itemInfo !== undefined) {
                let itemAllCustomizationInfo = this.getCustomizationDetail(itemInfo);
                isCust = this.validateRecentItemCustomization(itemInfo, reItem, itemAllCustomizationInfo);
                if (!isCust) {
                    reItem.customizations = null;
                }
                itemCustomization = this.getRecentOrderCustomization(itemInfo, reItem, itemAllCustomizationInfo);
            } else {
                itemInfo = reItem;
            }
            item = {
                itemId: 0,
                ooSaleItemId: itemInfo.id, saleItemId: itemInfo.saleItemId, quantity: reItem.qty, name: (itemInfo.name !== undefined) ? itemInfo.name : '',
                fixedPrice: itemInfo.fixedPrice, totalPrice: (itemInfo.fixedPrice + reItem.customizationUnitPriceWithTax) * reItem.qty,
                unitPriceWithTax: itemInfo.unitPriceWithTax, customizationUnitPriceWithTax: reItem.customizationUnitPriceWithTax,
                isCustomized: (itemCustomization.length > 0) ? true : false, customization: itemCustomization, remarks: reItem.remarks,
                taxCalculationMethod: itemInfo.taxCalculationMethod, tax1Percentage: itemInfo.tax1Percentage,
                netCustomizationTotal: 0,
                netTotal: 0, totalTax: 0
            };
            if (!this.orderPriceValidation(itemInfo, reItem)) {
                item.hasWarning = true;
                item.warningMessage = itemInfo.name + ' price has been changed. \n';
            }
            if (!isCust) {
                if (item.warningMessage === undefined) {
                    item.warningMessage = '';
                }
                item.hasWarning = true;
                item.warningMessage += 'Customisation options are found changed from last time. Please re-customise the item.';
            }
            if (itemCustomization.length > 0) {
                this.setCustomization(item);
            }
            this.taxService.reCalculateTax(item);
            this.addItem(item);
        }

    }

    /**
     * 
     * @param itemInfo 
     * @param reItem 
     * validate recent item customization only for selected customization option values.
     */
    validateRecentItemCustomization(itemInfo: any, reItem: any, itemAllCustomizationInfo) {
        let isValid = true;
        if (reItem.customizations !== null) {
            if (itemInfo.isCustomizable === 1) {
                if (itemInfo.itemCustOptionIds !== null && itemInfo.itemCustOptionIds !== undefined && itemInfo.itemCustOptionIds.length > 0) {

                    for (let cust of reItem.customizations) {
                        /** check recent item customization option id  is present in session customization option master data */
                        const optIndex = itemAllCustomizationInfo.findIndex(e => e.custOptId === cust.optionid);
                        if (optIndex > -1) {
                            let isInMainItemCustOptionIds = itemInfo.itemCustOptionIds.some(ico => ico === cust.optionid);
                            /**recent item customization option id is not present in main saleitem(session) option id list */
                            if (!isInMainItemCustOptionIds) {
                                isValid = false;
                                return isValid;
                            } else {
                                /**fetch session customization option value  based on recent item selected customization option value*/
                                let optVal = itemAllCustomizationInfo[optIndex].optionValue.find(ov => ov.id === cust.optionValueid);
                                if (optVal !== null && optVal !== undefined) {
                                    /**comparing the prices of recent optionValue and  session containing optionvalue's of customization master*/
                                    if (optVal.fixedPrice !== cust.fixedPrice) {
                                        isValid = false;
                                        return isValid;
                                    } else {
                                        // if(!this.isValidCustOptVal(optVal)){
                                        //     isValid = false;
                                        //     return isValid;
                                        // }
                                    }

                                } else {
                                    isValid = false;
                                    return isValid;
                                }
                            }
                        } else {
                            isValid = false;
                            return isValid;
                        }
                    }

                } else {
                    /** main session saleitem information has no option Ids  but recent item customization has value*/
                    if (reItem.customizations !== null) {
                        isValid = false;
                        return isValid;
                    }

                }
            } else {
                isValid = false;
                return isValid;
            }
        }
        return isValid;
    }

    /**
     * 
     * @param custOptVal Check customization is valid
     * @returns 
     */
    isValidCustOptVal(custOptVal) {
        let isValid = true;
        this.filteredSaleItemListData = this.sessionStorage.get(SESSION_FILTERED_SALE_ITEM_LIST);
        this.outOfStockItemIds = this.sessionStorage.get(SESSION_OUTOFSTOCK_ITEM_LIST);
        this.outOfStockItemInfo = this.filteredSaleItemListData.filter(fs => this.outOfStockItemIds.findIndex(os => fs.id === os) >= 0);
        if (custOptVal.saleItemId !== null) {
            isValid = this.filteredSaleItemListData.some(oi => custOptVal.saleItemId === oi.saleItemId);
            if (isValid && this.outOfStockItemInfo !== undefined && this.outOfStockItemInfo.length > 0) {
                isValid = !this.outOfStockItemInfo.some(oi => custOptVal.saleItemId === oi.saleItemId);
            }
        }

        // if(custOptVal.saleItemId !== null && this.outOfStockItemInfo !== undefined && this.outOfStockItemInfo.length > 0){
        //   isValid = !this.outOfStockItemInfo.some(oi => custOptVal.saleItemId === oi.saleItemId);
        // }

        return isValid;
    }

    /**
     * 
     * @param itemInfo 
     * @param item 
     * @returns 
     * In the price validation , compare prices of master saleitem and reordered item.
     */
    orderPriceValidation(itemInfo, item) {
        let noPriceChange = true;
        if (itemInfo.fixedPrice !== item.fixedPrice) {
            noPriceChange = false;
        }
        return noPriceChange;
    }

    /**
     * 
     * @param itemInfo 
     * @param reItem 
     * @returns 
     * mark selected customization item  in all customization list
     */
    getRecentOrderCustomization(itemInfo: any, reItem: any, itemAllCustomizationInformation) {
        let customization = [];
        if (reItem.customizations !== null) {
            let itemAllCustomizationInfo = itemAllCustomizationInformation;
            itemAllCustomizationInfo.forEach(element => {
                const customizationItem = element;
                for (let rc of reItem.customizations) {
                    if (customizationItem.custOptId === rc.optionid) {
                        for (let ov of customizationItem.optionValue) {
                            if (ov.id === rc.optionValueid) {
                                ov.isSelected = 1;
                            } else {
                                ov.isSelected = 0;
                            }
                        }
                    }
                }
                customization.push(customizationItem);
            });
        }
        return customization;
    }

    /**
     * 
     * @returns 
     * load promotionalSaleItem from filterd saleItem list based on promotional sale item Ids;
     * then fetch promotional items not in cart
     * then filter promotional items from out of stock items
     */
    getValidPromotionalItemData() {
        let filteredSaleItemList = this.sessionStorage.get(SESSION_FILTERED_SALE_ITEM_LIST);
        let promotionalSaleItemIds = this.sessionStorage.get(SESSION_PROMOTIONAL_SALEITEM_IDS);
        this.outOfStockItemIds = this.sessionStorage.get(SESSION_OUTOFSTOCK_ITEM_LIST);

        let promotionalSaleItem = (filteredSaleItemList !== undefined && filteredSaleItemList !== null && promotionalSaleItemIds !== undefined && promotionalSaleItemIds !== null) ?
            filteredSaleItemList.filter(fs => promotionalSaleItemIds.findIndex(ps => fs.saleItemId === ps) >= 0) : [];
        // let promoItemNotInCart = (promotionalSaleItem !== undefined && promotionalSaleItem !== null && this.cart.items !== undefined && this.cart.items !== null)?
        //                           promotionalSaleItem.filter(o1 => !this.cart.items.some(o2 => o1.saleItemId === o2.saleItemId)):[];
        // this.promotionalItems = (promoItemNotInCart !== undefined && promoItemNotInCart !== null && this.outOfStockItemIds !== undefined && this.outOfStockItemIds !== null)?
        //                           promoItemNotInCart.filter(pic => !this.outOfStockItemIds.some(os => pic.id === os)):[];

        this.promotionalItems = (promotionalSaleItem !== undefined && promotionalSaleItem !== null && this.outOfStockItemIds !== undefined && this.outOfStockItemIds !== null) ?
            promotionalSaleItem.filter(pic => !this.outOfStockItemIds.some(os => pic.id === os)) : [];

        return this.promotionalItems;
    }

    /**
     * Checks for item in cart
     * @param item 
     * @returns 
     */
    isItemInCart(item: any): boolean {
        var isItemInCart = false;
        isItemInCart = (this.cart.items.findIndex(o2 => item.saleItemId === o2.saleItemId) >= 0);
        return isItemInCart;
    }
    /**
     * each item quantity in cart
     * @param item 
     * @returns 
     */
    eachItemQuantityInCart(item) {
        var sameItemInCartArray = [];
        sameItemInCartArray = (this.cart.items.filter(o2 => item.saleItemId === o2.saleItemId));
        let quantity = 0;
        for (let it of sameItemInCartArray) {
            quantity += it.quantity;
        }
        return quantity;
    }

    getUnitpriceWithTax(taxCalculationMethod, fixedPrice, tax1Pc) {
        return (taxCalculationMethod === 1) ? (fixedPrice + (fixedPrice * tax1Pc / 100)) : fixedPrice;
    }

    /**
     * @param saleItemInfo 
     * @returns 
     *Get customization detail based on sale item info.
     * Sale item info means sale item information of a particular saleItem id.
     * Fetch customization detail of that item by using itemCustOptionIds and 
     * allCustmizationMasterDtl.
     */
    getCustomizationDetail(saleItemInfo: any): any {
        let customizationDetail = [];
        if (saleItemInfo.itemCustOptionIds != null && saleItemInfo.itemCustOptionIds.length > 0) {
            let allCustmizationMasterDtl = this.sessionStorage.get(SESSION_MASTER_CUSTMIZATION);
            customizationDetail = allCustmizationMasterDtl.filter(ac =>
                saleItemInfo.itemCustOptionIds.find(e => e === ac.custOptId)
            );

            if (customizationDetail !== undefined && customizationDetail.length > 0) {
                customizationDetail = JSON.parse(JSON.stringify(customizationDetail));
            }
        }
        return customizationDetail;
    }

    /**
     * get selected customization option value data
     * @param item 
     * @returns 
     */
    getSelectedCustomizationOptionValueData(item) {
        let cutstomisationSelectedArray = [];
        for (let cd of item.customization) {
            for (let ov of cd.optionValue) {
                if (ov.isDefault === 0 && ov.isSelected === 1) {
                    cutstomisationSelectedArray.push(ov);
                }
            }
        }
        return cutstomisationSelectedArray;
    }

    /**
     * check customization option value is valid or not
     * if not valid, show warning message, remove option values of that item.
     * @param cartItem 
     * @returns 
     */
    
    isitemAndCustOptInValid(cartItem) {
        let isValidCust = true;
        let custOptData = this.getSelectedCustomizationOptionValueData(cartItem);
        for (let custOpt of custOptData) {
            isValidCust = this.isValidCustOptVal(custOpt);
            if (!isValidCust) {
                cartItem.customizations = null;
                cartItem.customization=[];
                cartItem.customizationUnitPriceWithTax=0;
                cartItem.isCustomized = false;
                if (cartItem.warningMessage === undefined) {
                    cartItem.warningMessage = '';
                }
                cartItem.hasWarning = true;
                cartItem.warningMessage += 'Customisation options are found changed from last time. Please re-customise the item.';
            }
        }
        if(cartItem.hasWarning === undefined){
            cartItem.hasWarning = false;
        }
        
        return cartItem.hasWarning;
    }

}

