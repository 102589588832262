import { Component, Input, OnInit } from '@angular/core';
import { RewardService } from '../services/reward.service';
import { CartService } from '../cart/cart.service';
import { UtilService } from '../services/util.service';
import { TitleConfig } from 'src/fw/services/hdr-title.service';

@Component({
  selector: 'app-myrewards',
  templateUrl: './myrewards.component.html',
  styleUrls: ['./myrewards.component.css']
})
export class MyrewardsComponent implements OnInit {


  constructor(private utilService: UtilService,) {
    this.initTitleBarParams();
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  /***
   * Initialises the titlebar and footbars
   */
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'MY REWARDS',
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);

  }

}
