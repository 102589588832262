import { Component, OnInit, Inject, ViewEncapsulation, Pipe, PipeTransform, HostListener, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UtilService } from '../services/util.service';
import { initialMenuItems } from '../app.menu';
import { TitleConfig, HdrTitleService } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { StoreFinderService } from './store-finder.service';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import {
  SESSION_SHOP_LIST, SESSION_REGION_LIST, SESSION_AMENITY_LIST, SESSION_CUSTOMER_FAVOURITES,
  SESSION_AUTHENTICATED_CUSTOMER,
  SESSION_SELECTED_SERVICE,
  SESSION_ITEM_CATEGORY_LIST,
  SESSION_CART,
  SESSION_SELECTED_SHOP,
  SESSION_DEPARTMENT_LIST,
  SESSION_FILTERED_CATEGORY_ITEM_LIST,
  SESSION_FILTERED_SUB_CATEGORY_ITEM_LIST,
  SESSION_FILTERED_SALE_ITEM_LIST,
  SESSION_SHOP_LIST_CACHE_TIME
} from '../app.constants';
import { Router } from '@angular/router';
import { $, jQuery } from 'jquery';
import { ShopDetailService } from '../shop-detail/shop-detail.service';
import { FavouriteShop } from '../shop-detail/shop-detail';
import { ScreenService } from '../../fw/services/screens.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { StoreSelectionService } from '../../fw/services/store-selection.service';
import { storeFinderMenuItems } from './store-finder.menu';
import { CartService } from '../cart/cart.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { MatButtonModule } from '@angular/material/button';
import { DOCUMENT } from '@angular/platform-browser';
// import {  } from '@types/googlemaps';
import { Input, ViewChild, ElementRef } from '@angular/core';
import { MenuService } from '../../fw/services/menu.service';
import { now } from 'moment';
import { StartupService } from '../services/startup.service';
import { GtmService } from '../gtm.service';
declare var google: any;


@Component({
  selector: 'app-store-finder',
  templateUrl: './store-finder.component.html',
  styleUrls: ['./store-finder.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})
export class StoreFinderComponent implements OnInit {
  isSearchItem: boolean;
  departmentList: any;
  city: any;
  request: { latLng: any; };
  geocoder: any;
  latlng: any;
  isRegionListOpen: boolean;
  REGION_CURRENT_LOCATION_ID = 100000;
  REGION_FAVOURITES_ID = 100001;
  REGION_All_ID = 100002;
  userenter: string;
  selectedService: any;
  selectedLocation: any;
  selectedShop: any;
  favouriteShopList: any[];
  shopList: any[];
  selectedShopList: any[];
  nearestShopList: any[];
  radius: any;
  locationCoordinateData: any;
  sessionCustomer: any;
  amenityList: any[];
  regionList: any[];
  isCurrentLocationAvilable = false;
  itemCategoryList: any[];
  showError = false;
  @ViewChild('searchitem') nameField: ElementRef;
  @Output() itemClickEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService,
    private screenService: ScreenService, private confirmationService: ConfirmationService,
    private hdrService: HdrTitleService, private cartService: CartService,
    private _storeFinderService: StoreFinderService, @Inject(DOCUMENT) private document: Document,
    private router: Router, private _shopDetailService: ShopDetailService,
    private utilService: UtilService,
    private startUp: StartupService,
    private messageService: MessageService,
    private storeSelectionService: StoreSelectionService, public menuService: MenuService,
    private gtmService:GtmService) {
    this.isSearchItem = false;
    this.initTitleBarParams();

    this.locationCoordinateData = { 'longitude': '', 'latitude': ' ', 'radius': ' ' };
    // this.utilService.setMenuItems(storeFinderMenuItems);
    this.utilService.setMenuItems(initialMenuItems);
    // this.utilService.progressService.isLoading = true;
    this.isRegionListOpen = false;
    this.radius = this.storeSelectionService.filterParams.distance;
    this.selectedService = this.cartService.cart.service;
    // this.selectedService = this.sessionStorage.get(SESSION_SELECTED_SERVICE);
    this.sessionCustomer = this.sessionStorage.get(SESSION_AUTHENTICATED_CUSTOMER);

    this.shopList = this.sessionStorage.get(SESSION_SHOP_LIST);
    this.regionList = this.sessionStorage.get(SESSION_REGION_LIST);

    var cachedDur = undefined;
    var cachedAt = this.sessionStorage.get(SESSION_SHOP_LIST_CACHE_TIME);
    if (cachedAt !== undefined && cachedAt !== null) {
      const cachedTime = new Date(cachedAt);
      const currentTime = new Date();
      cachedDur = (currentTime.valueOf() - cachedTime.valueOf()) / 60000;
    }

    if (cachedDur == undefined || cachedDur > this.startUp.startupData.sessionexpired ||
      this.shopList == undefined || this.shopList == null || this.regionList == undefined || this.regionList == null) {
      this.getRegionShopData();
    } else {
      this.initPage();
    }

    // this.getAmenityData();
    // this.getDepartmentLIst();
    // this.getItemCategoryData();
    this.favouriteShopList = this.sessionStorage.get(SESSION_CUSTOMER_FAVOURITES);
    const shadesEl = document.querySelector('.body-style');
    shadesEl.classList.add('storefinder-scroll');
  }

  ngOnInit() {
    this.menuService.isVertical = true;
    if (this.cartService.cart.shop !== undefined) {
      this.selectedShop = this.cartService.cart.shop;
    }
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = true;
    this.hdrService.title = 'SELECT SHOP';
    this.hdrService.showLogo = false;
    this.hdrService.showShoppingCart = true;
    const titleConfig: TitleConfig = {
      title: 'STORE FINDER',
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: false,
      hideBackButton: false

    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showShopListBar: true,
      showGuestUserBtn: false,
      showShopInfoBar: false,
      showShopFilterBar: false,
      showitemDetailAddCartBar: false,
      showUpdateCartItemBar: false,
      showPaymentSucessBottomBar: false,
      showOrderDetailsBar: false,
      showItemListBar: false,
      showCartBar: false,
      showSignUpBar: false,
      orderConfirmationBar: false,
      showGuestBar: false,
      showChangePasswordBar: false,
      showMyProfileBar: false,
      showFaqBar: false,
      showSubmitContactUs: false,
      showRetryPaymentBar: false


    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }

  /* getting shop list of each location*/
  getRegionShopData() {
    this.utilService.progressService.isLoading = true;
    const searchParam = { 'serviceId': this.selectedService.id };
    this._storeFinderService.getRegionShopData(searchParam).subscribe(
      res => {
        this.utilService.progressService.isLoading = false;

        this.isRegionListOpen = true;
        this.regionList = res.object.region;
        this.regionList.splice(0, 0, { 'id': this.REGION_CURRENT_LOCATION_ID, 'code': 'Nearest Stores', 'name': 'Nearest Stores' },
          { 'id': this.REGION_FAVOURITES_ID, 'code': 'Favourite', 'name': 'Favourites' },
          { 'id': this.REGION_All_ID, 'code': 'All', 'name': 'All' });
        this.sessionStorage.remove(SESSION_SHOP_LIST);
        this.sessionStorage.set(SESSION_REGION_LIST, this.regionList);

        this.shopList = res.object.shops;
        this.sessionStorage.set(SESSION_SHOP_LIST, this.shopList);
        // const now = new Date();
        this.sessionStorage.set(SESSION_SHOP_LIST_CACHE_TIME, new Date());

        // if (this.storeSelectionService.selectedRegion !== undefined) {
        //   this.selectedLocation = this.storeSelectionService.selectedRegion;
        //   this.isRegionListOpen = false;
        //   const regionEvent = this.selectedLocation;
        //   this.onRegionSelected(regionEvent);
        // } else {
        //   this.onCurrentLocationClick();
        // }

        this.initPage();

      }, err => {
        this.showError = true;
        this.utilService.progressService.isLoading = false;
      }
    );
  }

  initPage() {
    if (this.storeSelectionService.selectedRegion !== undefined) {
      this.selectedLocation = this.storeSelectionService.selectedRegion;
      this.isRegionListOpen = false;
      const regionEvent = this.selectedLocation;
      this.onRegionSelected(regionEvent);
    } else {
      this.onCurrentLocationClick();
    }
  }
  // /*get all amenity list*/
  // getAmenityData() {
  //   this._storeFinderService.getAmenityData().subscribe(
  //     res => {
  //       this.amenityList = res.object;
  //       this.sessionStorage.set(SESSION_AMENITY_LIST, this.amenityList);
  //     }
  //   );
  // }
  // getDepartmentLIst() {
  //   this._storeFinderService.getDepartmentData().subscribe(
  //     res => {
  //       this.departmentList = res.object;
  //       this.sessionStorage.set(SESSION_DEPARTMENT_LIST, this.departmentList);
  //     }
  //   );
  // }
  /* get all item categories*/
  // getItemCategoryData() {
  //   this._storeFinderService.getItemCategoryData().subscribe(
  //     res => {
  //       this.itemCategoryList = res.object;
  //       this.sessionStorage.set(SESSION_ITEM_CATEGORY_LIST, this.itemCategoryList);
  //     }
  //   );
  // }

  regionListClicked(event) {
    const shadesEl = document.querySelector('.body-style');
    shadesEl.classList.add('storefinder-scroll');
    this.onRegionSelected(event);
    this.isRegionListOpen = false;
  }
  onRegionTabOpen(event) {
    // this.isOpenAcoordian = true;
    this.userenter = '';
    const shadesEl = document.querySelector('.body-style');
    shadesEl.classList.remove('storefinder-scroll');
  }
  /* shop list population when selecting each region*/
  onRegionSelected(event) {
    this.selectedLocation = event;
    this.isCurrentLocationAvilable = false;
    this.storeSelectionService.favBtnEnabled = true;
    // this.storeSelectionService.filterFavourites = false;
    this.utilService.progressService.isLoading = true;
    if (event.id === this.REGION_All_ID) {
      this.selectedShopList = this.sessionStorage.get(SESSION_SHOP_LIST);
    } else if (event.id === this.REGION_FAVOURITES_ID) {

      this.selectedShopList = [];
      if (this.utilService.checkValidCustomer('Favourite Shop', 'Please login to use this function')) {
        this.selectedShopList = this.shopList.filter(sle => this.favouriteShopList.findIndex(fs => fs === sle.id) >= 0);
        this.storeSelectionService.filterFavourites = true;
        this.storeSelectionService.favBtnEnabled = false;

        if (this.selectedShopList.length === 0) {
          this.geFilteredShopList();
        }
      }



    } else if (event.id === this.REGION_CURRENT_LOCATION_ID) {
      this.getCurrentLocation();
    } else {
      this.selectedShopList = this.getShopArray(event.shops);
    }
    this.storeSelectionService.selectedRegion = event;
    this.utilService.progressService.isLoading = false;
  }
  /* getting shop list of specifed region */
  getShopArray(shops) {
    const shopArray = this.shopList.filter(sl => shops.findIndex(s => s === sl.id) >= 0);
    return shopArray;
  }
  /* current location information */
  getCurrentLocation() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          const lng = +pos.coords.longitude;
          const lat = +pos.coords.latitude;
          this.locationCoordinateData = { 'longitude': lng, 'latitude': lat, 'radius': this.radius };
          this.getShopListByLocation(this.locationCoordinateData);
        },
        err => {
          if (this.selectedLocation.id === this.REGION_CURRENT_LOCATION_ID) {
            this.selectedShopList = [];
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    }
  }



  /* favourite shop list*/
  getFavouriteShopList() {
    let favShopArray = [];
    if (this.utilService.checkValidCustomer('Favourite Shop', 'Please login to use this function')) {
      favShopArray = this.shopList.filter(sle => this.favouriteShopList.findIndex(fs => fs === sle.id) >= 0);
    }
    this.selectedShopList = favShopArray;
  }
  /*  Clicking current location */
  onCurrentLocationClick() {
    const currentLoc = this.regionList.filter(
      locationObj => (locationObj.id === this.REGION_CURRENT_LOCATION_ID && locationObj.id !== this.REGION_FAVOURITES_ID));
    this.selectedLocation = currentLoc[0];
    this.storeSelectionService.selectedRegion = this.selectedLocation;
    this.getCurrentLocation();
  }
  /* Selecting shop list of nearest location*/
  getShopListByLocation(locationData) {
    this.isCurrentLocationAvilable = false;
    this._storeFinderService.getNearestShops(locationData).subscribe(
      res => {
        this.nearestShopList = res.object;
        // const nearestShopArray = this.shopList.filter(s => this.nearestShopList.findIndex(ns => ns.id === s.id) >= 0);
        const nearestShopArray = [];
        this.nearestShopList.forEach((ns) => {
          const index = this.shopList.findIndex(s => s.id === ns.id);
          if (index >= 0) {
            nearestShopArray.push(this.shopList[index]);
          }
        });
        this.selectedShopList = nearestShopArray;
        this.isCurrentLocationAvilable = true;
        if (this.isCurrentLocationAvilable) {
          this.isRegionListOpen = false;
          if (this.selectedShopList === undefined || this.selectedShopList.length === 0) {
            this.showError = true;
          }
        }
      }
    );
  }
  /* When clicking favourite button, the selected shop is added to the favourites list*/
  onFavouriteClick(event) {
    const favouriteShop = { id: 0, customerId: this.sessionCustomer.id, shopId: event.shop.id, isFav: (event.isFav) ? 1 : 0 };
    this._shopDetailService.setFavouriteShop(favouriteShop).subscribe(
      res => {
        if (event.isFav) {
          this.favouriteShopList.push(event.shop.id);
        } else {
          this.deleteFavouriteShop(event);
        }
        this.sessionStorage.set(SESSION_CUSTOMER_FAVOURITES, this.favouriteShopList);
      }, err => {
      }
    );
  }
  /* Delete the shop from the favourite list*/
  deleteFavouriteShop(event) {
    let index = -1;
    index = this.favouriteShopList.findIndex(e => e === event.shop.id);
    this.favouriteShopList.splice(index, 1);
  }
  getLocationData(shop): any {
    let shopLocationData;
    if (this.nearestShopList !== undefined && this.selectedLocation.id === this.REGION_CURRENT_LOCATION_ID) {
      shopLocationData = this.nearestShopList.find(e => e.id === shop.id);
    }
    return shopLocationData;
  }
  /* filtering all shop list based on different  filtering criteria*/
  geFilteredShopList(): any[] {
    let filteredShopList = this.selectedShopList;
    let filteredAmenityshopList = [];

    let filteredCategoryshopList = [];
    if (this.storeSelectionService.filterFavourites && this.favouriteShopList !== undefined && this.favouriteShopList != null) {
      filteredShopList = this.selectedShopList.filter(
        element => this.favouriteShopList.findIndex(e => e === element.id) >= 0);
    }
    filteredAmenityshopList = filteredShopList;
    if (this.storeSelectionService.filterParams.apply === true) {
      if (filteredAmenityshopList !== undefined && filteredAmenityshopList.length > 0) {
        if (this.storeSelectionService.filterParams.amenities.length > 0) {
          filteredShopList = [];
          for (let i = 0; i < filteredAmenityshopList.length; i++) {
            if (filteredAmenityshopList[i].amenity !== null) {
              let isAmenityExist = true;
              for (let k = 0; k < this.storeSelectionService.filterParams.amenities.length; k++) {
                if (!(filteredAmenityshopList[i].amenity.findIndex
                  (obj => obj === this.storeSelectionService.filterParams.amenities[k]) >= 0)) {
                  isAmenityExist = false;
                  break;
                }

              }
              if (isAmenityExist) {
                filteredShopList.push(filteredAmenityshopList[i]);
              }

            }

          }
        }
      }
      filteredCategoryshopList = filteredShopList;
      if (filteredCategoryshopList.length > 0) {
        if (this.storeSelectionService.filterParams.itemCategory.length > 0) {
          filteredShopList = [];
          for (let i = 0; i < filteredCategoryshopList.length; i++) {
            let jump = false;
            for (let k = 0; k < this.storeSelectionService.filterParams.itemCategory.length; k++) {
              if (filteredCategoryshopList[i].item_category !== null) {
                for (let j = 0; j < filteredCategoryshopList[i].item_category.length; j++) {
                  if (this.storeSelectionService.filterParams.itemCategory[k] === filteredCategoryshopList[i].item_category[j]) {
                    filteredShopList.push(filteredCategoryshopList[i]);
                    jump = true;
                  }
                  if (jump) {
                    break;
                  }
                }
              }
              if (jump) {
                break;
              }
            }
          }
        }
      }
    }
    return filteredShopList;

  }

  getShopDisplayList(): any[] {
    const currentShopList = this.geFilteredShopList();
    return (currentShopList === undefined || currentShopList.length === 0) ? [] : (this.userenter === undefined) ? currentShopList :
      currentShopList.filter(
        item => item.name.toLowerCase().includes(this.userenter.toLowerCase()));
  }

  shopSelection() {
    if (this.selectedShop === undefined && this.storeSelectionService.selectedShop === undefined) {
      this.utilService.openDialog('Shop Selection.', 'No shop selected. Select a shop from the list.');
    } else {
      if (this.cartService.cart.shop !== undefined && this.cartService.cart.shop.id !== this.selectedShop.id) {
        if (this.cartService.getTotalQuantity() === 0) {
          this.clearCart();
          this.setShop();
          this.gtmService.pushEvent(
            'page_engagement',
            {
              actionType: 'Select new store',
              storeSelected: this.gtmService.getStore(),  
              visitorType : this.gtmService.getVisitorType(),
              storeChanged: true 
            }
          );
        } else {
          this.confirmationService.confirm({
            message: 'Changing your pickup store will empty your order. Do you want to proceed?',
            accept: () => {
              this.clearCart();
              this.setShop();
              this.gtmService.pushEvent(
                'page_engagement',
                {
                  actionType: 'Select new store',
                  storeSelected: this.gtmService.getStore(),  
                  visitorType : this.gtmService.getVisitorType(),
                  storeChanged: true 
                }
              );
            },
            reject: () =>{
              // this.gtmService.pushEvent(
              //   'page_engagement',
              //   {
              //     actionType: 'Select new store',
              //     storeSelected: this.gtmService.getStore(),  
              //     visitorType : this.gtmService.getVisitorType(),
              //     storeChanged: false 
              //   }
              // );
            }
          });
        }
      }else{
        this.setShop();
        this.gtmService.pushEvent(
          'select store',
          {
            storeSelected: this.gtmService.getStore(),  
            visitorType : this.gtmService.getVisitorType()
          }
        );
      }
    }
  }

  clearCart() {
    this.cartService.cart.shop = undefined;
    this.cartService.cart.items = [];
    this.cartService.cart.deliveryDate = undefined;
    this.cartService.cart.deliveryTime = undefined;
    this.cartService.cart.remarks = undefined;
    this.cartService.cart.pickupLocationId = undefined;
    this.cartService.setToSession();
  }

  setShop() {
    this.storeSelectionService.selectedShop = this.selectedShop;
    if (this.storeSelectionService.saveSelectedShop()) {
      if (this.utilService.getStorageValuesByKey(SESSION_SELECTED_SHOP) !== null &&
        this.utilService.getStorageValuesByKey(SESSION_SELECTED_SHOP) !== undefined) {
        this.router.navigate(['/datetime-selection']);
      }
    }
  }

  /* select this  shop for further use*/
  setSelectedShop(shop) {
    this.selectedShop = shop;
    this.shopSelection();
  }
  /**
   * getting more details about the shop when clicking on the link
   */
  onShopInfoClick(shop) {
    let direction = 0;
    if (this.selectedLocation.id === this.REGION_CURRENT_LOCATION_ID) {
      direction = 1;
    }
    this.router.navigate(['shop-details'], { queryParams: { 'id': shop.id, 'map': 1, 'direction': direction } });

  }
  searchAllItems() {
    this.isSearchItem = true;
    setTimeout(() => {
      this.document.getElementById('searchKeyInput').focus();
    }, 500);
  }
  backButton() {
    this.userenter = '';
    this.isSearchItem = false;
  }
  itemClear() {
    this.userenter = '';
  }
  scrollToTop() {
    const el = document.getElementById('region-list-div-id');
    el.scrollIntoView({
      behavior: 'smooth'
    });
  }
  itemClick(userenter) {
    this.userenter = userenter;
    this.nameField.nativeElement.focus();
    this.itemClickEvent.emit(this.userenter);
  }

}
