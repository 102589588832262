import { Injectable } from '@angular/core';
import { timeout } from 'q';
import { StartupService } from './startup.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  serverTime: Date;
  timeInterval: any;
  lastUpdatedTime: Date;
  isServiceStarted: any;
  serverTimeUrl: string;
  constructor(private startUp: StartupService, private http: HttpClient) {
    this.timeInterval = 30000;
    this.isServiceStarted = false;
    this.serverTimeUrl = this.startUp.startupData.apiurl + '/util-service/get-time';
  }

  startTimeService() {
    if (!this.isServiceStarted) {
      this.updateServerTime();
    }
  }

  updateServerTime() {
    this.isServiceStarted = true;
    // Api call to get current servertime
    //  this.getServerTime().subscribe(
    //   res => {
    //    this.serverTime = new Date(res.object);
    //     this.lastUpdatedTime = new Date();
    //   },
    //   err => {},
    //   () => {
    //      setTimeout(() => {
    //          this.updateServerTime();
    //       }, this.timeInterval);
    //     }
    //   );
    this.serverTime = new Date();
    setTimeout(() => {
               this.updateServerTime();
            }, this.timeInterval);
  }

  getServerTime(): Observable<any> {
    return this.http.get<any>(this.serverTimeUrl)
    .pipe(

      );
  }

  toHMFormat(initialDelay: number): String {

    const initialDelayHours = initialDelay / 60;
    const initialHours = Math.floor(initialDelayHours);
    const initialDelayMinutes = (initialDelayHours - initialHours) * 60;
    const initialMinutes = Math.round(initialDelayMinutes);
    let initialTime = '';
    if (initialHours > 0 && initialMinutes > 0) {
      initialTime = initialHours + ' Hours' + "' " + initialMinutes + ' Minutes ';
    } else if (initialHours <= 0) {
      initialTime = initialMinutes + ' Minutes ';
    } else if (initialMinutes <= 0) {
      initialTime = initialHours + ' Hours' + "' ";
    }
    return initialTime;

  }
}
