// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//google -> info@stpierres.online
//face book -> info@mojosoft.in
//captcha -> info@stpierres.online

export const environment = {
  production: false,
  googleAuthKey:'988515229592-671vj0o7l8ifqeis84lcpsnut9gd8249.apps.googleusercontent.com',
  fbAuthKey:'692513481929649',
  gmapKey:'AIzaSyCpyPycx2BQQmmESdaQUldS-81-EKBj00g',
  captchaKey:'6Lfuu6QUAAAAABeE2c6xSXBFa6QhQ8kkjzydp-un',
  crmUser:"crm",
  crmPassword:"1234",
  crmAppKey:"e568458e9121478125d076b344e26c9e"
};

/*GTM-KKNTJVZ
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
