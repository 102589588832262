import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl, ValidatorFn, FormBuilder } from '@angular/forms';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { SESSION_AUTHENTICATED_CUSTOMER, DEF_DATE_FORMAT, SESSION_CART, SESSION_CUSTOMER_FAVOURITES, SESSION_SELECTED_SHOP, SESSION_SELECTED_SERVICE, SESSION_ITEM_CATEGORY_LIST, SESSION_SALE_ITEM_LIST, SESSION_SYSTEM_SETTINGS, SESSION_SELECTED_SUBCATEGORY_ID, COMPANY_INFO, SESSION_SHOP_LIST, SESSION_REGION_LIST, SESSION_AMENITY_LIST, SESSION_FAV_FILTER_BTN,
  SESSION_LOGIN_PROVIDER, 
  ERROR_CODE_ANOTHER_USER_MAIL_ID,
  REGISTERED_LOGIN} from '../app.constants';
import { Customer } from '../view-models/customer';
import { LoginService } from '../services/login.service';
import { CartService } from '../cart/cart.service';
import {ViewEncapsulation } from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { DatePipe } from '@angular/common';
import { GtmService } from '../gtm.service';
// import {defaultFormat as _rollupMoment} from 'moment';
// const moment = _rollupMoment || _moment;
export interface Title {
  value: string;
  display: string;
}


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: DEF_DATE_FORMAT},
  ]
})

export class MyProfileComponent implements OnInit {
  loading: boolean;
  validPassword: boolean;
  validEmail: boolean;
  dob: any;
  customerInfo = <Customer>{};
  sessionCustomer: any;
  formControl: any;
  invalidMessage: string;
  mailIdEnable:boolean;
  pwGroup: FormGroup;
  redirectUrl: any;
  titles: Title[] = [
    {value: 'Mr.', display: 'Mr.'},
    {value: 'Ms.', display: 'Ms.'},
    {value: 'Mrs.', display: 'Mrs.'}
  ];
  subscription: number;
  selectedTitle: any ;
  mailIdLabel: string ='Change';
  isUpdateMailId: boolean=false;
  mailVerMess: any;
  loginProvider: String;
  registerLogin: string = REGISTERED_LOGIN;

  constructor(private utilService: UtilService, private loginService: LoginService, private cartService: CartService,
     private router: Router , @Inject(SESSION_STORAGE) private storage: WebStorageService,  private datePipe: DatePipe,
      private activatedRoute: ActivatedRoute,
      private gtmService:GtmService) {
        this.sessionCustomer = this.storage.get(SESSION_AUTHENTICATED_CUSTOMER);
        this.loginProvider = this.storage.get(SESSION_LOGIN_PROVIDER);
        this.initTitleBarParams();
        this.loading = false;
        this.mailVerMess = '';
        this.mailIdEnable = false;
        Object.assign(this.customerInfo, this.sessionCustomer);
        this.subscription = (this.customerInfo.subscription)? 1:0;
        this.dob = new Date(this.customerInfo.dob);
        this.redirectUrl = this.activatedRoute.snapshot.queryParams['redirectUrl'];
        this.formControl = {
        email: new FormControl('', [Validators.required, Validators.email]),
        firstName: new FormControl('', [Validators.required]),
        phoneNumber: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(9)]),
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        dob: new FormControl(_moment())
        };
   }

  ngOnInit() {

     /**
     * GTM Code
     */
     this.gtmService.pushEvent(
      'my_profile',
      {
        newsletterOn: (this.subscription==1),
        profileUpdated: false,
        userId: this.gtmService.getCustomer(),
      });

  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = (this.sessionCustomer.customerType !== 0  && (this.loginProvider === REGISTERED_LOGIN))? true:false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'MY PROFILE',
      showLogo: false,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showMyProfileBar: (this.sessionCustomer.customerType !== 0 && (this.loginProvider === REGISTERED_LOGIN))? true:false

    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }
   /* Returns email validation errors */
   getEmailError(): any {
    let error: any;
    error ="";
    if (this.formControl.email.hasError('required')) {
      error = 'Please enter a valid email address.';
    } else if (this.formControl.email.hasError('email')) {
      error = 'It is not a valid email address.';
    }
    return error;

  }


    /* Returns password validation errors */
    getPasswordError(): any {
      let error: any;
      if (this.formControl.password.hasError('required')) {
        error = 'Please enter a valid password.';
      } else if (this.formControl.password.hasError('minlength')) {
        error = 'Passwords must be at least 6 characters long.';
      }
      return error;
    }
/**
 * first name validation
 */
    getFirstNameError(): any {
      let error: any;
      if (this.formControl.email.hasError('required')) {
        error = 'Please enter your name';
        } else if (!this.formControl.firstName.touched) {
           error = 'Please enter your name';
        }
      return error;
      }

     

      /**
 *
 * @param str password validation
 * at least one number, one lowercase and one uppercase letter
 * at least six characters
 */
  checkPassword(str) {
    this.validPassword =  false;
    if (str.length >= 6) {
      this.validPassword = true;
    } else {
      this.validPassword = false;
    }
    return this.validPassword;
  }

/**
 * phone number validation
 */
      getPhoneNumberError(): any {
      let error: any;
      if (this.formControl.phoneNumber.hasError('required')) {
      error = 'Please enter a valid phone number.';
      } else if
      (this.formControl.phoneNumber.errors.maxlength !== undefined &&
        (this.formControl.phoneNumber.errors.maxlength.actualLength) > (this.formControl.phoneNumber.errors.maxlength.requiredLength)) {
        error = 'Phone number with in 13 characters long.';
      } else if ( this.formControl.phoneNumber.errors.minlength !== undefined &&
        (this.formControl.phoneNumber.errors.minlength.actualLength) < (this.formControl.phoneNumber.errors.minlength.requiredLength)) {
        error = 'Phone number should be at least 9 characters long.';
      }
      return error;
    }
      changePassword(customerInfo: any) {

      this.router.navigate(['/change-password']);
    }
    /**
     *
     * @param email
     * Email validation
     */
    emailValidation(email) {
      this.validEmail = false;
      // tslint:disable-next-line:max-line-length
      const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (validEmailRegEx.test(email)) {
          this.validEmail = true;
      } else {
        this.validEmail = false;
      }
     return this.validEmail;
    }
/**
 * profile submit
 */
  myProfileSubmit() {

    this.customerInfo.subscription = (this.subscription) ? 1 : 0;
    this.customerInfo.dob = this.datePipe.transform(this.dob, 'yyyy/MM/dd');
    if (this.customerInfo.email !== null && this.customerInfo.email !== undefined && this.customerInfo.email !== '' &&
      this.customerInfo.phoneNumber !== null && this.customerInfo.phoneNumber !== undefined && this.customerInfo.phoneNumber !== '' &&
      this.customerInfo.firstName !== null && this.customerInfo.firstName !== undefined && this.customerInfo.firstName !== '') {
      if (this.emailValidation(this.customerInfo.email)) {   
        if(this.mailIdEnable === false){    
        if (this.checkPassword(this.customerInfo.password)) {
          if (this.formControl.phoneNumber.errors !== null &&
            ((this.formControl.phoneNumber.errors.minlength.actualLength) <
              (this.formControl.phoneNumber.errors.minlength.requiredLength))) {
            this.utilService.openDialog('Customer Info.', 'Phone number should be 9 characters long.');
          } else {
            this.loading = true;
            if (this.customerInfo.email !== this.customerInfo.loginId) {
              this.isUpdateMailId = true;
            };
            this.loginService.updateCustomer(this.customerInfo).subscribe(
              res => {
                this.loading = false;
                this.mailIdEnable = false;
                this.mailVerMess = '';
                if (res.success) {
                /**
                 * GTM Code
                 */
                this.gtmService.pushEvent(
                  'my_profile',
                  {
                    newsletterOn: (this.subscription==1),
                    profileUpdated: true,
                    userId: this.gtmService.getCustomer(),
                  });
                  if (this.isUpdateMailId == true && this.customerInfo.email !== this.customerInfo.loginId) {
                    this.redirectTologin();
                  } else {
                    this.utilService.openDialog('My profile', res.message);
                    this.storage.set(SESSION_AUTHENTICATED_CUSTOMER, res.object);
                    this.cartService.setCustomer(res.object);
                    if (this.redirectUrl !== undefined) {
                      this.router.navigate([this.redirectUrl]);
                    }
                  }
                } else {
                  if (res.errorCode !== null && res.errorCode === ERROR_CODE_ANOTHER_USER_MAIL_ID) {
                    this.isUpdateMailId = false;
                    this.mailVerMess = res.message;
                  } else {
                    this.utilService.openDialog('My profile', res.message);
                  }
                }
              }
            );
          }

        } else {
          // this.utilService.openDialog('Customer Info.',
          // 'Password contains at least six characters, one number, one lowercase and one uppercase letter');
          this.utilService.openDialog('Customer Info.', 'Password should contain at least six characters.');

        }
      }else{
        this.utilService.openDialog('My profile.', 'Please varify your email address');
      }
      } else {
        this.utilService.openDialog('Customer Info.', 'Enter valid email address');

      }

    } else {
      this.utilService.openDialog('My profile', 'Failed to update profile.You did not fill out the required fields.');
    }

  }

        redirectTologin() {
         
            this.clearSession();
            this.router.navigate(['/login'], { queryParams: { 'redirectUrl': '/dashboard' } });
         
        }
        /** clear session stored values */
        clearSession() {
          this.storage.remove(SESSION_AUTHENTICATED_CUSTOMER);
          localStorage.removeItem('customerId');
          this.storage.remove(SESSION_CART);
          this.storage.remove(SESSION_CUSTOMER_FAVOURITES);
          this.storage.remove(SESSION_SELECTED_SHOP); // new
          this.storage.remove(SESSION_SELECTED_SERVICE);
          this.storage.remove(SESSION_ITEM_CATEGORY_LIST);
          this.storage.remove(SESSION_SELECTED_SUBCATEGORY_ID);
          this.storage.remove(COMPANY_INFO);
          this.storage.remove(SESSION_FAV_FILTER_BTN);
          
        }
        changeMailId(customerInfo){
          this.mailIdEnable = true;
          this.mailVerMess = "";
        }
      
        VerifyMailId(customerInfo){
          if (this.emailValidation(this.customerInfo.email)) {
          this.loginService.verifyCustomerMail(this.customerInfo).subscribe(
            res => {
              if (!res.success) {
                this.isUpdateMailId = false;
               this.mailVerMess = res.message;
               
              }else{
                this.mailVerMess = res.message;
                this.isUpdateMailId = true;
                this.mailIdEnable = false;
               
              }
            });        
          } else {
            this.utilService.openDialog('Customer Info.', 'Enter valid email address');
          }  
          return this.isUpdateMailId;
        }

        undoMailId(customerInfo){
          customerInfo.email = customerInfo.loginId;
          this.mailIdEnable = false;
          this.mailVerMess = "";
        }
        
      }
