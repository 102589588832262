import { MenuItem } from '../fw/services/menu.service';

export let initialMenuItems: Array<MenuItem> = [

  {
    text: 'Home',
    icon: 'glyphicon-home',
    route: 'order',
    submenu: null,
    isVisibile: true
  },
  {
    text: 'My Profile',
    icon: 'glyphicon-user',
    route: 'my-profile',
    submenu: null,
    isVisibile: false
  },
  {
    text: 'My Orders',
    icon: 'glyphicon-shopping-cart',
    route: 'my-order',
    submenu: null,
    isVisibile: false
  },
  {
    text: 'FAQ',
    icon: 'glyphicon-question-sign',
    route: 'faq',
    submenu: null,
    isVisibile: true
  },
  {
    text: 'Contact Us',
    icon: 'glyphicon-info-sign',
    route: 'contact-us',
    submenu: null,
    isVisibile: true
  },
  {
    text: 'About Us',
    icon: 'glyphicon glyphicon-record',
    route: 'about-us',
    submenu: null,
    isVisibile: true
  },
  {
    text: 'SIGN IN',
    icon: 'glyphicon-log-in',
    route: 'login',
    redirectUrl: 'order',
    submenu: null,
    isVisibile: true
  }

];
