import { Component, OnInit, Inject } from '@angular/core';
import { ScreenService } from '../../fw/services/screens.service';
import { Router } from '@angular/router';
import { TitleConfig, HdrTitleService } from '../../fw/services/hdr-title.service';
import { StartupService } from '../services/startup.service';
import { DashboardService } from '../services/dashboard.service';
import { UtilService } from '../services/util.service';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { initialMenuItems } from '../app.menu';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { COMPANY_INFO, SESSION_ITEM_CATEGORY_LIST, SESSION_SALE_ITEM_LIST,
   SESSION_SHOP_LIST, SESSION_SELECTED_SUBCATEGORY_ID, SESSION_REGION_LIST,
   SESSION_AMENITY_LIST, SESSION_START_TIME, SESSION_DEPARTMENT_LIST,
   SESSION_FILTERED_SUB_CATEGORY_ITEM_LIST, SESSION_FILTERED_CATEGORY_ITEM_LIST, SESSION_FILTERED_SALE_ITEM_LIST,
    SESSION_SYSTEM_SETTINGS, PRODUCT_IMAGE_FOLDER, SESSION_BUSINESS_TYPE_LIST,
     SESSION_SERVICE_LIST, SESSION_AUTHENTICATED_CUSTOMER, SESSION_PROMOTIONAL_SALEITEM_IDS, PICKUP_LOCATION_LIST, SESSION_MASTER_CUSTMIZATION, SESSION_LOGIN_PROVIDER, REGISTERED_LOGIN } from '../app.constants';
import { CartService } from '../cart/cart.service';
import { ConfirmationService } from 'primeng/api';
import { ItemListService } from '../item-list/item-list.service';
import { StoreFinderService } from '../store-finder/store-finder.service';
import { AppDataService } from '../services/app-data.service';
import { ShopDetailService } from '../shop-detail/shop-detail.service';
import { TimeService } from '../services/time-service.service';
import { SettingsService } from '../settings/settings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from '../services/login.service';
import { OrderHistoryService } from '../order-history/order-history.service';
import { GtmService } from '../gtm.service';
import { CrmApiService } from '../services/crm-api.service';
import { RewardService } from '../services/reward.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [ConfirmationService]
})
export class DashboardComponent implements OnInit {

  amenityList: any;
  shopList: any;
  departmentList: any;
  orderServiceTypes: any;
  itemCategoryList: any;
  saleItemList: any;
  imageUrl: any;
  uploadUrl: any;
  businessTypes: any;
  deviceInfo = null;
  isProduction: boolean;
  showShopHoursViewOption:boolean;
  systemSettings: any;
  currencySymbol: any;
  isInfoPageVisible = true;
  infoPageContent: any;
  bgImageUrl: string;
  pickupLocList: any;
  recentOrderList: any;
  orderAgainImageUrl: any;
  isShopListLoaded=false;
  customizationOptionValues: any;
  constructor(
    private appDataService: AppDataService,
    private _storeFinderService: StoreFinderService,
    private _itemListService: ItemListService,
    private hdrTitleService: HdrTitleService,
    private dashboardService: DashboardService,
    private screenService: ScreenService, private shopDetailService: ShopDetailService,
    private cartService: CartService,
    private router: Router, private confirmationService: ConfirmationService,
    private _settingsService: SettingsService,
    private startUp: StartupService,
    private utilService: UtilService,
    public timeService: TimeService,
    private sanitizer: DomSanitizer,
    private orderHistoryService: OrderHistoryService,
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private loginService:LoginService,
    private crmApiService:CrmApiService,
    private rewardService:RewardService,
    private gtmService: GtmService
  ) {
    this.initTitleBarParams();
    this.cartService.initializeCart();
    this.getAllOnlineServices();
    this.getAllShopList();
    this.getBusinessTypes();
    this.utilService.setMenuItems(initialMenuItems);
    this.utilService.progressService.isLoading = false;
    this.utilService.setFooterBarParams(this, { showDashboardBar: true });
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.imageUrl = this.uploadUrl + '/images/icons/';
    this.bgImageUrl = this.uploadUrl + '/images/others/home-bg';
    this.orderAgainImageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;
     //this.getRecentOrderInfo();
    this.getDepartmentLIst();
    this.getPromotionalItems();
    this.getAmenityData();
    this.getPickupLocationdata();
    this.getSystemSettings();
    this.getCustomizationOptionDtl();
    this.setInfoPage();
  }

  ngOnInit() {

    this.loadData();
  }
  /* set the info div contents */
  setInfoPage() {
    let companyInfo = this.storage.get(COMPANY_INFO);
    let pageContent=companyInfo.infoPageContent;
    if(pageContent !== undefined && pageContent !== null){ 
      this.infoPageContent=this.sanitizer.bypassSecurityTrustHtml(pageContent)
      this.isInfoPageVisible=(companyInfo.showInfoPage);
    }else{
      this.isInfoPageVisible=false;
    }
  }
  /* Loads and sets the data to the session */
  loadData(): void {
    this.isProduction = this.startUp.startupData.isProduction;
    this.showShopHoursViewOption=this.startUp.startupData.showShopHoursViewOption;
    if (this.isProduction !== true) {
      this.utilService.openDialog('IMPORTANT',
        'This system is not ready for ordering by public yet! It is being trialed at the moment.');
    }
   /**
    * autologin
    */
    let customerId;
    let sessionAuthCustomer = this.storage.get(SESSION_AUTHENTICATED_CUSTOMER);
    if(sessionAuthCustomer != null && sessionAuthCustomer != undefined){
      customerId = sessionAuthCustomer.id;
    }else{
      let localStorageCustomerId = (localStorage.getItem('customerId'));
      customerId = localStorageCustomerId;
      if(localStorageCustomerId !== null && localStorageCustomerId !== undefined){
        this.storage.set(SESSION_LOGIN_PROVIDER, REGISTERED_LOGIN);
      }
    }
    if (customerId !== null) {
      this.loginService.getCustomerInfo(customerId).subscribe(
        res => {
          if(res.object !== null){
            this.loginService.getCustomerDetail(res.object);
            this.rewardService.loadCustomerRewards(customerId);
            this.recentOrderList = JSON.parse(res.object.customer.recentOrder);
          }
        });   
    }
    this.getSaleItemList();
    this.getItemCategoryData();
    const now = new Date();
    this.storage.set(SESSION_START_TIME, now);
  
  }

 

  businessTypeClick(businessType) {
    if (this.cartService.cart.businessType !== undefined &&
      this.cartService.cart.businessType.id !== businessType.id &&  this.cartService.getTotalQuantity() !== 0) {
      this.confirmationService.confirm({
        message: 'Changing the order type to ' + businessType.name + ' will empty your order. Do you want to proceed ?',
        accept: () => {
        this.cartService.cart.items = [];
        // this.cartService.cart.deliveryDate = undefined;
        // this.cartService.cart.deliveryTime = undefined;
        this.setBusinessType(businessType);
         
        }

      });


    } else {
      this.setBusinessType(businessType);
    }

  }

  /** Fetch all online service types */
  getAllOnlineServices() {
    this.appDataService.getOrderServiceTypes().subscribe(
      res => {
        if (res.success) {
          this.orderServiceTypes = res.object;         
          this.storage.set(SESSION_SERVICE_LIST, this.orderServiceTypes);
        }
      }
    );
  }


/** Fetch all online service types */
  setBusinessType(businessType) {

    this.cartService.setBusinessType(businessType);
    this.hdrTitleService.setCartImage(businessType);
    let serviceList = this.storage.get(SESSION_SERVICE_LIST);
    this.gtmService.pushEvent("click_and_collect",{
      selection: businessType.name,
      visitorType : this.gtmService.getVisitorType()
    })
    if(serviceList!=undefined && serviceList.length > 1){
      // this.utilService.setMenuItems(orderServiceMenuItems);
      this.utilService.setMenuItems(initialMenuItems);
      this.router.navigate(['/order-services']);
    }else{
      this.cartService.setService(this.orderServiceTypes[0]);
      this.router.navigate(['/login']);
    }
    // this.appDataService.getOrderServiceTypes().subscribe(
    //   res => {
    //     if (res.success) {
    //       this.orderServiceTypes = res.object;
    //       if ( this.orderServiceTypes.length > 1) {
    //         // this.utilService.setMenuItems(orderServiceMenuItems);
    //          this.utilService.setMenuItems(initialMenuItems);
    //         this.router.navigate(['/order-services']);
    //       } else {
    //         this.cartService.setService(this.orderServiceTypes[0]);
    //         this.router.navigate(['/login']);
    //       }

    //     }

    //   }
    // );
  }
  /* Title bar and footer bar configuration */
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    let companyInfo = this.storage.get(COMPANY_INFO);
    if (companyInfo === null || companyInfo === undefined) {
      this.storage.set(COMPANY_INFO, this.startUp.companyData.object[0]);
      companyInfo = this.storage.get(COMPANY_INFO);
    }
    const titleConfig: TitleConfig = {
      title: companyInfo.name,
      showLogo: true,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: true
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showGuestUserBtn: false,
      showShopListBar: false,
      showShopInfoBar: false,
      showitemDetailAddCartBar: false,
      showUpdateCartItemBar: false,
      showItemListBar: false,
      showCartBar: false,
      showPaymentSucessBottomBar: false,
      showOrderDetailsBar: false,
      showSignUpBar: false,
      orderConfirmationBar: false,
      showGuestBar: false,
      showChangePasswordBar: false,
      showMyProfileBar: false,
      showSubmitContactUs: false,
      showFaqBar: false,
      showRetryPaymentBar: false
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }
  /*
  whenever the user come to the dashboard, the following list
   will be cleared from the session
  */
  clearSession() {
    this.storage.remove(SESSION_ITEM_CATEGORY_LIST);
    this.storage.remove(SESSION_SALE_ITEM_LIST);
    this.storage.remove(SESSION_SHOP_LIST);
    this.storage.remove(SESSION_REGION_LIST);
    this.storage.remove(SESSION_AMENITY_LIST);
    this.storage.remove(COMPANY_INFO);
    this.storage.remove(SESSION_FILTERED_SUB_CATEGORY_ITEM_LIST);
    this.storage.remove(SESSION_FILTERED_CATEGORY_ITEM_LIST);
    this.storage.remove(SESSION_FILTERED_SALE_ITEM_LIST);
    }
getSaleItemList () {
  this._itemListService.loadSaleItem().subscribe(
    res => {
        this.saleItemList = res.object;
        this.storage.set(SESSION_SALE_ITEM_LIST, this.saleItemList);
    });
}
getCustomizationOptionDtl(){
    this._itemListService.loadCustomizationOptionDtl().subscribe(
      res => {
        this.customizationOptionValues = JSON.parse(res.object);
        this.storage.set(SESSION_MASTER_CUSTMIZATION, this.customizationOptionValues);
      }); 
}

getItemCategoryData() {
  this._storeFinderService.getItemCategoryData().subscribe(
    res => {
      this.itemCategoryList = res.object;
      this.storage.set(SESSION_ITEM_CATEGORY_LIST, this.itemCategoryList);
    }
  );
}

 getBusinessTypes() {
  this.dashboardService.getBusinessTypes().subscribe(
    res => {
      if (res.success) {
        this.businessTypes = res.object;
        this.storage.set(SESSION_BUSINESS_TYPE_LIST, this.businessTypes);
      }

    }, err => {
    }
  );
 }
 conditionsAndPrivacyPolicy() {
  this.router.navigate(['/terms-and-policies']);
 }

 getDepartmentLIst() {
  this._storeFinderService.getDepartmentData().subscribe(
    res => {
      this.departmentList = res.object;
      this.storage.set(SESSION_DEPARTMENT_LIST, this.departmentList);
    }
  );
}
getSystemSettings() {
  this.systemSettings = this._settingsService.systemSettings;
    if (this.systemSettings === null || this.systemSettings === undefined) {
    this._settingsService.loadSystemSettings().subscribe(
      res => {
        this.systemSettings = res.object;
        this.storage.set(SESSION_SYSTEM_SETTINGS, this.systemSettings);
        this.currencySymbol = this.systemSettings.currency.symbol
      }
    );
  }
}

getAllShopList() {
  this.shopDetailService.getShopList().subscribe(
    res => {
      this.shopList = res.object;
      this.storage.set(SESSION_SHOP_LIST, this.shopList);
    }
  );
}

getAmenityData() {
  this._storeFinderService.getAmenityData().subscribe(
    res => {
      this.amenityList = res.object;
      this.storage.set(SESSION_AMENITY_LIST, this.amenityList);
    }
  );
}

myOrderHistory(){
   /**
   * GTM Code
   */
   this.gtmService.pushEvent(
    'my_orders',
    {
      userId: this.gtmService.getCustomer(),
    });
  this.router.navigate(['/my-order']);
}

reOrderClick(reOrderItem){
    // this.cartService.setCartFromRecentOrders(reOrderItem);
    this.router.navigate(['/my-order-detail'], { queryParams: {'id': reOrderItem.orderId, 'src': 'reorder'} });
}
isShopandItemLoaded() {
  this.isShopListLoaded = false;
  if(this.storage.get(SESSION_SHOP_LIST) !== null && this.storage.get(SESSION_SHOP_LIST) !== undefined && this.storage.get(SESSION_SALE_ITEM_LIST) !== null && this.storage.get(SESSION_SALE_ITEM_LIST) !== undefined){
     this.isShopListLoaded= true;
  }
  return this.isShopListLoaded;
}
getPickupLocationdata(){
  this.shopDetailService.getPickuplocdata().subscribe(
    res => {
      this.pickupLocList = res.object;
      this.storage.set(PICKUP_LOCATION_LIST, this.pickupLocList);
    }
  );
}

hideOfferPanel(){

  this.isInfoPageVisible=false;
}
getRecentOrderInfo(){
  if(this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) != null && this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) != undefined && (this.recentOrderList ===  null || this.recentOrderList === undefined)){
  let customerId = this.storage.get(SESSION_AUTHENTICATED_CUSTOMER).id;
  
  
  let inputParam = {"customer_id" :customerId,"order_limit" :10};
  this.orderHistoryService.getRecentOrders(inputParam).subscribe(
    res => {
      this.recentOrderList = JSON.parse(res.object);/**json string return type convert to  object*/
     },err=>{
     });
    }
  }
  getPromotionalItems(){
    this._itemListService.getPromotionalItems().subscribe(
      res => {
        let promotionItemList = res.object;

        this.storage.set(SESSION_PROMOTIONAL_SALEITEM_IDS, promotionItemList);
       
      }
    );
  }

}

