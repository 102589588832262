import { Injectable, Inject } from '@angular/core';
import { SESSION_SELECTED_SHOP, SESSION_SELECTED_SHOP_SERVICES } from '../../app/app.constants';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { UtilService } from '../../app/services/util.service';
import { CartService } from '../../app/cart/cart.service';

@Injectable({
  providedIn: 'root'
})
export class StoreSelectionService {
  filterParams: any =  {'apply': false, 'amenities': [], 'distance': 5, 'itemCategory': []};
  filterFavourites: boolean;
  favBtnEnabled: boolean;
  selectedShop: any;
  // CurrentShopInfo: any;
  private _selectedRegion: any;
  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService, private cartService: CartService,
  private utilService: UtilService) {
  }
  get selectedRegion(): any {
    return this._selectedRegion;
  }
  set selectedRegion(region: any) {
    this._selectedRegion = region;
  }
  saveSelectedShop(): any {
    
    let saved = true;
    // if (this.CurrentShopInfo !== undefined) {
    //   this.selectedShop = this.CurrentShopInfo;
    // }
    if (this.selectedShop !== undefined) {
      this.cartService.setShop(this.selectedShop);
      this.sessionStorage.set(SESSION_SELECTED_SHOP, this.selectedShop);
   //   this.sessionStorage.set(SESSION_SELECTED_SHOP_SERVICES, this.selectedShop.shopServices);
    } else {
      this.utilService.openDialog('Shop Selection.', 'No shop selected. Select a shop from the list.');
      saved = false;
    }
    // this.CurrentShopInfo = undefined;
    return saved;
  }
}
