import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { HdrTitleService, TitleConfig } from '../../fw/services/hdr-title.service';
import { StatusBarService, FooterConfig } from '../../fw/services/status-bar.service';
import {
    COMPANY_INFO, SESSION_AUTHENTICATED_CUSTOMER, SESSION_START_TIME,
    SESSION_ITEM_CATEGORY_LIST, SESSION_SALE_ITEM_LIST, SESSION_SHOP_LIST
} from '../app.constants';
import { MenuService, MenuItem } from '../../fw/services/menu.service';
import { MatDialog, MatDialogActions } from '@angular/material';
import { CustomerLoginDialogComponent } from '../shared/customer-dialog/customer-login-dialog.component';
import { ProgressBarLoadService } from './progress-bar-load.service';
import { FrameworkConfigService } from '../../fw/services/framework-config.service';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { StartupService } from './startup.service';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    public data: any = [];

    constructor(private http: HttpClient, private router: Router,
        @Inject(SESSION_STORAGE) private storage: WebStorageService,
        private titleService: HdrTitleService, private footerService: StatusBarService,
        private menuService: MenuService, public dialog: MatDialog,
        private _progressService: ProgressBarLoadService,
        private _fwConfigService: FrameworkConfigService, private startUp: StartupService) {
    }

    /**Handle http error response*/
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {

            console.error('An error occurred:', error.error.message);
        } else {

            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }

        return throwError(
            'Something bad happened; please try again later.');
    }

    /**Set Titlebar configuration */
    setTitleBarParams(parent: any, titleConfig: TitleConfig) {
        this.titleService.configure(parent, titleConfig);
    }

    /**Set Footerbar configuration */
    setFooterBarParams(parent: any, footerConfig: FooterConfig) {
        this.footerService.configure(parent, footerConfig);
    }
    /**
     * validate session
     */
    validateSession(): boolean {
        let isSessionValid = false;
        if (this.storage.get(SESSION_START_TIME) !== undefined) {
            const sessionStartTime = new Date(this.storage.get(SESSION_START_TIME));
            const currentTime = new Date();
            const totalDiffMins = (currentTime.valueOf() - sessionStartTime.valueOf()) / 60000;
            if (totalDiffMins < this.startUp.startupData.sessionexpired) {
                isSessionValid = true;
            }
        }
        return isSessionValid;
    }

    /**Get company info */
    getCompanyInfo(): any {
        return this.storage.get(COMPANY_INFO);
    }

    /**Set menu items */
    setMenuItems(menuItems: Array<MenuItem>) {
        this.menuService.items = menuItems;
    }

    /**Check if the authenticated customer data exists in session storage, and session is valid*/
    checkValidCustomer(title?: string, message?: string): boolean {
        const isValidCustomer = (this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) !== null &&
            this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) !== undefined  &&
             this.storage.get(SESSION_AUTHENTICATED_CUSTOMER).customerType !== 2 ) ?
            true : false;
        if (!isValidCustomer && title && message) {
            this.openDialog(title, message);
        }
        return isValidCustomer;
    }

    /**Open dialogue box with the given message and title */
    openDialog(dialogTitle: string, dialogMessage: string) {
        this.dialog.open(CustomerLoginDialogComponent, {
            data: {
                title: dialogTitle.toUpperCase(),
                message: dialogMessage
            }
        });
    }

    openConfirmationDialog(dialogTitle: string, dialogMessage: string, dialogAction: string) {
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: dialogTitle.toUpperCase(),
                message: dialogMessage,
                noAction: dialogAction
            }
        });
    }

    /**return progress service instance*/
    get progressService() {
        return this._progressService;
    }

    /**reset the footer configuration parameters */
    resetFooterConfigData(parent: any) {
        const footerConfig: FooterConfig = {
            showGuestUserBtn: false,
            showShopListBar: false,
            showShopInfoBar: false,
            showDateSelectionBar: false,
            showItemListBar: false,
            showitemDetailAddCartBar: false,
            showUpdateCartItemBar: false,
            showPaymentSucessBottomBar: false,
            showOrderDetailsBar: false,
            showCartBar: false,
            showSignUpBar: false,
            orderConfirmationBar: false,
            showGuestBar: false,
            showChangePasswordBar: false,
            showMyProfileBar: false,
            showSubmitContactUs: false,
            showFaqBar: false,
            showRetryPaymentBar: false


        };
        this.setFooterBarParams(parent, footerConfig);
    }

    /**Get session stored values by passing the key */
    getStorageValuesByKey(key): void {
        return this.storage.get(key);
    }

    /**return framework config service instance*/
    get fwConfigService() {
        return this._fwConfigService;
    }
}
