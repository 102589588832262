import { Component, OnInit } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentInitService } from '../payment-init/payment-init.service';
import { CartService } from '../cart/cart.service';

@Component({
  selector: 'app-payment-fail',
  templateUrl: './payment-fail.component.html',
  styleUrls: ['./payment-fail.component.css']
})
export class PaymentFailComponent implements OnInit {

  result: any;
  errorPage: any;
  // cart: any;
  constructor( private utilService: UtilService, private route: ActivatedRoute, private paymentInitService: PaymentInitService,
    private cartService: CartService, private router: Router) {
    this.initTitleBarParams();
   }

  ngOnInit() {
    // this.cart = this.cartService.cart;
    const pid = this.route.snapshot.queryParams['pid']; // this.cart.customer.id;
    const sucessResultParam = this.route.snapshot.queryParams['result'];
    const txnId = this.route.snapshot.queryParams['txnid'];
    const retryId = undefined;
    this.paymentInitService.getOrderStatus(sucessResultParam, pid, txnId,retryId).subscribe(
         res => {
      this.result = res.success;
         if (this.result) {
       } else {
      this.errorPage = res.message;
       }
     }
   );
  }

  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = true;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'PAYMENT',
      showLogo: false,
      showShoppingCart: false,
      showLogin:false,
      hideHamBurgerMenu: false,
      hideBackButton: true,
      overrideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showRetryPaymentBar: true
    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }

  onBackButttonPressed(): Boolean {
    this.router.navigate(['/order-confirmation']);
    return true;
  }
}
