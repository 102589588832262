import { Injectable, Inject } from '@angular/core';
import { StartupService } from '../services/startup.service';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SESSION_SYSTEM_SETTINGS } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  validateTokenUrl: string;
  accountActivationUrl: string;
  systemSettingsUrl: string;
  private _systemSettings: string;
  get systemSettings(): string {
    this._systemSettings = this.sessionStorage.get(SESSION_SYSTEM_SETTINGS);
    if (this._systemSettings === null || this._systemSettings === undefined) {
      this.loadSystemSettings().subscribe(
        res => {
          this._systemSettings = res.object;
          this.sessionStorage.set(SESSION_SYSTEM_SETTINGS, this._systemSettings);
        }
      );
    }

    return this._systemSettings;
  }
  constructor(private startUp: StartupService, private http: HttpClient,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService) {
    this.systemSettingsUrl = this.startUp.startupData.apiurl + '/settings/get-all';
    this.accountActivationUrl = this.startUp.startupData.apiurl + '/customer/account-activation/';
   this.validateTokenUrl = this.startUp.startupData.apiurl + '/order-confirmation/';
  }
  loadSystemSettings(): Observable<any> {
    return this.http.get<any>(this.systemSettingsUrl)
      .pipe(
      );
  }

  accountactivation(accessTocken: any): Observable<any> {
    const url = this.accountActivationUrl + accessTocken;
    return this.http.get<any>(url)
      .pipe(
      );
  }

      validateToken(token: any): Observable<any> {
        const url = this.validateTokenUrl + token;
        return this.http.get<any>(url)
        .pipe(
        );
          }

  // getSystemSetings() {
  //     this.systemSettings =  this.sessionStorage.get(SESSION_SYSTEM_SETTINGS);
  //       if (this.systemSettings === null || this.systemSettings === undefined) {
  //         this.loadSystemSettings().subscribe(
  //           res => {
  //              this.systemSettings = res.object;
  //              this.sessionStorage.set(SESSION_SYSTEM_SETTINGS, this.systemSettings);
  //           }
  //         );
  //       }
  // }
}
