import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { StoreSelectionService } from '../../services/store-selection.service';
import { Router } from '@angular/router';
import { UtilService } from '../../../app/services/util.service';
import { SESSION_SELECTED_SHOP, SESSION_FAV_FILTER_BTN , SESSION_AUTHENTICATED_CUSTOMER} from '../../../app/app.constants';
import { StatusBarService } from '../../services/status-bar.service';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';

@Component({
  selector: 'app-select-shop-bottom-nav',
  templateUrl: './select-shop-bottom-nav.component.html',
  styleUrls: ['./select-shop-bottom-nav.component.css']
})
export class SelectShopBottomNavComponent implements OnInit {
  sessionCustomer: any;
  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService, public storeSelectionService: StoreSelectionService,
    private router: Router, private utilService: UtilService, public statusBarService: StatusBarService) {
    storeSelectionService.filterFavourites = false;
    storeSelectionService.favBtnEnabled = true;
  }

  ngOnInit() {
    this.storeSelectionService.filterFavourites = this.sessionStorage.get(SESSION_FAV_FILTER_BTN);

  }
  /**
   * Toggle Favourite and unfavourite shop list
   */
  toggleFavourite() {

    this.sessionCustomer = this.sessionStorage.get(SESSION_AUTHENTICATED_CUSTOMER);

     if (this.sessionCustomer !== null) {
       if (this.storeSelectionService.favBtnEnabled) {
        this.storeSelectionService.filterFavourites = !this.storeSelectionService.filterFavourites;
        this.sessionStorage.set(SESSION_FAV_FILTER_BTN,  this.storeSelectionService.filterFavourites);
      }
     } else if (this.utilService.checkValidCustomer('Favourite Shop', 'Please login to use this function')) {
      this.storeSelectionService.favBtnEnabled = false;
           return false;
         }
       }
  /**
   * This shop is selected for further use
   */
  shopSelection() {
    this.statusBarService.parent.shopSelection();
    // if (this.storeSelectionService.saveSelectedShop()) {
    //   if (this.utilService.getStorageValuesByKey(SESSION_SELECTED_SHOP) !== null &&
    //     this.utilService.getStorageValuesByKey(SESSION_SELECTED_SHOP) !== undefined) {
    //     this.router.navigate(['/datetime-selection']);
    //   }
    // }
  }
  shopFilter() {
    this.router.navigate(['/shop-filter']);
  }
}
