import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationBarService } from './notification-bar.service';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.css']
})
export class NotificationBarComponent implements OnInit {


  constructor(public notificationBarService:NotificationBarService) { 
  }

  ngOnInit() {
  }

 
  /**
   * Close the notification
   */
  close():void{
    this.notificationBarService.showNotification=false;
  }

}
