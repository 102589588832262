import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { CrmApiService } from './crm-api.service';
import { NotificationBarService } from '../notification-bar/notification-bar.service';
import { SESSION_CUSTOMER_REWARDS } from '../app.constants';

export interface Reward {
  id: number,
}

@Injectable({
  providedIn: 'root'
})

export class RewardService {

  public customerRewards = []
// [
//     {
//       id: 999000027,
//       code: "AXP01",
//       name: "Free Shipping Coupons",
//       description: "SZ",
//       customer_status: 1,
//       crm_reward_category_id: 13,
//       category_name: "Free Shipping Coupons 1",
//       customer_filter: null,
//       shop_filter: null,
//       image_file_name: "64f44a1ad4008",
     
//     },
//     {
//       id: 999000027,
//       code: "DYP02",
//       name: "Free Shipping Coupons 2",
//       description: "SZ",
//       customer_status: 1,
//       crm_reward_category_id: 13,
//       category_name: "Free Shipping Coupons 2",
//       customer_filter: null,
//       shop_filter: null,
//       image_file_name: "64f44a1ad4008"
//     },
//     {
//       id: 999000027,
//       code: "DXP02",
//       name: "Free Shipping Coupons 3",
//       description: "SZ",
//       customer_status: 2,
//       crm_reward_category_id: 13,
//       category_name: "Free Shipping Coupons 3",
//       customer_filter: null,
//       shop_filter: null,
//       image_file_name: "64f44a1ad4008"
//     },
//     {
//       id: 999000027,
//       code: "HXP03",
//       name: "Free Shipping Coupons 4",
//       description: "SZ",
//       customer_status: 3,
//       crm_reward_category_id: 13,
//       category_name: "Free Shipping Coupons 4",
//       customer_filter: null,
//       shop_filter: null,
//       image_file_name: "64f44a1ad41008"
//     },
//     {
//       id: 999000027,
//       code: "HXP03",
//       name: "Free Shipping Coupons 5",
//       description: "SZ",
//       customer_status: 1,
//       crm_reward_category_id: 13,
//       category_name: "Free Shipping Coupons 5",
//       customer_filter: null,
//       shop_filter: null,
//       image_file_name: "64f44a1ad4008"
//     },
//     {
//       id: 999000027,
//       code: "DXP03",
//       name: "Free Shipping Coupons 6",
//       description: "SZ",
//       customer_status: 1,
//       crm_reward_category_id: 13,
//       category_name: "Free Shipping Coupons 6",
//       customer_filter: null,
//       shop_filter: null,
//       image_file_name: "64f44a1ad41008"
//     },
//     {
//       id: 999000027,
//       code: "QXP03",
//       name: "Free Shipping Coupons 7",
//       description: "SZ",
//       customer_status: 1,
//       crm_reward_category_id: 13,
//       category_name: "Free Shipping Coupons 7",
//       customer_filter: null,
//       shop_filter: null,
//       image_file_name: "64f44a1ad40081"
//     }
//   ]

  constructor(private http: HttpClient,
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private crmApiService:CrmApiService,
    private notficationService:NotificationBarService
  ) { }

  /**
   * Returns the loaded rewards
   * @param id 
   * @returns 
   */
  getMyRewards(id: any): any[] {
    let sessionRewards=localStorage.getItem(SESSION_CUSTOMER_REWARDS);
    this.customerRewards=(sessionRewards!==null && sessionRewards!==undefined)?JSON.parse(sessionRewards):[];
    return this.customerRewards;
  }


  /**
   * Load and set the customer rewards
   * @param customerId 
   */
  loadCustomerRewards(customerId):void{
    this.crmApiService.getRewards(customerId).subscribe({
      next: (response:any) => {
        if (response.status == 'SUCCESS') {
          this.customerRewards=response.data.customer_reward;
          if(this.customerRewards.length>0){
            this.notficationService.showNotification=true;
            localStorage.setItem(SESSION_CUSTOMER_REWARDS,JSON.stringify(this.customerRewards));
          }
        } else {
        }
      },
      error: (error) => {
        
      }
    });
  }

}