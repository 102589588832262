// session variables

export const SESSION_SELECTED_SERVICE = 'selectedOrderService';
export const SESSION_AUTHENTICATED_CUSTOMER = 'authenticatedCustomer';
export const COMPANY_INFO = 'companyInfo';
export const SESSION_CUSTOMER_FAVOURITES = 'customerFavourites';
export const SESSION_SHOP_LIST_CACHE_TIME = 'shop_list_cache_time';
export const SESSION_SHOP_LIST = 'shop_list';
export const SESSION_REGION_LIST = 'region_list';
export const SESSION_AMENITY_LIST = 'amenity_list';
export const SESSION_SELECTED_SHOP = 'selected_shop';
export const SESSION_SELECTED_SHOP_SERVICES = 'selected_shop_services';
export const SESSION_ITEM_CATEGORY_LIST = 'item_category_list';
export const SESSION_SELECTED_SUBCATEGORY_ID = 'selectedSubCategory';
export const SESSION_SALE_ITEM_LIST = 'sale_item_list';
export const SESSION_SYSTEM_SETTINGS = 'system_settings';
export const SESSION_CART = 'cart';
export const SESSION_START_TIME = 'session_start_time';
export const SESSION_DEPARTMENT_LIST = 'department_list';
export const SESSION_SELECTED_ITEM_CATEGORY_ID = 'selectedItemCategoryId';
export const SESSION_TOKEN = 'token';
export const SESSION_CONFIRMATION_MESSAGE = 'confirmation_messge';
export const SESSION_LAST_SUCCESS_TXN_ID = 'last_success_txn_id';
export const SESSION_LAST_SUCCESS_TAX_INVOICE_NO = 'last_success_tax_invoice_no';
export const SESSION_LAST_SUCCESS_TXN_RECEIPT = 'last_success_txn_receipt';
export const SESSION_LAST_TXN_RECEIPT_DOWNLOADED = 'last_success_txn_receipt_downloaded';
export const SESSION_MASTER_CUSTMIZATION = 'customization_option_detail';

export const SESSION_CUSTOMER_REWARDS = 'customer_rewards';

export const SESSION_AUTH_TOKEN = 'auth_token';

// VARIABLE FOR SESSION_FILTERED_SALE_ITEM_LIST
export const SESSION_FILTERED_SALE_ITEM_LIST = 'filtered_sale_item_list';
export const SESSION_FILTERED_CATEGORY_ITEM_LIST = 'filtered_category_item_list';
export const SESSION_FILTERED_SUB_CATEGORY_ITEM_LIST = 'filtered_sub_category_item_list';

export const SESSION_FAV_FILTER_BTN = 'faouritefilterbutton';

export const SHOP_IMAGE_FOLDER  = '/images/shop/';
export const  PRODUCT_IMAGE_FOLDER  = '/images/product/';

export const CATEGORY_IMAGE_FOLDER = '/images/category/';

export const SESSION_BUSINESS_TYPE_LIST = 'business_type_list';
export const SESSION_SERVICE_LIST = 'online_service_list';
export const SESSION_PROMOTIONAL_SALEITEM_IDS = 'promotional_item_list';
export const SESSION_OUTOFSTOCK_ITEM_LIST = 'out_of_stock_ooSaleItem_Ids';
export const SESSION_LOGIN_PROVIDER='login_type';
export const PICKUP_LOCATION_LIST ='pickup_location_list';
export const ERROR_CODE_OUT_OF_STOCK ='102';
export const ERROR_CODE_ANOTHER_USER_MAIL_ID ='103';
export const FACEBOOK_LOGIN = 'facebook';
export const GOOGLE_LOGIN = 'google';
export const REGISTERED_LOGIN = 'registered';
export const DEF_DATE_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const WEEK_DAYS = [
  {day: 1, weekDay: 'Monday'},
  {day: 2, weekDay: 'Tuesday'},
  {day: 3, weekDay: 'Wednesday'},
  {day: 4, weekDay: 'Thursday'},
  {day: 5, weekDay: 'Friday'},
  {day: 6, weekDay: 'Saturday'},
  {day: 7, weekDay: 'Sunday'}
];
