import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { ActivatedRoute } from '@angular/router';
import { OrderHistoryService } from '../order-history/order-history.service';
import { SettingsService } from '../settings/settings.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import html2canvas from 'html2canvas';
import { CartService } from '../cart/cart.service';
import * as moment from 'moment';
import { StartupService } from '../services/startup.service';
import { PRODUCT_IMAGE_FOLDER, SESSION_SHOP_LIST } from '../app.constants';
import { TaxService } from '../services/tax.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { GtmService } from '../gtm.service';


@Component({
  selector: 'app-order-info-detail',
  templateUrl: './order-info-detail.component.html',
  styleUrls: ['./order-info-detail.component.css']
})
export class OrderInfoDetailComponent implements OnInit, OnDestroy {


  receiptMail: boolean;
  htmlData: SafeHtml;
  result: any;
  currencySymbol: any;
  systemSettings: any;
  orderHistoryDetail: any;
  orderDetailsInfo: any;
  orderInfoDetail: any;
  orderId: any;
  recentOrderObject: any;
  src: any;
  imageUrl: string;
  uploadUrl: any;
  orderCustomizationInfoDtl: any;
  orderDetailArray: any[];
  totalQuantity: number;
  shopList: any;
  constructor(private utilService: UtilService, private orderHistoryService: OrderHistoryService, private route: ActivatedRoute,
    private _settingsService: SettingsService, private sanitizer: DomSanitizer,private taxService: TaxService,
    private cartService: CartService,@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService,
    private startUp: StartupService,
    private gtmService:GtmService) {
    this.systemSettings = this._settingsService.systemSettings;
    if (this.systemSettings !== null) {
      this.currencySymbol = this.systemSettings.currency.symbol;
    }
    this.initTitleBarParams();
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.imageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;
    this.receiptMail = false;
    this.orderId = this.route.snapshot.queryParams['id'];
    this.src = this.route.snapshot.queryParams['src'];
    this.getOrderHistoryDetail(this.orderId);
  }

  ngOnInit() {

  
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'ORDER-DETAIL',
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);

  }
  getOrderHistoryDetail(orderId) {
    this.orderHistoryService.getMyOrderDetail(orderId).subscribe(
      res => {
        this.result = res.success;
        if (this.result) {

          this.orderInfoDetail = res.object.orderInfo;
          this.orderInfoDetail.deliveryTime = moment(this.orderInfoDetail.deliveryTime, "HH:mm:ss").format("LT");
          this.orderDetailsInfo = res.object.orderDtl;
          this.orderHistoryDetail = res.object.orderHistory;
          /**
          * GTM Code
          */
          this.gtmService.pushEvent(
            'previous_orders_details',
            {
              userId: this.gtmService.getCustomer(),
              previousStore: this.orderInfoDetail.shopName,
              previousDate: this.orderInfoDetail.deliveryDate,
              previousTime: this.orderInfoDetail.deliveryTime
            });
          this.getOrderDetailAllInfo();
        }
      });

  }

  /**
   * format orderDetail information.
   * find total quantity.
   * format customization items  display .
   * set main as first item in the more button display.
   */
  getOrderDetailAllInfo() {
    this.orderDetailArray = [];
    this.totalQuantity = 0;
    for (let element of this.orderDetailsInfo) {
      const orderDetailPerItem = element;
      this.totalQuantity += element.qty;
      orderDetailPerItem.isShow = false;
      orderDetailPerItem.itemTotalWithCustomization = this.cartService.getUnitpriceWithTax(element.taxCalculationMethod, element.fixedPrice, element.tax1Pc);
      if (element.remarks !== null) {
        orderDetailPerItem.remarks = (element.remarks.toString().trim().length > 0) ? '*' + element.remarks : '';
      }
      if (orderDetailPerItem.customizations !== null && orderDetailPerItem.customizations.toString().trim().length > 0) {
        orderDetailPerItem.customizations = JSON.parse(orderDetailPerItem.customizations);
        const mainItem = {
          'id': element.id,
          'orderId': element.orderId,
          'parentDtlId': '',
          'optionValueNameToPrint': element.name,
          'fixedPrice': element.fixedPrice,
          'saleItemId': element.saleItemId,
          'taxCalculationMethod': element.taxCalculationMethod,
          'tax1Percentage': element.tax1Pc,
          'unitPriceWithTax': this.cartService.getUnitpriceWithTax(element.taxCalculationMethod, element.fixedPrice, element.tax1Pc)
        }
        orderDetailPerItem.customizations.unshift(mainItem);
        orderDetailPerItem.itemTotalWithCustomization = this.getItemWithCustomTotalPrice(orderDetailPerItem.customizations);
      }
      this.orderDetailArray.push(orderDetailPerItem);
    }
  }

  /**
   * sum up main item and customization item prices.
   * @param itemCustomization 
   * @returns 
   */
  getItemWithCustomTotalPrice(itemCustomization): any {
    var customizationpriceTotal = 0;
    for (let subItem of itemCustomization) {
      customizationpriceTotal += subItem.unitPriceWithTax;
    }
    return customizationpriceTotal;
  }

  /**
   * more and hide button settings
   * @param itemDetail 
   */
  orderCustomizationInfo(itemDetail) {
    if ((itemDetail.customizations !== null) || (itemDetail.remarks !== null && itemDetail.remarks.toString().trim().length > 0)) {
      itemDetail.isShow = !itemDetail.isShow
    }
  }

  getDisplayDate(orderInfo){
    let parsedDate1 =moment(orderInfo.deliveryDate.split(" ")[0],"YYYY-MM-DD").format("DD-MMM-YY")
    return parsedDate1;
  }

  receiptClick() {
    const ement = document.querySelector('.main-div-wrap');
    ement.scrollTo(0, 0);
    ement.classList.add('Orderdet');
    this.receiptMail = true;
    this.htmlData = this.sanitizer.bypassSecurityTrustHtml(this.orderInfoDetail.orderMailReceipt);
  }

  outerReceiptClick() {
    this.receiptMail = false;
    const ement = document.querySelector('.main-div-wrap');
    ement.classList.remove('Orderdet');

  }

  downloadReceipt() {

    var container = document.getElementById('receiptContentDiv'); 
    html2canvas(container).then(function(canvas) {
              var link = document.createElement("a");
              document.body.appendChild(link);
              link.download = "oo_recipt.png";
              link.href = canvas.toDataURL("image/png");
              link.target = '_blank';
              link.click();
          });
}
  ngOnDestroy(): void {
    this.outerReceiptClick();
  }
  orderAgainClick(){
    this.utilService.progressService.isLoading = true;
    let orderid = this.orderId;
    this.orderHistoryService.getRecentOrderByOrderId(orderid).subscribe(
      res => {
        this.recentOrderObject = JSON.parse(res.object);/**json string return type convert to  object*/
        if(!this.validateRecentOrderShop()){
          return;
        }
         /**
          * GTM Code
          */
         this.gtmService.pushEvent(
          'order_again',
          {
            userId: this.gtmService.getCustomer(),
            previousStore: this.orderInfoDetail.shopName,
            previousDate: this.orderInfoDetail.deliveryDate,
            previousTime: this.orderInfoDetail.deliveryTime
          });
        this.cartService.setCartFromRecentOrders(this.recentOrderObject);

       },err=>{
       });
  }
  
  /**
   * validate the shop of reordered item shop.
   * @returns 
   */
  validateRecentOrderShop() {
    let shopExist =true;
    let shopList = this.sessionStorage.get(SESSION_SHOP_LIST);
    const selectedShop = shopList.filter(shp => shp.id === this.recentOrderObject.shopId);
    if(selectedShop.length ===0){
      this.utilService.openDialog('Sorry!', 'You can not place this order again. The shop '+this.orderInfoDetail.shopName+' is not valid now.');
      shopExist = false;
    }
    this.utilService.progressService.isLoading = false;
    return shopExist;
  }

}
