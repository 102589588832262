import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarLoadService {
  private _isLoading: boolean;
  constructor() {
    this._isLoading = false;
  }

  /**Return  _isLoading*/
  get isLoading() {
  return this._isLoading;
  }

  /**Set _isLoading */
  set isLoading(isVisible: boolean) {
    this._isLoading = isVisible;
  }
}
