import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from '../../services/dashboard.service';
import { StartupService } from '../../services/startup.service';
import { $, jQuery } from 'jquery';
import { Router } from '@angular/router';
import { UtilService } from '../../services/util.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-banner-image-panel',
  templateUrl: './banner-image-panel.component.html',
  styleUrls: ['./banner-image-panel.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BannerImagePanelComponent implements OnInit {
  images: Array<string>;
  @ViewChild('slideshow1') slideshow1: any;
  @ViewChild('slideshow2') slideshow2: any;
  banner1Images: any[];
  banner2Images: any[];
  // banner1Links: Array<string>;
  // banner2Links: Array<string>;
  uploadUrl: string;
  constructor(config: NgbCarouselConfig,
    private _http: HttpClient,
    private dashboardService: DashboardService,
    private startUp: StartupService, private router: Router,
    private utilService: UtilService) {
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.utilService.progressService.isLoading = true;
  }

  ngOnInit() {
    this.images = [];
    this.getBanners();
  }

  /**Fetch banner json data*/
  getBanners() {
    this.dashboardService.getBannersData().subscribe(
      res => {
        this.utilService.progressService.isLoading = false;
        if (res.success) {
          this.setBannerData(res.object);
        }
      }
    );
  }

  setBannerData(bannerData) {

    this.banner2Images = [
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-2/images/banner-img1.jpg', caption: '' },
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-2/images/banner-img2.jpg', caption: '' },
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-2/images/banner-img3.jpg', caption: '' },
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-2/images/banner-img4.jpg', caption: '' },
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-2/images/banner-img5.jpg', caption: '' },
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-2/images/banner-img6.jpg', caption: '' }
    ];
    this.banner1Images = [
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-1/images/banner-img1.jpg',
       caption: 'Vegan Sushi and Homemade Pickled Ginger' },
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-1/images/banner-img2.jpg', caption: 'Sushi of Jappan' },
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-1/images/banner-img3.jpg', caption: 'Saikou Sushi', },
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-1/images/banner-img4.jpg', caption: '' },
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-1/images/banner-img5.jpg', caption: '' },
      { url: 'http://178.128.101.208:8080/oo-uploads/banners/banner-1/images/banner-img6.jpg', caption: '' }
    ];
    // { url:'http://178.128.101.208:8080/oo-uploads/banners/banner-1/images/banner-img6.jpg', caption:''}
    // this.banner1Links = [];
    // this.banner2Links = [];
    // for (let i = 0; i < bannerData.length; i++) {
    //   for (let j = 0; j < bannerData[i].bannorJsonList.length; j++) {
    //     const filePath = this.uploadUrl + '/'
    //       + bannerData[i].bannorJsonList[j].image;
    //     if (bannerData[i].folderName === 'banner-1') {
    //       this.banner1Images[j] = '';
    //       this.banner1Links[j] = '';
    //       this.banner1Links[j] = bannerData[i].bannorJsonList[j].link;
    //       this.banner1Images[j] = filePath;
    //     }
    //     if (bannerData[i].folderName === 'banner-2') {
    //       this.banner2Images[j] = '';
    //       this.banner2Links[j] = '';
    //       this.banner2Links[j] = bannerData[i].bannorJsonList[j].link;
    //       this.banner2Images[j] = filePath;
    //     }
    //   }
    // }

  }

  // redirectBanner1(event) {
  //   for (let i = 0; i < this.banner1Images.length; i++) {
  //     if (this.slideshow1.slides[i].selected && event.target.tagName.toLowerCase() !== 'i') {
  //       window.open(this.banner1Links[i], '_blank');
  //     }
  //   }
  // }
  // redirectBanner2(event) {
  // for (let i = 0; i < this.banner2Images.length; i++) {
  //     if (this.slideshow2.slides[i].selected && event.target.tagName.toLowerCase() !== 'i') {
  //       window.open(this.banner2Links[i], '_blank');
  //     }
  //   }
  // }
}
