import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { FormControl, Validators, FormGroup, AbstractControl, ValidatorFn, FormBuilder } from '@angular/forms';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { SESSION_AUTHENTICATED_CUSTOMER } from '../app.constants';
import { Customer } from '../view-models/customer';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  validPassword: boolean;
  loading: boolean;
  customerInfo: { 'customerName': string; 'password': string; 'oldPassword': string };
  sessionCustomer: any;
  formControl: any;
  pwGroup: FormGroup;
  redirectUrl: any;
  passwordConfirm: any;
  currentPassword: any;
  newPassword: any;
  invalidMessage: string;

    /* Compare the passwords and set the error */
    matchPassword(pwGroup: AbstractControl) {
      const password = pwGroup.get('newPassword').value;
      const confirmPassword = pwGroup.get('passwordConfirm').value;
      if (password !== confirmPassword) {
       // console.log('false');
        pwGroup.get('passwordConfirm').setErrors({ mismatch: true });
      } else {
       // console.log('true');
        return null;
      }
    }


  constructor(private utilService: UtilService,  @Inject(SESSION_STORAGE) private storage: WebStorageService,
  private loginService: LoginService, private router: Router) {
    this.sessionCustomer = this.storage.get(SESSION_AUTHENTICATED_CUSTOMER);
    this.initTitleBarParams();
    this.loading = false;
        /* Form validators */
        this.formControl = {
          currentPassword:  new FormControl('', [Validators.required, Validators.minLength(6)]),
          newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
          passwordConfirm: new FormControl('', [Validators.required])
        };

  }

  ngOnInit() {
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = true;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'CHANGE PASSWORD',
      showLogo: false,
      showShoppingCart: false,
      showLogin:false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showChangePasswordBar: true

    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }
/**
 * password change
 */
    updatePassword() {
      if (this.isFormValid()) {
        if (this.checkPassword(this.newPassword)) {
        if (this.newPassword !== this.passwordConfirm) {
        this.invalidMessage = 'Entered Password does not match';
        } else {

            this.customerInfo = {'customerName': this.sessionCustomer.loginId, 'password': this.newPassword,
             'oldPassword': this.currentPassword};
             this.loading = true;
            this.loginService.changePassword(this.customerInfo).subscribe(
            res => {
              this.loading = false;
                if (res.success) {
                    this.utilService.openDialog('Password change', res.message);
                    this.router.navigate(['/my-profile']);

                } else {
                   this.utilService.openDialog('Password change', res.message);
                }
            }
            );
      }
    } else {
      // this.utilService.openDialog('Customer Info.',
      // 'Password contains at least six characters, one number, one lowercase and one uppercase letter');
      this.utilService.openDialog('Customer Info.',
      'Password should contain at least six characters. ');

    }


      }
    }
    checkPassword(str) {
      this.validPassword =  false;
      // const  re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
      // const  re = /6/;
      // if (re.test(str)) {
      //   this.validPassword =  true;
      // } else {
      //   this.validPassword =  false;
      // }
      if (str.length >= 6) {
        this.validPassword =  true;
      } else {
        this.validPassword =  false;
      }
      return this.validPassword;
    }

  /* Returns password validation errors */
  getCurrentPasswordError(): any {
    let error: any;
    if (this.formControl.currentPassword.hasError('required')) {
      error = 'Please enter a valid password.';
    } else if (this.formControl.currentPassword.hasError('minlength')) {
      error = 'Passwords must be at least 6 characters long.';
    }
    return error;
  }
    /* Returns password validation errors */
    getNewPasswordError(): any {
      let error: any;
      if (this.formControl.newPassword.hasError('required')) {
        error = 'Please enter a valid password.';
      } else if (this.formControl.newPassword.hasError('minlength')) {
        // error = 'Password contains at least six characters, one number, one lowercase and one uppercase letter';
        error = 'Password should contain at least six characters.';
      }
      return error;
    }
    isFormValid(): boolean {
      const form = document.getElementById('changePasswordForm');
      return (form[0].checkValidity());
    }
      /* Returns confirm password validation errors */

    getPasswordConfirmError(): any {
    let error: any;
    if (this.formControl.passwordConfirm.hasError('required')) {
      error = 'Please enter a valid password.';
    } else if (this.formControl.newPassword.value !== this.formControl.passwordConfirm.value ) {
      error = 'Passwords does not match.';
    }
    return error;
  }


}
