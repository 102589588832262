import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { SESSION_AUTH_TOKEN } from '../app.constants';
import { environment } from 'src/environments/environment';
import { StartupService } from './startup.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  crmBaseUrl: any;

  constructor(private http: HttpClient, private startUp: StartupService) {
    this.crmBaseUrl = this.startUp.startupData.crmApiUrl;

  }

  /**
   * Returns token from the local storage
   * if not found any token call the API to obtain the token and set to storage and return it.
   * @returns 
   */
  getToken(): Observable<string> {

    const storedToken = localStorage.getItem(SESSION_AUTH_TOKEN);

    if (storedToken) {
      // Token found in local storage, return it as an Observable
      return of(storedToken);
    } else {
      // Token not found, make an API request to fetch it
      let data = { user_name: environment.crmUser, password: environment.crmPassword, apikey: environment.crmAppKey };
      return this.http.post(this.crmBaseUrl + '/auth', data).pipe(
        map((response) => response),
        catchError((error) => {
          console.error('Error fetching token:', error);
          return of(''); // Return an empty string in case of error
        }),
        switchMap((response: any) => {
          let newToken = '';
          if (response.status == 'SUCCESS') {
            newToken = response.token;
          }
          // Store the new token in local storage
          localStorage.setItem(SESSION_AUTH_TOKEN, newToken);

          // Return the new token as an Observable
          return of(newToken);
        })
      );
    }
  }
}
