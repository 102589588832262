import { Component, OnInit, Input, HostBinding, HostListener, ElementRef, Renderer, Inject, ViewEncapsulation } from '@angular/core';
import { MenuItem, MenuService } from '../../services/menu.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import {
  SESSION_AUTHENTICATED_CUSTOMER,
  SESSION_CUSTOMER_FAVOURITES, SESSION_CART,
  SESSION_SELECTED_SHOP, SESSION_SELECTED_SERVICE,
  SESSION_SELECTED_SHOP_SERVICES, SESSION_ITEM_CATEGORY_LIST,
  SESSION_SELECTED_SUBCATEGORY_ID, SESSION_SALE_ITEM_LIST, SESSION_SYSTEM_SETTINGS,
  COMPANY_INFO, SESSION_SHOP_LIST, SESSION_REGION_LIST, SESSION_AMENITY_LIST, SESSION_FAV_FILTER_BTN
} from '../../../app/app.constants';
import { filter } from 'rxjs/operators';
import { CartService } from '../../../app/cart/cart.service';
import { UtilService } from '../../../app/services/util.service';
import { HdrTitleService } from 'src/fw/services/hdr-title.service';
import { GtmService } from 'src/app/gtm.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css'],
  animations: [
    trigger('visibilityChanged', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class MenuItemComponent implements OnInit {
  @Input() item: MenuItem;
  @HostBinding('class.parent-is-popup')
  @Input() parentIsPopup = true;
  logoutItem: MenuItem;
  loginItem: MenuItem;
  myProfile: MenuItem;
  isActiveRoute = false;
  mouseInItem = false;
  mouseInPopup = false;
  popupLeft = 0;
  popupTop = 34;
  previousUrl: string;
  constructor(public menuService: MenuService,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer,
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    public hdrService: HdrTitleService,
    private activatedRoute: ActivatedRoute, private cartService: CartService,
    private utilService: UtilService,
    private gtmService: GtmService) {

    this.logoutItem = {
      text: 'SIGN OUT',
      icon: 'glyphicon-log-out',
      route: 'logout',
      submenu: null,
      isVisibile: hdrService.showLogin
    };
    this.previousUrl = this.router.url;
    // this.previousUrl = this.router.url.split('?')[0];

    this.loginItem = {
      text: 'SIGN IN',
      icon: 'glyphicon-user',
      route: 'login',
      redirectUrl: 'order',
      submenu: null,
      isVisibile: hdrService.showLogin
    };
  }
  checkActiveRoute(route: string) {
    this.isActiveRoute = (route === '/' + this.item.route);
  }

  ngOnInit() {

    this.checkActiveRoute(this.router.url);

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.checkActiveRoute(event.url);
        }
      });
    if (this.item.route === 'login') {
      this.item.redirectUrl = this.previousUrl;
    }
    if (this.item.route === 'login' && this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) !== null &&
      this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) !== undefined && this.storage.get(SESSION_AUTHENTICATED_CUSTOMER).customerType !== 2) {
      this.item = this.logoutItem;
      this.item.text = 'SIGN OUT (' + this.storage.get(SESSION_AUTHENTICATED_CUSTOMER).firstName + ')';
    }
  }
  @HostListener('click', ['$event'])
  onClick(event): void {

    event.stopPropagation();

    if (this.item.submenu) {
      if (this.menuService.isVertical) {
        this.mouseInPopup = !this.mouseInPopup;
      }
    } else if (this.item.route) {
      // force horizontal menus to close by sending a mouseleave event
      const newEvent = new MouseEvent('mouseleave', { bubbles: true });
      this.renderer.invokeElementMethod(
        this.el.nativeElement, 'dispatchEvent', [newEvent]);
      if (this.item.route !== 'login') {
        this.router.navigate(['/' + this.item.route]);
      }
    }
  }
  onPopupMouseEnter(event): void {
    if (!this.menuService.isVertical) {
      this.mouseInPopup = true;
    }
  }

  onPopupMouseLeave(event): void {
    if (!this.menuService.isVertical) {
      this.mouseInPopup = false;
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event): void {
    if (!this.menuService.isVertical) {
      this.mouseInItem = false;
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (!this.menuService.isVertical) {
      if (this.item.submenu) {
        this.mouseInItem = true;
        if (this.parentIsPopup) {
          this.popupLeft = 160;
          this.popupTop = 0;
        }
      }
    }
  }
  menuItemClk() {
    if (this.item.route === 'my-order') {
      /**
        * GTM Code
        */
      this.gtmService.pushEvent(
        'my_orders',
        {
          userId: this.gtmService.getCustomer(),
        });
    } else if (this.item.route === 'logout') {
      this.menuService.toggleLeftSideMenu();
      this.menuService.isVertical = false;
      this.item = this.loginItem;
      /* *********SAVE ALL NOT COMPLETED ORDERS********  */
      // this.saveallcartdata();
      // this.clearsessiondata();
      // this.router.navigate(['order']);

      // }
    }
    if (this.item.submenu) {
      this.menuService.showingLeftSideMenu = true;
    } else {
      this.menuService.showingLeftSideMenu = false;
    }


  }
  /* Function to save non completed order details*/
  // saveallcartdata() {
  //   this.cartService.saveCart().subscribe(
  //     res => {
  //       const result = res.success;
  //       if (result) {
  //         // this.getOrderConfirmationHtml();

  //       } else {
  //         // this.utilService.openDialog('Operation failed', 'Failed to save order, please try again');
  //         // this.router.navigate(['/cart']);
  //       }
  //     }
  //   );
  // }

  /**** Clear session data on logout *********/
  // clearsessiondata() {
  //   this.storage.remove(SESSION_AUTHENTICATED_CUSTOMER);
  //   this.storage.remove(SESSION_CART);
  //   this.storage.remove(SESSION_CUSTOMER_FAVOURITES);
  //   this.storage.remove(SESSION_SELECTED_SHOP); // new
  //   this.storage.remove(SESSION_SELECTED_SERVICE);
  //   //  this.storage.remove(SESSION_SELECTED_SHOP_SERVICES);
  //   this.storage.remove(SESSION_ITEM_CATEGORY_LIST);
  //   this.storage.remove(SESSION_SALE_ITEM_LIST);
  //   this.storage.remove(SESSION_SYSTEM_SETTINGS);
  //   this.storage.remove(SESSION_SELECTED_SUBCATEGORY_ID);
  //   this.storage.remove(COMPANY_INFO);
  //   this.storage.remove(SESSION_SHOP_LIST);
  //   this.storage.remove(SESSION_REGION_LIST);
  //   this.storage.remove(SESSION_AMENITY_LIST);
  //   this.storage.remove(SESSION_FAV_FILTER_BTN);
  // }
}
