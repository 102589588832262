import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { StartupService } from 'src/app/services/startup.service';

@Component({
  selector: 'app-reward-detail-panel',
  templateUrl: './reward-detail-panel.component.html',
  styleUrls: ['./reward-detail-panel.component.css']
})
export class RewardDetailPanelComponent implements OnInit {

  @Input() reward:any;
  assetsUrl: any;

  constructor(private startUp: StartupService,private dialog: MatDialog) { 
    this.assetsUrl = this.startUp.startupData.crmAssetUrl;
  }

  ngOnInit() {
  }

}
