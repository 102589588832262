import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Alert } from 'selenium-webdriver';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import {
  SESSION_SHOP_LIST, SESSION_AMENITY_LIST, SESSION_CUSTOMER_FAVOURITES,
  SESSION_AUTHENTICATED_CUSTOMER, SHOP_IMAGE_FOLDER, SESSION_CART, SESSION_DEPARTMENT_LIST, SESSION_SELECTED_SHOP, WEEK_DAYS
} from '../app.constants';
import { HdrTitleService, TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { UtilService } from '../services/util.service';
import { FavouriteShop } from '../shop-detail/shop-detail';
import { ShopDetailService } from '../shop-detail/shop-detail.service';
import { StoreSelectionService } from '../../fw/services/store-selection.service';
import { StoreFinderService } from '../store-finder/store-finder.service';
import { CartService } from '../cart/cart.service';
import { storeFinderMenuItems } from '../store-finder/store-finder.menu';
import { LightboxModule } from 'primeng/lightbox';
// import { } from '@types/googlemaps';
import { StartupService } from '../services/startup.service';
declare var google: any;
import { COMPANY_INFO } from '../app.constants';
import { initialMenuItems } from '../app.menu';
import { DateTimeServiceService } from '../services/date-time-service.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { DateSelectionService } from '../services/date-selection.service';
import { TimeService } from '../services/time-service.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-shop-info',
  templateUrl: './shop-info.component.html',
  styleUrls: ['./shop-info.component.css'],
  providers: [ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class ShopInfoComponent implements OnInit {
  weekDays: { day: number; weekDay: string; }[];
  shopDepartmentList: any;
  departmentList: any;
  shopWorkingInfo: any;
  shopWorkingHours: any;
  uploadUrl: string;
  shopImages: any[] = [];
  workinghours: any[];
  zoom: number;
  radius: number;
  currentLatitude: number;
  currentLongitude: number;
  direction: number;
  isFavourite: boolean;
  favouriteShopList: any[];
  amenityList: any[];
  currentShop: any;
  shopList: any[];
  sessionCustomer: any;
  currentShopId: any;
  shopAmenityList: any[];
  favouriteShop: FavouriteShop;
  selectedShop: any;
  selectedService: any;
  public dir = undefined;
  redirectUrl: any;
  circleRad: any;
  shopHoursMessage: string;
  shopHourViewOption = 'daily';
  previousInfoWindow: any;
  showAllShops: boolean;
  otherShopControlImage: string;
  mapInitialized: boolean;
  showShopHoursViewOption: any;
  shopDailyWorkHours: any[];
  constructor(private route: ActivatedRoute, private startUp: StartupService, private dateTimeServiceService: DateTimeServiceService,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService,
    private utilService: UtilService, private _shopDetailService: ShopDetailService, private storeSelectionService: StoreSelectionService,
    private hdrService: HdrTitleService, private dateSelectionService: DateSelectionService,
    private _storeFinderService: StoreFinderService, private cartService: CartService,
    private confirmationService: ConfirmationService, private router: Router,
    private timeService: TimeService,
    private datePipe: DatePipe
  ) {
    this.shopHourViewOption=startUp.startupData.defaultShopHoursViewOption;
    this.showShopHoursViewOption=this.startUp.startupData.showShopHoursViewOption;
    this.mapInitialized = false;
    this.weekDays = WEEK_DAYS;
    this.showAllShops = true;
    this.currentShopId = +this.route.snapshot.queryParams['id'];
    this.shopList = this.sessionStorage.get(SESSION_SHOP_LIST);
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.redirectUrl = this.route.snapshot.queryParams['redirectUrl'];
    const index = this.shopList.findIndex(
      shopObj => shopObj.id === this.currentShopId);
    this.currentShop = this.shopList[index];
    this.shopDailyWorkHours=this.currentShop.shopDailyWorkingHours;
    this.setShopHourMessage();
    
    if (this.currentShop.logoSmall !== undefined && this.currentShop.logoSmall !== '' && this.currentShop.logoSmall !== null) {
      this.shopImages.push(
        {
          source: this.uploadUrl + SHOP_IMAGE_FOLDER + this.currentShop.logoSmall,
          thumbnail: this.uploadUrl + SHOP_IMAGE_FOLDER + this.currentShop.logoSmall, title: ''
        });
    }
    if (this.currentShop.shopWorkingHours.length > 0) {
      this.shopWorkingInfoChecking();
      this.workinghours = this.currentShop.shopWorkingHours;

    }

    this.initTitleBarParams();
    // this.utilService.setMenuItems(storeFinderMenuItems);
    this.utilService.setMenuItems(initialMenuItems);
    
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.currentLongitude = +pos.coords.longitude;
        this.currentLatitude = +pos.coords.latitude;
        this.radius = 10;
        this.setSelectedShop(this.currentShop);
      }
      );
    }
    const shadesEl = document.querySelector('.body-style');
    shadesEl.classList.remove('storefinder-scroll');
  }

  setShopHourMessage(){

    // var curDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd').toString();
    this.shopHoursMessage="";
    // if(curDate<='2020-12-25'){
    //   this.shopHoursMessage= "We are closed on Christmas. Trading hours may vary during the festive holidays. For more enquiries, please call your local store.";
    // }else if(curDate<='2021-01-05'){
    //   this.shopHoursMessage= "Trading hours may vary during the festive holidays. For more enquiries, please call your local store.";
    // }


  }

  getDepartment(): any {

    return this.departmentList.filter(e => this.currentShop.department.find(cd => cd === e.id && e.isCommon === 0));
  }

  getDepartmentDisplayTime(deptId, weekDay) {

    let displayTime = 'Closed';
    const workingHours = this.workinghours.find(wh => wh.weekDay === weekDay);
    if (workingHours !== undefined) {
      const deptHour = workingHours.deptHourList.find(dh => dh.departmentId === deptId);
      if (deptHour !== undefined) {
        const dt = new Date();
        const tz = this.dateTimeServiceService.timezoneOffset();
        const currntTimeStr = dt.getHours() + ':' + dt.getMinutes();
        const day = dt.getDay();
        // displayTime = ((new Date('1970-01-01T' + deptHour.openTime + tz) <= new Date('1970-01-01T' + currntTimeStr + tz))
        //   && (new Date('1970-01-01T' + currntTimeStr + tz) <= new Date('1970-01-01T' + deptHour.closeTime + tz))) ?
        //   deptHour.displayTime :
        //   weekDay === day ? 'Closed' : deptHour.displayTime;
        displayTime = deptHour.displayTime;
      }
    }
    return displayTime;
  }
  /**
   * Return the display time fro selected department.
   * @param deptId 
   * @param dailyHours 
   */
  getDailyDepartmentItem(deptId, dailyHours): String{
    var selectedDept=dailyHours.deparmentHours.filter(dept=> dept.departmentId==deptId);
    var selectedDeptTime=selectedDept[0].displayHours;
    return selectedDeptTime;
  }

  ngOnInit() {
    // if (this.cartService.cart.shop !== undefined) {
    //   this.selectedShop = this.cartService.cart.shop;
    // }
    this.selectedService = this.cartService.cart.service;
    this.zoom = 10;
    this.amenityList = this.sessionStorage.get(SESSION_AMENITY_LIST);
    this.departmentList = this.sessionStorage.get(SESSION_DEPARTMENT_LIST);

    // this.departmentList = this.sessionStorage.get(SESSION_DEPARTMENT_LIST).filter(e => e.isCommon === 0);
    this.direction = +this.route.snapshot.queryParams['direction'];
    this.sessionCustomer = this.sessionStorage.get(SESSION_AUTHENTICATED_CUSTOMER);
    this.shopAmenityList = this._shopDetailService.getShopAmenities(this.currentShop, this.amenityList);
    this.shopDepartmentList = this._shopDetailService.getShopDepartments(this.currentShop, this.departmentList);
    this.favouriteShopList = this.sessionStorage.get(SESSION_CUSTOMER_FAVOURITES);
    if (this.favouriteShopList !== null) {
      const favouriteId = this._shopDetailService.getFavouriteShopId(this.favouriteShopList, this.currentShop);
      this.isFavourite = (favouriteId.length === 1);
    }
    // this.storeSelectionService.CurrentShopInfo = this.currentShop;
    this.initTitleBarParams();
    this.circleRad = this.storeSelectionService.filterParams.distance * 1000;
  }

  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = !(this.redirectUrl !== undefined && this.redirectUrl.length > 0);
    this.hdrService.title = 'SHOP DETAILS';
    this.hdrService.showLogo = false;
    this.hdrService.showShoppingCart = true;
    const titleConfig: TitleConfig = {
      title: 'SHOP DETAILS',
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: false,
      hideBackButton: false

    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showGuestUserBtn: false,
      showShopListBar: false,
      showShopInfoBar: true,
      showShopFilterBar: false,
      showitemDetailAddCartBar: false,
      showUpdateCartItemBar: false,
      showPaymentSucessBottomBar: false,
      showOrderDetailsBar: false,
      showItemListBar: false,
      showCartBar: false,
      showSignUpBar: false,
      orderConfirmationBar: false,
      showGuestBar: false,
      showChangePasswordBar: false,
      showMyProfileBar: false,
      showFaqBar: false,
      showSubmitContactUs: false,
      showRetryPaymentBar: false


    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }
  /**
   * From here also the shop is either added to the favourite shop list
   * or remove from the favourite shop list
   */
  favouriteShopClick() {
    if (this.utilService.checkValidCustomer('Favourite Shop', 'Please login to use this function')) {
      this.isFavourite = !this.isFavourite;
      this.favouriteShop = {
        id: 0, customerId: this.sessionCustomer.id, shopId: this.currentShop.id,
        isFav: (this.isFavourite) ? 1 : 0
      };

      this._shopDetailService.setFavouriteShop(this.favouriteShop).subscribe(
        res => {
          if (this.isFavourite) {
            this.favouriteShopList.push(this.currentShop.id);
          } else {
            const index = this.favouriteShopList.findIndex(e => e === this.currentShop.id);
            this.favouriteShopList.splice(index, 1);
          }
          this.sessionStorage.set(SESSION_CUSTOMER_FAVOURITES, this.favouriteShopList);
        }, err => {
        }
      );
    }
  }

  shopSelection() {
    if (this.cartService.cart.shop !== undefined && this.cartService.cart.shop.id !== this.currentShopId &&
      this.cartService.getTotalQuantity() !== 0) {
      this.confirmationService.confirm({
        message: 'Changing your pickup store will empty your order. Do you want to proceed?',
        accept: () => {
          this.cartService.cart.shop = undefined;
          this.cartService.cart.items = [];
          this.cartService.cart.deliveryDate = undefined;
          this.cartService.cart.deliveryTime = undefined;
          this.cartService.cart.remarks = undefined;
          this.cartService.cart.pickupLocationId = undefined;
          this.cartService.setToSession();
          this.setShop();
        }

      });
    } else {
      this.setShop();
    }

  }

  setShop() {

    this.storeSelectionService.selectedShop = this.currentShop; //this.selectedShop;
    if (this.storeSelectionService.saveSelectedShop()) {
      if (this.utilService.getStorageValuesByKey(SESSION_SELECTED_SHOP) !== null &&
        this.utilService.getStorageValuesByKey(SESSION_SELECTED_SHOP) !== undefined) {
        this.router.navigate(['/datetime-selection']);
      }
    }
  }
  /**
   *
   * @param shop hide the opened info window
   * @param infoWindow
   */
  markerClicked(shop, infoWindow) {

    if (this.previousInfoWindow) {
      this.previousInfoWindow.close();
    }
    this.previousInfoWindow = infoWindow;
  }
  /**
   * @param shop
   *When click on the location marker ,getting shop distance from the current location
   */
  setSelectedShop(shop) {
    const selectedShop = shop;
    this.dir = {
      destination: { lat: selectedShop.location.latitude, lng: selectedShop.location.longitude },
      origin: { lat: this.currentLatitude, lng: this.currentLongitude }
    };
  }
  /**
   *@param shop
   *getting shop distance from current location to the selected shop
   */
  getShopDistance(shop): any {
    const latFrom = this.currentLatitude;
    const latTo = shop.location.latitude;
    const lonFrom = this.currentLongitude;
    const lonTo = shop.location.longitude;
    const radius = 6371;
    const latitudeDistance = this.deg2rad(latFrom - latTo);
    const longitudeDistance = this.deg2rad(lonFrom - lonTo);
    const a = Math.sin(latitudeDistance / 2) * Math.sin(latitudeDistance / 2) + Math.cos(this.deg2rad(latTo)) *
      Math.cos(this.deg2rad(latFrom)) *
      Math.sin(longitudeDistance / 2) * Math.sin(longitudeDistance / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = (radius * c);
    return distance;
  }
  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  showAllShopsClicked() {
    this.showAllShops = !this.showAllShops;
  }

  mapReady() {
    this.mapInitialized = true;
  }
/**
 * Returns the url for marker based on favorite shop
 * @param shop
 */
  getMarkerIcon(shop: any): string {

    return ( shop.code === this.currentShop.code ) ?
              'assets/images/icons/active-shop-marker.png' :
              ((this.isFavoriteShop(shop) ? 'assets/images/icons/fav-shop-marker.png' : 'assets/images/icons/oth-shop-marker.png'));
  }

  /**
   * Check the shop is favorite
   * @param shop
   */
  isFavoriteShop(shop: any) {

    let isFavourite = false;
    if (this.favouriteShopList !== null) {
      const favouriteId = this._shopDetailService.getFavouriteShopId(this.favouriteShopList, shop);
      isFavourite = (favouriteId.length === 1);
    }
    return isFavourite;
  }
  shopWorkingInfoChecking() {
    // const searchParam = { 'id': this.currentShop.id, 'from': this._shopDetailService.formatDate(new Date()),
    // 'to': this._shopDetailService.formatDate(new Date()) };
    // this.dateSelectionService.getShopHoliday(searchParam).subscribe(
    //   res => {
    //     const shopHoliday = res.object;
    //     // this.shopWorkingInfo = this._shopDetailService.getShopHours(this.currentShop, shopHoliday,
    //     //   new Date(res.serverTime));
    //     this.shopWorkingInfo = this._shopDetailService.getShopHours(this.currentShop,
    //       new Date(this.timeService.serverTime));
    //   });

    this.shopWorkingInfo = this._shopDetailService.getShopHours(this.currentShop,
      new Date(this.timeService.serverTime));
}

hourViewOptionSelected(option){
  this.shopHourViewOption=option;
}
}
