import { Component, Inject, OnInit } from '@angular/core';
import { FrameworkConfigService, FrameworkConfigSettings } from '../fw/services/framework-config.service';
import { MenuService } from '../fw/services/menu.service';
import { initialMenuItems } from './app.menu';
import { TitleConfig } from '../fw/services/hdr-title.service';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { AppDataService } from './services/app-data.service';
import { UtilService } from './services/util.service';
import { Router, NavigationStart } from '@angular/router';
import { SESSION_ITEM_CATEGORY_LIST, SESSION_SALE_ITEM_LIST, SESSION_SHOP_LIST, SESSION_SHOP_LIST_CACHE_TIME, SESSION_START_TIME } from './app.constants';
import { interval } from 'rxjs';
import { TimeService } from './services/time-service.service';
import { CrmApiService } from './services/crm-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isTimerRunning: any;
  constructor(private frameworkConfigService: FrameworkConfigService,
    private menuService: MenuService, private utilService: UtilService,
    private timeService: TimeService,
    private crmApiService:CrmApiService,
    @Inject(SESSION_STORAGE) private storage: WebStorageService, private router: Router
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = function(){
    //   return false;
    // }
    const config: FrameworkConfigSettings = {
      socialIcons: [
        { imageFile: 'assets/social-fb-bw.png', alt: 'Facebook', link: 'http://www.facebook.com' },
        { imageFile: 'assets/social-google-bw.png', alt: 'Google +', link: 'http://www.google.com' },
        { imageFile: 'assets/social-twitter-bw.png', alt: 'Twitter', link: 'http://www.twitter.com' }
      ],
      showLanguageSelector: true,
      showUserControls: true,
      showStatusBar: true,
      showStatusBarBreakpoint: 800

    };
    this.isTimerRunning = false;
    const titleConfig: TitleConfig = {
      showLogo: true,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: true
    };

    frameworkConfigService.configure(config);
    menuService.items = initialMenuItems;
    utilService.setTitleBarParams(this, titleConfig);
    utilService.fwConfigService.showFooterBar = true;

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!this.utilService.validateSession()) {
          const timeExpiredUrls = ['/cart', '/datetime-selection', '/category-list', '/item-list'];
          const isExpired = timeExpiredUrls.findIndex(element => (event.url.indexOf(element) === 0));
          if (isExpired > -1) {
            this.utilService.openDialog('Time Expired.', 'The session time has expired. ');

            this.storage.remove(SESSION_ITEM_CATEGORY_LIST);
            this.storage.remove(SESSION_SALE_ITEM_LIST);
            this.storage.remove(SESSION_SHOP_LIST);
            this.storage.remove(SESSION_START_TIME);
            this.storage.remove(SESSION_SHOP_LIST_CACHE_TIME);
            this.router.navigate(['/']);
            return;
          }
        }
        this.timeService.startTimeService();
        //  if( !this.isTimerRunning) {
        //   this.getServerTime();
        //  }
      }
    });
  }

}
