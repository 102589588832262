import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { PRODUCT_IMAGE_FOLDER, SESSION_OUTOFSTOCK_ITEM_LIST } from '../app.constants';
import { CartItem, CartService } from '../cart/cart.service';
import { AnimationService } from '../services/animation.service';
import { StartupService } from '../services/startup.service';
import { TaxService } from '../services/tax.service';
import { SettingsService } from '../settings/settings.service';

@Component({
  selector: 'app-promo-panel',
  templateUrl: './promo-panel.component.html',
  styleUrls: ['./promo-panel.component.css']
})
export class PromoPanelComponent implements OnInit {

  @Input() promoItem: any;
  systemSettings: any;
  currencySymbol: any;
  quantity: number = 1;
  outOfStockItemIds: any;
  imageUrl: any;
  uploadUrl: any;

  constructor(private startUp: StartupService,private _settingsService: SettingsService,public cartService: CartService,
    private cartAnimServce: AnimationService, public taxService: TaxService, private router: Router,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService) { 
    this.systemSettings = this._settingsService.systemSettings;
    if (this.systemSettings !== null) {
      this.currencySymbol = this.systemSettings.currency.symbol;
    }
    this.outOfStockItemIds = this.sessionStorage.get(SESSION_OUTOFSTOCK_ITEM_LIST);
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.imageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;
  }

  ngOnInit() {
  }
  getItemTotalPrice(promoItem) {
    this.quantity =1;
    const total = this.quantity * promoItem.fixedPrice;
    return total;
  }

  itemClick():void{
    this.router.navigate(['/item-details'], { queryParams: { 'source': '', 'id': this.promoItem.saleItemId } });
  }

  addPromoItemToCart(promoItem) {
    const imageclass = 'item-img_' + promoItem.id;
    // this.cartAnimServce.addToCartAnimation(imageclass, 'span-hamburger-icon');
    this.cartAnimServce.scalAnim('cart-update-anim');
    let item: CartItem;
    item = {
      itemId: 0,
      ooSaleItemId: promoItem.id, saleItemId: promoItem.saleItemId, quantity: this.quantity,
      fixedPrice: promoItem.fixedPrice, totalPrice: promoItem.fixedPrice * this.quantity,
      unitPriceWithTax: promoItem.unitPriceWithTax,customizationUnitPriceWithTax:0,
      isCustomized: false, customization: [], remarks: '',
      taxCalculationMethod: promoItem.taxCalculationMethod, tax1Percentage: promoItem.tax1Percentage,
      netTotal: 0, totalTax: 0,netCustomizationTotal:0
    };
    this.taxService.reCalculateTax(item);
    this.cartService.addItem(item);
    this.cartService.getValidPromotionalItemData()
  }


}
