import { Component, Input, OnInit } from '@angular/core';
import { PRODUCT_IMAGE_FOLDER } from '../app.constants';
import { StartupService } from '../services/startup.service';
import { SettingsService } from '../settings/settings.service';

@Component({
  selector: 'app-reorder-display',
  templateUrl: './reorder-display.component.html',
  styleUrls: ['./reorder-display.component.css']
})
export class ReorderDisplayComponent implements OnInit {

  @Input() recentOrder: any;
  uploadUrl: any;
  orderAgainImageUrl: string;
  systemSettings: any;
  constructor(private startUp: StartupService,
    private _settingsService: SettingsService) {
    this.uploadUrl = this.startUp.startupData.uploadurl;
    this.orderAgainImageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;
    this.systemSettings = this._settingsService.systemSettings;

   }

  ngOnInit() {
  }

}
