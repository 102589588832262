import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { FrameworkBodyComponent } from './framework-body/framework-body.component';
import { ContentComponent } from './content/content.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { ScreenLarge } from './directives/screen-large.directive';
import { ScreenBelowLarge } from './directives/screen-below-large.directive';
import { MenuComponent } from './menus/menu/menu.component';
import { MenuItemComponent } from './menus/menu-item/menu-item.component';
import { PopupMenuComponent } from './menus/popup-menu/popup-menu.component';
import { PanelComponent } from './panels/panel/panel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectShopBottomNavComponent } from './footer-bars/select-shop-bottom-nav/select-shop-bottom-nav.component';
import { ShopInfoBottomNavComponent } from './footer-bars/shop-info-bottom-nav/shop-info-bottom-nav.component';
import { ShopFilterBottomNavComponent } from './footer-bars/shop-filter-bottom-nav/shop-filter-bottom-nav.component';
import { DashboardBottomNavComponent } from './footer-bars/dashboard-bottom-nav/dashboard-bottom-nav.component';
import { CartBottomNavComponent } from './footer-bars/cart-bottom-nav/cart-bottom-nav.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    MatProgressBarModule,
    ConfirmDialogModule
  ],
  declarations: [
    FrameworkBodyComponent,
    ContentComponent,
    TitleBarComponent,
    TopBarComponent,
    StatusBarComponent,
    ScreenLarge,
    ScreenBelowLarge,
    MenuComponent,
    MenuItemComponent,
    PopupMenuComponent,
    PanelComponent,
    SelectShopBottomNavComponent,
    ShopInfoBottomNavComponent,
    ShopFilterBottomNavComponent,
    DashboardBottomNavComponent,
    CartBottomNavComponent



  ],
  exports: [
    FrameworkBodyComponent,
    PanelComponent,
    ScreenLarge,
    ScreenBelowLarge
  ]
})
export class FwModule { }
