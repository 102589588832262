import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { animate, style, AnimationBuilder } from '@angular/animations';

@Component({
  selector: 'app-item-images',
  templateUrl: './item-images.component.html',
  styleUrls: ['./item-images.component.css']
})

export class ItemImagesComponent implements OnInit, OnDestroy {

  imgUrl: any[];
  timerId: any;
  @Input() itemImages: any;
  @Input() timeInterval: any;
  currentIndex = 0;
  constructor(private builder: AnimationBuilder) {
  }

  ngOnInit() {
    if (this.itemImages !== null && this.itemImages.length > 0) {
      this.imgUrl = this.itemImages[0].source;
      if (this.itemImages.length > 1) {
        this.timerId = setInterval(() => {
          this.setImage();
        }, this.timeInterval);
      }
    }
  }

  ngOnDestroy() {
    clearInterval(this.timerId);
  }

  setImage() {
      this.fadeOutAnimation();
  }


  fadeOutAnimation() {
    const imageElement = document.getElementById('oo-gallery-img');
    const itemAnimFactory = this.builder.build([
      animate('.2s 0.0s ease-in-out', style({
        opacity: '0'
      })),
    ]);
    const player = itemAnimFactory.create(imageElement);
    player.play();
    player.onDone(() => {
      this.currentIndex++;
      if (this.currentIndex >= this.itemImages.length) {
        this.currentIndex = 0;
      }
      this.imgUrl = this.itemImages[this.currentIndex].source;
      this.fadeInAnimation();
    });
  }
  fadeInAnimation() {
    const imageElement = document.getElementById('oo-gallery-img');
    const itemAnimFactory = this.builder.build([
      animate('.8s 0.0s ease-in-out', style({
        opacity: '1'
      })),
    ]);
    const player = itemAnimFactory.create(imageElement);
    player.play();
  }

}

