import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AppComponent } from './app.component';
import { FwModule } from '../fw/fw.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { appRoutes } from './app.routing';
import { StoreFinderComponent } from './store-finder/store-finder.component';
import { ItemCategoryPanelComponent } from './panels/item-category-panel/item-category-panel.component';
import { ItemCategoryListComponent } from './item-category-list/item-category-list.component';
import { BannerImagePanelComponent } from './panels/banner-image-panel/banner-image-panel.component';
import { MenuImagePanelComponent } from './panels/menu-image-panel/menu-image-panel.component';
import { ProductsMenuComponent } from './products-menu/products-menu.component';
import { StartupService } from './services/startup.service';
import { SlideshowModule } from 'ng-simple-slideshow';
import {DropdownModule} from 'primeng/dropdown';
import {LightboxModule} from 'primeng/lightbox';
import { ShopDetailComponent } from './shop-detail/shop-detail.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { StorageServiceModule } from 'angular-webstorage-service';
import { OrderServicePanelComponent } from './panels/order-service-panel/order-service-panel.component';
import { LoginComponent } from './login/login.component';
import { SettingsComponent } from './settings/settings.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LogoutComponent } from './logout/logout.component';
import { OrderServiceSelectionComponent } from './order-service-selection/order-service-selection.component';
import { CustomerLoginDialogComponent } from './shared/customer-dialog/customer-login-dialog.component';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angular-6-social-login';
import {ToastModule} from 'primeng/toast';
import { MessageService } from 'primeng/components/common/messageservice';
import {GrowlModule} from 'primeng/growl';
import { ShopInfoComponent } from './shop-info/shop-info.component';
import {CardModule} from 'primeng/card';
import { ShopFilterComponent } from './shop-filter/shop-filter.component';
import {CheckboxModule} from 'primeng/checkbox';
import {SliderModule} from 'primeng/slider';
import { ServiceTypeDateSelectionComponent } from './pickup-date-selection/servicetype-date-selection.component';
import { MatNativeDateModule, MatInputModule, MatSelectModule, MatCheckboxModule } from '@angular/material';
import { ItemListComponent } from './item-list/item-list.component';
import { ItemListPanelComponent } from './panels/item-list-panel/item-list-panel.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import {GalleriaModule} from 'primeng/galleria';
import { CartComponent } from './cart/cart.component';
import {PanelModule} from 'primeng/panel';
import {ButtonModule} from 'primeng/button';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { CartPanelComponent } from './cart-panel/cart-panel.component';
import {AccordionModule} from 'primeng/accordion';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { ItemImagesComponent } from './item-images/item-images.component';
import { HorizontalNavBarComponent } from './horizontal-nav-bar/horizontal-nav-bar.component';
import {ListboxModule} from 'primeng/listbox';
import { RegionListFilter } from './store-finder/region-filter.pipe';
import { PaymentInitComponent } from './payment-init/payment-init.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { GuestFormComponent } from './guest-form/guest-form.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { RemarksDialogComponent } from './remarks-dialog/remarks-dialog.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TermsAndPolicyComponent } from './terms-and-policy/terms-and-policy.component';
import {CalendarModule} from 'primeng/calendar';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { AllItemListComponent } from './all-item-list/all-item-list.component';
import { AllItemFilterList } from './all-item-list/all-item-filter.pipe';
import { SearchItemComponent } from './search-item/search-item.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { AboutUsComponent } from './about-us/about-us.component';

import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { OrderInfoComponent } from './order-info/order-info.component';
import { OrderInfoDetailComponent } from './order-info-detail/order-info-detail.component';
import { ViewReceiptComponent } from './view-receipt/view-receipt.component';
import { ReorderDisplayComponent } from './reorder-display/reorder-display.component';
import { PromoPanelComponent } from './promo-panel/promo-panel.component';
import { environment } from 'src/environments/environment';
import { GtmService } from './gtm.service';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { RewardNotificationComponent } from './notification-bar/components/reward-notification/reward-notification.component';
import { MyrewardsComponent } from './myrewards/myrewards.component';
import { RewardPanelComponent } from './myrewards/components/reward-panel/reward-panel.component';
import { RewardDialogComponent } from './myrewards/dialogs/reward-dialog/reward-dialog.component';
import { RewardListComponent } from './myrewards/components/reward-list/reward-list.component';
import { RewardDetailDialogComponent } from './myrewards/dialogs/reward-detail-dialog/reward-detail-dialog.component';
import { RewardDetailPanelComponent } from './myrewards/components/reward-detail-panel/reward-detail-panel.component';

export function startupServiceFactory(startupService: StartupService): Function {
  return () => startupService.load();
}

export function getAuthServiceConfigs() {
  const config = new AuthServiceConfig(
      [
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider(environment.fbAuthKey)
        },
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(environment.googleAuthKey)
        }
      ]
  );
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    StoreFinderComponent,
    ItemCategoryListComponent,
    ItemCategoryPanelComponent,
    BannerImagePanelComponent,
    MenuImagePanelComponent,
    ProductsMenuComponent,
    ShopDetailComponent,
    OrderServiceSelectionComponent,
    OrderServicePanelComponent,
    LoginComponent,
    SettingsComponent,
    ErrorPageComponent,
    LogoutComponent,
    CustomerLoginDialogComponent,
    ShopInfoComponent,
    ShopFilterComponent,
    ServiceTypeDateSelectionComponent,
    ItemListComponent,
    ItemListPanelComponent,
    ItemDetailsComponent,
    CartComponent,
    CartPanelComponent,
    ItemImagesComponent,
    HorizontalNavBarComponent,
    RegionListFilter,
    AllItemFilterList,
    PaymentInitComponent,
    PaymentSuccessComponent,
    PaymentFailComponent,
    SignUpComponent,
    OrderConfirmationComponent,
    GuestFormComponent,
    MyProfileComponent,
    ChangePasswordComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    FaqComponent,
    RemarksDialogComponent,
    ContactUsComponent,
    TermsAndPolicyComponent,
    ConfirmationDialogComponent,
    AllItemListComponent,
    SearchItemComponent,
    ActivateAccountComponent,
    AboutUsComponent,
    OrderInfoComponent,
    OrderInfoDetailComponent,
    OrderHistoryComponent,
    ViewReceiptComponent,
    ReorderDisplayComponent,
    PromoPanelComponent,
    NotificationBarComponent,
    MyrewardsComponent,
    RewardNotificationComponent,
    RewardPanelComponent,
    RewardDialogComponent,
    RewardListComponent,
    RewardDetailDialogComponent,
    RewardDetailPanelComponent,
  
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule, DropdownModule, GalleriaModule, PanelModule, ButtonModule, ListboxModule,
    LightboxModule, StorageServiceModule, MatButtonModule, MatIconModule, CheckboxModule, AutoCompleteModule, CalendarModule,
    FormsModule, ReactiveFormsModule, HttpClientModule, StorageServiceModule, CardModule, SliderModule, ProgressSpinnerModule,
    FwModule, MatCardModule, AccordionModule, MatRadioModule, InputTextareaModule, ConfirmDialogModule,
    NgbModule.forRoot(),
    AgmDirectionModule,
    RouterModule.forRoot(appRoutes),
    AgmCoreModule.forRoot({
      apiKey: environment.gmapKey,
      libraries: ['geometry']
    }),
    SlideshowModule,
    MatProgressBarModule,
    MatDialogModule,
    FlexLayoutModule,
    SocialLoginModule,
    ToastModule,
    GrowlModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MaterialModule,
    MatCheckboxModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    {
    // Provider for APP_INITIALIZER
    provide: APP_INITIALIZER,
    useFactory: startupServiceFactory,
    deps: [StartupService],
    multi: true
  },
  // {
  //   // Provider for APP_INITIALIZER
  //   provide: APP_INITIALIZER,
  //   useFactory: autoLoginServiceFactory,
  //   deps: [StartupService,LoginService],
  //   multi: true
  // },
  {
    provide: AuthServiceConfig,
    useFactory: getAuthServiceConfigs
  },
  MessageService,
  GtmService
  ],
  entryComponents: [CustomerLoginDialogComponent, RemarksDialogComponent, ConfirmationDialogComponent,RewardDialogComponent, RewardDetailDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {

  
 }
