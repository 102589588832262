import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { HdrTitleService, TitleConfig } from '../../fw/services/hdr-title.service';
import { UtilService } from '../services/util.service';
import { FooterConfig, StatusBarService } from '../../fw/services/status-bar.service';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { SESSION_AMENITY_LIST, SESSION_ITEM_CATEGORY_LIST, SESSION_CART } from '../app.constants';
import { findIndex } from 'rxjs/operators';
import { StoreSelectionService } from '../../fw/services/store-selection.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shop-filter',
  templateUrl: './shop-filter.component.html',
  styleUrls: ['./shop-filter.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShopFilterComponent implements OnInit {
  itemCategoryArray: any[];
  amenityList: any[];
  itemCategoryList: any[];
  amenityModel: any[] = [];
  itemModel: any[] = [];
  distanceSlider: any;
  selectAllItemCategoryModel: boolean;
  selectAllAmenityModel: boolean;
  constructor(private hdrService: HdrTitleService, private utilService: UtilService,
    @Inject(SESSION_STORAGE) private sessionStorage: WebStorageService,
    private storeSelectionService: StoreSelectionService, private router: Router,
    private statusBarService: StatusBarService) {
    this.initTitleBarParams();
  }

  ngOnInit() {
    this.statusBarService.parent = this;
    this.distanceSlider = 5;
    this.selectAllAmenityModel = false;
    this.selectAllItemCategoryModel = false;
    this.amenityList = this.sessionStorage.get(SESSION_AMENITY_LIST);
    this.amenityList.forEach(e => this.amenityModel.push(false));
    this.itemCategoryList = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST);
    this.itemCategoryArray = this.itemCategoryList.filter(e => e.parentId !== 0);
    this.itemCategoryArray.forEach(e => this.itemModel.push(false));
    this.getFilterConditions();
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = true;
    this.hdrService.title = 'SHOP FILTER';
    this.hdrService.showLogo = false;
    this.hdrService.showShoppingCart = true;
    const titleConfig: TitleConfig = {
      title: 'SHOP FILTER',
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showGuestUserBtn: false,
      showShopListBar: false,
      showShopInfoBar: false,
      showShopFilterBar: true,
      showitemDetailAddCartBar: false,
      showUpdateCartItemBar: false,
      showPaymentSucessBottomBar: false,
      showOrderDetailsBar: false,
      showItemListBar: false,
      showCartBar: false,
      showSignUpBar: false,
      orderConfirmationBar: false,
      showGuestBar: false,
      showChangePasswordBar: false,
      showMyProfileBar: false,
      showFaqBar: false,
      showSubmitContactUs: false,
      showRetryPaymentBar: false


    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }
/**
 * Once filtering conditions(amenity,item category and distance) are applied,
 * these selected conditions should be reloaded at the time of again selecting the page
 */
  getFilterConditions() {
    if (this.storeSelectionService.filterParams.apply === true) {
      for (let i = 0; i < this.amenityList.length; i++) {
        if (this.storeSelectionService.filterParams.amenities.findIndex(obj => obj === this.amenityList[i].id) >= 0) {
          this.amenityModel[i] = true;
        }
      }
      this.amenityClicked();
      for (let i = 0; i < this.itemCategoryArray.length; i++) {
        for (let j = 0; j < this.storeSelectionService.filterParams.itemCategory.length; j++) {
          if (this.storeSelectionService.filterParams.itemCategory[j] === this.itemCategoryArray[i].id) {
            this.itemModel[i] = true;
          }
        }
      }
      this.selectAnItemCategory();
      this.distanceSlider = this.storeSelectionService.filterParams.distance;
    }
  }
  /**
   * Select  all amenity for  filtering  shop data
   */
  selectAllAmenityClicked() {
    this.amenityModel.forEach((e, i) => this.amenityModel[i] = this.selectAllAmenityModel);

  }
   /*Filtering based on each selected  amenity */
  amenityClicked() {
    const filteredValues = this.amenityModel.filter(e => e === false);
    this.selectAllAmenityModel = !(filteredValues.length > 0);
  }
   /**
    * Select  all item category for  filtering  shop data
    */
  selectAllItemCategory() {
    this.itemModel.forEach((e, i) => this.itemModel[i] = this.selectAllItemCategoryModel);
  }
   /*Filtering based on each selected  item category */
  selectAnItemCategory() {
    const filteredItemValues = this.itemModel.filter(e => e === false);
    this.selectAllItemCategoryModel = !(filteredItemValues.length > 0);
  }
  /**
   * When clicking the filter button,filtering
   * conditions(amenity,item category and distance ) are added to the filter params
   *
   */
  applyFilter() {
    for (let i = 0; i < this.amenityModel.length; i++) {
      if (this.amenityModel[i] === true) {
        this.storeSelectionService.filterParams.amenities.push(this.amenityList[i].id);
      }
    }

    for (let i = 0; i < this.itemModel.length; i++) {
      if (this.itemModel[i] === true) {
        this.storeSelectionService.filterParams.itemCategory.push(this.itemCategoryArray[i].id);
      }
    }
    this.storeSelectionService.filterParams.distance = this.distanceSlider;
    this.router.navigate(['/storefinder']);
  }
}

