import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ItemCategory } from '../view-models/itemcategory';
import { UtilService } from './util.service';
import { catchError } from 'rxjs/operators';
import { StartupService } from './startup.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  businessUrl: string;
  companyInfoUrl: string;
  imageBannerUrl: string;

  bannerUrl: string;
  constructor(private http: HttpClient, private utilService: UtilService,
              private startUp: StartupService) {
      this.companyInfoUrl = this.startUp.startupData.apiurl + '/company/get-info';
      this.bannerUrl = this.startUp.startupData.apiurl + '/company/get-banners';
      this.businessUrl = this.startUp._startupData.apiurl + '/dashboard/get-info';
  }

  /**Get current company information */
  getCompanyInfo(): Observable<any> {
    return this.http.get<any>(this.companyInfoUrl)
      .pipe(
        catchError(this.utilService.handleError)
      );
  }

  /**Get banner json data*/
  getBannersData():  Observable<any> {
    return this.http.get<any>(this.bannerUrl)
      .pipe(
        catchError(this.utilService.handleError)
      );
  }

  getBusinessTypes(): Observable<any> {
    return this.http.get<any>(this.businessUrl)
    .pipe(
      catchError(this.utilService.handleError)
    );
  }
}
