import { Component, OnInit } from '@angular/core';
import { FrameworkConfigService } from '../services/framework-config.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-framework-body',
  templateUrl: './framework-body.component.html',
  styleUrls: ['./framework-body.component.css']
})
export class FrameworkBodyComponent implements OnInit {

  constructor(public fwConfigService: FrameworkConfigService,public utilService: UtilService) { }

  ngOnInit() {
  }

}
