import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilService } from '../services/util.service';
import { StartupService } from '../services/startup.service';
@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  contactUsMailUrl: string;
  constructor( private http: HttpClient, private utilService: UtilService, private startUp: StartupService) {
    this.contactUsMailUrl = this.startUp.startupData.apiurl + '/contact-us/contact-mail';

   }
   sendContactUsMail(mail: any): Observable<any> {
    return this.http.post<any>(this.contactUsMailUrl, mail)
    .pipe(
      catchError(this.utilService.handleError)
    );

  }
}
