import { Injectable } from '@angular/core';
import { ItemCategory } from '../view-models/itemcategory';
import { Observable } from 'rxjs';
import { StartupService } from './startup.service';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './util.service';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  orderServiceUrl: string;
  constructor(private startUp: StartupService, private http: HttpClient,
              private utilService: UtilService) {
    this.orderServiceUrl = this.startUp.startupData.apiurl + '/company/get-online-services';
  }

  /**Get all order service types */
  getOrderServiceTypes(): Observable<any> {
    return this.http.get<any>(this.orderServiceUrl)
      .pipe(
        catchError(this.utilService.handleError)
      );
  }

}
