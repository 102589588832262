import {Component, Inject, OnChanges} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import { ConfirmationDialogData } from './confirmation-dialog-data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent  {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,  private router: Router) { }

  onYesClick() {
    this.router.navigate(['/logout']);
  }
  onNoClick(noAction) {
    this.router.navigate(['/' + noAction]);
  }
}
