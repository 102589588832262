import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StoreFinderComponent } from './store-finder/store-finder.component';
import { ItemCategoryListComponent } from './item-category-list/item-category-list.component';
import { ProductsMenuComponent } from './products-menu/products-menu.component';
import { OrderServiceSelectionComponent } from './order-service-selection/order-service-selection.component';
import { LoginComponent } from './login/login.component';
import { SettingsComponent } from './settings/settings.component';
import { LoginGuardService } from './services/login-guard.service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LogoutComponent } from './logout/logout.component';
import { ShopInfoComponent } from './shop-info/shop-info.component';
import { ShopFilterComponent } from './shop-filter/shop-filter.component';
import { ServiceTypeDateSelectionComponent } from './pickup-date-selection/servicetype-date-selection.component';
import { ItemListComponent } from './item-list/item-list.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { CartComponent } from './cart/cart.component';
import { PaymentInitComponent } from './payment-init/payment-init.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { GuestFormComponent } from './guest-form/guest-form.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FaqComponent } from './faq/faq.component';
import { TermsAndPolicyComponent } from './terms-and-policy/terms-and-policy.component';
import { AllItemListComponent } from './all-item-list/all-item-list.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { OrderInfoDetailComponent } from './order-info-detail/order-info-detail.component';
import { ViewReceiptComponent } from './view-receipt/view-receipt.component';
import { MyrewardsComponent } from './myrewards/myrewards.component';


export const appRoutes: Routes = [
  { path: '', redirectTo: 'order', pathMatch: 'full' },
  { path: 'order', component: DashboardComponent },
  { path: 'activation', component: ActivateAccountComponent },
  { path: 'menu', component: ProductsMenuComponent },
  { path: 'order-services', component: OrderServiceSelectionComponent },
  { path: 'my-order', component: OrderHistoryComponent },
  { path: 'my-order-detail', component: OrderInfoDetailComponent },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuardService] },
  { path: 'logout', component: LogoutComponent },
  { path: 'my-profile', component: MyProfileComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'terms-and-policies', component: TermsAndPolicyComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'datetime-selection', component: ServiceTypeDateSelectionComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: 'category-list', component: ItemCategoryListComponent },
  { path: 'payment-init', component: PaymentInitComponent },
  { path: 'payment-success', component: PaymentSuccessComponent },
  { path: 'payment-fail', component: PaymentFailComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'guest-form', component: GuestFormComponent },
  { path: 'order-confirmation', component: OrderConfirmationComponent },
  { path: 'storefinder', component: StoreFinderComponent },
  { path: 'shop-details', component: ShopInfoComponent },
  { path: 'shop-filter', component: ShopFilterComponent },
  { path: 'cart', component: CartComponent },
  { path: 'item-list', component: ItemListComponent },
  { path: 'item-details', component: ItemDetailsComponent },
  { path: 'all-item-list', component: AllItemListComponent },
  { path: 'view-receipt', component: ViewReceiptComponent },
  { path: 'my-rewards', component: MyrewardsComponent },
  { path: '', redirectTo: 'order', pathMatch: 'full' },
  { path: '**', redirectTo: 'order', pathMatch: 'full' }

];
