import { Component, OnInit } from '@angular/core';
import { StoreSelectionService } from '../../services/store-selection.service';
import { Router } from '@angular/router';
import { UtilService } from '../../../app/services/util.service';
import { SESSION_SELECTED_SHOP } from '../../../app/app.constants';
import { StatusBarService } from '../../services/status-bar.service';
@Component({
  selector: 'app-shop-info-bottom-nav',
  templateUrl: './shop-info-bottom-nav.component.html',
  styleUrls: ['./shop-info-bottom-nav.component.css']
})
export class ShopInfoBottomNavComponent implements OnInit {

  constructor(private storeSelectionService: StoreSelectionService,
              private router: Router, private utilService: UtilService, public statusBarService: StatusBarService) { }

  ngOnInit() {
  }
  /**
   * From here also we can add this shop to the further use
   */
  shopSelection() {
    this.statusBarService.parent.shopSelection();
    // this.storeSelectionService.saveSelectedShop();
    // if (this.utilService.getStorageValuesByKey(SESSION_SELECTED_SHOP) !== null &&
    // this.utilService.getStorageValuesByKey(SESSION_SELECTED_SHOP) !== undefined) {
    //   this.router.navigate(['/datetime-selection']);
    // }
 }
}
