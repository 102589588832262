import { Component, OnInit } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { FormControl, Validators, FormGroup, AbstractControl, ValidatorFn, FormBuilder } from '@angular/forms';
import { ContactUsService } from './contact-us.service';
import { CartService } from '../cart/cart.service';
import { Router } from '@angular/router';
import { HdrTitleService } from '../../fw/services/hdr-title.service';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
  providers: [ConfirmationService]
})
export class ContactUsComponent implements OnInit {
  mail: { 'from': any; 'content': any; 'name': any; 'phoneNumber': any; };
  selectedCustomer: any;
  // loading: boolean;
  result: any;
  validEmail: boolean;
  message: any;
  email: any;
  name: any;
  phoneNumber: any;
  pwGroup: FormGroup;
  formControl: any;
  captcha: any;
  constructor(private utilService: UtilService,
    private router: Router, private contactUsService: ContactUsService, public hdrService: HdrTitleService, private location: Location,
    private confirmationService: ConfirmationService,
    private cartService: CartService) {
    // this.loading = false;
      if (this.cartService.cart.customer !== undefined && this.cartService.cart.customer !== null) {
      this.selectedCustomer = this.cartService.cart.customer;
      // if (this.selectedCustomer.firstName !== undefined && this.selectedCustomer.lastName !== null
      //   && this.selectedCustomer.lastName !== undefined) {
      //   this.name = this.selectedCustomer.firstName + ' ' +
      // (this.selectedCustomer.lastName != null) ? this.selectedCustomer.lastName : '';
      // } else {
        this.name =
        (((this.selectedCustomer.firstName != null && this.selectedCustomer.firstName !== undefined) ? this.selectedCustomer.firstName : '')
        + ' ' +
          ((this.selectedCustomer.lastName != null && this.selectedCustomer.lastName !== undefined) ? this.selectedCustomer.lastName : '')).trim();
      // }
      this.email = this.selectedCustomer.email;
      this.phoneNumber = this.selectedCustomer.phoneNumber;
    }
    this.initTitleBarParams();
    this.formControl = {
      email: new FormControl('', [Validators.required, Validators.email]),
      name: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(9)]),
      message: new FormControl('', [Validators.required]),
      captcha: new FormControl('', [Validators.required])
    };
  }

  ngOnInit() {
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = true;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'CONTACT US',
      showLogo: false,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showSubmitContactUs: true
    };
    this.utilService.setFooterBarParams(this, footerConfig);

  }
  /**
 * phone number validation
 */
  getPhoneNumberError(): any {
    // alert(this.formControl.phoneNumber);
    // const letters = /^[A-Za-z]+$/;
    // if(letters.test(this.formControl.phoneNumber)) {
    //   alert('true'); }
    let error: any;
    if (this.formControl.phoneNumber.hasError('required')) {
      error = 'Please enter a valid phone number.';
    } else if
      (this.formControl.phoneNumber.errors.maxlength !== undefined &&
      (this.formControl.phoneNumber.errors.maxlength.actualLength) > (this.formControl.phoneNumber.errors.maxlength.requiredLength)) {
      error = 'Phone number with in 13 characters long.';
    } else if (this.formControl.phoneNumber.errors.minlength !== undefined &&
      (this.formControl.phoneNumber.errors.minlength.actualLength) < (this.formControl.phoneNumber.errors.minlength.requiredLength)) {
      error = 'Phone number should be at least 9 characters long.';
    }
    return error;
  }
  /**
 * first name validation
 */
  getNameError(): any {
    let error: any;
    if (this.formControl.name.hasError('required')) {
      error = 'Please enter your name';
    } else if (!this.formControl.name.touched) {
      error = 'Please enter your name';
    }
    return error;
  }

  getMessageError() {
    let error: any;
    if (this.formControl.message.hasError('required')) {
      error = 'Please enter your message';
    } else if (!this.formControl.message.touched) {
      error = 'Please enter your message';
    }
    return error;
  }
  emailValidation(email) {
    this.validEmail = false;
    // tslint:disable-next-line:max-line-length
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validEmailRegEx.test(email)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    return this.validEmail;
  }
  /* Returns email validation errors */
  getEmailError(): any {

    return this.formControl.email.hasError('required') ? 'Please enter a valid email address.' :
      this.formControl.email.hasError('email') ? 'It is not a valid email address.' :
        '';
  }
  contactUsSubmit() {
    const responsez = grecaptcha.getResponse();
    if (responsez !== "") {
    this.mail = { 'from': this.email, 'content': this.message, 'name':  this.name , 'phoneNumber' : this.phoneNumber};

    if (this.mail.content !== null && this.mail.content !== undefined && this.mail.from !== null && this.mail.from !== undefined &&
      this.name !== '' && this.name !== null && this.name !== undefined && this.message !== '' && this.email !== '') {
      if (this.emailValidation(this.email)) {
        if (this.formControl.phoneNumber.errors !== null &&
          ((this.formControl.phoneNumber.errors.minlength.actualLength) <
            (this.formControl.phoneNumber.errors.minlength.requiredLength))) {
          this.utilService.openDialog('Customer Info.', 'Phone number should be at least 9 characters long.');
        } else if (this.phoneNumber <= 0) {
          this.utilService.openDialog('Customer Info. ', 'Enter a valid phone number');
        } else if (this.mail.content === "") {
            this.utilService.openDialog('Customer Info. ', 'Enter a valid message');
          } else {
            this.utilService.progressService.isLoading = true;
          this.contactUsService.sendContactUsMail(this.mail).subscribe(
            res => {
              this.result = res.success;
              this.utilService.progressService.isLoading = false;
              if (this.result) {
                this.confirmationService.confirm({
                  message: 'Thank you for taking the time to contact us. We will be in touch shortly.',
                  accept: () => {
                    this.location.back();
                  }
                });
              } else {
                this.utilService.openDialog('Failed', res.message);
                }
              }
          );
        }
      } else {
        this.utilService.openDialog('Customer Info.', 'Enter valid email address ');
      }
    } else {
      this.utilService.openDialog('Alert!', 'You can not leave this empty');
    }
  } else {
    this.utilService.openDialog('SORRY',
    'You didn\'t checked the captcha correctly. Please select the captcha.');
  }
  }
  resolved(event): void {

  }
}
