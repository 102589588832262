import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SESSION_AUTHENTICATED_CUSTOMER } from '../app.constants';

@Injectable({
    providedIn: 'root'
})
export class LoginGuardService implements CanActivate {

    constructor(private _router: Router,
        @Inject(SESSION_STORAGE) private storage: WebStorageService) { }

    /**Guard login page - Check if the customer is already authenticated and if there is a redirect url
     * and  redirect accordingly.
    */
    canActivate(route: ActivatedRouteSnapshot): boolean {
        const redirectUrl = route.queryParams.redirectUrl;
        // if session valid redirect to storefinder
        if (redirectUrl !== undefined && redirectUrl !== null &&
            this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) !== null &&
            this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) !== undefined &&
             this.storage.get(SESSION_AUTHENTICATED_CUSTOMER).customerType !== 2) {
            this._router.navigate(['/' + redirectUrl]);
        } else if (this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) !== null &&
            this.storage.get(SESSION_AUTHENTICATED_CUSTOMER) !== undefined &&
             this.storage.get(SESSION_AUTHENTICATED_CUSTOMER).customerType !== 2) {
            this._router.navigate(['/storefinder']);
        }
        return true;
    }
}
