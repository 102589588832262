import { Component, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-products-menu',
  templateUrl: './products-menu.component.html',
  styleUrls: ['./products-menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProductsMenuComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
