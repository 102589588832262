import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { OrderHistoryService } from './order-history.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SESSION_AUTHENTICATED_CUSTOMER } from '../app.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {

  result: any;
  OrderHistoryArrayssss: any[];
  itemCount: any;
  orderHistoryArray: any[];
  listCount: number;
  itemPerPage: number;
  offset: number;
  limit: any;
  sessionCustomer: any;
  orderHistory: any;
  constructor(private utilService: UtilService,  @Inject(SESSION_STORAGE) private storage: WebStorageService,
   private orderHistoryService: OrderHistoryService, private router: Router) {
    this.sessionCustomer = this.storage.get(SESSION_AUTHENTICATED_CUSTOMER);
    this.itemPerPage = 10;
    this.listCount = 1;
    this.offset = (this.listCount - 1) * this.itemPerPage;
    this.orderHistoryArray = [];
    this.getOrderHistory();

  this.initTitleBarParams();
   }

  ngOnInit() {
      }


  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'MY ORDERS',
      showLogo: false,
      showShoppingCart: true,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);

  }

  getOffset() {
    return (this.listCount - 1) * this.itemPerPage;
  }

getOrderHistory() {
  // this.utilService.progressService.isLoading = true;
  this.result = null;

  this.orderHistoryService.getMyOrders(this.sessionCustomer.id, this.itemPerPage, this.offset).subscribe(
    res => {
      
      this.result = res.success;
      if (this.result) {
        this.orderHistory = res.object.orderInfo;
        this.itemCount = res.object.itemCount;
        this.orderHistoryArray.push(this.orderHistory);
        setTimeout(() => {
          if (this.orderHistoryArray.length > 1) {
            const scrollIndex = this.orderHistoryArray.length - 1;
            const orderInfoId = 'orderHistoryArray_' + (this.offset/this.itemPerPage);
            const el = document.getElementById(orderInfoId);
            el.scrollIntoView({behavior: "smooth"});
          }
        }, 150);
      }
    }, err => {
      this.utilService.progressService.isLoading = false;
    });
}

showMoreClick() {

  this.listCount = this.listCount + 1;
  this.offset = (this.listCount - 1) * this.itemPerPage;
  this.getOrderHistory();


}
redirectToOrderInfoDtl(orderId) {
  this.router.navigate(['/my-order-detail'], { queryParams: {'id': orderId} });

}



}
