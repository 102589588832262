import { Injectable } from '@angular/core';
import { StartupService } from '../services/startup.service';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class StoreFinderService {
  currentTimeZoneUrl: string;
  departmentUrl: any;
  nearestShopUrl: string;
  amenityUrl: string;
  regionShopUrl: string;
  itemCategoryUrl: string;
  constructor(private http: HttpClient, private startUp: StartupService) {
    this.regionShopUrl = this.startUp.startupData.apiurl + '/region-shop/get-all';
    this.amenityUrl = this.startUp.startupData.apiurl + '/amenity/get-all';
    this.nearestShopUrl = this.startUp.startupData.apiurl + '/shop/get-nearest';
    this.itemCategoryUrl = this.startUp.startupData.apiurl + '/itemCategory/get-all';
    this.departmentUrl = this.startUp.startupData.apiurl + '/department/get-all';
    this.currentTimeZoneUrl = this.startUp.startupData.apiurl + '/util/get-info';
  }
  /**
     * Fetching regions wchich have our shops  based on
     * available
     */
  getRegionShopData(queryParam): Observable<any> {
    return this.http.post<any>(this.regionShopUrl, queryParam)
      .pipe(
      );
  }
  /**
   * Fetching all amenity data
   */
  getAmenityData(): Observable<any> {
    return this.http.get<any>(this.amenityUrl)
      .pipe(
      );
  }
  getDepartmentData(): Observable<any> {
    return this.http.get<any>(this.departmentUrl)
      .pipe(
      );
  }
  getCurrentTimeZone(): Observable<any> {
    return this.http.get<any>(this.currentTimeZoneUrl)
    .pipe();
  }
  /**
   * @param locationCoordinateData
   * Fetching nearest shop list with in the particular distance
   *  based on current latitude,longitude and radius
   *
   */
  getNearestShops(locationCoordinateData): Observable<any> {
    return this.http.post<any>(this.nearestShopUrl, locationCoordinateData)
      .pipe(

      );
  }
  /**
  * Fetching all item category data
  */
  getItemCategoryData(): Observable<any> {
    return this.http.get<any>(this.itemCategoryUrl)
      .pipe(

      );
  }
}
