import { Component, OnInit } from '@angular/core';
import { UtilService } from '../services/util.service';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from '../settings/settings.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit {

  result: any;
  activationMessage: any;
  accountAccessToken: number;
  constructor(private utilService: UtilService, private route: ActivatedRoute, private _settingsService: SettingsService) {
    this.initTitleBarParams();
    this.accountAccessToken = this.route.snapshot.queryParams['token'];
    this._settingsService.accountactivation(this.accountAccessToken).subscribe(
      res => {
        this.result = res.success;
        if (this.result) {
          this.activationMessage = res.message;
        }
      }
    );

   }

  ngOnInit() {
  }
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'ACCOUNT-ACTIVATION',
      showLogo: false,
      showShoppingCart: false,
      showLogin:false,
      hideHamBurgerMenu: true,
      hideBackButton: true
    };
    this.utilService.setTitleBarParams(this, titleConfig);
  }



}
