import { Injectable } from '@angular/core';

export interface FooterConfig {
  showGuestUserBtn ?: boolean;
  showShopListBar ?: boolean;
  showShopInfoBar ?: boolean;
  showShopFilterBar ?: boolean;
  showDashboardBar ?: boolean;
  showDateSelectionBar ?: boolean;
  showItemListBar ?: boolean;
  showitemDetailAddCartBar ?: boolean;
  showCartBar ?: boolean;
  showUpdateCartItemBar ?: boolean;
  showPaymentSucessBottomBar ?: boolean;
  showOrderDetailsBar ?: boolean;
  showSignUpBar ?: boolean;
  orderConfirmationBar ?: boolean;
  showGuestBar ?: boolean;
  showChangePasswordBar ?: boolean;
  showMyProfileBar ?: boolean;
  showSubmitContactUs ?: boolean;
  showFaqBar ?: boolean;
  showRetryPaymentBar ?: boolean;
  showViewReceiptFooter ?: boolean;
}
@Injectable({
    providedIn: 'root'
  })
export class StatusBarService {
  showGuestUserBtn: boolean;
  showShopListBar: boolean;
  showShopInfoBar: boolean;
  showShopFilterBar: boolean;
  showDashboardBar: boolean;
  showDateSelectionBar: boolean;
  showItemListBar: boolean;
  showitemDetailAddCartBar: boolean;
  showCartBar: Boolean;
  showUpdateCartItemBar: boolean;
  showPaymentSucessBottomBar: boolean;
  showOrderDetailsBar: boolean;
  showSignUpBar: boolean;
  orderConfirmationBar: boolean;
  showGuestBar: boolean;
  showChangePasswordBar: boolean;
  showMyProfileBar: boolean;
  showSubmitContactUs: boolean;
  showFaqBar: boolean;
  parent: any;
  showRetryPaymentBar: boolean;
  showViewReceiptFooter: boolean;

  configure(parent: any, footerSettings: FooterConfig): void {
    this.removeBottomBars();
    this.setBars(footerSettings);
    this.parent = parent;
  }

  private setBars(footerSettings: FooterConfig) {

    Object.assign(this, footerSettings);
  }
  removeBottomBars() {
    this.setBars( {
      showGuestUserBtn : false,
      showShopListBar : false,
      showShopInfoBar : false,
      showShopFilterBar : false,
      showDashboardBar : false,
      showDateSelectionBar : false,
      showItemListBar : false,
      showitemDetailAddCartBar : false,
      showCartBar : false,
      showUpdateCartItemBar : false,
      showPaymentSucessBottomBar : false,
      showOrderDetailsBar : false,
      showSignUpBar: false,
      orderConfirmationBar: false,
      showGuestBar: false,
      showChangePasswordBar: false,
      showMyProfileBar: false,
      showSubmitContactUs: false,
      showFaqBar: false,
      showRetryPaymentBar: false,
      showViewReceiptFooter: false

    });
  }
}
