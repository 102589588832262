import { Component, OnInit } from '@angular/core';
import { TitleConfig } from '../../fw/services/hdr-title.service';
import { UtilService } from '../services/util.service';
import { FooterConfig } from '../../fw/services/status-bar.service';
import { initialMenuItems } from '../app.menu';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(private utilService: UtilService) {
    this.initTitleBarParams();
    this.utilService.setMenuItems(initialMenuItems);
    this.utilService.progressService.isLoading = false;
  }

  ngOnInit() {
  }

  /**Configure title bar and footer bar for settings page */
  initTitleBarParams() {
    this.utilService.fwConfigService.showFooterBar = false;
    this.utilService.resetFooterConfigData(this);
    const titleConfig: TitleConfig = {
      title: 'SETTINGS',
      showLogo: true,
      showShoppingCart: false,
      hideHamBurgerMenu: false,
      hideBackButton: false
    };
    this.utilService.setTitleBarParams(this, titleConfig);
    const footerConfig: FooterConfig = {
      showGuestUserBtn: false,
      showShopListBar: false,
      showShopInfoBar: false,
      showitemDetailAddCartBar: false,
      showUpdateCartItemBar: false,
      showPaymentSucessBottomBar: false,
      showOrderDetailsBar: false,
      showItemListBar: false,
      showCartBar: false,
      showSignUpBar: false,
      orderConfirmationBar: false,
      showGuestBar: false,
      showChangePasswordBar: false,
      showMyProfileBar: false,
      showSubmitContactUs: false,
      showFaqBar: false,
      showRetryPaymentBar: false



    };
    this.utilService.setFooterBarParams(this, footerConfig);
  }
}
