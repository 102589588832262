import { Component, OnInit, Input, Inject, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { SESSION_AMENITY_LIST, SESSION_CUSTOMER_FAVOURITES, SESSION_DEPARTMENT_LIST } from '../app.constants';
import { ShopDetailService } from './shop-detail.service';
import { UtilService } from '../services/util.service';

import { CartService } from '../cart/cart.service';
import { StoreSelectionService } from '../../fw/services/store-selection.service';
import { DateSelectionService } from '../services/date-selection.service';
import { TimeService } from '../services/time-service.service';
@Component({
  selector: 'app-shop-detail',
  templateUrl: './shop-detail.component.html',
  styleUrls: ['./shop-detail.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ShopDetailComponent implements OnInit {
  serverTime: any;
  message: string;
  shopDepartmentList: any[];
  departmentList: any;

  shopWorkingInfo: any;
  private _selectedShop: boolean;
  isFavourite: boolean;
  favouriteShopList: any[];
  shopAmenityList: any[];
  amenityList: any[];
  @Input() shop: any;
  @Input() locationData: any;
  @Output() favouriteClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() shopInfo: EventEmitter<any> = new EventEmitter<any>();
  @Input() isSelectedShop: boolean;
  @Input() isActiveShop: boolean;
  constructor(@Inject(SESSION_STORAGE) private sessionStorage: WebStorageService, private storeSelectionService: StoreSelectionService,
    private _shopDetailService: ShopDetailService, private cartService: CartService, private dateSelectionService: DateSelectionService,
    private timeService: TimeService, private utilService: UtilService) {
  }
  ngOnInit() {
    // this.shopSelection();
    this.shopWorkingInfoChecking();
    // console.log(this.shop);
    this.message = 'Closed';
    this.amenityList = this.sessionStorage.get(SESSION_AMENITY_LIST);
    this.departmentList = this.sessionStorage.get(SESSION_DEPARTMENT_LIST);
    this.favouriteShopList = this.sessionStorage.get(SESSION_CUSTOMER_FAVOURITES);
    this.shopAmenityList = this._shopDetailService.getShopAmenities(this.shop, this.amenityList);
    this.shopDepartmentList = this._shopDetailService.getShopAmenities(this.shop, this.departmentList);
    if (this.utilService.checkValidCustomer() && this.favouriteShopList !== null) {
      const favouriteId = this._shopDetailService.getFavouriteShopId(this.favouriteShopList, this.shop);
      this.isFavourite = (favouriteId.length === 1);
    }

  }
  /*assign shop to the favourite list only if whether the customer is valid*/
  favouriteShopClick() {
    if (this.utilService.checkValidCustomer('Favourite Shop', 'Please login to use this function')) {
      this.isFavourite = !this.isFavourite;
      const param = { shop: this.shop, isFav: this.isFavourite };
      this.favouriteClick.emit(param);
    }
    return false;
  }



  // passing  shop information to another  coponent by emiting an event
  shopDetail() {
    this.shopInfo.emit(this.shop);
  }
  shopWorkingInfoChecking() {
    // // const searchParam = { 'id': this.shop.id, 'from': this._shopDetailService.formatDate(new Date()),
    // // 'to': this._shopDetailService.formatDate(new Date()) };
    // // this.dateSelectionService.getShopHoliday(searchParam).subscribe(
    // const searchParam = { 'id': this.shop.id, 'NumDays': 0 };
    // this.dateSelectionService.getHolidayBasedOnNumDays(searchParam).subscribe(
    //   res => {
    //     const shopHoliday = res.object;
    //     // this.serverTime = new Date(res.serverTime);
    //     this.serverTime = new Date(this.timeService.serverTime);
    //   //  console.log('server time ' + this.serverTime);
    //     this.shopWorkingInfo = this._shopDetailService.getShopHours(this.shop, this.serverTime);
    //     });
    this.serverTime = new Date(this.timeService.serverTime);
    this.shopWorkingInfo = this._shopDetailService.getShopHours(this.shop, this.serverTime);
  }

  
}
